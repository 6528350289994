import { Omit } from "@tm/utils"
import { Component, ComponentClass, ComponentType, createElement } from "react"
import { ILocalizationContext, IWithLocalization } from "./models"
import { LocalizationContext } from "./LocalizationContext"

export type LocalizationProps = {
    localization: IWithLocalization
}

export function withLocalization<P extends LocalizationProps>(component: ComponentType<P>): ComponentClass<Omit<P, keyof LocalizationProps>> {
    return class extends Component<Omit<P, keyof LocalizationProps>> {
        static displayName = `withLocalization(${component.displayName || component.name})`

        renderInnerComponent(localizationContext?: ILocalizationContext) {
            if (!localizationContext) {
                throw "No localization initialized. Make sure your app is wrapped by the <Localized ... /> component."
            }

            const currentProps: any = this.props || {}

            const props: LocalizationProps & P = {
                ...currentProps,
                localization: {
                    ...currentProps.localization,
                    language: localizationContext.language,
                    languageId: localizationContext.languageId,
                    date: localizationContext.date,
                    number: localizationContext.number,
                    currency: localizationContext.currency,
                    translate: localizationContext.translate,
                    translateText: localizationContext.translateText,
                    isTranslationId: localizationContext.isTranslationId,
                },
            }

            return createElement(component, props)
        }

        render() {
            return (
                <LocalizationContext.Consumer>
                    {(localizationContext?: ILocalizationContext) => this.renderInnerComponent(localizationContext)}
                </LocalizationContext.Consumer>
            )
        }
    }
}

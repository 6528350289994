import { Component } from "react"

// Alle handle* und render* Methoden werden an component gebunden
export function bindSpecialReactMethods(component: Component) {
    const comp = component as any

    Object.getOwnPropertyNames(Object.getPrototypeOf(comp)).forEach((key) => {
        if ((key.indexOf("handle") == 0 || key.indexOf("render") == 0) && typeof comp[key] == "function") {
            comp[key] = comp[key].bind(comp)
        }
    })
}

import { Component, ComponentClass, ComponentType, createElement } from "react"
import { SizeContext } from "."
import { ScreenSizeProps } from "./model"

export function withScreenSize<P extends ScreenSizeProps>(component: ComponentType<P>): ComponentClass<Omit<P, keyof ScreenSizeProps>> {
    return class extends Component<Omit<P, keyof ScreenSizeProps>> {
        renderInnerComponent(screenSize?: ScreenSizeProps["screenSize"]) {
            if (!screenSize) {
                console.warn("There is no ScreenSize Provider")
            }

            const currentProps: any = this.props || {}
            const props: P & ScreenSizeProps = {
                ...currentProps,
                screenSize,
            }

            return createElement(component, props)
        }

        render() {
            return (
                <SizeContext.Consumer>{(screenSize?: ScreenSizeProps["screenSize"]) => this.renderInnerComponent(screenSize)}</SizeContext.Consumer>
            )
        }
    }
}

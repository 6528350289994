import { styled } from "@mui/material/styles"
import { MessageBubble, MessageBubbleProps } from "./chatMessageBubble"
import { AttachmantButtonProps, AttachmentButton } from "../../generics/button"
import { StatePill } from "../../generics/statePill"
import { PropsWithChildren } from "react"

const ContentWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: " space-around;",
    alignItems: "center",
    flexWrap: "wrap",
    "> *": {
        margin: theme.spacing(0.5),
    },
}))

const MessageContainer = styled("div")(({ theme }) => ({
    flex: 1,
    padding: `0 0 ${theme.spacing(1)} 0`,
}))

type Props = PropsWithChildren<
    MessageBubbleProps &
        AttachmantButtonProps & {
            accept?: boolean
            acceptText: string
            declineText: string
        }
>

export function MessageOffer(props: Props) {
    return (
        <MessageBubble {...props}>
            {props.children && <MessageContainer>{props.children}</MessageContainer>}
            <ContentWrapper>
                <AttachmentButton {...props} />
                {props.accept !== undefined && (
                    <StatePill text={props.accept ? props.acceptText : props.declineText} state={props.accept ? "accept" : "rejected"} />
                )}
            </ContentWrapper>
        </MessageBubble>
    )
}

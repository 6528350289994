import { AttributeModel } from "."

// CompareWithVehicleRecords
export type CompareWithVehicleRecordsRequest = {
    vehicleId: string
    attributes: Array<AttributeModel>
}

export type CompareWithVehicleRecordsResponse = {
    attributes: Array<AttributeComparisonModel>
}

export type AttributeComparisonModel = {
    attribute: AttributeModel
    recorded: AttributeModel
    visualization: CompareWithVehicleRecordsAttributeVisualization
}

export enum CompareWithVehicleRecordsAttributeVisualization {
    None = 0,
    Collided = 1,
    Suggested = 2,
    Confirmed = 3,
    HiddenCollided = 4,
}

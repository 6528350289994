import { styled } from "@mui/material"
import { ReactNode } from "react"
import { SkeletonChatCard } from "./chatCard"

const ChatStack = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "auto",
    height: "inherit",
    maxHeight: "inherit",
    overflowY: "scroll",
    overflowX: "hidden",
    backgroundColor: "#fafafa",

    "&::-webkit-scrollbar": {
        backgroundColor: "inherit",
        width: "10px",
        borderBottomRightRadius: "5px",
        marginBottom: "10px",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "inherit",
        borderBottomRightRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: "16px",
        border: "2px solid #fff",
        height: "30px",
    },
})

type Props = {
    children: ReactNode | ReactNode[]
    loading?: boolean
    skeletonElements?: number
}

export function ChatList({ children, loading, skeletonElements }: Props) {
    const skeletonChilds = Array(skeletonElements || 10).fill(<SkeletonChatCard />)
    return <ChatStack>{loading ? skeletonChilds : children}</ChatStack>
}

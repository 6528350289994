import { styled } from "@mui/material"
import { FC } from "react"
import { Link, LinkProps as reactRouterLinkProps } from "react-router-dom"

export type LinkProps = reactRouterLinkProps & {
    to: string
}

const StyledLink = styled(Link)<LinkProps>({
    textDecoration: "none",
})

export const RouterLink: FC<LinkProps> = (props) => {
    return <StyledLink to={props.to}>{props.children}</StyledLink>
}

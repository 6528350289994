import { FC, PropsWithChildren } from "react"
import { styled } from "@mui/material"
import { NoteTypes } from "@tm/models"
import { Button, IconButton } from "../../generics/button"
import { Icon } from "../../generics/Icons"
import { NoteTooltip } from "./NotesTooltip"

export type NoteMessage = {
    title: string
    message?: string
    type: NoteTypes
}

type Props = PropsWithChildren<{
    iconOnly?: boolean
    hasNote?: boolean
    articleNotes: NoteMessage[]
    onClick?: () => void
    size?: "small" | "medium" | "large"
    noPadding?: boolean
}>

const NotesIcon = styled(Icon, { shouldForwardProp: (prop) => prop !== "hasNote" })<{ hasNote?: boolean }>(({ hasNote, theme }) => ({
    fill: hasNote ? theme.overwrites?.components?.notesButton?.background || theme.palette.primary.main : "#202020",
}))

const BorderedNoteButton = styled(Button, { shouldForwardProp: (prop) => prop !== "hasNote" })<{
    hasNote?: boolean
}>(({ hasNote, theme }) => ({
    ...(hasNote && {
        [`&::before`]: {
            content: '""',
            background: theme.overwrites?.components?.notesButton?.background || theme.palette.primary.main,
            borderBottomLeftRadius: theme.overwrites?.components?.button?.borderRadius || "3px",
            borderBottomRightRadius: theme.overwrites?.components?.button?.borderRadius || "3px",
            position: "absolute",
            width: "calc(100% + 2px)",
            height: "3px",
            left: "-1px",
            bottom: "-1px",
        },
        "&:hover": {
            svg: {
                fill: theme.palette.common.black,
            },
        },
    }),
}))

export const NotesButton: FC<Props> = (props) => {
    const iconName = props.hasNote ? "notes-added" : "add-note"

    const icon = <NotesIcon hasNote={props.hasNote} name={iconName} />

    if (props.iconOnly) {
        return (
            <NoteTooltip notes={props.articleNotes} onClick={props.onClick}>
                <IconButton size={props.size} sx={props.noPadding ? { padding: "0" } : {}}>
                    {icon}
                </IconButton>
            </NoteTooltip>
        )
    }

    return (
        <NoteTooltip notes={props.articleNotes}>
            <BorderedNoteButton {...props} hasNote={props.hasNote} startIcon={icon}>
                {props.children}
            </BorderedNoteButton>
        </NoteTooltip>
    )
}

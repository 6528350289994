import { useEffect, KeyboardEvent, useState, CSSProperties, createRef } from "react"
import { FixedSizeList, FixedSizeList as List } from "react-window"
import { lighten, MenuItem, styled } from "@mui/material"
import { fixPrecisionSymbol } from "@tm/utils"
import { IconButton } from "../button"
import { Icon } from "../Icons"
import { TextField } from "../textfield"
import { fillArray } from "../../utils/fillArray"

const DEFAULT_MIN = 0
const DEFAULT_MAX = 100
const DEFAULT_ITEMSTOSHOW = 8
const DEFAULT_ITEMSIZE = 30
const DEFAULT_WIDTH = 80

type Props = {
    setSelectedValue: (val: number) => void
    selectedValue: number
    handleClose: (val: number) => void
    maxAmount?: number
    minAmount?: number
    steps?: number
    maxItemsToShow?: number
}

const SmallInput = styled(TextField)(({ theme }) => ({
    width: "80px",
    ".MuiInputBase-input": {
        textAlign: "center",
        fontSize: theme.font?.body1?.fontSize,
        padding: "2px",
        ".Mui-focused": {
            boxShadow: "none",
        },
    },
    ".MuiFilledInput-root.Mui-focused": {
        boxShadow: "none",
        borderColor: "#e2e2e1",
    },
}))

const StyledMenuItems = styled(MenuItem)(({ theme }) => ({
    padding: "2px",
    color: theme.palette.common.black,
    minHeight: "30px",
    opacity: 1,
    justifyContent: theme.overwrites?.components?.dropdown?.menu?.justifyContent || "center",
    "&.Mui-selected": {
        color:
            theme.overwrites?.components?.dropdown?.item?.text?.selected || theme.palette.getContrastText(lighten(theme.palette.primary.main, 0.5)),
        backgroundColor: theme.overwrites?.components?.dropdown?.item?.background?.selected || lighten(theme.palette.primary.main, 0.5),
        "&:hover": {
            backgroundColor: theme.overwrites?.components?.dropdown?.item?.background?.hover || lighten(theme.palette.primary.main, 0.5),
        },
    },
    "&:hover": {
        backgroundColor: theme.overwrites?.components?.dropdown?.item?.background?.hover || lighten(theme.palette.primary.main, 0.5),
        color: theme.overwrites?.components?.dropdown?.item?.text?.hover || theme.palette.getContrastText(lighten(theme.palette.primary.main, 0.5)),
    },
}))

const ScrollWrapper = styled(List)({
    width: "100%",
    height: "inherit",
    maxHeight: "inherit",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
        display: "none",
    },
    "&::-webkit-scrollbar-track": {
        display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
        display: "none",
    },
})

export function NumberSelectList(props: Props) {
    const { setSelectedValue, selectedValue, handleClose, maxAmount, minAmount, steps, maxItemsToShow } = props
    const [inputValue, setInputValue] = useState(fixPrecisionSymbol(selectedValue.toString()) || minAmount?.toString() || DEFAULT_MIN.toString())
    const maxValue = maxAmount || DEFAULT_MAX
    const minValue = minAmount || DEFAULT_MIN
    const values = fillArray(minValue, maxValue, steps || 1)
    const scrollWrapperHeight = Math.min(maxItemsToShow ?? DEFAULT_ITEMSTOSHOW, values.length) * DEFAULT_ITEMSIZE

    const listRef = createRef<FixedSizeList>()

    useEffect(() => {
        if (listRef.current) {
            const index = values.findIndex((element) => element === selectedValue)
            listRef.current.scrollToItem(index, "center")
        }
    }, [listRef, values, selectedValue])

    const handleClickElement = (val: number) => {
        handleClose(val)
    }

    const validate = (s: string) => {
        const rgx = /^[0-9]*[\.|\,]?[0-9]*$/
        return s.match(rgx)
    }

    const onInputChanged = (val: string | number) => {
        if (typeof val === "string" && validate(val)) {
            setInputValue(val)
            const intVal = parseFloat(val.replace(",", "."))
            if (!isNaN(intVal) && intVal > minValue) {
                if (intVal > maxValue) {
                    setSelectedValue(maxValue)
                } else {
                    setSelectedValue(intVal)
                }
            } else {
                setSelectedValue(minValue)
            }
        } else if (typeof val === "number") {
            setInputValue(val.toString())
            setSelectedValue(val)
        }
    }

    const increase = () => {
        if (selectedValue > minValue) {
            onInputChanged(selectedValue - (steps || 1))
        }
    }

    const decrease = () => {
        if (selectedValue < maxValue) {
            onInputChanged(selectedValue + (steps || 1))
        }
    }

    const keyPress = (e: KeyboardEvent) => {
        if (e.key === "ArrowDown") {
            e.preventDefault()
            decrease()
        }
        if (e.key === "ArrowUp") {
            e.preventDefault()
            increase()
        }
    }

    type RowProps = {
        index: number
        style: CSSProperties
    }

    function Row({ index, style }: RowProps) {
        const isSelected = values[index] === Math.trunc(selectedValue)
        return (
            <StyledMenuItems style={style} selected={isSelected} key={`key_${index}`} onClick={() => handleClickElement(values[index])}>
                {fixPrecisionSymbol(values[index].toString())}
            </StyledMenuItems>
        )
    }

    return (
        <>
            <SmallInput
                value={inputValue}
                autoFocus
                onKeyDown={keyPress}
                onChange={(e) => onInputChanged(e.target.value)}
                onFocus={(e) => e.target.select()}
                autoComplete="off"
            />
            <IconButton size="small" onClick={increase} disabled={selectedValue === (props.minAmount || DEFAULT_MIN)}>
                <Icon name="up" />
            </IconButton>

            <ScrollWrapper ref={listRef} height={scrollWrapperHeight} itemCount={values.length} itemSize={DEFAULT_ITEMSIZE} width={DEFAULT_WIDTH}>
                {Row}
            </ScrollWrapper>

            <IconButton size="small" onClick={decrease} disabled={selectedValue === (props.maxAmount || DEFAULT_MAX)}>
                <Icon name="down" />
            </IconButton>
        </>
    )
}

import { styled } from "@mui/material"
import { ComponentType, HTMLAttributes } from "react"

export const ImageViewerContainer = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    paddingTop: "2px",
    alignContent: "start",
})) as ComponentType<HTMLAttributes<HTMLDivElement>>

import { Component, PropsWithChildren, ReactNode } from "react"
import type { Skins } from "../../models"
import { bindMethodsToContext } from "../../helper"
import Icon from "../icon"
import Headline from "../headline"
import { Size } from "../../models/skins"

export type PanelSectionProps = PropsWithChildren<{
    title?: ReactNode
    className?: string
    darkMode?: boolean
    skin?: Skins
    collapsible?: boolean
    initiallyClosed?: boolean
    size?: Size
    id?: string
}>

export type PanelSectionState = {
    opened: boolean
}

export default class PanelSection extends Component<PanelSectionProps, PanelSectionState> {
    constructor(props: PanelSectionProps) {
        super(props)
        bindMethodsToContext(this)

        this.state = {
            opened: !this.props.initiallyClosed ?? true,
        }
    }

    componentDidUpdate(prevProps: PanelSectionProps) {
        if (prevProps.initiallyClosed !== this.props.initiallyClosed) {
            this.setState({ opened: !this.props.initiallyClosed })
        }
    }

    handleClick() {
        this.setState((prevState) => {
            return {
                opened: !prevState.opened,
            }
        })
    }

    render() {
        const { opened } = this.state
        const { size, id } = this.props

        let title = null
        let panelSectionContentClassName = "panel__content"
        let panelSectionClassName = "panel"

        if (this.props.darkMode) {
            panelSectionContentClassName += " panel__content--dark"
        }
        if (this.props.skin) {
            panelSectionContentClassName += ` ${this.props.skin} `
        }
        if (this.props.collapsible && this.props.title) {
            panelSectionClassName += " panel__section--collapsible"

            if (!this.state.opened) {
                panelSectionContentClassName += " panel__content--closed"
            }
        }
        if (this.props.title) {
            title = (
                <Headline className="panel__title" size={size}>
                    <div className="panel__text"> {this.props.title} </div>{" "}
                </Headline>
            )
        }
        if (this.props.title && this.props.collapsible) {
            title = (
                <div className="panel__title" onClick={this.handleClick}>
                    {this.props.title}
                    <Icon className="panel__collapseicon" name={opened ? "up" : "down"} />
                </div>
            )
        }

        return (
            <div id={id} className={`${panelSectionClassName} ${this.props.className ? this.props.className : ""}`}>
                {title}
                <div className={panelSectionContentClassName}>{this.props.children}</div>
            </div>
        )
    }
}

import { Autocomplete, autocompleteClasses, Paper, styled, AutocompleteProps } from "@mui/material"
import { ForwardedRef, Ref, forwardRef } from "react"
import { Icon } from "../../generics/Icons"

const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
    [`.${autocompleteClasses.endAdornment}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        cursor: "pointer",
        top: "0",
        right: "0 !important",
        height: "100%",
        "border-top-right-radius": "3px",
        "border-bottom-right-radius": theme.radius?.default,

        ":hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
        },

        [`.${autocompleteClasses.popupIndicator}`]: {
            height: "100%",
            color: theme.palette.common.white,
            borderRadius: 0,
            right: 0,
            width: theme.spacing(5),

            ":hover": {
                backgroundColor: "inherit",
            },

            ".MuiTouchRipple-root": {
                display: "none",
            },
        },
    },
    [`.${autocompleteClasses.inputRoot}`]: {
        paddingTop: "0px",
    },
})) as typeof Autocomplete

function CustomPaper(props: any) {
    return <Paper elevation={8} style={{ opacity: "1" }} {...props} />
}

function RefAutocompleteField<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = true,
    FreeSolo extends boolean | undefined = undefined,
>(p: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, ref: Ref<HTMLDivElement>) {
    return (
        <StyledAutoComplete<T, Multiple, DisableClearable, FreeSolo>
            ref={ref}
            {...p}
            PaperComponent={CustomPaper}
            popupIcon={<Icon name="down" />}
            disableClearable={(p.disableClearable || true) as any}
        />
    )
}

export const AutocompleteField = forwardRef(RefAutocompleteField) as <
    T,
    Multiple extends boolean | undefined = undefined, // If true, value must be an array and the menu will support multiple selections
    DisableClearable extends boolean | undefined = undefined, // If true, the input can't be cleared.
    FreeSolo extends boolean | undefined = undefined, // If true, the Autocomplete is free solo, meaning that the user input is not bound to provided options.
>(
    p: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof Autocomplete>

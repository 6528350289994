import { forwardRef, PropsWithChildren } from "react"

import { SearchField, SearchFieldProps } from "../searchfield"

type Props = PropsWithChildren<SearchFieldProps>

export const PartnerSearchField = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { children, ...rest } = props

    return (
        <SearchField
            {...rest}
            ref={ref}
            color="highlight"
            autoComplete="off"
            sx={{ width: "100%", maxWidth: "31vw" }}
            alwaysColoredBorder
            InputProps={{
                startAdornment: <>{children && children}</>,
            }}
        />
    )
})

import { useLayoutEffect, useState } from "react"
import { BoxSize } from "."

export function useWindowSize(): BoxSize {
    const getSize = (): BoxSize => [window.innerWidth, window.innerHeight]

    const [size, setSize] = useState<BoxSize>(getSize())

    useLayoutEffect(() => {
        function updateSize() {
            setSize(getSize())
        }

        window.addEventListener("resize", updateSize)
        updateSize()
        return () => window.removeEventListener("resize", updateSize)
    }, [])

    return size
}

import { Component, ComponentType, ReactNode, createElement } from "react"
import { Route, Switch, RouterChildContext, useParams } from "react-router"
import { createStore, applyMiddleware, Action, compose } from "redux"
import thunk from "redux-thunk"
import { Subject } from "rxjs"
import * as PropTypes from "prop-types"
import { compile } from "path-to-regexp"
import type { Action as MorpheusAction, IContext, ComponentConfig, Transmission, PayloadAction } from ".."

import ErrorBoundary from "../error-boundary"
import { createAbsoluteRoute } from "./createAbsoluteRoute"
import { ComponentProps } from "../models/configuration"
import { Dictionary } from "../models/dictionary"
import { executeConditionalPlugin, executePlugin } from "./executePlugin"
import { createMicro } from "./createMicros"
import { BundleMicroContext } from "./BundleMicroContext"

let transitionDeprecationShowed = false

const store_debug_bundles: string[] = JSON.parse(window.localStorage?.getItem("store_debug_bundles") ?? "[]")
const store_debug_names: string[] = JSON.parse(window.localStorage?.getItem("store_debug_names") ?? "[]")

// Erstellt Komponente und dazugehörige Kommunikationswege
export function createComponent(
    context: IContext,
    key: string,
    componentConfig: ComponentConfig,
    moduleActions$: Subject<Transmission>,
    parentKey: string,
    parentRoute: string
): ReactNode {
    const bundle = context.bundles[componentConfig.bundle]
    if (!bundle) {
        console.info(`Bundle '${componentConfig.bundle}' not found.`)
        return
    }
    if (!bundle.components) {
        console.info(`Bundle '${componentConfig.bundle}': No components found.`)
        return
    }
    const component = bundle.components.find((x) => x.name == componentConfig.component)
    if (!component) {
        console.info(`Bundle '${componentConfig.bundle}': Component '${componentConfig.component}' not found.`)
        return
    }

    const componentKey = `${parentKey}/${key}`

    const props: any = {
        ...(componentConfig.props || {}), // Wenn in der Config props übergeben wurden.
        key: componentKey,
    }

    if (componentConfig.moduleProps) {
        props.moduleProps = componentConfig.moduleProps
    }

    let store = componentConfig.storeId ? context.multiStores[componentConfig.storeId] : undefined

    if (!store) {
        const reduce = component.reduce || component.reducer || ((state = {}) => state)

        // To be able to reset a store, we need a wrapper around the reducer
        const extReduce = (state: any, action: Action) => {
            if (typeof action != "function" && action.type == "@@redux/INIT") {
                return reduce(undefined, action)
            }
            return reduce(state, action)
        }

        let composeEnhancers = compose
        if (process.env.NODE_ENV != "production") {
            const devTool = (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            if (store_debug_bundles.includes(bundle.name) || (store_debug_names.includes(component.name) && devTool)) {
                // console.warn(`${componentConfig.bundle}-${component.name} it's in debug mode (Please remove debug:true from bundle definition)`)
                composeEnhancers = devTool({
                    name: componentConfig.storeId ?? `${componentConfig.bundle}_${componentConfig.component}`,
                })
            }
        }

        store = createStore(
            extReduce,
            composeEnhancers(
                applyMiddleware(thunk, () => (next) => (action: MorpheusAction | Function) => {
                    next(action as Action)
                    // Der TransmitFilter kann dazu verwendet werden Actions aus Redux nach außerhalb der Komponente zu senden
                    const transmit = component.transmit || component.transmitFilter
                    if (typeof action != "function" && transmit) {
                        const transmitAction = transmit(action as PayloadAction<any>)
                        if (transmitAction) {
                            moduleActions$.next({
                                module: componentKey,
                                bundle: transmitAction.broadcast ? "*" : componentConfig.bundle,
                                component: componentConfig.component,
                                componentId: componentConfig.id,
                                action: transmitAction,
                                received: [],
                            })
                        }
                    }
                })
            )
        )

        if (componentConfig.storeId) {
            context.multiStores[componentConfig.storeId] = store
        }

        // Actions die aus einer anderen Komponente nach außen gegeben werden, werden hier in Empfang genommen
        const receive = component.receive || component.receiver
        if (receive) {
            moduleActions$.subscribe((transmission) => {
                try {
                    store = store!
                    // if the action is targetted and the current component does not match the action target do nothing
                    if (transmission.action.targetComponentId && transmission.action.targetComponentId != componentConfig.id) {
                        return
                    }

                    // If the transmission already has received from this component do nothing
                    if (transmission.received.some((x) => x == componentKey)) {
                        return
                    }
                    transmission.received.push(componentKey)

                    if (
                        (bundle.name == transmission.bundle &&
                            (component.name != transmission.component ||
                                (componentConfig.id != undefined && transmission.componentId != componentConfig.id))) ||
                        transmission.bundle == "*"
                    ) {
                        receive(transmission.action, store.dispatch, store.getState)
                    }
                    const transition = context.transitions.find(
                        (x) =>
                            (x.transmit.bundle || (x.transmit as any).toolkit) == transmission.bundle &&
                            x.transmit.action == transmission.action.type &&
                            (x.receive.bundle || (x.receive as any).toolkit) == bundle.name
                    )
                    if (transition) {
                        if (!transitionDeprecationShowed && (transition.transmit as any).toolkit) {
                            transitionDeprecationShowed = true
                            console.warn(transition, `Transition: toolkit is deprecated, please use bundle) instead.`)
                        }
                        const action = {
                            type: transition.receive.action,
                            payload: (transition.mapper && transition.mapper(transmission.action.payload)) || transmission.action.payload,
                        }
                        receive(action, store.dispatch, store.getState)
                    }
                } catch (ex) {
                    console.error(`Error while executing method receive of bundle "${bundle.name}", component "${component.name}"`, ex, transmission)
                }
            })
        }
    }

    const route = createAbsoluteRoute(componentConfig.route, parentRoute)

    props.store = context.stores[componentKey] = store

    if (!component.component) {
        return
    }

    const componentElement = createElement(() => {
        const routerParams = useParams()
        return createElement(ComponentBoundary, {
            key: componentKey,
            context,
            component: component.component,
            componentProps: {
                ...props,
                key: componentKey,
                __config: componentConfig,
            },
            routerParams,
        })
    })

    const wrappedComponent = createElement(
        BundleMicroContext.Provider,
        {
            value: createMicro.bind(undefined, context, componentConfig, moduleActions$, componentKey),
        },
        componentElement
    )

    if (componentConfig.route == undefined) {
        return createElement(ErrorBoundary, { key: componentKey, errorComponent: context.errorComponent }, wrappedComponent)
    }

    // Erstelle eine RouteComponent mit der Komponente falls diese nur bei einer bestimmten Route angezeigt wird
    const getRoutedComponent = () => {
        return createElement(ErrorBoundary, { key: componentKey, errorComponent: context.errorComponent }, wrappedComponent)
    }
    context.routes.push(route)

    return createElement(
        Switch,
        { key: componentKey }, // we have to create a switch with key before react-router is doing that without key
        createElement(Route, {
            ...route,
            component: getRoutedComponent,
            key: componentKey,
        })
    )
}

const PATH_TRAVERSE = /~(\d)~/

export type ComponentBoundaryProps = {
    context: IContext
    component: ComponentType<ComponentProps>
    componentProps: ComponentProps
    routerParams: any
}

class ComponentBoundary extends Component<ComponentBoundaryProps> {
    private cancelRender: boolean

    // eslint-disable-next-line
    static contextTypes = {
        // eslint-disable-next-line
        router: PropTypes.object,
        // eslint-disable-next-line
        routes: PropTypes.array,
    }

    constructor(props: ComponentBoundaryProps) {
        super(props)
        this.cancelRender = executeConditionalPlugin(props.context, props, "COMPONENT/SHOULD_RENDER") === false
    }

    // eslint-disable-next-line
    context: RouterChildContext<any> & {
        routes: Array<string>
    }

    UNSAFE_componentWillMount() {
        executePlugin(this.props.context, this.props, "COMPONENT/MOUNT")
    }

    componentWillUnmount() {
        executePlugin(this.props.context, this.props, "COMPONENT/UNMOUNT")
    }

    componentDidUpdate() {
        executePlugin(this.props.context, this.props, "COMPONENT/UPDATE")
    }

    render() {
        if (this.cancelRender) {
            return null
        }
        // rewrite routes and add urls
        let { componentProps } = this.props
        let { routes } = componentProps

        if (routes) {
            const urls: Dictionary<string> = {}

            Object.keys(routes).forEach((key) => {
                if (!Array.isArray(this.context.routes)) {
                    return
                }

                const item = routes![key]
                if (typeof item != "string") {
                    return
                }

                const match = PATH_TRAVERSE.exec(item)
                if (match) {
                    const contextRoutes = this.context.routes!
                    const index = contextRoutes.length - 1 - parseInt(match[1])

                    if (index < 0 || index >= contextRoutes.length) {
                        return
                    }

                    routes = {
                        ...routes,
                        [key]: item.replace(PATH_TRAVERSE, contextRoutes[index]),
                    }
                }

                const url = decodeURIComponent(renderRoute(routes![key], this.props.routerParams)!)
                if (url) {
                    urls[key] = url
                }
            })

            componentProps = { ...componentProps, routes, urls }
        }

        return createElement(this.props.component, componentProps)
    }
}

function renderRoute(path: string, params: Dictionary<string>) {
    if (!path) {
        return
    }
    try {
        const stringParams: Dictionary<string> = {}
        Object.keys(params).forEach((key) => (stringParams[key] = params[key] != null ? String(params[key]) : ""))
        path = path.replace("//", "/")
        return compile(path)(stringParams)
    } catch {}
}

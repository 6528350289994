import { Customer, ModuleConflictType, ModuleTab, Vehicle, WorkTaskStatus } from "@tm/models"
import { encodeUniqueId } from "@tm/utils"

import * as Data from "../data"
import { WorkTaskInfo } from "../model"
import { saveCustomerOrGetId, saveVehicleOrGetId } from "./helpers"
import { WorkTaskProvider } from "./WorkTaskProvider"

/**
 * @returns A promise. If it's resolved the data has been attached or a new WorkTask has been created. If it's rejected, the conflict dialog has been canceled.
 *
 * If you want to open a specific page after everything is attached, use the subPage property in the data parameter.
 */
export async function attachToWorkTask(
    this: WorkTaskProvider,
    data: { customer?: string | Customer; vehicle?: string | Vehicle; subPage?: string },
    allowVehicleChange?: boolean
): Promise<void> {
    const { workTaskInfo } = this.state

    if (!workTaskInfo) {
        return this.createWorkTask(data)
    }
    if (workTaskInfo.statusValue === WorkTaskStatus.Undefined) {
        const conflictingStatus = checkConflictingModules(workTaskInfo, data)
        if (conflictingStatus.conflictedModules.length > 0) {
            await promptConflict(this, data, conflictingStatus.conflictedModules, conflictingStatus.conflictType)
        }

        return this.createWorkTask({ ...data, workTaskId: workTaskInfo.id, skipRedirect: !data.subPage })
    }

    const conflictingStatus = checkConflictingModules(workTaskInfo, data)
    if (conflictingStatus.conflictedModules.length > 0) {
        await promptConflict(this, data, conflictingStatus.conflictedModules, conflictingStatus.conflictType)
    }

    const customerId = await saveCustomerOrGetId(data.customer)

    if (workTaskInfo.customer && workTaskInfo.vehicle) {
        const workTaskInfoCustomer = workTaskInfo.customer

        if (customerId && data.vehicle) {
            // case 1
            if (workTaskInfo.customer.id === customerId) {
                if (!allowVehicleChange && checkVehicleConflict(data.vehicle, workTaskInfo.vehicle)) {
                    await new Promise<void>((resolve, reject) => {
                        this.setState({
                            conflict: {
                                currentWorkTaskId: workTaskInfo.id,
                                data,
                                options: {
                                    showNewWorkTask: false,
                                    showNewCustomerVehicle: false,
                                },
                                onConfirm: async () => {
                                    await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, data.vehicle, workTaskInfoCustomer.id)
                                    resolve()
                                },
                                onCancel: () => {
                                    this.handleChangeDialogCancel()
                                    reject()
                                },
                            },
                        })
                    })
                } else {
                    await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, data.vehicle, workTaskInfoCustomer.id)
                }
            } else {
                return this.createWorkTask(data)
            }
        } else if (customerId) {
            // case 2
            if (workTaskInfo.customer.id === customerId) {
                await this.loadWorkTaskInfo(workTaskInfo.id)
            } else {
                return this.createWorkTask(data)
            }
        } else if (!allowVehicleChange && checkVehicleConflict(data.vehicle, workTaskInfo.vehicle)) {
            // case 3
            const newWorkTaskWasCreated = await new Promise<boolean>((resolve, reject) => {
                this.setState({
                    conflict: {
                        currentWorkTaskId: workTaskInfo.id,
                        data,
                        options: {
                            showNewWorkTask: true,
                            showNewCustomerVehicle: true,
                        },
                        onConfirm: async (newWorkTask, asCustomerVehicle) => {
                            if (newWorkTask) {
                                await this.createWorkTask({
                                    vehicle: data.vehicle,
                                    customer: asCustomerVehicle ? workTaskInfoCustomer.id : undefined,
                                    subPage: data.subPage,
                                })
                            } else if (asCustomerVehicle) {
                                if (data.vehicle && window.userContext.hasTelesales) {
                                    // In Telesales catalogs the customerId of the vehicle should not be considered (see NEXT-15165))
                                    let vehicle: Vehicle

                                    if (typeof data.vehicle === "string") {
                                        vehicle = await Data.loadVehicle(data.vehicle)
                                    } else {
                                        vehicle = data.vehicle
                                    }

                                    await saveVehicleOrGetIdAndAttachVehicle(
                                        this,
                                        workTaskInfo,
                                        { ...vehicle, customerId: undefined },
                                        workTaskInfoCustomer.id
                                    )
                                } else {
                                    await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, data.vehicle, workTaskInfoCustomer.id)
                                }
                            } else {
                                const { vehicleId } = await saveVehicleOrGetId(data.vehicle)
                                if (vehicleId) {
                                    const workTask = await Data.detachCustomerAndReturnNewVersion(workTaskInfo.id, workTaskInfo.version)
                                    const workTask2 = await Data.attachVehicleAndReturnNewVersion(workTaskInfo.id, vehicleId, workTask.version)
                                    await this.loadWorkTaskInfo(workTaskInfo.id, workTask2)
                                }
                            }
                            resolve(newWorkTask)
                        },
                        onCancel: () => {
                            this.handleChangeDialogCancel()
                            reject()
                        },
                    },
                })
            })

            if (newWorkTaskWasCreated) {
                return
            }
        } else {
            await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, data.vehicle, workTaskInfoCustomer.id)
        }
    } else if (workTaskInfo.customer) {
        const workTaskInfoCustomer = workTaskInfo.customer

        if (customerId && data.vehicle) {
            // case 4
            if (workTaskInfo.customer.id === customerId) {
                await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, data.vehicle, customerId)
            } else {
                return this.createWorkTask(data)
            }
        } else if (customerId) {
            // case 5
            if (workTaskInfo.customer.id === customerId) {
                await this.loadWorkTaskInfo(workTaskInfo.id)
            } else {
                return this.createWorkTask(data)
            }
        } else if (data.vehicle) {
            // case 6
            const vehicle = typeof data.vehicle === "string" ? await Data.loadVehicle(data.vehicle) : data.vehicle
            if (vehicle) {
                if (!vehicle.customerId || vehicle.customerId === workTaskInfoCustomer.id) {
                    await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, vehicle, workTaskInfoCustomer.id)
                } else if (window.userContext.hasTelesales) {
                    // In Telesales catalogs the customerId of the vehicle should not be considered (see NEXT-15165))
                    await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, { ...vehicle, customerId: undefined }, workTaskInfoCustomer.id)
                } else {
                    return this.createWorkTask({ ...data, customer: vehicle.customerId })
                }
            }
        } else {
            await this.loadWorkTaskInfo(workTaskInfo.id)
        }
    } else if (workTaskInfo.vehicle) {
        if (customerId && data.vehicle) {
            // case 7
            if (typeof data.vehicle === "string" ? data.vehicle === workTaskInfo.vehicle.id : data.vehicle.id === workTaskInfo.vehicle.id) {
                await saveVehicleOrGetId(data.vehicle, customerId)
                const workTask = await Data.attachCustomerAndReturnNewVersion(workTaskInfo.id, customerId, workTaskInfo.version)
                await this.loadWorkTaskInfo(workTaskInfo.id, workTask)
            } else {
                const newWorkTaskWasCreated = await new Promise<boolean>((resolve, reject) => {
                    this.setState({
                        conflict: {
                            currentWorkTaskId: workTaskInfo.id,
                            data,
                            options: {
                                showNewWorkTask: true,
                                showNewCustomerVehicle: false,
                            },
                            onConfirm: async (newWorkTask) => {
                                if (newWorkTask) {
                                    await this.createWorkTask(data)
                                } else {
                                    const { vehicleId, computedCustomerId } = await saveVehicleOrGetId(data.vehicle, customerId)
                                    if (vehicleId || computedCustomerId) {
                                        const workTask = await Data.attachCustomerAndVehicleAndReturnNewVersion({
                                            workTaskId: workTaskInfo.id,
                                            vehicleId,
                                            customerId: computedCustomerId!, // small hack, typescript doesn't remember (vehicleId || computedCustomerId)
                                            version: workTaskInfo.version,
                                        })
                                        await this.loadWorkTaskInfo(workTaskInfo.id, workTask)
                                    }
                                }
                                resolve(newWorkTask)
                            },
                            onCancel: () => {
                                this.handleChangeDialogCancel()
                                reject()
                            },
                        },
                    })
                })

                if (newWorkTaskWasCreated) {
                    return
                }
            }
        } else if (customerId) {
            // case 8
            if (!workTaskInfo.vehicle.customerId) {
                await Data.attachCustomerToVehicle(workTaskInfo.vehicle.id, customerId)
                const workTask = await Data.attachCustomerAndReturnNewVersion(workTaskInfo.id, customerId, workTaskInfo.version)
                await this.loadWorkTaskInfo(workTaskInfo.id, workTask)
            } else if (workTaskInfo.vehicle.customerId === customerId) {
                const workTask = await Data.attachCustomerAndReturnNewVersion(workTaskInfo.id, customerId, workTaskInfo.version)
                await this.loadWorkTaskInfo(workTaskInfo.id, workTask)
            } else {
                return this.createWorkTask(data)
            }
        } else if (!allowVehicleChange && checkVehicleConflict(data.vehicle, workTaskInfo.vehicle)) {
            // case 9
            const newWorkTaskWasCreated = await new Promise<boolean>((resolve, reject) => {
                this.setState({
                    conflict: {
                        currentWorkTaskId: workTaskInfo.id,
                        data,
                        options: {
                            showNewWorkTask: true,
                            showNewCustomerVehicle: false,
                        },
                        onConfirm: async (newWorkTask) => {
                            if (newWorkTask) {
                                await this.createWorkTask({ vehicle: data.vehicle, subPage: data.subPage })
                            } else {
                                await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, data.vehicle)
                            }
                            resolve(newWorkTask)
                        },
                        onCancel: () => {
                            this.handleChangeDialogCancel()
                            reject()
                        },
                    },
                })
            })

            if (newWorkTaskWasCreated) {
                return
            }
        } else {
            await saveVehicleOrGetIdAndAttachVehicle(this, workTaskInfo, data.vehicle)
        }
    } else {
        // case 10
        const { vehicleId, computedCustomerId } = await saveVehicleOrGetId(data.vehicle, customerId)
        if (vehicleId || computedCustomerId) {
            const workTask = await Data.attachCustomerAndVehicleAndReturnNewVersion({
                workTaskId: workTaskInfo.id,
                vehicleId,
                customerId: computedCustomerId!, // small hack, typescript doesn't remember (vehicleId || computedCustomerId)
                version: workTaskInfo.version,
            })
            await this.loadWorkTaskInfo(workTaskInfo.id, workTask)
        }
    }

    // All code paths that handle subPage themselves should have returned by now
    if (data.subPage) {
        const url = `/${encodeUniqueId(workTaskInfo.id)}${data.subPage.indexOf("/") !== 0 ? "/" : ""}${data.subPage}`
        this.props.history.push(url)
    }
}

function checkVehicleConflict(vehicleOrId?: Vehicle | string, comparison?: Vehicle) {
    if (!vehicleOrId && !!comparison) {
        return true
    }
    if (!comparison || !vehicleOrId) {
        return false
    }
    if (typeof vehicleOrId == "string") {
        return comparison.id != vehicleOrId
    }
    if (vehicleOrId.id != comparison.id) {
        return true
    }
    return false
}

function parseModuleList(workTaskInfo: WorkTaskInfo) {
    try {
        return JSON.parse(sessionStorage.getItem(`${encodeUniqueId(workTaskInfo.id)}_openModules`) ?? "")
    } catch (_) {
        return []
    }
}

function checkConflictingModules(
    workTaskInfo: WorkTaskInfo,
    data: { customer?: string | Customer; vehicle?: string | Vehicle; subPage?: string }
): {
    conflictedModules: ModuleTab[]
    conflictType: ModuleConflictType | undefined
} {
    // If the worktask has both customer and vehicle attached we don't need to handle the first attach conflict
    if (workTaskInfo.customer && workTaskInfo.vehicle) {
        return {
            conflictedModules: [],
            conflictType: undefined,
        }
    }

    let conflictType: ModuleConflictType | undefined
    if (data.customer && !workTaskInfo.customer && data.vehicle && !workTaskInfo.vehicle) {
        conflictType = ModuleConflictType.CustomerAndVehicle
    } else if (data.customer && !workTaskInfo.customer) {
        conflictType = ModuleConflictType.Customer
    } else if (data.vehicle && !workTaskInfo.vehicle) {
        conflictType = ModuleConflictType.Vehicle
    }

    const moduleList: ModuleTab[] = parseModuleList(workTaskInfo)

    if (!conflictType) {
        return {
            conflictedModules: [],
            conflictType: undefined,
        }
    }

    const conflictedModules = moduleList.filter((tab) => {
        if (tab.firstAttachConflictType === undefined) {
            return false
        }

        if (conflictType === ModuleConflictType.CustomerAndVehicle) {
            return (
                tab.firstAttachConflictType === ModuleConflictType.Customer ||
                tab.firstAttachConflictType === ModuleConflictType.Vehicle ||
                tab.firstAttachConflictType === ModuleConflictType.CustomerAndVehicle
            )
        }

        return tab.firstAttachConflictType === conflictType || tab.firstAttachConflictType === ModuleConflictType.CustomerAndVehicle
    })

    return {
        conflictType,
        conflictedModules,
    }
}

async function saveVehicleOrGetIdAndAttachVehicle(
    workTaskProvider: WorkTaskProvider,
    workTaskInfo: WorkTaskInfo,
    vehicle?: string | Vehicle,
    customerId?: string
) {
    const { vehicleId, computedCustomerId } = await saveVehicleOrGetId(vehicle, customerId)
    if (vehicleId) {
        const workTask = await Data.attachCustomerAndVehicleAndReturnNewVersion({
            workTaskId: workTaskInfo.id,
            vehicleId,
            customerId: computedCustomerId !== workTaskInfo.customer?.id ? computedCustomerId : undefined,
            version: workTaskInfo.version,
        })
        return workTaskProvider.loadWorkTaskInfo(workTaskInfo.id, workTask)
    }
}

async function promptConflict(
    workTaskProvider: WorkTaskProvider,
    data: { customer?: string | Customer; vehicle?: string | Vehicle; subPage?: string },
    conflictedModules?: ModuleTab[],
    conflictType?: ModuleConflictType
) {
    const { workTaskInfo } = workTaskProvider.state
    if (!workTaskInfo) {
        return
    }

    return new Promise<boolean>((resolve, reject) => {
        workTaskProvider.setState({
            conflict: {
                currentWorkTaskId: workTaskInfo.id,
                data: {
                    ...data,
                    conflictedTabs: conflictedModules,
                    conflictType,
                },
                options: {
                    showNewWorkTask: false,
                    showNewCustomerVehicle: false,
                },
                onConfirm: async (newWorkTask) => {
                    resolve(newWorkTask)
                },
                onCancel: () => {
                    workTaskProvider.handleChangeDialogCancel()
                    reject()
                },
            },
        })
    })
}

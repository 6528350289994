import { Box, Typography, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { Icon } from "../../../generics/Icons"
import { Tip } from "../../Tip"

const TipIcon = styled(Icon)(({ theme }) => {
    const backgroundColor = theme.palette.grey[300]
    return {
        background: backgroundColor,
        opacity: 1,
        borderRadius: "50%",
        fill: theme.palette.getContrastText(backgroundColor),
    }
})

const ToolTipText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
}))
ToolTipText.defaultProps = {
    variant: "body2",
}

const SampleProductGroupText = styled(Typography)(({ theme }) => ({
    display: "inline-block",
    backgroundColor: theme.palette.grey[200],
    opacity: "unset",
    padding: "0 4px",
    borderRadius: theme.radius?.default,
}))
SampleProductGroupText.defaultProps = {
    variant: "h4",
}

type Props = {
    isSecondLevel?: boolean
}

export function SearchtreeTip(props: Props) {
    const { translateText } = useLocalization()
    const text = translateText(13628).split("{0}")

    return (
        <Tip
            variant="light"
            width={340}
            withoutBorderColor={false}
            className="search-tree-tip"
            text={
                <Box sx={{ mx: "2px", my: "4px" }}>
                    <ToolTipText>
                        {text[0]}
                        <Box
                            component="span"
                            sx={{
                                position: "relative",
                                display: "inline-block",
                                verticalAlign: "bottom",
                            }}
                        >
                            <TipIcon
                                name={!props.isSecondLevel ? "brake-system" : "searchtree-arrow"}
                                width="26px"
                                height="26px"
                                sx={{ padding: "4px", display: "block" }}
                            />
                            {!props.isSecondLevel && (
                                <TipIcon
                                    name="searchtree-arrow"
                                    width="9px"
                                    height="9px"
                                    sx={{
                                        position: "absolute",
                                        right: 4,
                                        bottom: 4,
                                    }}
                                />
                            )}
                        </Box>
                        {text[1]?.trimEnd()}
                        {!props.isSecondLevel && (
                            <>
                                {" "}
                                <SampleProductGroupText>{translateText(13328)}</SampleProductGroupText>
                            </>
                        )}
                        {text[2]}
                    </ToolTipText>
                </Box>
            }
        />
    )
}

import { Stack } from "@mui/material"
import { ErpIconType } from "@tm/models"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { GraduatedPrices } from "../GraduatedPrices"
import { IconAction } from "./IconAction"

export function ErpAreaActions() {
    const articleActions = useDefaultArticleItemState((x) => x.articleActions)
    const isExpanded = useDefaultArticleItemState((x) => x.expanded)
    const articleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos)
    const hasGraduatedPrices = articleErpInfos?.default?.state === "success" && articleErpInfos.default.response.graduatedPrices

    if (!hasGraduatedPrices && !articleActions?.erpArea.length) {
        return null
    }

    return (
        <Stack alignItems="end">
            <GraduatedPrices isExpanded={isExpanded} />
            {articleActions?.erpArea.map((action) => <IconAction key={ErpIconType[action.iconType]} action={action} />)}
        </Stack>
    )
}

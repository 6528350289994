import {
    Children,
    FC,
    forwardRef,
    ForwardRefExoticComponent,
    isValidElement,
    PropsWithChildren,
    ReactElement,
    RefAttributes,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react"
import { Box, Popover, PopoverProps, PopoverActions } from "@mui/material"
import { EasyPopoverContext } from "./context"
import { PopoverActionButton } from "./PopoverActionButton"
import { PopoverContent, PopoverContentProps } from "./PopoverContent"
import { useResizeRef } from "./useResizeRef"

export type EasyPopoverProps = PropsWithChildren<{
    onPopoverClose?: () => void
}> &
    Pick<PopoverProps, "anchorOrigin" | "transformOrigin">

export type EasyPopoverHandles = {
    closePopup: () => void
}

type EasyPopoverComponentType = ForwardRefExoticComponent<EasyPopoverProps & RefAttributes<EasyPopoverHandles>>

const EasyPopoverComponent = forwardRef<EasyPopoverHandles, EasyPopoverProps>(({ onPopoverClose, children, ...rest }, ref) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)
    const popoverActions = useRef<PopoverActions>(null)

    const contentRef = useResizeRef(() => {
        if (popoverActions.current) {
            popoverActions.current.updatePosition()
        }
    })

    const closePopup = () => {
        setAnchor(null)
        onPopoverClose?.()
    }

    const handlePopoverClose = (e: Event) => {
        e.preventDefault()
        e.stopPropagation()
        closePopup()
    }

    useImperativeHandle(ref, () => ({
        closePopup,
    }))

    const actionButton = useMemo(
        () => Children.toArray(children).find((child) => isValidElement(child) && (child as ReactElement).type === PopoverActionButton),
        [children]
    )

    const content = useMemo(() => {
        if (!anchor) {
            return null
        }

        return Children.toArray(children).filter((child) => isValidElement(child) && (child as ReactElement).type !== PopoverActionButton)
    }, [children, anchor])

    return (
        <Box
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <EasyPopoverContext.Provider value={{ anchor, setAnchor }}>
                {actionButton}
                <Popover
                    action={popoverActions}
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    onClose={handlePopoverClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    disableScrollLock
                    {...rest}
                >
                    <Box ref={contentRef}>{content}</Box>
                </Popover>
            </EasyPopoverContext.Provider>
        </Box>
    )
})

export type EasyPopoverType = EasyPopoverComponentType & {
    ActionButton: FC<PropsWithChildren>
    Content: FC<PopoverContentProps>
}

const EasyPopoverWithStatics = EasyPopoverComponent as EasyPopoverType
EasyPopoverWithStatics.ActionButton = PopoverActionButton
EasyPopoverWithStatics.Content = PopoverContent

export { EasyPopoverWithStatics as EasyPopover }

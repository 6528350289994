import { FilledTextFieldProps, styled } from "@mui/material"
import { LocalizationProvider, DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useLocalization } from "@tm/localization"
import { cloneElement, forwardRef, ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { DateUnit, isValidDate } from "@tm/utils"
import enLocale from "date-fns/locale/en-GB"
import { DateValidationError } from "@mui/x-date-pickers/internals"
import { ForwardRefIconProps, Icon } from "../../Icons"
import { TextField, TextFieldProps } from "../../textfield"
import type { LocalizationType } from "../helper/locale"

const StyledTextField = styled(TextField)({
    ".MuiInputAdornment-positionEnd": {
        margin: 0,
    },
    ".inputButtons": {
        padding: 0,
    },
})

export type DatePickerProps = Omit<MuiDatePickerProps<Date, Date>, "renderInput"> & {
    localisation: LocalizationType
    textfieldProps?: TextFieldProps
    size?: "small" | "medium" | "large" | "extralarge"
    renderInputOverride?: ReactElement
    errorMessage?: string
}

function fixUTCDate(date: Date | null): Date | null {
    // The function in the check below ensures that the year can be written completely by the user,
    // otherwise after typing one digit for the year, the date is automatically set to "000" + the typed digit
    if (!date || !isValidDate(date)) {
        return date
    }

    // Because the datepicker only selects a date and not a time,
    // we create a new date with the time set to UTC 00:00:00
    const newDate = new Date()
    newDate.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate())
    newDate.setUTCHours(0, 0, 0, 0)
    return newDate
}

function CalendarIcon(props: ForwardRefIconProps) {
    return <Icon name="calendar" height="24px" {...props} />
}

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
    ({ localisation, renderInputOverride, textfieldProps = { sx: { width: 180 } }, onAccept, onChange, onError, errorMessage, ...rest }, ref) => {
        const { translateText } = useLocalization()
        const [locale, setLocale] = useState(enLocale)
        const [dateError, setDateError] = useState<string>("")

        useEffect(() => {
            const importLocaleFile = async () => {
                // This webpack option stops all of the date-fns files being imported and chunked.
                const localeToSet = await import(
                    /* webpackMode: "lazy", webpackChunkName: "i18n/[request]", webpackExclude: /_lib/ */
                    `date-fns/locale/${localisation.locale}/index.js`
                )
                setLocale(localeToSet.default)
            }

            importLocaleFile()
        }, [localisation.locale])

        const showTodayButton =
            (rest.minDate === undefined || !rest.minDate.isAfter(new Date(), DateUnit.Day)) &&
            (rest.maxDate === undefined || !rest.maxDate.isBefore(new Date(), DateUnit.Day))

        const dateMask = useMemo((): { inputFormat: string; mask: string } => {
            /* use this to see how you should configure the date https://date-fns.org/v2.25.0/docs/format */
            switch (locale.code) {
                case "en-GB": {
                    return {
                        inputFormat: "yyyy/MM/dd",
                        mask: "____/__/__",
                    }
                }

                default: {
                    return {
                        inputFormat: "dd.MM.yyyy",
                        mask: "__.__.____",
                    }
                }
            }
        }, [locale])

        const validateDate = useCallback(
            (date: Date | null) => {
                if (!date || !isValidDate(date)) {
                    return date
                }

                let newDate = date

                if (rest.minDate && date.isBefore(rest.minDate)) {
                    newDate = rest.minDate
                }

                if (rest.maxDate && date.isAfter(rest.maxDate)) {
                    newDate = rest.maxDate
                }

                return newDate
            },
            [rest.maxDate, rest.minDate]
        )

        const handleAccept = useCallback(
            (date: Date | null) => {
                onAccept?.(fixUTCDate(date))
            },
            [onAccept]
        )

        const handleChange = useCallback(
            (date: Date | null) => {
                const validatedDate = validateDate(date)
                onChange(fixUTCDate(validatedDate))
            },
            [onChange, validateDate]
        )

        const handleError = useCallback(
            (reason: DateValidationError, value: Date | null) => {
                setDateError(reason ? translateText(13931) : "")
                onError?.(reason, value)
            },
            [onError, translateText]
        )

        const renderTextfield = useCallback(
            (params: TextFieldProps) => {
                const castedParams = { ...params, size: rest.size } as FilledTextFieldProps

                if (renderInputOverride) {
                    return cloneElement(renderInputOverride as ReactElement, {
                        ...castedParams,
                    })
                }

                return (
                    <StyledTextField
                        errorHandling={[{ errorMessage: dateError && (errorMessage || dateError) }]}
                        {...textfieldProps}
                        {...castedParams}
                    />
                )
            },
            [rest.size, renderInputOverride, errorMessage, dateError, textfieldProps]
        )

        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale} localeText={{ todayButtonLabel: translateText(142) }}>
                <MuiDatePicker
                    inputFormat={dateMask.inputFormat}
                    mask={dateMask.mask}
                    ref={ref}
                    disableMaskedInput={false}
                    renderInput={(params) => renderTextfield(params)}
                    components={{
                        OpenPickerIcon: CalendarIcon,
                        ...(rest.components || {}),
                    }}
                    componentsProps={{
                        actionBar: showTodayButton
                            ? {
                                  actions: ["today"],
                              }
                            : undefined,
                        ...rest.componentsProps,
                    }}
                    views={["year", "month", "day"]}
                    onAccept={onAccept && handleAccept}
                    onChange={handleChange}
                    onError={handleError}
                    {...rest}
                />
            </LocalizationProvider>
        )
    }
)

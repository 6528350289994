import { alpha, Skeleton, styled } from "@mui/material"
import { ReactNode } from "react"

type Props = {
    id?: string
    isNew?: boolean
    selected?: boolean
    title: string
    subline: string
    sublinePrefix?: ReactNode
    lastUpdate: string
    onClick?: (id?: string) => void
}

type CardProps = {
    selected?: boolean
}
const Card = styled("div", {
    shouldForwardProp: (prop) => prop !== "selected",
})<CardProps>(({ theme, selected }) => ({
    display: "flex",
    position: "relative",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "3px",
    minWidth: "200px",
    margin: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    filter: `drop-shadow(0px 1px 5px  ${alpha(selected ? theme.palette.primary.main : theme.palette.common.black, 0.13)})`,
    border: selected ? `1px solid ${theme.palette.primary.main}` : "1px solid transparent",
    cursor: "pointer",
    ":hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.contrastText),
    },
}))

type DotProps = {
    full?: boolean
}

const Dot = styled("div", {
    shouldForwardProp: (prop) => prop !== "full",
})<DotProps>(({ theme, full }) => ({
    position: "absolute",
    left: 0,
    transform: "translateX(15px)",
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    backgroundColor: "white",
    border: `${full ? "5px" : "1px"} solid ${theme.palette.primary.main}`,
}))

const Stack = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px 10px 10px 40px",
})

const SpaceRow = styled("div")({
    display: "flex",
    justifyContent: "space-between",
})

const Row = styled("div")({
    display: "flex",
    fontSize: "12px",
})

const Name = styled("div")(({ theme }) => ({
    ...theme.typography.body1,
    flex: "1",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
}))

const LastUpdate = styled("div")({
    fontSize: "12px",
    whiteSpace: "nowrap",
})

const Subline = styled("div")({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px",
})

export function SkeletonChatCard() {
    return (
        <Card>
            <Skeleton animation="wave" variant="rectangular" width="100%" height={62} sx={{ paddingLeft: "-40px" }} />
        </Card>
    )
}

export function ChatCard({ id, onClick, isNew, selected, title, subline, lastUpdate, sublinePrefix }: Props) {
    const onCardClick = () => {
        if (onClick) {
            onClick(id)
        }
    }
    return (
        <Card selected={selected} onClick={onCardClick} key={id}>
            <Dot full={isNew} />
            <Stack>
                <SpaceRow>
                    <Name>{title}</Name>
                    <LastUpdate>{lastUpdate}</LastUpdate>
                </SpaceRow>
                <Row>
                    {sublinePrefix}
                    <Subline>{subline}</Subline>
                </Row>
            </Stack>
        </Card>
    )
}

import { Box, Link, styled, Typography } from "@tm/components"
import { HotlineInfo } from "@tm/models"

type HotlineProps = HotlineInfo & {
    valueColor?: string
    bold?: boolean
}

export function Hotline(hotlineInfo: HotlineProps) {
    const { headline, email, phone, valueColor, bold } = hotlineInfo

    const StyledTypography = styled(Typography)({
        fontWeight: bold === true ? 800 : undefined,
    })

    return (
        <Box mt={1}>
            {headline && <StyledTypography>{headline}</StyledTypography>}
            {phone && (
                <StyledTypography>
                    T{" "}
                    <StyledTypography display="inline" color={valueColor}>
                        {phone}
                    </StyledTypography>
                </StyledTypography>
            )}
            {email && (
                <StyledTypography>
                    E{" "}
                    <Link display="inline" href={`mailto:${email}`}>
                        <StyledTypography display="inline-block" color={valueColor} sx={{ textDecoration: "underline" }}>
                            {email}
                        </StyledTypography>
                    </Link>
                </StyledTypography>
            )}
        </Box>
    )
}

import { Theme } from "@mui/material"

export const getWetGripClassLabelColor = (value: string, theme: Theme) => {
    switch (value) {
        case "A":
            return "#2b5baa"
        case "B":
            return "#377bc5"
        case "C":
            return "#6cabe6"
        case "D":
            return "#92c1ef"
        case "E":
            return "#c2e5fa"
        default:
            return theme.palette.primary.main
    }
}

import { Component } from "react"
import { bindMethodsToContext, elementId } from "../../helper"
import { Size } from "../../models/skins"
import cx from "bem-classnames"

export type RadioProps = {
    checked: boolean
    value?: any
    group?: string
    label?: string
    id?: string
    disabled?: boolean
    onCheck(value?: any): void
    /** size of the radio button, Size */
    size?: Size
}

const bemConfig = {
    name: "radio",
    modifiers: ["size", "disabled"],
}

export default class Radio extends Component<RadioProps> {
    private elementId = elementId()

    constructor(props: RadioProps) {
        super(props)
        bindMethodsToContext(this)
    }

    static get defaultProps(): Partial<RadioProps> {
        return {
            size: "m",
        }
    }

    handleChange() {
        const { checked, value, onCheck, disabled } = this.props
        if (disabled || checked) {
            return
        }
        onCheck(value)
    }

    render() {
        const { checked, label, group, size, disabled, id } = this.props

        return (
            <span className={cx(bemConfig, { size, disabled: !!disabled })}>
                <input type="radio" id={this.elementId} className="input radio__input" name={group} checked={checked} onChange={this.handleChange} />
                <span className="radio__frame" onClick={this.handleChange}>
                    {checked && <span className="radio__tick" />}
                </span>
                {!!label && (
                    <label id={id && id} className="radio__label" htmlFor={this.elementId}>
                        {label}
                    </label>
                )}
            </span>
        )
    }
}

import { useLocalization } from "@tm/localization"
import { Box, Tooltip } from "@mui/material"
import { EFilterNames, TyreArticleAttribute } from "@tm/models"
import { getIconByGroup, getTitleByGroup } from "@tm/utils"
import { useMemo } from "react"
import { useTyresV2 } from "@tm/context-distribution"
import { Icon } from "../../../../../generics/Icons"
import { Descriptions } from "../../../Descriptions"
import TyresIcons from "./TyresIcons"
import { useTyresItemState } from "../../../ContextProviderStates/TyresItemState"
import TyresIconsV2 from "./TyresIconsV2"

type Props = {
    isCompact?: boolean
}

export function SeasonIconWithTyreEfficiency({ isCompact }: Props) {
    const article = useTyresItemState((x) => x.article)
    const { translateText } = useLocalization()
    const hasTyresV2 = useTyresV2()

    const allAttr = useMemo(() => {
        if (!article.attributes?.length) {
            return []
        }
        return [...article.attributes[0].topAttributes, ...article.attributes[0].articleAttributes]
    }, [article.attributes])

    const renderIcons = () => {
        const fuelEfficiency = allAttr.find((x) => x.group === EFilterNames.fuelEfficiency)
        const wetGripClass = allAttr.find((x) => x.group === EFilterNames.wetGripClass)
        const externalRolling = allAttr.find((x) => x.group === EFilterNames.externalRolling)
        const noiseLevel = allAttr.find((x) => x.group === EFilterNames.noiseLevel)

        const items: (TyreArticleAttribute & { selected?: boolean })[] = []
        if (fuelEfficiency) {
            items.push(fuelEfficiency)
        }
        if (wetGripClass) {
            items.push(wetGripClass)
        }
        if (externalRolling) {
            items.push(externalRolling)
        }

        return hasTyresV2 ? <TyresIconsV2 items={items} noiseLevel={noiseLevel} /> : <TyresIcons items={items} />
    }

    return (
        <>
            {!isCompact && <Descriptions />}
            <Box display="flex" flexDirection="row" gap=".25em">
                {article.productGroup?.season && (
                    <Tooltip title={translateText(getTitleByGroup(article.productGroup.season))}>
                        <Icon name={getIconByGroup(article.productGroup.season)} size="1.5em" sx={{ marginRight: ".2em" }} />
                    </Tooltip>
                )}
                {renderIcons()}
                {isCompact && (
                    <Box sx={{ marginLeft: "0.5em", display: "flex", alignItems: "center" }}>
                        <Descriptions />
                    </Box>
                )}
            </Box>
        </>
    )
}

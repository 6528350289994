import { ExtendSliderUnstyled } from "@mui/base/SliderUnstyled"
import { styled, Slider, SliderTypeMap } from "@mui/material"

export const ZoomSlider = styled(Slider)(() => ({
    "& .MuiSlider-thumb": {
        boxShadow: "none",
        "&:hover, &.Mui-focusVisible, &.Mui-active": {
            boxShadow: "none",
        },
    },
})) as ExtendSliderUnstyled<SliderTypeMap<"span", {}>>

import { channel } from "@tm/models"
import cloneDeep from "clone-deep"
import { encodeUniqueId } from "../../../helpers/id"
import { StoreContext } from "../event-storage"
import { TmaMode } from "../models"
import { ArticleListFilteredEvent } from "./event-handle"
import { compareEvents, isModal } from "./helper"
import { ArticleListFilteredRequest, EventPlugin } from "./models"
import { getCategoryTypeFromUrl } from "../.."

type TmaWindow = Window & typeof globalThis & { tma: { subscribedEvents: Array<ArticleListFilteredRequest> } }
;(window as TmaWindow).tma = { subscribedEvents: [] }

/**
 * @description This class is used as a plugin inside the eventhandler and will replace the reset and send functions. New properties will be added and the ajax request will be delayed and executed
 * @property { ArticleListFilteredEvent } eventHandler  is the origin event handle
 * @property { number } delayTimer the amount of seconds the submit request will be delayed
 * @property { string } currentCategory is used to store and detect the active catogory of the article list
 * @property { Function } OriginalResetEvent the origin reset function
 * @property { Function } OriginalSendEvent the origin send function
 * @property { {[suppliedId: number]: number[]}} productGroupsPerSupplier all productgroups per supplier combinations which occured in one processid
 */
export class EventSubmitDelay extends EventPlugin {
    eventHandler: ArticleListFilteredEvent

    delayTimer = 15

    subscriptions: { unsubscribe: Function; name: string }[] = []

    currentCategory: string

    OriginalResetEvent: Function

    OriginalSendEvent: Function

    productGroupsPerSupplier: { [supplierId: number]: number[] }

    private interval?: number /// delete me, because it's only used for console logs

    private timeoutId?: number = undefined

    constructor() {
        super("submit")
    }

    /**
     * @description initialize some properties, replace send and reset functions
     * @param eventHandler ArticleListFilteredEvent Handle
     * @returns the reference of this object
     */
    init = (eventHandler: ArticleListFilteredEvent) => {
        this.productGroupsPerSupplier = {}
        this.eventHandler = eventHandler

        this.OriginalSendEvent = this.eventHandler.sendEvent.bind(this.eventHandler)
        this.eventHandler.sendEvent = this.send

        this.OriginalResetEvent = this.eventHandler.reset.bind(this.eventHandler)
        this.eventHandler.reset = this.reset
        ;(window as any).setTmaDelay = (seconds: number) => {
            this.delayTimer = seconds
        }

        window.addEventListener("beforeunload", (e) => {
            const _THIS = this
            const requests: { [key: string]: ArticleListFilteredRequest } =
                JSON.parse(sessionStorage.getItem("tma") || `{}`)[_THIS.eventHandler.storage.identifier] || {}
            const keys = Object.keys(requests)
            for (let i = 0; i < keys.length; i++) {
                try {
                    _THIS.submitCompletedEvents(requests[keys[i]])
                } catch (e) {
                    // will occur if there is no authorization available after logout. just catching the reject.
                }
            }
        })
        return this
    }

    /**
     * @description strip the new properties and submit
     * @param request the request params
     */
    classicSend = (request: any) => {
        const { allProductGroupsPerSupplier, eventStatus, ...rest } = request
        this.OriginalSendEvent(rest)
    }

    /**
     * @description will recognize the mdm controlled TmaMode, if delayed the productGroupPerSupplier property will be added
     * @param request request params
     * @returns {Promise<void>}
     */
    send = (request?: ArticleListFilteredRequest): Promise<void> => {
        const requestBody = request

        if (!requestBody) {
            return Promise.reject()
        }

        const requestBodyClone = cloneDeep(requestBody)
        const tmaMode = (window as any).userContext?.parameter?.tmaMode ?? TmaMode.Classic

        if (tmaMode !== TmaMode.LessRequests) {
            this.classicSend(requestBodyClone)

            if (tmaMode !== TmaMode.Combined) {
                return Promise.resolve()
            }
        }

        Object.keys(requestBody.productGroupsPerSupplier).forEach((supplierId: any) => {
            const pgs = requestBody.productGroupsPerSupplier[supplierId]
            const all = (this.productGroupsPerSupplier[supplierId] = this.productGroupsPerSupplier[supplierId] || [])
            if (all.indexOf(pgs) < 0) {
                all.push(pgs)
            }
        })

        this.submit(requestBody)
        return Promise.resolve()
    }

    reset = () => {
        const resetFunc = () => {
            this.productGroupsPerSupplier = {}
            this.OriginalResetEvent()
        }

        // temporärer requestbody prüfen abfrage ob es nicht das gleiche event ist
        if (this.eventHandler.temporaryRequestBody?.eventStatus && this.eventHandler.temporaryRequestBody.eventStatus !== "completed") {
            this.finishEvent().then(resetFunc)
        } else {
            resetFunc()
        }
    }

    /**
     * @description request validation
     * @returns true or false
     */
    isValidRequest = () => {
        return !this.eventHandler.temporaryRequestBody?.articleList
    }

    submit = (rquest: ArticleListFilteredRequest) => {
        if ((window as any).__NEXT_WORKTASKID__) {
            const url = window.location?.pathname
            this.currentCategory = getCategoryTypeFromUrl(url)
            const activeRequest = rquest || this.eventHandler.requestBody
            const processId = activeRequest.searchStep?.processId

            if (activeRequest.eventStatus === "initial") {
                this.submitInitial(activeRequest)
                this.subscriptions = []
            } else if (processId && this.eventHandler.storage.getModified(processId)) {
                this.submitProgress(activeRequest)
            } else if (activeRequest.context && isModal(activeRequest.context.contextId)) {
                this.sendOriginEvent(activeRequest)
            } else {
                console.log(`%c${processId} was not modified`, "color:red", activeRequest)
            }

            if (this.subscriptions.length === 0) {
                this.subscribeCompletedEvents()
                this.subscribeProgressEvents()
            }
        }
    }

    submitInitial = (rquest?: ArticleListFilteredRequest) => {
        const activeRequest = rquest || this.eventHandler.temporaryRequestBody
        if (activeRequest.eventStatus === "initial") {
            if (
                sessionStorage.getItem("storeIdent") === "*" ||
                this.eventHandler.storage.identifier === (sessionStorage.getItem("storeIdent") || "/articles/articleListFiltered")
            ) {
                console.log(`%c${"submitInitial"}`, "color: blue;", activeRequest.eventStatus, activeRequest.searchStep?.processId)
            }
            this.sendOriginEvent(activeRequest)
            this.setEventStatus("progress")
        }
    }

    submitProgress = (rquest?: ArticleListFilteredRequest) => {
        const event = this.eventHandler.temporaryRequestBody || rquest
        if (this.eventHandler.temporaryRequestBody && rquest && JSON.stringify(rquest) === JSON.stringify(this.eventHandler.temporaryRequestBody)) {
            console.log("ERROR ", rquest, "UNEQUAL", this.eventHandler.temporaryRequestBody)
        }
        if (event?.eventStatus === "progress") {
            if (
                sessionStorage.getItem("storeIdent") === "*" ||
                this.eventHandler.storage.identifier === (sessionStorage.getItem("storeIdent") || "/articles/articleListFiltered")
            ) {
                console.log(`%c${"submitProgress"}`, "color: blue;", event.eventStatus, event.searchStep?.processId, event)
            }
            this.timeout(event)
        }
    }

    setEventStatus = (status: "initial" | "progress" | "completed") => {
        if (this.eventHandler.temporaryRequestBody.searchStep) {
            this.eventHandler.temporaryRequestBody.eventStatus = status
            this.eventHandler.saveChanges(this.eventHandler.temporaryRequestBody)
        }
    }

    submitCompletedEvents = (requestBody: ArticleListFilteredRequest) => {
        if (!requestBody) {
            return requestBody
        }

        requestBody.eventStatus = "completed"
        return this.sendOriginEvent(requestBody)
    }

    submitCompletedWhenOutOfContext = (context: StoreContext) => {
        const requestBody: ArticleListFilteredRequest = this.eventHandler.storage.loadContent(context)
        if (!requestBody) {
            return requestBody
        }

        requestBody.eventStatus = "completed"
        this.eventHandler.storage.set(requestBody)

        console.log("submitCompleted", requestBody?.searchStep?.processId)

        return this.sendOriginEvent(requestBody).then(() => {
            if (requestBody.context) {
                if (compareEvents(this.eventHandler.temporaryRequestBody, requestBody)) {
                    requestBody.context && this.eventHandler.storage.delete(requestBody.context)
                    this.eventHandler.resetTemporaryRequest()
                } else {
                    this.eventHandler.storage.delete(requestBody.context)
                    console.log("%cremoved out of context", "color: red")
                }
                requestBody.searchStep && this.eventHandler.storage.removeModifiedState(requestBody.searchStep.processId)
            }
        })
    }

    submitCompleted = () => {
        let { requestBody } = this.eventHandler

        if (!requestBody || !requestBody.articleList || !requestBody.allProductGroupsPerSupplier) {
            requestBody = this.eventHandler.loadRequest()
        }

        this.setEventStatus("completed")
        requestBody.eventStatus = "completed"

        return this.sendOriginEvent(requestBody).then(() => {
            requestBody.context && this.eventHandler.storage.delete(requestBody.context)
        })
    }

    timeout = (rquest: ArticleListFilteredRequest) => {
        this.timeoutId && this.clearTimeout()
        this.interval && window.clearInterval(this.interval)
        if (
            sessionStorage.getItem("storeIdent") === "*" ||
            this.eventHandler.storage.identifier === (sessionStorage.getItem("storeIdent") || "/articles/articleListFiltered")
        ) {
            console.log(`%c${"timeout"}`, "color: blue;", `eventStatus: ${rquest.eventStatus}`, `processId: ${rquest.searchStep?.processId}`)
        }

        this.interval && console.timeEnd("waiting")
        console.time("waiting")
        this.interval = window.setInterval(() => {
            console.timeLog("waiting", rquest.searchStep?.processId)
        }, 2000)
        this.timeoutId = window.setTimeout(() => {
            this.interval && window.clearInterval(this.interval)
            this.interval = undefined
            this.sendOriginEvent(rquest)
            this.eventHandler.saveChanges(rquest)
            console.timeEnd("waiting")
        }, this.delayTimer * 1000)
    }

    sendOriginEvent = (rquest?: ArticleListFilteredRequest) => {
        const requestBody = rquest || this.eventHandler.requestBody
        if (!requestBody) {
            console.log("need a request to submit")
            return
        }
        requestBody.allProductGroupsPerSupplier = rquest?.allProductGroupsPerSupplier || this.productGroupsPerSupplier // do we really need this?
        const processId = requestBody.searchStep?.processId
        if (processId && (requestBody.eventStatus === "completed" || this.eventHandler.storage.getModified(processId))) {
            if (
                sessionStorage.getItem("storeIdent") === "*" ||
                this.eventHandler.storage.identifier === (sessionStorage.getItem("storeIdent") || "/articles/articleListFiltered")
            ) {
                console.log(
                    `%c${requestBody.eventStatus}\r\n${document.location.pathname}\r\n${processId}\r\nevent will be send now\r\n`,
                    "color:green",
                    rquest || requestBody
                )
            }

            return this.OriginalSendEvent(rquest).then(() => {
                const contextId = rquest?.context?.contextId
                if (contextId && isModal(contextId)) {
                    // modal events wont be saved
                    return
                }

                if (requestBody.eventStatus === "initial") {
                    requestBody.eventStatus = "progress" // wird der temporary eventStatus hier auch gesetzt? Unwahrscheinlich. und in den sessionStorage wird es auch nicht geschrieben. da requestBody nicht getzt
                }

                processId && this.eventHandler.storage.setModifiedToFalse(processId)

                if (
                    sessionStorage.getItem("storeIdent") === "*" ||
                    this.eventHandler.storage.identifier === (sessionStorage.getItem("storeIdent") || "/articles/articleListFiltered")
                ) {
                    console.log(
                        "%cajax set modified to false",
                        "color:green",
                        processId ? this.eventHandler.storage.getModified(processId) : "no processid"
                    )
                }
            })
        }
        requestBody.eventStatus === "completed"
            ? console.log(" % calready completed", requestBody.searchStep?.processId, "color:orange")
            : processId
              ? console.log("%cshouldn't send unmodified data", "color:red")
              : console.log("%cCan't send because processId is missing")
    }

    finishEvent = async (context?: StoreContext) => {
        // module close -> if context matches with temporary request context, take that request, if not load an event
        const { temporaryRequestBody } = this.eventHandler
        const storedRequest = context && this.eventHandler.storage.loadContent(context)
        let request: ArticleListFilteredRequest = storedRequest || temporaryRequestBody

        if (compareEvents(storedRequest, temporaryRequestBody)) {
            request = temporaryRequestBody
        }

        const { allProductGroupsPerSupplier, articleList } = request

        if (!articleList || !allProductGroupsPerSupplier) {
            return Promise.resolve()
        }

        this.clearTimeout()
        this.deleteSubscriptions()

        if (request.context) {
            await this.submitCompletedWhenOutOfContext(request.context)
        } else {
            await this.submitCompleted()
        }

        return Promise.resolve()
    }

    clearTimeout = () => {
        this.interval && clearInterval(this.interval)
        this.timeoutId && clearTimeout(this.timeoutId)
    }

    deleteSubscriptions = () => {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
        this.subscriptions = []
    }

    subscribeCompletedEvents = () => {
        const storageContext = { identifier: this.eventHandler.storage.identifier, contextId: this.eventHandler.storage.getContextId() }

        const closeHandler = (params: any) => {
            this.finishEvent(storageContext).catch((e) => {
                // if there was a corrupted event in the queue, delete it from the storage
                this.eventHandler.storage.delete(storageContext)
                this.reset()
            })
        }

        const moduleClosed = {
            name: "MODULE/CLOSED",
            unsubscribe: channel("WORKTASK", (window as any).__NEXT_WORKTASKID__).subscribe("MODULE/CLOSED", closeHandler),
        }

        this.subscriptions.push(moduleClosed)

        this.subscriptions.push({
            name: "worktaskClose",
            unsubscribe: channel("GLOBAL").subscribe("WORKTASK/CLOSED", (content: { ids: string[] }) => {
                const { ids } = content

                if (
                    ids.some((id) => {
                        const uniqueid = encodeUniqueId(id)
                        return storageContext.contextId.includes(id) || storageContext.contextId.includes(uniqueid)
                    })
                ) {
                    closeHandler(ids)
                }
            }),
        })
    }

    subscribeProgressEvents = () => {
        this.subscriptions.push({
            name: "basketArticleAdded",
            unsubscribe: channel("WORKTASK", (window as any).__NEXT_WORKTASKID__).subscribe("BASKET/ARTICLE_ADDED", () => {
                console.log(
                    `%c${"BASKET/ARTICLE_ADDED"}`,
                    "color: blue;",
                    " unsub subscriptions -> submitProgress ",
                    this.eventHandler.requestBody.eventStatus,
                    this.eventHandler.requestBody.searchStep?.processId
                )

                this.submitProgress()
            }),
        })

        this.subscriptions.push({
            name: "categoryChanged",
            unsubscribe: channel("WORKTASK", (window as any).__NEXT_WORKTASKID__).subscribe("PARTS/CATEGORY_CHANGED", (content: any) => {
                console.log(
                    `%c${"PARTS/CATEGORY_CHANGED"}`,
                    "color: yellow;",
                    " unsub subscriptions -> submitProgress ",
                    this.eventHandler.requestBody?.eventStatus,
                    this.eventHandler.requestBody.searchStep?.processId
                )

                if (this.currentCategory !== content.category) {
                    this.submitProgress()
                    this.currentCategory = content.category
                }
            }),
        })
    }
}

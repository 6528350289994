import { clone, getValue, setValue, getFieldErrors, Overwrite } from "@tm/utils"
import { Component, CSSProperties, ReactNode } from "react"
import { createErrorMessage, FormElementProps, TextAutoComplete } from "../../models"
import Icon from "../icon"
import Tooltip from "../tooltip"
import { elementId, bindMethodsToContext } from "../../helper"
import { Button, Loader } from ".."

export type DefaultSearchFieldProps = Overwrite<
    FormElementProps,
    {
        autoComplete?: TextAutoComplete
        value?: string
        pattern?: RegExp
        placeholder?: string
        maxLength?: number
        showClear?: boolean
        showClearTooltip?: string
        showSearch?: boolean
        tooltip?: string
        loading?: boolean
        additionalInputIcons?: ReactNode
        buttonIcon?: string

        onChangeConfirm?(model: any, path?: Array<any>): void
        onChangeReset?(): void
        onInputClick?(): void
    }
>

export type DefaultSearchFieldState = {
    value?: any
    inputValue?: any
    id?: any
    edit?: any
    errors?: Array<any>
    shouldConfirmChange: boolean
}

export default class DefaultSearchField extends Component<DefaultSearchFieldProps, any> {
    private _inputRef: HTMLElement

    private _isMounted = false

    private outerContainerRef: HTMLElement | null

    /**
     *
     */
    constructor(props: DefaultSearchFieldProps) {
        super(props)
        const { value } = props

        this.state = {
            value,
            inputValue: value || "",
            id: elementId(),
            shouldConfirmChange: false,
        }

        bindMethodsToContext(this)
    }

    UNSAFE_componentWillMount() {
        const value = this.getValue(this.props)
        this.checkErrors(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps: DefaultSearchFieldProps) {
        const nextValue = this.getValue(nextProps)
        const value = this.getValue(this.props)

        if (value != nextValue) {
            this.setState({
                value: nextValue,
                inputValue: nextValue,
            })
            this.checkErrors(nextProps)
        }
    }

    componentDidMount() {
        this._isMounted = true
        if (this.props.autoFocus) {
            this.focus()
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.autoFocus) {
            this.focus()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    checkErrors(props: DefaultSearchFieldProps) {
        if (props.modelState && props.path) {
            const errors = getFieldErrors(props.modelState, props.path)
            if (errors) {
                this.setState({
                    errors,
                })
            }
        } else {
            this.setState({
                errors: [],
            })
        }
    }

    getValue(props: DefaultSearchFieldProps): string {
        const value = props.model && props.path ? getValue(props.model, props.path) : props.value
        return value != null ? value.toString() : ""
    }

    setValueToModel(value: any): any {
        const model = clone(this.props.model)
        if (this.props.path) {
            setValue(model, this.props.path, value)
        }
        return model
    }

    handleRef(inputRef: HTMLInputElement) {
        this._inputRef = inputRef
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    handleChange(e: any) {
        let targetValue: string = e.target.value
        const currentValue = this.getValue(this.props)
        const inputValue = targetValue != null ? targetValue : ""

        if (this.props.pattern) {
            const test = this.props.pattern.exec(targetValue)
            if (test == null) {
                return
            }
            targetValue = test[0]
        }

        this.setState({
            value: targetValue,
            inputValue,
            shouldConfirmChange: true,
        })

        if (currentValue == targetValue) {
            this.setState({ shouldConfirmChange: false })
            return
        }

        if (this.props.model && this.props.path) {
            targetValue = this.setValueToModel(targetValue)
        }

        if (this.props.onChange) {
            this.props.onChange(targetValue)
        }
    }

    handleFocus() {
        if (this.props.readonly) {
            return
        }
        this.setState({ edit: true })

        const { onFocus } = this.props
        onFocus && onFocus()
    }

    handleBlur() {
        if (this.props.readonly) {
            return
        }
        this.setState({ edit: false })

        const { onBlur } = this.props
        onBlur && onBlur()
    }

    handleChangeConfirm() {
        if (!this.state.shouldConfirmChange) {
            return
        }
        if (this.props.onChangeConfirm) {
            let { value } = this.state

            if (this.props.model && this.props.path) {
                value = this.setValueToModel(value)
            }

            this.setState({ shouldConfirmChange: false })
            this.props.onChangeConfirm(value)
        }
    }

    handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        const { readonly } = this.props
        if (readonly) {
            return
        }
        switch (e.which) {
            case 13: {
                this.handleChangeConfirm()
                break
            }
            case 27: {
                this.handleClear()
                break
            }
        }
    }

    handleClear(ev?: React.SyntheticEvent<HTMLButtonElement>) {
        ev && ev.preventDefault()
        const e = {
            target: {
                value: "",
            },
        }
        this.focus()
        this.handleChange(e)
        if (this.props.onChangeReset) {
            this.props.onChangeReset()
        }
    }

    focus() {
        if (!this._isMounted || this.props.readonly) {
            return
        }
        if (!this.state.edit) {
            this.setState({ edit: true })
        }
        setTimeout(() => {
            this._inputRef && this._inputRef.focus()
        }, 0)
    }

    render() {
        const {
            autoComplete,
            label,
            className,
            readonly,
            placeholder,
            maxLength,
            showClear,
            disabled,
            tooltip,
            showSearch,
            showClearTooltip,
            loading,
            buttonIcon,
            onInputClick,
        } = this.props
        const hasErrors = this.state.errors != null && this.state.errors.length > 0
        let elClassName = "input input--textfield has-icons"

        elClassName += className ? ` ${className}` : ""
        elClassName += hasErrors ? " has-error" : ""
        elClassName += readonly ? " readonly" : ""
        elClassName += this.state.edit ? " is-active" : ""
        elClassName += this.props.size ? ` input--${this.props.size}` : ""

        const inputClassName = "input__field"

        const labelElement = label ? (
            <label className="input__label" htmlFor={this.state.id}>
                {label}
            </label>
        ) : (
            false
        )
        const tabIndex = readonly ? 0 : this.props.tabIndex

        return (
            <div className={elClassName} ref={this.handleOuterRef}>
                <div className="input__inner">
                    {labelElement}
                    <input
                        autoComplete={autoComplete}
                        className={inputClassName}
                        type="text"
                        placeholder={placeholder}
                        value={this.state.inputValue}
                        ref={this.handleRef}
                        onChange={this.handleChange}
                        onKeyUp={this.handleKeyUp}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        readOnly={!!readonly}
                        tabIndex={tabIndex}
                        disabled={!!disabled}
                        id={this.state.id}
                        maxLength={maxLength}
                        onClick={() => {
                            onInputClick && onInputClick()
                        }}
                    />

                    <div className="input__icons">
                        <Loader visible={!!loading} />
                        {showClear && !readonly ? (
                            <Tooltip content={showClearTooltip || undefined} position="bottom">
                                <Button
                                    className="partner-close-button"
                                    layout={["ghost"]}
                                    onClick={this.handleClear}
                                    icon="close"
                                    style={this.getVisibility()}
                                />
                            </Tooltip>
                        ) : null}
                        {showSearch ? (
                            <Button
                                className="partner-search-button"
                                layout={["ghost"]}
                                disabled={!!disabled}
                                onClick={this.handleChangeConfirm}
                                icon={buttonIcon ?? "search"}
                            />
                        ) : null}
                        {tooltip ? (
                            <div className="input__tooltip">
                                <Tooltip content={tooltip} position="bottom">
                                    <Icon name="info" />
                                </Tooltip>
                            </div>
                        ) : null}
                        {this.props.additionalInputIcons}
                    </div>

                    {hasErrors && this.outerContainerRef && createErrorMessage(this.state.errors, this.outerContainerRef, "bottom")}
                </div>
            </div>
        )
    }

    handleOuterRef = (ref: HTMLElement | null) => {
        this.outerContainerRef = ref
    }

    getVisibility = (): CSSProperties => {
        return { display: this.state.inputValue ? "unset" : "none" }
    }
}

import { useMemo } from "react"
import { Stack, styled } from "@mui/material"
import { ArticleAttributeParams } from "@tm/models"
import { Typography } from "../../../generics/typographie"
import { Divider } from "../../../generics/divider"
import { AttributeValue } from "./AttributeValue"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

const AttributeLabel = styled(Typography, { shouldForwardProp: (prop: string) => !["isHighlighted", "isCompact"].includes(prop) })<{
    isCompact?: boolean
    isHighlighted?: boolean
}>(({ theme, isHighlighted, isCompact }) => ({
    fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
    whiteSpace: "nowrap",
    padding: isCompact ? "3px 0" : 0,
    height: "100%",
    border: "1px solid transparent",
    borderRadius: theme.radius?.default ?? 3,
    flex: isCompact ? "0 1 auto" : "0 0 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: 3,
    ...(isHighlighted && {
        paddingLeft: 4,
        paddingRight: 4,
        borderColor: theme.palette.highlight.main,
        backgroundColor: theme.palette.highlight.light,
        fontWeight: 600,
    }),
}))
AttributeLabel.defaultProps = { variant: "label" }

type AttributeItemElementProps = {
    hasBackground?: boolean
    isHighlighted?: boolean
    isCompact?: boolean
}

const AttributeItemElement = styled(Stack, {
    shouldForwardProp: (prop: string) => !["hasBackground", "isHighlighted", "isCompact"].includes(prop),
})<AttributeItemElementProps>(({ theme, hasBackground, isHighlighted, isCompact }) => ({
    padding: "0 4px",
    flexWrap: isCompact ? "nowrap" : "wrap",
    maxWidth: "100%",
    alignItems: "baseline",
    alignContent: "center",
    whiteSpace: "nowrap",
    flex: isCompact ? "none" : "0 0 auto",
    minWidth: 0,
    flexDirection: "row",

    ...(isHighlighted && {
        "> div": {
            backgroundColor: theme.palette.highlight.light,
        },
    }),
    ...(hasBackground && {
        backgroundColor: "#f0f0f0",
    }),
}))

AttributeItemElement.defaultProps = {
    direction: "row",
    spacing: "6px",
}

export type AttributeItemProps = {
    hasBackground?: boolean
    hideDivider?: boolean
    hide?: boolean
    attribute: ArticleAttributeParams
    onClickAttributeValue?(id: number, value?: string): void
    dividerInFront?: boolean
}

export function AttributeItem({
    hasBackground,
    hideDivider,
    attribute: { values, label },
    onClickAttributeValue,
    hide,
    dividerInFront,
}: AttributeItemProps) {
    const isHighlighted = values?.length === 1 && values.some((x) => !x.value && x.isHighlighted)
    const isCompactView = useDefaultArticleItemState((x) => !x.expanded)

    if (hide) {
        return null
    }

    return (
        <>
            {!hideDivider && dividerInFront && <Divider orientation="vertical" flexItem />}
            <AttributeItemElement hasBackground={hasBackground} isHighlighted={isHighlighted} isCompact={isCompactView}>
                {label && (
                    <AttributeLabel isHighlighted={isHighlighted} isCompact={isCompactView}>
                        {label}
                    </AttributeLabel>
                )}
                {values
                    ?.filter((x) => !!x.value)
                    .map((value) => <AttributeValue key={`${value.id}_${value.value}`} valueData={value} onClick={onClickAttributeValue} />)}
                {!hideDivider && !dividerInFront && <Divider orientation="vertical" flexItem />}
            </AttributeItemElement>
        </>
    )
}

import { channel } from "@tm/models"
import Bowser from "bowser"

declare let window: Window & { navigator: Navigator & { msSaveOrOpenBlob: Function } }

export function openStreamPdf(stream: Array<number>, translateText: (id: number) => string) {
    openPdfHandler(stream as any, translateText)
}

export function openBase64Pdf(pdf: string, translateText: (id: number) => string) {
    const byteArray = Uint8Array.from(
        window
            .atob(pdf)
            .split("")
            .map((char) => char.charCodeAt(0))
    )
    const file = new Blob([byteArray], { type: "application/pdf" })

    openPdfHandler(file, translateText)
}

function openPdfHandler(file: Blob | MediaSource, translateText: (id: number) => string) {
    let blocked = false

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        blocked = !window.navigator.msSaveOrOpenBlob(file, "Document.pdf")
    } else {
        const fileURL = URL.createObjectURL(file)
        const popup = window.open(fileURL, "_blank")

        blocked = !popup || popup?.closed || typeof popup?.closed === "undefined"
    }

    if (!blocked) {
        return
    }

    let textId
    const browser = Bowser.getParser(window.navigator.userAgent)

    switch (browser.getBrowserName()) {
        case "Firefox":
            textId = 12836
            break

        case "Chrome":
        default:
            textId = 12831
            break
    }

    channel("APP").publish("TOAST_MESSAGE/SHOW", {
        message: translateText(textId),
        icon: "alert",
        skin: "warning",
        closeDelay: 120000,
    })
}

import { ArticleActionButton, ErpIconType } from "@tm/models"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"
import { IconAction } from "./IconAction"
import { sendTmaEvent } from "./ActionsTmaHelper"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

type ButtonActionProps = {
    action: ArticleActionButton
    hideText?: boolean
}

export function ButtonAction({ action, hideText }: ButtonActionProps) {
    const { tooltip, text, handler, icon, isHighlight, iconType } = action
    const isExpanded = useDefaultArticleItemState((x) => x.expanded)
    const handleClickEventWithTma = (event: React.MouseEvent<HTMLElement>) => {
        handler(event)
        sendTmaEvent(ErpIconType[iconType])
    }

    const isButton = !hideText && isExpanded

    let content = isButton ? (
        <Button
            onClick={handleClickEventWithTma}
            startIcon={<Icon name={icon} color={isHighlight ? "primary" : undefined} />}
            size="small"
            variant="outlined"
            color={isHighlight ? "primary" : undefined}
        >
            {text}
        </Button>
    ) : (
        <IconAction key={ErpIconType[action.iconType]} action={{ ...action, displayType: "ICON", tooltip: action.text }} />
    )

    if (tooltip) {
        content = <Tooltip title={tooltip}>{content}</Tooltip>
    }

    return content
}

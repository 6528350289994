import { clone, getValue, setValue, getFieldErrors, ButtonKeyDefinition, Overwrite } from "@tm/utils"
import { ChangeEvent, Component, SyntheticEvent } from "react"
import { FormElementState, createErrorMessage, FormElementProps } from "../../models"
import { Icon, Tooltip } from ".."
import { bindMethodsToContext, elementId } from "../../helper"

export type Props = Overwrite<
    FormElementProps,
    {
        value?: number | null
        placeholder?: string
        minimum?: number
        maximum?: number
        stepSize?: number
        /** Deprecated */
        floatPoint?: boolean
        showClear?: boolean
        showButtons?: boolean
        showDropDown?: boolean
        selectValueOnFocus?: boolean
        /**
         * set to nullable if you want to allow an empty field
         */
        nullable?: boolean
        /**
         * show all decimal places eg: instead of "3,10" => "3,1" show "3,10"
         */
        enforceDecimalDigits?: boolean
        /**
         * if enforceDecimalDigits == true then you can enforce single ints to be displayed as double zero float
         * eg: 1 => 1,00
         */
        enforceZeroOnlyDecimalDigits?: boolean
        onChangeConfirm?(model: any, path?: Array<any>): void
        onChangeReset?(model: any, path?: Array<any>): void
        onKeyDown?(keyevent: React.KeyboardEvent<HTMLInputElement>): void
    }
>

export type State = FormElementState & {
    parsedValue: number | null
    inputValue?: string
    dropDownOffset: number
    edit?: boolean
}

export default class NumberField extends Component<Props, State> {
    private inputRef: HTMLInputElement | null = null

    private tooltipRef: Tooltip | null = null

    private outerContainerRef: HTMLElement | null

    static get defaultProps(): Partial<Props> {
        return {
            stepSize: 1,
        }
    }

    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)

        const value = this.getPropsValue(this.props)
        const formattedInputValue = this.replaceSeperator(value)
        const inputValue = props.enforceDecimalDigits ? this.enforceDecimalDigits(formattedInputValue) : formattedInputValue
        // inputValue = !props.enforceDecimalDigits && props.enforceDoubleZeroDecimalDigits ? this.attachDoubleZero(formattedInputValue) : formattedInputValue // i dont think we need a seperated enforceDecimalDigits handling

        this.state = {
            id: elementId(),
            parsedValue: value,
            inputValue,
            dropDownOffset: 0,
            errors: this.getErrors(this.props),
        }

        if (this.props.floatPoint) {
            console.warn("NumberField: the property 'floatPoint' is deprecated, please use the property stepSize with a float value instead.")
        }

        this.adjustValue = this.adjustValue.bind(this)
        this.getPrecision = this.getPrecision.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const nextValue = this.getPropsValue(nextProps)

        if (nextValue != this.state.parsedValue) {
            let inputValue = this.replaceSeperator(nextValue)

            if (nextProps.enforceDecimalDigits) {
                inputValue = this.enforceDecimalDigits(inputValue)
            }

            this.setState({
                parsedValue: nextValue,
                inputValue,
                errors: this.getErrors(nextProps),
            })
        }
    }

    componentDidMount() {
        this.props.autoFocus && this.focus()
    }

    private getErrors(props: Props): Array<string> | undefined {
        const { modelState, path } = props

        if (modelState && path) {
            return getFieldErrors(modelState, path)
        }
    }

    private getPropsValue(props: Props): number | null {
        const { value, model, path, minimum, maximum, nullable } = props
        const propsValue: number | undefined = model && path ? getValue(model, path) : value

        if (nullable && propsValue == null) {
            return null
        }
        if (propsValue != null) {
            return this.adjustValue(propsValue)
        }
        if (minimum != null) {
            return minimum
        }
        if (maximum != null && maximum < 0) {
            return maximum
        }

        return 0
    }

    adjustValue(value: number) {
        const { stepSize } = this.props
        if (!stepSize) {
            return value
        }
        const precision = this.getPrecision(stepSize)
        const fixedValue = parseFloat(value.toFixed(precision))
        const rest = Math.abs(parseFloat((fixedValue % stepSize).toFixed(precision))) // Negative Values supported

        let adjustedValue = fixedValue

        if (rest != 0) {
            if (rest < stepSize / 2) {
                adjustedValue = fixedValue - rest
            } else {
                adjustedValue = fixedValue - rest + stepSize
            }
        }

        adjustedValue = this.adjustMinMaxValue(adjustedValue)

        return adjustedValue
    }

    getPrecision(value: number) {
        const tmpValue = value.toString()
        if (tmpValue.indexOf(".") >= 0) {
            const splittedValue = tmpValue.split(".")
            const valueDecimals = splittedValue.last()
            return valueDecimals ? valueDecimals.length : 0
        }

        return 0
    }

    adjustMinMaxValue(value: string | number) {
        const { maximum, minimum } = this.props

        let fittedValue = typeof value == "string" ? parseFloat(value) : value

        if (maximum != undefined && fittedValue > maximum) {
            fittedValue = maximum
        }

        if (minimum != undefined && fittedValue < minimum) {
            fittedValue = minimum
        }

        return fittedValue
    }

    private replaceSeperator(value: number | string | null): string {
        if (value == null) {
            return ""
        }

        return value.toString().replace(".", ",")
    }

    private parseValue(value: string): number | null {
        const stepSize = this.props.stepSize as number // typescript fix: stepSize gets assigned a default value and so could not be undefined
        const number = parseFloat(value)
        if (isNaN(number)) {
            return null
        }

        return number
    }

    private setValueToModel(value: number | null): any {
        const { model, path } = this.props
        const clonedModel = clone(model)

        if (path) {
            setValue(clonedModel, path, value)
        }

        return clonedModel
    }

    private handleInputRef(ref: HTMLInputElement) {
        this.inputRef = ref

        const { onRef } = this.props
        onRef && onRef(ref)
    }

    private handleTooltipRef(ref: Tooltip | null) {
        this.tooltipRef = ref
    }

    private handleChange(e: ChangeEvent<HTMLInputElement>) {
        if (this.props.readonly) {
            return
        }

        const match = e.target.value.match(/[-+]?[\d]*[.,]?[\d]*/g)
        const inputValue: string = match ? match.first() || "" : ""

        let parsedValue = this.parseValue(inputValue.replace(",", "."))
        parsedValue = parsedValue != null ? this.adjustValue(parsedValue) : this.state.parsedValue

        this.setState({
            parsedValue,
            inputValue: this.replaceSeperator(inputValue),
            dropDownOffset: 0,
        })

        const { onChange, model, path } = this.props
        if (onChange) {
            if (model && path) {
                onChange(this.setValueToModel(parsedValue), path)
            } else {
                onChange(parsedValue)
            }
        }

        this.tooltipRef && this.tooltipRef.hide()
    }

    private handleChangeConfirm(value: number | null) {
        if (this.props.readonly) {
            return
        }

        const { model, path, onChangeConfirm, enforceDecimalDigits } = this.props

        if (onChangeConfirm) {
            if (model && path) {
                onChangeConfirm(this.setValueToModel(value), path)
            } else {
                onChangeConfirm(value)
            }
        }
        const inputValue = value && enforceDecimalDigits ? this.enforceDecimalDigits(value) : (value != null ? value : "").toString()

        this.setState({
            parsedValue: value,
            inputValue,
            dropDownOffset: 0,
        })
    }

    private handleChangeReset(value: number | null) {
        if (this.props.readonly) {
            return
        }

        const { model, path, onChangeReset } = this.props

        if (onChangeReset) {
            if (model && path) {
                onChangeReset(model, path)
            } else {
                onChangeReset(value)
            }
        }
    }

    private handleFocus(e: React.FocusEvent<HTMLInputElement>) {
        if (this.props.readonly) {
            return
        }

        this.setState({ edit: true })

        const { onFocus, selectValueOnFocus } = this.props
        onFocus && onFocus()

        if (selectValueOnFocus) {
            e.currentTarget.select()
        }
    }

    private handleBlur() {
        const { inputValue, parsedValue } = this.state
        let displayValue: string | null = null
        let returnValue: number | null = parsedValue

        if (inputValue == "" && this.props.nullable) {
            displayValue = inputValue
            returnValue = null
        } else {
            displayValue = this.replaceSeperator(parsedValue)
        }

        this.setState({
            edit: false,
            inputValue: displayValue,
        })

        this.handleChangeConfirm(returnValue)
    }

    private handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (this.props.readonly) {
            return
        }

        switch (e.key) {
            case ButtonKeyDefinition.Tab: {
                this.handleBlur()
                this.tooltipRef && this.tooltipRef.hide()
                break
            }
            case ButtonKeyDefinition.Escape: {
                const parsedValue = this.getPropsValue(this.props)

                this.setState({
                    parsedValue,
                    inputValue: this.replaceSeperator(parsedValue),
                })

                this.handleChangeReset(parsedValue)
                this.tooltipRef && this.tooltipRef.hide()

                break
            }
            case ButtonKeyDefinition.Enter: {
                this.handleBlur()
                this.tooltipRef && this.tooltipRef.hide()
                break
            }
        }

        const { onKeyDown } = this.props
        onKeyDown && onKeyDown(e)
    }

    private handleStepChange(direction: "increment" | "decrement") {
        if (this.props.readonly) {
            return
        }

        // const { minimum, maximum, model, path, onChangeConfirm } = this.props
        const stepSize = this.props.stepSize as number // typescript fix: stepSize gets assigned a default value and so could not be undefined
        let { parsedValue } = this.state

        parsedValue = (parsedValue || 0).add(direction == "increment" ? stepSize : -stepSize)
        parsedValue = this.adjustValue(parsedValue)

        this.setState({
            parsedValue,
            inputValue: this.replaceSeperator(parsedValue),
        })

        this.handleChangeConfirm(parsedValue)
    }

    private handleIncrement(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        this.handleStepChange("increment")
    }

    private handleDecrement(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        this.handleStepChange("decrement")
    }

    private handleClear(ev?: SyntheticEvent<HTMLButtonElement>) {
        ev && ev.preventDefault()
        const e = {
            target: {
                value: "",
            },
        }

        this.handleChange(e as ChangeEvent<HTMLInputElement>)
    }

    private handlePageDropDownValues(direction: "increment" | "decrement", prevDisabled: boolean, nextDisabled: boolean) {
        if ((direction == "decrement" && prevDisabled) || (direction == "increment" && nextDisabled)) {
            return
        }

        const stepSize = this.props.stepSize as number // typescript fix: stepSize gets assigned a default value and so could not be undefined

        this.setState((prevState) => {
            return {
                dropDownOffset: prevState.dropDownOffset + (direction == "increment" ? stepSize : -stepSize),
            }
        })
    }

    focus() {
        this.tooltipRef && this.tooltipRef.show()

        if (this.inputRef) {
            // reset the value so the value will be changed by the setState after focus
            // and the cursor will be at the end of input
            const { value } = this.inputRef
            this.inputRef.value = ""
            this.inputRef.value = value
            setTimeout(() => {
                this.inputRef && this.inputRef.focus()
            }, 0)
        }
    }

    private renderDropDownValue(value: number, idx?: number) {
        return (
            <li
                key={idx}
                className="input--numberfield__value"
                onClick={() => {
                    this.handleChangeConfirm(value)
                    this.tooltipRef && this.tooltipRef.hide()
                }}
            >
                {value}
            </li>
        )
    }

    // TODO: fix this - handleBlur calls handleChangeConfirm, due to dropdown is not part of the input. So dropdown click handleChangeConfirm won't be executed
    private renderDropDown() {
        const { minimum, maximum } = this.props
        const stepSize = this.props.stepSize as number // typescript fix: stepSize gets assigned a default value and so could not be undefined
        const { parsedValue, dropDownOffset } = this.state
        const values: Array<number> = []

        for (let i = 1; i <= 5; i++) {
            const value = (parsedValue || 0) + dropDownOffset + stepSize * i
            if (maximum != null && value > maximum) {
                break
            }
            values.push(value)
        }

        const itemClassName = "input--numberfield__value"
        const itemDisabledClassName = "input--numberfield__value--disabled"

        const firstValue = values.first()
        const lastValue = values.first()

        const prevDisabled = !!(minimum != null && firstValue && firstValue < minimum + stepSize)
        const nextDisabled = !!((maximum != null && lastValue && lastValue + stepSize > maximum) || !values.length)

        return (
            <ul
                className="input--numberfield__values"
                onWheel={(e) => {
                    e.preventDefault()
                    this.handlePageDropDownValues(e.deltaY > 0 ? "increment" : "decrement", prevDisabled, nextDisabled)
                }}
            >
                <li
                    className={`${itemClassName} ${prevDisabled ? itemDisabledClassName : ""}`}
                    onClick={this.handlePageDropDownValues.bind(this, "decrement", prevDisabled, nextDisabled)}
                >
                    <Icon name="up" />
                </li>
                {values.map(this.renderDropDownValue)}
                <li
                    className={`${itemClassName} ${nextDisabled ? itemDisabledClassName : ""}`}
                    onClick={this.handlePageDropDownValues.bind(this, "increment", prevDisabled, nextDisabled)}
                >
                    <Icon name="down" />
                </li>
            </ul>
        )
    }

    private renderContent() {
        const { readonly, showClear, disabled, placeholder, label, showButtons, showDropDown, enforceDecimalDigits } = this.props
        const { inputValue, id, errors } = this.state

        const labelElement = label ? (
            <label className="input__label" htmlFor={id}>
                {label}
            </label>
        ) : (
            false
        )
        const tabIndex = readonly ? 0 : this.props.tabIndex

        return (
            <div className="input__inner">
                {labelElement}
                <input
                    className="input__field"
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    ref={this.handleInputRef}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    readOnly={!!readonly}
                    tabIndex={tabIndex}
                    disabled={!!disabled}
                    id={id}
                />

                <div className="input__icons">
                    {showClear && !readonly && (
                        <button className="btn btn--ghost" onClick={this.handleClear}>
                            <Icon name="close" />
                        </button>
                    )}
                    {showButtons && (
                        <button className="btn btn--ghost" disabled={readonly} onClick={this.handleDecrement}>
                            <Icon name="minus" />
                        </button>
                    )}
                    {showButtons && (
                        <button className="btn btn--ghost" disabled={readonly} onClick={this.handleIncrement}>
                            <Icon name="plus" />
                        </button>
                    )}
                </div>
                {!!errors && !!errors.length && this.outerContainerRef ? createErrorMessage(errors, this.outerContainerRef, "bottom") : null}
            </div>
        )
    }

    enforceDecimalDigits = (inputValue: number | string) => {
        const { enforceZeroOnlyDecimalDigits } = this.props
        const stringValue = typeof inputValue != "string" ? inputValue.toString() : inputValue
        const value = enforceZeroOnlyDecimalDigits ? this.attachDoubleZero(stringValue) : stringValue
        return /[\.,](\d)$/.test(value) ? `${value}0` : value
    }

    attachDoubleZero(value: string) {
        return /[\.,]/.test(value) ? value : `${value},00`
    }

    render() {
        const { readonly, showButtons, floatingLabel, showDropDown } = this.props
        const { errors, edit, value } = this.state

        let className = "input input--textfield input--numberfield "
        className += floatingLabel ? "input--floating-label " : ""
        className += readonly ? "readonly " : ""
        className += edit ? "is-active " : ""
        className += showButtons ? "has-buttons " : ""
        className += value != "" ? "has-value " : ""
        className += !!errors && !!errors.length ? "has-error " : ""
        className += this.props.className || ""

        const { layout } = this.props

        layout &&
            layout.forEach((element) => {
                if (element == "dropshadow") {
                    className += ` has-${element}`
                } else {
                    className += ` input--${element}`
                }
            })

        if (showDropDown && !readonly) {
            return (
                <div className={className} ref={this.handleOuterRef}>
                    <Tooltip ref={this.handleTooltipRef} content={this.renderDropDown()} event="click" style="light">
                        {this.renderContent()}
                    </Tooltip>
                </div>
            )
        }

        return (
            <div className={className} ref={this.handleOuterRef}>
                {this.renderContent()}
            </div>
        )
    }

    handleOuterRef = (ref: HTMLElement | null) => {
        this.outerContainerRef = ref
    }
}

import { bindSpecialReactMethods, Overwrite, Omit } from "@tm/utils"
import { Component, createRef, ReactNode, RefObject } from "react"
import { SearchFieldProps, InputGroup, SuggestionField, Button } from ".."
import { InputGroupSizes } from "../inputgroup"
import { Size } from "../../models/skins"
import { ExternalTooltip } from "../search-field/external-hint"
import { SearchFieldConfig } from "../search-field"
import { ControlsConfig } from "../../configuration"

export type Props<TSuggestion> = Overwrite<
    Omit<SearchFieldProps, keyof { model: any; path: any }>,
    {
        value: string // value can just be a string, model and path are not available

        loading?: boolean
        suggestions: Array<TSuggestion>
        requestSuggestions(value: string): void

        onChange(value: string): void // overwrite to only support value
        onChangeConfirm(value: string): void // overwrite to only support value
        onSuggestionSelect?(suggestion: TSuggestion): void

        renderTableColumns?(): Array<JSX.Element>

        additionalInputIcons?: ReactNode
        appendix?: JSX.Element | Array<JSX.Element>
        dropdownPrefix?: ReactNode

        maxHeight?: number
        suggestDelay?: number
        modifier?: "active" | "highlight"

        handleSearchButtonClick: () => void
        extraButton?: JSX.Element
        size?: InputGroupSizes
        suggestBoxSize?: Size
        buttonTitle?: string
        enableLeadingTrim?: boolean
        minCharactersToSuggest?: number
        ignoreConfig?: Array<keyof SearchFieldConfig>
        forceShowTooltipOnHover?: boolean
        placeholder?: string
        buttonIcon?: string
    }
>

export type State = {
    isFocused: boolean
    props: SearchFieldConfig
}

export default class SuggestionFieldButtonGroup<TSuggestion> extends Component<Props<TSuggestion>, State> {
    suggestionFieldRef: RefObject<SuggestionField<any>>

    constructor(props: Props<TSuggestion>) {
        super(props)
        bindSpecialReactMethods(this)
        this.suggestionFieldRef = createRef()
        this.state = {
            isFocused: false,
            props: ControlsConfig.get<SearchFieldConfig>("SearchField"),
        }
    }

    handleFocus(e?: React.FocusEvent) {
        this.setState({ isFocused: true })
        this.props.onFocus && this.props.onFocus(e)
    }

    handleBlur(e?: React.FocusEvent) {
        this.setState({ isFocused: false })
        this.props.onBlur && this.props.onBlur(e)
    }

    focus(loadSuggestions?: boolean) {
        if (this.suggestionFieldRef && this.suggestionFieldRef.current) {
            this.suggestionFieldRef.current.focus(loadSuggestions)
        }
    }

    render() {
        const { handleSearchButtonClick, extraButton, size, suggestBoxSize, buttonTitle, tooltip, buttonIcon, ...otherProps } = this.props
        const { isFocused } = this.state

        let className = isFocused ? "is-active" : ""
        className += size ? `${className != "" ? " " : ""}inputgroup--${size}` : ""

        const modifier: undefined | "active" | "highlight" = this.props.modifier
            ? this.props.modifier != "highlight"
                ? this.props.modifier
                : "highlight"
            : isFocused
              ? "active"
              : undefined
        const showTooltip = this.state.props.showHintAsTooltip ? tooltip : this.props.forceShowTooltipOnHover ? tooltip : undefined

        return (
            <>
                <InputGroup className={className}>
                    <SuggestionField
                        {...otherProps}
                        tooltip={this.props.suggestions.length > 0 ? tooltip : showTooltip}
                        ref={this.suggestionFieldRef}
                        modifier={modifier}
                        size={suggestBoxSize}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        ignoreConfig={this.props.ignoreConfig || ["showHintAsTooltip"]}
                    />
                    <Button
                        icon={buttonIcon ?? "search"}
                        size="l"
                        scaleIcon
                        onClick={handleSearchButtonClick}
                        title={buttonTitle}
                        skin={modifier == "highlight" ? "highlight" : undefined}
                        disabled={!!this.props.disabled}
                    />
                    {extraButton || <></>}
                </InputGroup>
                {!this.props.forceShowTooltipOnHover && !this.state.props.showHintAsTooltip && <ExternalTooltip tooltip={tooltip} />}
            </>
        )
    }
}

import { Box, Menu, MenuItem, Stack, styled, Tooltip } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ArticleActionButton, ArticleActionIcon, ErpIconType, type WatchList as WatchListType } from "@tm/models"
import { useEffect, useState } from "react"
import { Checkbox } from "../../generics/Checkbox"
import { Icon } from "../../generics/Icons"
import { LoadingContainer } from "../../generics/LoadingContainer"
import { Button } from "../../generics/button"
import { Divider } from "../../generics/divider"
import { TextField } from "../../generics/textfield"
import { Typography } from "../../generics/typographie"
import { ButtonAction } from "./Actions/ButtonAction"
import { IconAction } from "./Actions/IconAction"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"

const CustomMenuEntry = styled(MenuItem)({
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "6px",
})

const SINGLEWATCHLISTNAME = "Watchlist"

type WatchlistProps = {
    hideText?: boolean
}

export function Watchlist(props: WatchlistProps) {
    const watchList = useDefaultArticleItemState((x) => x.watchList)
    if (!watchList) {
        return null
    }
    return <WatchlistComponent watchList={watchList} {...props} />
}

function WatchlistComponent({ watchList, hideText }: WatchlistProps & { watchList: WatchListType }) {
    const {
        isInWatchList,
        watchLists: initialWatchList,
        toggleWatchList,
        createWatchList,
        isToggling,
        isLoading,
        isCreating,
        createOrAddWatchlist,
        singleWatchlist,
    } = watchList
    const { translateText } = useLocalization()
    const [creationMode, setCreationMode] = useState(false)
    const [newListName, setNewListName] = useState("")
    const [watchLists, setWatchLists] = useState(initialWatchList ?? [])
    const { highlightWatchlistButton } = useDefaultArticleItemState((x) => x.options)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
        if (singleWatchlist) {
            createOrAddWatchlist(SINGLEWATCHLISTNAME)
        } else {
            event && setAnchorEl(event.currentTarget)
        }
    }
    const handleClose = () => {
        setCreationMode(false)
        setAnchorEl(null)
    }

    const createWatchlist = () => {
        createWatchList(newListName)
        setNewListName("")
        setCreationMode(false)
    }

    useEffect(() => {
        // Only set the watchlist when we have the new watchlist available
        if (initialWatchList && initialWatchList?.length > 0) {
            setWatchLists(initialWatchList)
        }
    }, [initialWatchList])

    const action = {
        iconType: ErpIconType.Watchlist,
        displayType: hideText ? "ICON" : "BUTTON",
        icon: isInWatchList ? "filled-star" : "vote-star",
        sort: -1,
        tooltip: translateText(1330),
        text: translateText(13789),
        isHighlight: highlightWatchlistButton,
        handler: handleClick,
    }

    return (
        <>
            {hideText ? <IconAction action={action as ArticleActionIcon} /> : <ButtonAction action={action as ArticleActionButton} />}
            <Menu
                sx={{ maxHeight: "600px" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <LoadingContainer isLoading={isToggling || isLoading || isCreating}>
                    {watchLists.map((listEntry) => {
                        return (
                            <CustomMenuEntry key={listEntry.id} onClick={() => toggleWatchList(listEntry.id)} disabled={listEntry.disabled}>
                                {listEntry.hasErrors ? (
                                    <Tooltip title={translateText(1261)}>
                                        <Icon name="danger" color="error" size="extrasmall" sx={{ px: "10px" }} />
                                    </Tooltip>
                                ) : (
                                    <Checkbox size="small" checked={listEntry.selected} />
                                )}
                                {listEntry.name}
                            </CustomMenuEntry>
                        )
                    })}
                </LoadingContainer>
                <Divider />
                {creationMode ? (
                    <MenuItem disableRipple onKeyDown={(e) => e.stopPropagation()}>
                        <Stack direction="row" spacing="4px">
                            <TextField
                                disabled={isCreating}
                                sx={{ marginTop: 0 }}
                                onChange={(e) => setNewListName(e.currentTarget.value)}
                                value={newListName}
                            />
                            <Button disabled={isCreating} onClick={handleClose} startIcon={<Icon name="cancel-x" />} />
                            <Button disabled={isCreating} onClick={createWatchlist} startIcon={<Icon name="check" />} color="success" />
                        </Stack>
                    </MenuItem>
                ) : (
                    <MenuItem>
                        <Box onClick={() => setCreationMode(true)} display="flex">
                            <Icon name="plus" sx={{ marginRight: "4px" }} /> <Typography>{translateText(1242)}</Typography>
                        </Box>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

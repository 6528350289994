export type GetCatMemoVKNRequest = {
    manufacturerId?: number
    modelSeriesId?: number
    tecdoc?: number
    engineCode?: string
}

// TODO: create the model
export type GetCatMemoVKNResponse = {
    memoForManufacturer: GetMemoVKN_Out_V2
    memoForModel: GetMemoVKN_Out_V2
    memoForTecdoc: GetMemoVKN_Out_V2
    memoForEngineCode: GetMemoVKN_Out_V2
}
export type GetMemoVKN_Out_V2 = {
    refs: CAT_VKN_V2[]
    mainItems: Memo_V1[]
    bMCodeRelevant: boolean
    motor: MotorCode_V1
}
export type CAT_VKN_V2 = {
    infID: number
    vknArt: string
    bAlarm: boolean
    bLog: boolean
    bBasket: boolean
    bMainInfo: boolean
    kTypNr: number[]
    kHerNr: number[]
    kModNr: number[]
    motoren: MotorCode_V1[]
    qStart: QStart_V2[]
    parts: VKNPart_V1[]
    repairs: VKNRepair_V1[]
    dienste: VKNDienst_V1[]
    progContexts: string[]
}
export type MotorCode_V1 = {
    motNr: number
    herNr: number
    mCode: string
}
export type QStart_V2 = {
    treeID: number
    quickstartID: number
    bezeichnung: string
    genArtNr: number[]
}
export type VKNPart_V1 = {
    einspNr: number
    eArtNr: string
    genartNr: number
    einspBez: string
    genartBez: string
    existMemos: boolean
    existAccessories: boolean
}
export type VKNRepair_V1 = {
    awSystemNr: number
    repCode: string
    nr: number
    bez: string
    genarts: string
}
export type VKNDienst_V1 = {
    dLSystemNr: number
    nr: string
    bez: string
}
export type Memo_V1 = {
    id: number
    type: string
    code_Path: string
    txt: string
    richText: string
    bLog: boolean
    bBasket: boolean
    bAlarm: boolean
    bLinked: boolean
    bChanged: boolean
    eType: EMemoType
}

export enum EMemoType {
    Unknown = 0,
    Note = 1,
    PDF = 2,
    HTML = 3,
    GIF = 4,
    PNG = 5,
    JPG = 6,
    BMP = 7,
}

export type CheckHasMemoRequest = {
    manufacturerName: string
    modelId: number
    engineCode?: string
}

export type CheckHasMemoResponse = {
    hasMemoInfo: boolean
    hasTechnicalNotes: boolean
}
export type GetMemoTechnicalInfoRequest = {
    modelId: number
    engineCode: string
}

export type GetMemoInfoRequest = {
    manufacturerName: string
}

export type GetMemoInfoResponse = {
    repairInstructions: RepairCategory[]
}

export type GetMemoTechnicalInfoResponse = {
    technicalNotes: TechnicalNote[]
}

export type TechnicalNote = {
    id: string
    title: string
    link: string
    memoStr: string
}

export type RepairCategory = {
    id: number
    title: string
    instructions: RepairInstruction[]
}

export type RepairInstruction = {
    title: string
    link: string
}

// Important notes
export type GetMemoImportantNoteRequest = {
    manufacturerName?: string
}
export type Label = {
    memoStr: string
    id: string
}
export type GetMemoImportantNoteResponse = {
    label: Label
    id: string
    type: string
    priority: string
    level: string
}

import { Box, Collapse, LinearProgress, Stack, styled } from "@mui/material"
import { memo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Icon } from "../../generics/Icons"
import { Tooltip } from "../../generics/tooltip"
import { IconButton } from "../../generics/button"
import { Tab, TabPanel, Tabs } from "../Tabs"
import { Typography } from "../../generics/typographie"
import { CustomersAlsoBoughtButton } from "./CustomersAlsoBoughtButton"

const Container = styled(Box)({
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    padding: "10px 5px 5px 20px",
    marginLeft: 16,
})

export const RecommendedArticle = memo(() => {
    const { translateText } = useLocalization()
    const [activeNavIdentifier, setActiveNavIdentifier] = useState<string>("CustomersAlsoBought")
    const crossSelling = useDefaultArticleItemState((x) => x.crossSelling)

    const ListComponent = crossSelling?.ListComponent

    function handleNavSelect(_: unknown, nextNavIdentifier: string) {
        setActiveNavIdentifier(nextNavIdentifier)
    }

    function renderArticlesChain() {
        const RecommendedArticlesChain = crossSelling?.RecommendedArticlesChain
        return !!RecommendedArticlesChain && <RecommendedArticlesChain />
    }

    function renderTabButtons() {
        return (
            <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "-4px" }}>
                <Tabs size="small" value={activeNavIdentifier} onChange={handleNavSelect} sx={{ "& .MuiTabs-indicator": { borderRadius: "3px" } }}>
                    <Tab sx={{ borderRadius: "3px" }} value="CustomersAlsoBought" label={translateText(13838)} />
                </Tabs>
                <Tooltip title={translateText(70)}>
                    <IconButton onClick={crossSelling?.onClose} sx={{ padding: "0 0.5em" }}>
                        <Icon name="close" />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    function renderTabContent() {
        return (
            <TabPanel value="CustomersAlsoBought" index={activeNavIdentifier}>
                <Stack>
                    <Typography ml={0.5} mb={1} variant="body2">
                        {translateText(13837)}
                    </Typography>
                    {!!crossSelling?.displayButton && (
                        <CustomersAlsoBoughtButton productGroup={crossSelling?.productGroup} onProductGroupClick={crossSelling.onProductGroupClick} />
                    )}
                    {!crossSelling?.displayButton && ListComponent && <ListComponent listId="recommended-articles" listType="recommended-articles" />}
                    <Icon
                        name="poweredByTM"
                        sx={{ width: "180px", height: "20px", alignSelf: "end", opacity: "0.5", marginTop: "-6px" }}
                        viewBox="0 0 180 20"
                    />
                </Stack>
            </TabPanel>
        )
    }

    return (
        <Collapse in={crossSelling?.isEnabled}>
            <Container>
                {/* only render linearProgress if its loading, if its only hidden we have multiply not displayed animations and on the articlelist and a memory leak */}
                {crossSelling?.isLoading && <LinearProgress />}
                {renderTabButtons()}
                {renderArticlesChain()}
                {renderTabContent()}
            </Container>
        </Collapse>
    )
})

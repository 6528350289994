import { memo } from "react"
import { Box, Fade, Grid, Stack, styled } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { AdditionalInformations } from "../../AdditionalInformations"
import { ArticleNumbers } from "../../ArticleNumbers"
import { Attributes } from "../../Attributes"
import { Descriptions } from "../../Descriptions"
import { DefaultArticleErpContainer } from "../../ErpContainer"
import { Image } from "../../Image"
import { Notifications } from "../../Notifications"
import { References } from "../../References"
import { Supplier } from "../../Supplier"
import { Transactions } from "../../Transactions"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { BonusSystem } from "../../BonusSystem"
import { Icon } from "../../../../generics/Icons"
import { ExpandIconButton, GridItem } from "./ArticleItemComponents"
import { DmsArticleInformation } from "../../Dms/DmsArticleInformation"
import AlreadyOrderedArticle from "../../Vouchers/AlreadyOrderedArticle"
import { Watchlist } from "../../Watchlist"
import { Tooltip } from "../../../../generics/tooltip"
import { useArticleItemState } from "../../ContextProviderStates"
import { SeasonIconWithTyreEfficiency } from "../Tyres/components/SeasonIconWithTyreEfficiency"
import { InformationAreaActions } from "../../Actions/InformationAreaActions"
import { ImageAreaActions } from "../../Actions/ImageAreaActions"

export const StyledGridItem = styled(GridItem)({
    width: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
})

export const DefaultExpandedArticleContentComponent = memo(() => {
    const toggleExpand = useDefaultArticleItemState((x) => x.toggleExpand)
    const dmsInformation = useDefaultArticleItemState((x) => x.dmsArticleInfo)
    const articleOrdered = useDefaultArticleItemState((x) => x.alreadyOrdered)
    const showReplaceButton = useDefaultArticleItemState((x) => x.showReplaceButton)
    const { hasWatchList, highlightWatchlistButton, hideCostEstimationButton, hideAddToBasketButton } = useDefaultArticleItemState((x) => x.options)
    const { translateText } = useLocalization()
    const isTyres = useArticleItemState((x) => x.type === "Tyres")
    const isPartToReplace = hideAddToBasketButton && hideCostEstimationButton && !showReplaceButton

    const handleExpand = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListExpandArticle, true)
        toggleExpand()
    }

    return (
        <Grid container direction="row" wrap="nowrap" alignItems="start" className="expandedArticleItem">
            {/* Expand Icon */}
            <StyledGridItem>
                {/* Article Image, Supplier */}
                <Grid item>
                    <Stack alignItems="center" gap="2px">
                        <Image />
                        <Supplier />
                        <BonusSystem />
                        <ImageAreaActions />
                    </Stack>
                </Grid>

                {/* Expand Icon */}
                <Grid item alignSelf="start">
                    <Tooltip title={translateText(13862)}>
                        <ExpandIconButton onClick={handleExpand} hoverEffect disableRipple>
                            <Icon name="minus" />
                        </ExpandIconButton>
                    </Tooltip>
                </Grid>
            </StyledGridItem>

            <Stack flex="1">
                <Stack display="flex" direction="row" padding={1} alignItems="flex-start">
                    <Stack flex={1} gap={isTyres ? 0.4 : 0}>
                        <ArticleNumbers />
                        {!isTyres && <Descriptions />}
                        {isTyres && <SeasonIconWithTyreEfficiency />}
                    </Stack>
                    <InformationAreaActions />
                </Stack>
                <Stack display="grid" gridTemplateColumns="1fr auto" mt={1}>
                    {/* Article Infos */}
                    <GridItem item xs px={1} pb={1} overflow="hidden">
                        <Stack spacing={1}>
                            <Attributes />

                            <Fade in unmountOnExit mountOnEnter appear={false}>
                                <Box>
                                    <References />
                                    <AdditionalInformations />
                                    <Notifications />
                                </Box>
                            </Fade>

                            {articleOrdered && <AlreadyOrderedArticle />}
                            <DmsArticleInformation dmsArticleInfo={dmsInformation} />
                        </Stack>
                    </GridItem>

                    {/* Actions, ERP Infos */}
                    <Grid item justifyContent="end" px={1.5} pb={1} minWidth="258px" alignSelf={isPartToReplace ? "stretch" : "auto"}>
                        <Stack spacing={2} sx={{ flexGrow: "1", height: "100%", justifyContent: "space-between" }}>
                            {hasWatchList && highlightWatchlistButton ? (
                                <Stack direction="row" justifyContent="end">
                                    <Watchlist />
                                </Stack>
                            ) : (
                                <>
                                    <Transactions />
                                    <DefaultArticleErpContainer />
                                </>
                            )}
                        </Stack>
                    </Grid>
                </Stack>
            </Stack>
        </Grid>
    )
})

import { IErpRequest, OrderOptions, SendOrderOptions } from "."
import {
    PriceType,
    AvailabilityStatus,
    Memo,
    ItemTour,
    DispatchCostsType,
    WarehouseDispatchType,
    CatalogContext,
    LinkedItemsRole,
    CostsType,
} from ".."
import { OeArticleOrigin } from "../article/oe"

export type ErpInformationResponse = {
    items?: ErpInformation[]
    hasErrors?: boolean
    errorText?: string
    showAlternatives?: boolean
    showGraduatedPrices?: boolean
    showIsReplacementPart?: boolean
    showBonusInformation?: boolean
    preOrderActions?: PreOrderActions
    distributorId?: number
}

export type GetErpInfosRequest = {
    items: ErpInformationRequestItem[]
    distributorId?: number
    telesalesCustomerNo?: string
    foundBySearchTerm?: string
}

export type ErpInformationRequest = IErpRequest & {
    items: Array<ErpInformationRequestItem>
    catalogContext?: CatalogContext
    orderOptions?: SendOrderOptions
    warehouseOrderOptions?: Array<SendOrderOptions>
    foundBySearchTerm?: string
    maxItemsPerRequest?: number
}

export type ErpInformationRequestItem = {
    itemId: string
    wholesalerArticleNumber?: string
    dataSupplierArticleNumber?: string
    dataSupplierId?: number
    dataSupplierName?: string
    productGroupId?: number
    productGroupName?: string
    articleDescription?: string
    quantityValue: number
    warehouseId?: string
    vehicleId?: string
    selectedShipmentModeId?: string // used only in the detail page. getDetailedErpInfo
    foundBySearchTerm?: string // TODO Basket V2: Remove?
    linkedItems?: Array<ErpInformationRequestItem>
    warehouses?: Array<Warehouse>
    oeArticleOrigin?: OeArticleOrigin
    externalBasketItemId?: string
}

export type ErpInformation = {
    articles?: Array<ErpInformation>
    itemId: string
    wholesalerArticleNumber: string
    dataSupplierArticleNumber: string
    dataSupplierId: number
    prices?: Array<ErpPrice>
    availability: Availability
    quantity?: Quantity
    additionalInformation?: string
    additionalInformationExtended?: string
    hasAlternatives: boolean
    isNonReturnable: boolean
    hasObligatedArticles: boolean
    hasLinkedArticles: boolean
    hasAccessoryArticles: boolean
    hasReplacementArticles: boolean
    hasPawnArticles: boolean
    hasGraduatedPurchasePrice: boolean
    hasBonusInformation: boolean
    hasProcurementCosts: boolean
    specialIcons: Array<ArticleErpIcon>
    isTeccomRequestAvailable: boolean
    isReplacementPart: boolean
    tour?: ItemTour
    graduatedPrices?: Array<ErpPrice>
    isOrderable?: boolean
    itemOrderableInformation?: string
    isPromotional?: boolean
    isSellOffArticle?: boolean
    promoText?: string
    warehouses?: Array<Warehouse>
    orderOptions?: OrderOptions
    showSafetyDataSheets?: boolean
    itemsRole?: LinkedItemsRole
    useSpecialProcurement?: boolean
    memos?: Array<Memo>
    specialProcurementErpInformation?: SpecialProcurementErpInformation // Used only in FE https://jira.dvse.de/browse/NEXT-21590
    sortNumber?: number
    costs?: Array<Cost>
}

export type Cost = {
    value: number
    currencySymbol: string
    currencyCode: string
    type: CostsType
    shouldBeCalculated: boolean
}

export type TeccomErpInformation = {
    itemId: string
    prices?: Array<ErpPrice>
    specialProcurementErpInformation?: SpecialProcurementErpInformation
    additionalInformation?: string
    memos?: Array<Memo>
    quantity?: Quantity
}

export type ErpPriceDictionary = {
    [key: string]: ErpPrice | undefined
}

export type ErpPrice = {
    type: PriceType
    description: string
    shortDescription: string
    value: number
    valueTaxIncluded?: number
    rebateValue?: number
    rebate?: number
    rebatePercent?: number
    currencySymbol: string
    currencyCode_Iso_4217: string
    memos?: Array<Memo>
    priceUnit?: number
    vat: number
    taxIncluded?: boolean
}

export type Availability = {
    type?: AvailabilityStatus
    shortDescription?: string
    description?: string
    icon?: ErpIcon
    iconColor?: string
    isFakeAvailability?: boolean
    additionalIcons?: ErpIcon[]
}

export type Quantity = {
    description?: string
    value?: number
    quantityUnit?: string
    packagingUnit?: string
    minQuantity?: number
    maxQuantity?: number
    expectedDeliveryTime?: Date
    expectedDeliveryTimeString?: string
    availability?: Availability
    // lotSizes: { [key: string]: string }
    division?: number
    quantityPackingUnit?: number
    tour?: ItemTour
    confirmedValue?: number
    requestedValue: number
}

export type Warehouse = {
    id?: string
    name?: string
    shortDescription: string
    description: string
    showWarehouseInfo?: boolean
    memos?: Memo[]
    quantities?: Quantity[]
    isDefault: boolean
    isManufacturerWarehouse?: boolean
    tour?: ItemTour
    nightExpressDelivery?: DispatchType[]
    dayExpressDelivery?: DispatchType[]
    collection?: DispatchType[]
    standardDelivery?: DispatchType[]
    /** Is an object when passed by the basket and cost-estimation bundles */
    prices?: ErpPrice[] | { [key: string]: ErpPrice }
}

export type DispatchType = {
    costs?: DispatchCosts
    description?: string
    dispatchConditions?: DispatchConditions
    id?: string
    isSelected?: boolean
    tours?: Array<ItemTour>
    type?: WarehouseDispatchType
    uniqueId?: string
}

export type DispatchConditions = {
    carriageLimit?: number
    basicWeightKg?: number
    pricePerAdditionalKg?: number
    shouldWeightBeChecked: boolean
}

export type DispatchCosts = {
    value: number
    currencySymbol: string
    currencyCode: string
    type: DispatchCostsType
    shouldBeCalculated: boolean
}

/** Used to send data from erp to article list and article details */
export type ErpPartsModifiers = {
    minQuantity?: number
    maxQuantity?: number
    division?: number
    availability?: AvailabilityStatus
}

/** Used to send data from erp to article details navigation */
export type ArticleDetailsErpTabs = {
    alternativeArticles: boolean
    replacementArticles: boolean
    safetyDataSheets: boolean
    pawnArticles: boolean
    useSpecialProcurement: boolean
}

export type AvailabilityLog = {
    status: AvailabilityStatus
    description?: string
}

export type GetCompleteDeliveryTourRequest = {
    tours: Array<ItemTour>
}

export type GetCompleteDeliveryTourResponse = {
    completeDeliveryTour: ItemTour
}

export type GetBonusInformationRequest = IErpRequest & {
    items: Array<ErpInformationRequestItem>
    catalogContext?: CatalogContext
}

export type GetBonusInformationResponse = {
    hasErrors?: boolean
    items: Array<BonusInformationResponseItem>
    bonusTotal: number
    errorText?: string
}

export type BonusInformationResponseItem = {
    itemId: string
    bonusPoints: number
}

export enum ErpSystemConfigMode {
    Default,
    Partnersystems,
}

export enum ETransferMode {
    Default = 0,
    BasketTransferBoschOs3 = 1,
}

export type ErpSystemConfig = {
    id: number
    description: string
    displayMode: string
    transferMode?: ETransferMode
    useForWorkTaskOrder: boolean
    useForWorkTaskSendCostEstimation: boolean
    useForGetErpInformation?: boolean
    isDefaultGetErpInfoSystem: boolean
    tradeReferenceId?: number
    username: string
    erpRequestArticleCount?: number
    hasOeArticleSupport: boolean
    sortNumber?: number
}

export type GetErpConfigResponse = {
    mode: ErpSystemConfigMode
    erpSystemConfigs?: Array<ErpSystemConfig>
    useOrderByDistributor: boolean
    useSendCostEstimation: boolean
    styleCustomization?: string
}

export type SpecialProcurementErpInformation = {
    supplierName: string
    supplierId: number
    message: string
    availability: Availability
    orderOptions: OrderOptions
    tourOrderAcceptanceTimeLimit: Date
    expectedDelivery: Date
    requestedQuantity: number
    confirmedQuantity: number
}

type PreOrderActions = {
    showProcurementCostsConfirmation?: boolean
}

export type ErpIcon = {
    useIconFromUrl: boolean
    url: string
    topmotiveId?: string
    description?: string
    linkUrl?: string
    displayType?: ErpIconDisplayType
    type?: ErpIconType
}

export type ArticleErpIcon = {
    useIconFromUrl: boolean
    url: string
    topmotiveId?: string
    description?: string
    linkUrl?: string
    displayType?: ErpIconDisplayType
    displayLocations?: ErpIconDisplayLocation[]
    type: ErpIconType
    actionType: ErpIconActionType
}

export enum ErpIconDisplayType {
    Default = 0,
    Small = 1,
}

export enum ErpIconType {
    NotDefined = 0,
    SafetyDataSheets = 1,
    Documents = 2,
    ReplacementArticles = 3,
    ProcurementCosts = 4,
    Promotional = 5,
    NonRefundable = 6,
    SellOff = 7,
    ScaleAvailable = 8,
    AlternativeItemAvailable = 9,
    Pawn = 10,
    // The following are only being used internally
    Details = -1,
    Delete = -2,
    Print = -3,
    Watchlist = -4,
    Notes = -5,
    Repairtimes = -6,
    Feedback = -7,
    Accessories = -8,
    Partslist = -9,
    AdditionalInformations = -10,
}

export enum ErpIconDisplayLocation {
    ErpContainer = 0,
    ArticleInformationContainer = 1,
    ArticleImageContainer = 2,
}

export enum ErpIconActionType {
    /// <summary>
    /// Default if a customer don't know what to do and svc need to make the mapping for predefined ActionTypes.
    /// </summary>
    Unknown = 0,
    /// <summary>
    /// Deactivates the predefined action types and default configuration of the special icons in svc.
    /// </summary>
    Deactivate = 1,
    /// <summary>
    /// Open an url from LinkUrl in new window. Will be removed if LinkUrl is empty.
    /// </summary>
    OpenInWindow = 2,
    /// <summary>
    /// Open an url from LinkUrl in a new tab. Will be removed if LinkUrl is empty.
    /// </summary>
    OpenInTab = 3,
    /// <summary>
    /// Open a pdf-url from LinkUrl in a specific security sheet tab in the article detail page.
    /// Only works with a not empty LinkUrl and if SpecialIconType is SafetyDataSheets.
    /// It is not evaluated if SpecialIconType has a different value.
    /// </summary>
    SafetyDataSheetsDetailPage = 4,
    /// <summary>Open wholesaler information tab in article details page.</summary>
    WholesalerInformationDetailPage = 5,
    /// <summary>Open replacement Items tab in the article details page</summary>
    ReplacementItemsDetailPage = 6,
    /// <summary>Open alternative Items tab in the article details page</summary>
    AlternativeItemsDetailPage = 7,
    /// <summary>Open pawn Items tab in article details Page</summary>
    PawnItemsDetailPage = 8,
    /// <summary>Open trader info tab in details page</summary>
    ImagesAndDocumentsOnDetailPage = 9,
}

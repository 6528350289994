import Button, { ButtonSkins } from "./button"
import { useStyle } from "@tm/context-distribution"
import { useMemo } from "react"
import { classes } from "@tm/utils"

export type ReplaceButtonProps = {
    onClick: () => void
    disabled?: boolean
    isWm?: boolean
    skin?: ButtonSkins
    className?: string
    disableMargin?: boolean
}

function getStyle(isWm?: boolean, disableMargin?: boolean) {
    return useStyle({
        button: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: isWm ? "13em" : "6em",
            marginLeft: disableMargin ? "initial" : "auto",
        },
    })(ReplaceButton)
}

export function ReplaceButton({ onClick, disabled, isWm, skin, className, disableMargin }: ReplaceButtonProps) {
    const style = useMemo(() => getStyle(isWm, disableMargin), [isWm, disableMargin])

    return (
        <Button
            skin={skin || "highlight"}
            disabled={disabled}
            className={classes("replace", style.button, "add-to-basket__button", isWm ? "wm-replace" : "", className)}
            icon={isWm ? "wm-logo" : "replace"}
            onClick={onClick}
        />
    )
}

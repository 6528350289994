import { Theme } from "@mui/material"

export const getOldTyreLabelColor = (value: string, theme: Theme) => {
    switch (value) {
        case "A":
            return "#2d7327"
        case "B":
            return "#55d545"
        case "C":
            return "#b5e941"
        case "D":
            return "#f6ed4e"
        case "E":
            return "#f6a328"
        case "F":
            return "#dd901f"
        case "G":
            return "#e12816"
        default:
            return theme.palette.primary.main
    }
}

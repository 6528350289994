import { Vehicle, Customer, WorkTaskDetailsReadModel } from "@tm/models"
import { uniqueId } from "@tm/utils"
import { WorkTaskProvider } from "./WorkTaskProvider"
import * as Data from "../data"
import { saveCustomerOrGetId, saveVehicleOrGetId } from "./helpers"

const creatingPromises: Array<{ workTaskId: string; prom: Promise<WorkTaskDetailsReadModel> }> = []

export async function createWorkTask(
    this: WorkTaskProvider,
    data: {
        workTaskId?: string
        customer?: string | Customer
        vehicle?: string | Vehicle
        subPage?: string
        skipRedirect?: boolean
        withoutCustomerVehicle?: boolean
    }
): Promise<void> {
    const { vehicleId, computedCustomerId: customerId } = await saveVehicleOrGetId(data.vehicle, await saveCustomerOrGetId(data.customer))

    if (data.workTaskId) {
        const { workTaskId } = data

        const workTask = await Data.loadWorktask(data.workTaskId)
        if (workTask) {
            await attachIfDifferent(workTaskId, customerId, vehicleId, workTask)
            this.openWorkTask(workTask.workTaskId, data.subPage, data.skipRedirect)
        } else {
            const index = creatingPromises.findIndex((x) => x.workTaskId == workTaskId)
            if (index != -1) {
                const { prom } = creatingPromises[index]
                creatingPromises.splice(index, 1)

                const createdWorkTask = await prom
                const possiblyLoadedWorkTask = await attachIfDifferent(workTaskId, customerId, vehicleId, createdWorkTask)

                if (possiblyLoadedWorkTask) {
                    await this.loadWorkTaskInfo(workTaskId, possiblyLoadedWorkTask)
                }
                return
            }

            const prom = Data.createWorktaskAndReturnNewVersion(workTaskId, vehicleId, customerId)
            creatingPromises.push({ workTaskId, prom })
            const createdWorkTask = await prom
            this.openWorkTask(workTaskId, data.subPage, data.skipRedirect, createdWorkTask)
        }
    } else {
        const workTaskId = uniqueId()

        if (data.withoutCustomerVehicle) {
            await Data.createWorktask(workTaskId, vehicleId)
        } else {
            await Data.createWorktask(workTaskId, vehicleId, customerId)
        }

        this.openWorkTask(workTaskId, data.subPage, data.skipRedirect)
    }
}

function attachIfDifferent(workTaskId: string, customerId: string | undefined, vehicleId: string | undefined, workTask: WorkTaskDetailsReadModel) {
    let attachData
    if (customerId && (!workTask.customer || customerId !== workTask.customer.id)) {
        attachData = { customerId }
    }
    if (vehicleId && (!workTask.vehicle || vehicleId !== workTask.vehicle.id)) {
        attachData = { ...attachData, vehicleId }
    }

    if (attachData) {
        return Data.attachCustomerAndVehicleAndReturnNewVersion({ workTaskId, ...attachData, version: workTask.version })
    }
}

import { FC, useCallback, useMemo, useState } from "react"
import Image from "./image"

export type Props = {
    className?: string
    id: number
    name: string
    imageUrl: string
}

export const BonusSystemImage: FC<Props> = (props) => {
    const [useDefaultTheme, setUseDefaultTheme] = useState(false)
    const [trySvgImage, setTrySvgImage] = useState(true)
    const [imageError, setImageError] = useState(false)

    const imageUrl = useMemo(() => {
        let url = props.imageUrl

        if (useDefaultTheme) {
            url = url.replace(/(\/)\d+(\/)/, "$1-1$2")
        }

        if (trySvgImage) {
            url = `${url.substring(0, url.lastIndexOf("."))}.svg`
        }

        return url
    }, [useDefaultTheme, trySvgImage])

    const handleImageLoadError = useCallback(() => {
        if (trySvgImage) {
            setTrySvgImage(false)
        } else if (!useDefaultTheme) {
            setTrySvgImage(true)
            setUseDefaultTheme(true)
        } else {
            setImageError(true)
        }
    }, [useDefaultTheme, trySvgImage])

    if (imageError) {
        return null
    }

    return (
        <div className={props.className}>
            <Image url={imageUrl} title={props.name} onError={handleImageLoadError} />
        </div>
    )
}

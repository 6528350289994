import { Omit } from "@tm/utils"
import { Component, ComponentClass, ComponentType, createElement } from "react"
import { UserProviderContext } from "../model/UserProviderContext"
import { User } from "../model"

export type WithUserSettingsProps = Pick<User, "userSettings" | "reloadUserSettings">

export function withUserSettings<P extends WithUserSettingsProps>(component: ComponentType<P>): ComponentClass<Omit<P, keyof WithUserSettingsProps>> {
    return class extends Component<Omit<P, keyof WithUserSettingsProps>> {
        constructor(props: Omit<P, keyof WithUserSettingsProps>) {
            super(props)
            this.renderInnerComponent = this.renderInnerComponent.bind(this)
        }

        renderInnerComponent(user: User) {
            const currentProps: any = this.props || {}

            const props: P & WithUserSettingsProps = {
                ...currentProps,
                userSettings: user.userSettings,
                reloadUserSettings: user.reloadUserSettings,
            }

            return createElement(component, props)
        }

        render() {
            return <UserProviderContext.Consumer>{this.renderInnerComponent}</UserProviderContext.Consumer>
        }
    }
}

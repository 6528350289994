import { ReactNode, useMemo } from "react"
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material"
import { ErpIconType } from "@tm/models"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { MemoTooltip } from "./MemoTooltip"
import { FoundBy } from "./FoundBy"
import { AdditionalInformation } from "./AdditionalInformation"
import { Selection } from "./Selection"
import { ButtonAction } from "./ButtonAction"
import { IconAction } from "./IconAction"
import { Watchlist } from "../Watchlist"
import { MoreMenuButton } from "./MoreMenuButton"

export function InformationAreaActions() {
    const articleActions = useDefaultArticleItemState((x) => x.articleActions)
    const { hasWatchList, highlightWatchlistButton } = useDefaultArticleItemState((x) => x.options)
    const articleFoundBy = useDefaultArticleItemState((x) => x.foundBy)
    const article = useDefaultArticleItemState((x) => x.article)
    const memoTooltipIcon = useDefaultArticleItemState((x) => x.memoTooltipIcon)
    const defaultArticleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos?.default)
    const erpInfos = defaultArticleErpInfos?.state === "success" ? defaultArticleErpInfos.response : undefined
    const hasAdditionalInfo = !!article.additionalReferenceArticleInformation?.links.length

    const { breakpoints } = useTheme()
    const isHighResolution = useMediaQuery(breakpoints.up("xl"))

    const actionList = useMemo<ReactNode[]>(() => {
        const elements: ReactNode[] = []
        articleActions?.informationArea.forEach((action) => {
            // Actions coming from article item state
            if (action.displayType === "BUTTON") {
                elements.unshift(<ButtonAction key={ErpIconType[action.iconType] + action.tooltip} action={action} hideText={!isHighResolution} />)
            } else {
                elements.unshift(<IconAction key={ErpIconType[action.iconType] + action.tooltip} action={action} />)
            }

            // Additional actions, which cannot be defined in the state because of additional components like a menu
            if (action.iconType === ErpIconType.Details && hasWatchList && !highlightWatchlistButton) {
                elements.unshift(<Watchlist key={ErpIconType[ErpIconType.Watchlist] + action.tooltip} hideText={!isHighResolution} />)
            }
        })
        return elements
    }, [articleActions?.informationArea, hasWatchList, highlightWatchlistButton, isHighResolution])

    return (
        <Box display="flex" gap={1}>
            <Stack direction="row" gap={1} alignItems="center" alignSelf="baseline">
                {erpInfos?.memos && memoTooltipIcon && <MemoTooltip memos={erpInfos.memos} iconName={memoTooltipIcon} />}
                {articleFoundBy && <FoundBy articleFoundBy={articleFoundBy} />}
                {hasAdditionalInfo && <AdditionalInformation article={article} />}
                {actionList}

                <MoreMenuButton />
            </Stack>
            <Selection />
        </Box>
    )
}

import { Component } from "react"

export type RangeProps = {
    value: number
    state?: "danger" | "success" | "warning"
    min: number
    max: number
    showValue?: boolean
    onChange?(newValue: number): void
}

export default class RangeSlider extends Component<RangeProps, any> {
    constructor(props: RangeProps) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }

    UNSAFE_componentWillMount() {
        this.setState({ value: this.props.value })
    }

    UNSAFE_componentWillReceiveProps(props: RangeProps) {
        if (props.value != this.state.value) {
            this.setState({ value: props.value })
        }
    }

    /**
     * Calculates the position of the slider as percentage. Can be used as width of the ".range-slider__bar" or the offset of "range-slider__value".
     * @return number
     */
    calculateSliderPosition() {
        return ((this.state.value - this.props.min) / (this.props.max - this.props.min)) * 100
    }

    onChange(e: any) {
        this.setState({ value: e.target.value })

        if (this.props.onChange) {
            this.props.onChange(e.target.value)
        }
    }

    render() {
        let className = "range-slider"
        const sliderPosition = `${this.calculateSliderPosition()}%`
        let value = null

        if (this.props.showValue) {
            value = (
                <span style={{ left: sliderPosition }} className={`range-slider__value${this.props.state ? ` ${this.props.state}` : ""}`}>
                    {this.state.value}
                </span>
            )
            className += " has-value"
        }

        return (
            <div className={className}>
                {value}
                <input value={this.state.value} min={this.props.min} max={this.props.max} type="range" onChange={this.onChange} />
            </div>
        )
    }
}

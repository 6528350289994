import { CategoryType, GetArticlesRequest, GetUniArticlesResponse, TmaEModule } from "@tm/models"
import { TmaEventTracking } from "../.."
import { BaseTrackingModel, QuerySearchType } from "../../models/models"
import { CriterionFilter, PremiumUniPartsSearchCriterion, TreeMode } from "./models"
import { getCatalogOrigin } from "../../helper/origin"
import { determineKeywordSearchOrigin, determineOrigin, determineUniSearchTreeTmaModule } from "../article-list-filtered/originDetection"

const TMA_EVENT = "PREMIUM_UNI_PARTS_SEARCH"
export interface ArticleListViewOptions {
    compactView: boolean
    showArticleImages: boolean
    showVehicleRecordsFilters: boolean
}

export const PremiumUniPartsSearchEventHelper = {
    UniParts: {
        Event: {
            IncreaseStepNumber: () => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    bundle: "parts",
                    component: "searchtree",
                    action: "increase-step-number",
                    type: "click",
                    source: "selected-filter",
                    payload: true,
                })
            },
            Reset: () => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    bundle: "parts",
                    component: "area-swap",
                    action: "reset",
                    type: "click",
                    source: "selected-filter",
                    payload: true,
                })
            },
            Swap: () => {
                // nothing to do here, for now .....
            },
        },
        SearchTree: {
            NodeSelected: async (data: { mode?: TreeMode; nodeId?: number; treeId?: number; origin?: TmaEModule }) => {
                const { mode, nodeId, treeId } = data
                const eventOrigin = determineUniSearchTreeTmaModule(data.origin || getCatalogOrigin(), mode)
                // not clear when and how and why to use this, it's implemented in handleSelectNode, but there is no further handling
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "nodeSelected",
                    source: mode || "fast-click-2.0",
                    payload: { mode, origin: eventOrigin, nodeId, treeId },
                    bundle: "parts",
                    component: "shared/tree",
                    type: "commit",
                })
            },
        },
        Search: {
            AutoCompleteResults: (hits: string[]) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "autocomplete",
                    bundle: "parts",
                    component: "repository",
                    payload: { hits },
                    source: "uni-product-group-search",
                    type: "request",
                })
            },
            KeywordSearchFromStartPage: (query: string, targetUrl: string) => {
                const origin = determineKeywordSearchOrigin("universalParts", true)
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "keyword-search-redirect",
                    bundle: "parts",
                    component: "navigation",
                    payload: {
                        query,
                        origin,
                        targetUrl,
                    },
                    source: "widget",
                    type: "render",
                })
            },
            Assistent: (targetUrl: string, query: string) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "keyword-search-redirect",
                    bundle: "parts",
                    component: "partslist",
                    payload: {
                        origin: TmaEModule.ARTICLE_DIRECT_SEARCH_SEARCH_ASSISTENT,
                        query,
                        targetUrl,
                    },
                    source: "widget",
                    type: "render",
                })
            },
            SetSearchContext: (categoryType: CategoryType, query: string, workTaskId?: string, fromWidget?: boolean) => {
                const origin = determineKeywordSearchOrigin(categoryType, fromWidget)
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "keyword-search-context",
                    bundle: "parts",
                    component: "navigation",
                    payload: {
                        categoryType,
                        query,
                        workTaskId,
                        fromWidget,
                        origin,
                    },
                    source: "widget",
                    type: "render",
                })
            },
            Submit: (origin: TmaEModule, query: string, categoryType: CategoryType) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "handleSearch",
                    bundle: "parts",
                    component: "SearchComponent",
                    payload: {
                        query,
                        origin,
                        categoryType,
                    },
                    source: "searchfield",
                    type: "request",
                })
            },
            SubmitExternal: (query?: string, origin?: TmaEModule) => {
                origin = origin ?? getCatalogOrigin()

                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "handleExternalSearch",
                    bundle: "api",
                    component: "post-message-controller",
                    payload: {
                        query,
                        origin,
                    },
                    source: "searchfield",
                    type: "request",
                })
            },
            TreeChanged: (mode?: "fastClick" | "fast-click-2.0", thumbnailClicked?: boolean) => {
                TmaEventTracking.dispatchTrack({
                    // move this to business actions? function changeBreadcrumbs(breadcrumbs: Array<Models.TreeNode>, startSearch?: boolean): AsyncAction<ComponentActionType, WidgetState> {
                    tmaEvent: TMA_EVENT,
                    bundle: "parts",
                    component: "searchtree",
                    action: ["handleChangeBreadcrumbs"],
                    type: "search",
                    source: thumbnailClicked ? "thumbnail" : "label",
                    payload: {
                        mode: mode || "fast-click-2.0",
                    },
                })
            },
            TyresSelection: (query?: string, origin?: TmaEModule) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "tyresSelection",
                    bundle: "tyres",
                    component: "",
                    payload: {
                        query,
                        origin: origin ?? TmaEModule.TM_TYRE_SEARCH,
                    },
                    source: "searchfield",
                    type: "request",
                })
            },
            WheelsSelection: (query: string) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "keyword-search-context",
                    bundle: "parts",
                    component: "navigation",
                    payload: {
                        query,
                        origin: TmaEModule.WHEEL_CONFIGURATOR,
                    },
                    source: "widget",
                    type: "render",
                })
            },
        },
        List: {
            Results: async (
                request: GetArticlesRequest,
                result: GetUniArticlesResponse | undefined,
                worktaskId: string,
                url: string,
                searchedNodeId?: string
            ) => {
                const match = /nodeId=(\d+)/.exec(document.location.search)
                const latestSearchedNodeId = match?.last()

                if (!searchedNodeId || searchedNodeId == latestSearchedNodeId) {
                    const origin = determineOrigin()
                    // look at article-list-filtered/helper.ts
                    TmaEventTracking.dispatchTrack({
                        tmaEvent: TMA_EVENT,
                        bundle: "parts",
                        component: "repository",
                        action: "articlesLoaded",
                        type: "request",
                        payload: { request, result, worktaskId, origin },
                        source: url,
                        contextData: request.pageIndex
                            ? {
                                  paging: {
                                      pageIndex: request.pageIndex,
                                  },
                              }
                            : undefined,
                    })
                }
            },
            FilterChanged: (data: { criterionFilters: Array<CriterionFilter> }) => {
                const criterionFilters: Array<PremiumUniPartsSearchCriterion> = data.criterionFilters.map((critFilter) => {
                    return {
                        group: critFilter.group,
                        id: critFilter.id,
                        name: critFilter.name,
                    }
                })

                TmaEventTracking.dispatchTrack({
                    action: "filterChanged",
                    bundle: "parts",
                    component: "helper",
                    payload: { criterionFilters },
                    source: "loadUniFilters",
                    tmaEvent: TMA_EVENT,
                    type: "request",
                })
            },
            ViewOptions: (viewOptions: ArticleListViewOptions) => {
                const trackingData: BaseTrackingModel<{ viewOptions: ArticleListViewOptions }> = {
                    action: "viewoptions-changed",
                    bundle: "parts",
                    component: "list",
                    source: "dropdown",
                    tmaEvent: TMA_EVENT,
                    type: "click",
                    payload: {
                        viewOptions,
                    },
                }

                TmaEventTracking.dispatchTrack(trackingData)
            },
        },
    },
}

/**
 * @deprecated ArticleListFilteredEvents was renamed to ArticleListFilteredEventHelper
 */
export const ArticleListFilteredEvents = PremiumUniPartsSearchEventHelper

import { styled, useTheme } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { forwardRef, useCallback, useMemo } from "react"
import { Button, ButtonProps } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"

type CostEstimationProps = ButtonProps & {
    disabled?: boolean
    size?: "small" | "medium"
    onAddToCostEstimation(): void
}

const CostEstimationButtonComponent = styled(Button)(({ theme }) => ({
    ".MuiButton-startIcon>svg.icon-add-repair-times": {
        width: 30,
    },
    ".MuiButton-startIcon>svg.icon-add-repair-times-international": {
        width: 30,
    },
    ...(theme.name === "create-business" && {
        ".MuiButton-startIcon>svg.icon-add-repair-times": {
            width: 36,
        },
        ".MuiButton-startIcon>svg.icon-add-repair-times-international": {
            width: 36,
        },
    }),
}))

export const CostEstimationButton = forwardRef<HTMLButtonElement, CostEstimationProps>((props, ref) => {
    const { color, disabled, size, title, onAddToCostEstimation } = props
    const theme = useTheme()
    const { translateText } = useLocalization()

    const handleAddToCostEstimation = useCallback(() => onAddToCostEstimation(), [onAddToCostEstimation])

    const sizeProps = useMemo<Partial<ButtonProps>>(
        () => ({
            ...(size === "medium" && {
                sx: { width: 60 },
            }),
        }),
        [size]
    )

    return (
        <Tooltip title={disabled ? undefined : translateText(917)}>
            <CostEstimationButtonComponent
                color={theme.overwrites?.components?.addToCostEstimation?.button?.color || color}
                ref={ref}
                className="costEstimationButton"
                disabled={disabled}
                onClick={handleAddToCostEstimation}
                variant="bordered"
                startIcon={<Icon name="add-repair-times" />}
                {...sizeProps}
                size={size}
                title={title}
            />
        </Tooltip>
    )
})

import { Box, BoxProps, styled } from "@mui/material"
import { FC } from "react"
import { IconButton } from "../../generics/button"
import { Icon } from "../../generics/Icons"
import { VehicleThumbnail } from "./VehicleThumbnail"
import { Divider } from "../../generics/divider"

const calcTabWidth = ({ hasResetIcon, hasDashboardIcon, hasImg }: { hasResetIcon?: boolean; hasDashboardIcon?: boolean; hasImg?: boolean }) => {
    if (hasResetIcon && hasDashboardIcon && hasImg) {
        return { width: "315px" }
    }
    if ((hasResetIcon || hasDashboardIcon) && hasImg) {
        return { width: "266px" }
    }
    if (!hasDashboardIcon) {
        return { width: "233px" }
    }
    return { width: "48px" }
}

type TabProps = BoxProps & { isSelected?: boolean; hasResetIcon?: boolean; hasDashboardIcon?: boolean; hasImg?: boolean }

const Tab = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isSelected",
})<TabProps>(({ isSelected, hasResetIcon, hasDashboardIcon, hasImg, theme }) => ({
    ...(isSelected && { transform: "translateY(3px)" }),
    height: "58px",
    ...calcTabWidth({ hasResetIcon, hasDashboardIcon, hasImg }),
    backgroundColor: "white",
    borderRadius: "3px 0 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...(!hasDashboardIcon && { borderRight: `2px solid ${isSelected ? theme.colors?.highlight : theme.colors?.primary}` }),
    position: "relative",
}))

const CenterContent = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
}))

type InfoWrapperProps = BoxProps & { hasDashboardIcon?: boolean }

const InfoWrapper = styled(Box)<InfoWrapperProps>(({ hasDashboardIcon }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...(hasDashboardIcon ? { marginLeft: "122px" } : { marginLeft: "73px" }),
}))

const CarInfo = styled("div")(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: "11px",
    maxWidth: "155px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "5px",
}))

const CustomerInfo = styled("div")(({ theme }) => ({
    color: theme.palette.common.black,
    maxWidth: "155px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
}))

export type Props = {
    isSelected?: boolean
    title?: string
    subtitle?: string
    img?: string
    emptyText: string
    onResetIconClick?(): void
    hasDashboardIcon?: boolean
}

export const TabWithImage: FC<Props> = (props: Props) => {
    const { isSelected, title, subtitle, img, emptyText, onResetIconClick, hasDashboardIcon } = props

    return (
        <Tab isSelected={isSelected} hasResetIcon={!!onResetIconClick} hasDashboardIcon={hasDashboardIcon} hasImg={!!img}>
            {hasDashboardIcon && (
                <>
                    <Box position="absolute" left="14px">
                        <Icon name="dashboard" />
                    </Box>
                    {img && <Divider orientation="vertical" sx={{ position: "absolute", left: "48px", width: "1px", height: "36px" }} />}
                </>
            )}
            {img && <VehicleThumbnail src={img} hasDashboardIcon={hasDashboardIcon} />}

            {!title && !subtitle && !hasDashboardIcon && (
                <CenterContent pl={onResetIconClick ? "36px" : undefined}>
                    <span>{emptyText}</span>
                </CenterContent>
            )}
            {(title || subtitle) && (
                <InfoWrapper hasDashboardIcon={hasDashboardIcon}>
                    <CustomerInfo>{title}</CustomerInfo>
                    <CarInfo>{subtitle}</CarInfo>
                </InfoWrapper>
            )}
            {onResetIconClick && (
                <IconButton aria-label="reset" onClick={onResetIconClick} disableRipple>
                    <Icon name="refresh" />
                </IconButton>
            )}
        </Tab>
    )
}

import { ECounterType, TmaEModule } from "@tm/models"

export const OriginTypes: { paths: string[]; origin: TmaEModule; counterType?: ECounterType }[] = [
    {
        origin: TmaEModule.WHEEL_CONFIGURATOR,
        paths: ["/wheels"],
        counterType: ECounterType.TMWheelsAndTyres,
    },
    {
        origin: TmaEModule.TM_TYRE_SEARCH,
        paths: ["/tyres"],
        counterType: ECounterType.PassengerCarTyres,
    },
    {
        origin: TmaEModule.TYRES_AUDACON,
        paths: ["/tecrmi-tyres"],
        counterType: ECounterType.PassengerCarTyres,
    },
    {
        origin: TmaEModule.PREMIUMUNIPARTSSEARCH_ARTICLE_LIST,
        paths: ["/parts/universal/list"],
    },
    {
        origin: TmaEModule.ARTICLE_LIST,
        paths: ["/parts/direct/list", "/parts/vehicles/list"],
        counterType: ECounterType.ArticleDirectSearch,
    },
    {
        origin: TmaEModule.VEHICLE_GRAPHIC_SEARCH,
        paths: ["/vehicles/details", "/vehicles/select"],
        counterType: ECounterType.PassengerCarDetails,
    },
    {
        origin: TmaEModule.ARTICLE_DETAILS,
        paths: ["modal/part-details"],
    },
    {
        origin: TmaEModule.DIRECTBUY,
        paths: ["/basket"],
    },
    {
        origin: TmaEModule.SHOPPING_CART,
        paths: ["/cost-estimation"],
    },
    {
        origin: TmaEModule.SPECIALOFFER,
        paths: ["/offers"],
        counterType: ECounterType.Offers,
    },
    {
        origin: TmaEModule.TECH_HAYNES,
        paths: ["/td/haynespro"],
        counterType: ECounterType.PassengerCarTechnicalDataHaynesPro,
    },
    {
        origin: TmaEModule.SERVICE_HAYNES,
        paths: ["/sd/haynespro"],
        counterType: ECounterType.PassengerCarServiceDataHaynesPro,
    },
    {
        origin: TmaEModule.TECH_AUDACON,
        paths: ["/td/tecrmi"],
        counterType: ECounterType.PassengerCarTechnicalDataTecRMI,
    },
    {
        origin: TmaEModule.SERVICE_AUDACON,
        paths: ["/sd/tecrmi"],
        counterType: ECounterType.PassengerCarServiceDataTecRmi,
    },
    {
        origin: TmaEModule.TECH_AUTODATA,
        paths: ["/td/autodata"],
        counterType: ECounterType.PassengerCarTechnicalDataAutodata,
    },
    {
        origin: TmaEModule.SERVICE_AUTODATA,
        paths: ["/sd/autodata"],
        counterType: ECounterType.PassengerCarServiceDataAutoData,
    },
    {
        origin: TmaEModule.GPI_DAT,
        paths: ["/gpi/dat"],
        counterType: ECounterType.PassengerCarGpiDat,
    },
    {
        origin: TmaEModule.GPI_EUROTAX,
        paths: ["/gpi/eurotax", "/eurotax"],
        counterType: ECounterType.PassengerCarGpiEurotax,
    },
    {
        origin: TmaEModule.GPI_TEMOT,
        paths: ["/gpi/temot"],
        counterType: ECounterType.PassengerCarGpiTemot,
    },
    {
        origin: TmaEModule.GPI_AUDACON,
        paths: ["/gpi/tecrmi"],
        counterType: ECounterType.PassengerCarGpiTecRmi,
    },
    {
        origin: TmaEModule.REPESTIMATE_EUROTAX,
        paths: ["/damage-calculation/eurotax"],
        counterType: ECounterType.PassengerCarRepairCalculation,
    },
    {
        origin: TmaEModule.REPESTIMATE_DAT,
        paths: ["/damage-calculation/dat"],
        counterType: ECounterType.PassengerCarRepairCalculation,
    },
    {
        origin: TmaEModule.GLASS_REPESTIMATE_DAT,
        paths: ["/damage-calculation/glass"],
        counterType: ECounterType.PassengerCarRepairCalculation,
    },
    {
        origin: TmaEModule.GLASS_SAINTGOBAIN,
        paths: ["/damage-calculation/aswegas"],
        counterType: ECounterType.PassengerCarRepairCalculation,
    },
    {
        origin: TmaEModule.TRUCKVIN,
        paths: ["/truck/vin"],
        counterType: ECounterType.TruckVIN,
    },
    {
        origin: TmaEModule.MY_TRUCK,
        paths: ["/truck/my"],
        counterType: ECounterType.MyTruck,
    },
    {
        origin: TmaEModule.MY_TRUCK_PLUS,
        paths: ["/truck/plus"],
        counterType: ECounterType.MyTruckPlus,
    },
    {
        origin: TmaEModule.TRUCKGARAGE,
        paths: ["/truck/garage"],
        counterType: ECounterType.TruckGarage,
    },
    {
        origin: TmaEModule.FAST_CALCULATOR,
        paths: ["/fast-calculator-next"],
        counterType: ECounterType.PassengerCarFastCalculator,
    },
    {
        origin: TmaEModule.FAST_CALCULATOR,
        paths: ["/fast-calculator"],
        counterType: ECounterType.PassengerCarFastCalculator,
    },
    {
        origin: TmaEModule.FAST_CHECK,
        paths: ["/fastcheck"],
        counterType: ECounterType.PassengerCarFastCheck,
    },
    {
        origin: TmaEModule.FAST_SERVICE,
        paths: ["/fast-service"],
        counterType: ECounterType.FastService,
    },
    {
        origin: TmaEModule.FAST_DTC,
        paths: ["/fastdtc"],
        counterType: ECounterType.FastDTC,
    },
    {
        origin: TmaEModule.EXTERNAL_DATA_SOURCE,
        paths: ["/eds"],
        counterType: ECounterType.EDS,
    },
    {
        origin: TmaEModule.REPTIMES_AWDOC,
        paths: ["/repair-times/awdoc"],
        counterType: ECounterType.PassengerCarRepairTimesAwDoc,
    },
    {
        origin: TmaEModule.REPTIMES_AUTODATA,
        paths: ["/repair-times/autodata"],
        counterType: ECounterType.PassengerCarRepairTimesAutodata,
    },
    {
        origin: TmaEModule.REPTIMES_HAYNES,
        paths: ["/repair-times/haynespro"],
        counterType: ECounterType.PassengerCarRepairTimesHaynesPro,
    },
    {
        origin: TmaEModule.REPTIMES_AUDACON,
        paths: ["/repair-times/tecrmi"],
        counterType: ECounterType.PassengerCarRepairTimesTecRmi,
    },
]

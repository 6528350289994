import { Box, Button, Tooltip, styled } from "@mui/material"
import { ArticleAttribute } from "@tm/models"
import { bem, classes } from "@tm/utils"
import { getOldTyreLabelColor } from "../helpers/getOldTyreLabelColor"

type SelectableArticleAttribute = ArticleAttribute & { selected?: boolean }

type Props = {
    items: SelectableArticleAttribute[]
    onSelect?: (article: ArticleAttribute) => void
}

export default function TyresIcons({ items, onSelect }: Props) {
    const renderIcon = (attr: SelectableArticleAttribute, key: number) => (
        <Tooltip key={key} title={attr.description}>
            <StyledIconButton
                size="small"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                className={classes(bem("buttonNumber", attr.value), attr.selected && "selected")}
                value={attr.value}
                onClick={() => onSelect?.(attr)}
            >
                {attr.value}
            </StyledIconButton>
        </Tooltip>
    )

    return <StyledIconsContainer>{items.map(renderIcon)}</StyledIconsContainer>
}

export const StyledIconsContainer = styled(Box)(({ theme }) => ({
    minWidth: "6em",
    ".selected": {
        outline: "1px",
        outlineColor: theme.palette.primary.main,
        outlineStyle: "auto",
    },
}))

export const StyledIconButton = styled(Button)<{ value: string }>(({ value, theme }) => ({
    cursor: "default",
    minWidth: "24px",
    height: "24px",
    marginLeft: "0.2em",
    backgroundColor: getOldTyreLabelColor(value, theme),
    color: theme.palette.common.white,
    textShadow: "0 0 3px #202020",
    "&:hover": {
        backgroundColor: getOldTyreLabelColor(value, theme),
        textShadow: "none",
    },
}))

import { createElement, ReactNode } from "react"
import { Switch } from "react-router"
import { Subject } from "rxjs"
import type { IContext, SwitchConfig, Transmission } from ".."
import { createAbsoluteRoute } from "./createAbsoluteRoute"
// eslint-disable-next-line
import { createModule } from "./createModule"

// Erstellt einen Switch, der abhängig der URL zwischen Untermodulen umschalten kann
export function createSwitch(
    context: IContext,
    key: string,
    switchConfig: SwitchConfig,
    moduleActions$: Subject<Transmission>,
    parentKey: string,
    parentRoute: string
): ReactNode {
    const className = `layout__switch ${switchConfig.className ? switchConfig.className : ""}`
    const switchKey = `${parentKey}/${key}`

    const children = Object.keys(switchConfig.children)
        .map((key) => {
            const childConfig = switchConfig.children[key]

            switch (childConfig.type) {
                case "module":
                    return createModule(context, key, childConfig, moduleActions$, switchKey, parentRoute)
                case "reference": {
                    const route = createAbsoluteRoute(childConfig.route, parentRoute)
                    // context.routes.push(route)
                    let moduleConfig = context.config.modules[childConfig.name]

                    if (!moduleConfig) {
                        console.warn(`Reference [${childConfig && childConfig.name}] not found`)
                        return null
                    }

                    moduleConfig = {
                        ...moduleConfig,
                        props: {
                            ...(moduleConfig.props || {}),
                            ...(childConfig.props || {}),
                        },
                        broadcast: [
                            ...(childConfig.broadcast
                                ? Array.isArray(childConfig.broadcast)
                                    ? childConfig.broadcast
                                    : [childConfig.broadcast]
                                : []),
                            ...(moduleConfig.broadcast
                                ? Array.isArray(moduleConfig.broadcast)
                                    ? moduleConfig.broadcast
                                    : [moduleConfig.broadcast]
                                : []),
                        ],
                    }

                    return createModule(context, childConfig.name, moduleConfig, moduleActions$, switchKey, route.path)
                }
                default:
            }
        })
        .filter((x) => !!x)
        .sort((a: any, b: any) => {
            let order = 0
            const aFragCount = a.props.path.split("/").filter((x: string) => !!x).length
            const bFragCount = b.props.path.split("/").filter((x: string) => !!x).length
            order = aFragCount < bFragCount ? 1 : aFragCount == bFragCount ? 0 : -1
            if (order == 0) {
                order = countRouteParams(a.props.path) > countRouteParams(b.props.path) || b.props.path > a.props.path ? 1 : -1
            }
            return order
        })

    return createElement("div", { className, key }, createElement(Switch, undefined, children))
}

const paramCountRegex = /\/:/g

function countRouteParams(route: string): number {
    let result = 0
    while (paramCountRegex.exec(route)) {
        result++
    }
    return result
}

import { Component } from "react"
import Button from "../button"
import Icon from "../icon"
import { Text } from ".."
import bem from "bem-classnames"

export type AlertProps = {
    skin?: "warning" | "danger" | "success"
    buttonText?: string
    message: string
    description?: string
    icon?: string
    onButtonClick?(action: any): void
}

export default class Alert extends Component<AlertProps, any> {
    private static defaultProps: Partial<AlertProps> = {
        icon: "info",
        skin: undefined,
        message: "",
    }

    render() {
        const alertClass = "alert"
        const iconSize = "l"

        const alert = {
            default: {
                name: `${alertClass}`,
                modifiers: ["long", "skin"],
            },
            icon: {
                name: `${alertClass}__icon`,
                modifiers: ["iconSize", "icon"],
            },
            description: {
                name: `${alertClass}__description`,
            },
            textWrapper: {
                name: `${alertClass}__text-wrapper`,
            },
            message: {
                name: `${alertClass}__message`,
            },
            closeButton: {
                name: `${alertClass}__close-button`,
            },
        }

        const { skin, description, buttonText, message, onButtonClick, icon } = this.props

        return (
            <div className={bem(alert.default, { ...this.props, long: !!message })}>
                <div className={bem(alert.icon, { ...this.props, iconSize })}>
                    <Icon name={this.setIcon(skin)} size={iconSize} />
                </div>
                <div className={bem(alert.textWrapper, this.props)}>
                    <Text className={bem(alert.message, this.props)}>{message}</Text>
                    <Text size="s" className={bem(alert.description, this.props)}>
                        {description}
                    </Text>
                </div>
                {onButtonClick && buttonText && (
                    <Button className={bem(alert.closeButton, this.props)} layout={["ghost"]} icon="close" onClick={onButtonClick} />
                )}
            </div>
        )
    }

    setIcon(skin?: "warning" | "danger" | "success") {
        let iconName
        switch (skin) {
            case "warning": {
                iconName = "attention-dark"
                break
            }
            case "danger": {
                iconName = "attention-light"
                break
            }
            case "success": {
                iconName = "success-dark"
                break
            }
            default: {
                iconName = "information-light"
                break
            }
        }
        return iconName
    }
}

import { ConfigParams, RegistrationNoType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { getStoredAuthorization } from "@tm/utils"
import { Location } from "history"
import { User, useUser } from "../user"
import { useWorkTask, WorkTask } from "../worktask"

// Only Tags that start with a "#"
const availableTags = [
    "HOSTETTLER_DOM",
    "HOSTETTLER_KTYPNR",
    "HOSTETTLER_LOCALE",
    "HOSTETTLER_RUB",
    "HOSTETTLER_SHOP_RUB",
    "HOSTETTLER_SRV",
    "HSN",
    "KHER",
    "KTYPNR",
    "LANGUAGE",
    "LICENSEPLATE",
    "ORDERID",
    "PRICEFILTER",
    "PRICEFILTER_NUMBER",
    "PRODUCT_GROUP_ID",
    "QUANTITY",
    "REGISTRATIONNO",
    "SEARCHQUERY",
    "SUPPLIERARTICLENUMBER",
    "SUPPLIERNUMBER",
    "TOKEN",
    "TSN",
    "URLAPPENDIX",
    "VSN",
    "WHOLESALERARTICLENUMBER",
    "WORKTASKID",
    "WORKTASK_CUSTOMER_ID",
    "WORKTASK_VEHICLE_ID",
    "WORKTASK_VEHICLE_TYPE",
] as const

type UrlTag = (typeof availableTags)[number]

export function getUrlTags() {
    return availableTags
}

// UrlTagsProps musst match in availableTags
export type UrlTagsProps = {
    kbaNumbers?: string[]
    languageId?: string
    location?: Location
    orderId?: string
    productGroupId?: number
    quantity?: number
    supplierArticleNumber?: string
    supplierNumber?: number
    wholesalerArticleNumber?: string
}

export function useReplaceUrlTags(url: string | undefined, props: UrlTagsProps) {
    const userContainer = useUser()
    const workTaskContainer = useWorkTask()

    if (!url) {
        return
    }

    return replaceUrlTags(url, props, userContainer, workTaskContainer)
}

export function replaceUrlTags(url: string, props: UrlTagsProps, userContainer?: User, workTaskContainer?: WorkTask) {
    const { userContext, userSettings } = userContainer ?? {}
    const { workTask, workTaskId } = workTaskContainer ?? {}

    let error = false
    const { location, kbaNumbers, languageId, quantity, wholesalerArticleNumber, supplierArticleNumber, supplierNumber, productGroupId, orderId } =
        props
    let match

    let kbaNumber = new URLSearchParams(location?.search).get("kba")
    if (!kbaNumber) {
        if (workTask?.vehicle?.registrationNo && workTask?.vehicle?.registrationTypeId === RegistrationNoType.Kba) {
            kbaNumber = workTask.vehicle.registrationNo
        } else if (kbaNumbers && kbaNumbers.length && kbaNumbers[0]) {
            kbaNumber = kbaNumbers[0]
        }
    }

    while ((match = RegExp("{#(.+?)}", "g").exec(url)) !== null) {
        const tag = match[1]
        let queryValue = ""

        switch (tag.toUpperCase() as UrlTag) {
            // Alphabetically sorted
            case "HOSTETTLER_DOM": {
                if (languageId) {
                    if (languageId == "6" || languageId == "7") {
                        queryValue = "2"
                    } else {
                        queryValue = "1"
                    }
                }
                break
            }
            case "HOSTETTLER_KTYPNR": // TODO: This should be removed and replaced by "KTYPNR" in code and in MDM
            case "KTYPNR": {
                queryValue = workTask?.vehicle?.tecDocTypeId?.toString() || ""
                break
            }
            case "HOSTETTLER_LOCALE": {
                if (languageId) {
                    if (languageId == "6" || languageId == "7") {
                        queryValue = "fr_CH"
                    } else {
                        queryValue = "de_CH"
                    }
                }
                break
            }
            case "HOSTETTLER_RUB": {
                if (workTask?.vehicle) {
                    if (languageId) {
                        if (languageId == "6" || languageId == "7") {
                            queryValue = "78"
                        }
                    } else {
                        queryValue = "77"
                    }
                } else if (languageId) {
                    if (languageId == "6" || languageId == "7") {
                        queryValue = "75"
                    }
                } else {
                    queryValue = "74"
                }
                break
            }
            case "HOSTETTLER_SHOP_RUB": {
                if (workTask?.vehicle) {
                    if (languageId) {
                        if (languageId == "6" || languageId == "7") {
                            queryValue = "2"
                        }
                    }
                } else {
                    queryValue = "1"
                }
                break
            }
            case "HOSTETTLER_SRV": {
                if (workTask?.vehicle) {
                    queryValue = "vehiculesearch"
                } else {
                    queryValue = "tiresearch"
                }
                break
            }
            case "HSN": {
                if (kbaNumber) {
                    const hsn = kbaNumber.replace(" ", "")
                    queryValue = hsn.substr(0, 4)
                }
                break
            }
            case "KHER": {
                queryValue = workTask?.vehicle?.tecDocManufacturerId?.toString() || ""
                break
            }
            case "LANGUAGE": {
                if (languageId) {
                    queryValue = languageId
                } else {
                    return
                }
                break
            }
            case "LICENSEPLATE": {
                queryValue = workTask?.vehicle?.plateId || ""
                break
            }
            case "ORDERID": {
                if (orderId) {
                    queryValue = encodeURIComponent(orderId)
                }
                break
            }
            case "PRICEFILTER": {
                if (userSettings) {
                    queryValue = userSettings.showPurchasePrice ? "false" : "true"
                } else {
                    return
                }
                break
            }
            case "PRICEFILTER_NUMBER": {
                if (userSettings) {
                    queryValue = userSettings.showPurchasePrice ? "0" : "1"
                } else {
                    return
                }
                break
            }
            case "PRODUCT_GROUP_ID": {
                if (productGroupId) {
                    queryValue = encodeURIComponent(productGroupId)
                }
                break
            }
            case "QUANTITY": {
                if (quantity) {
                    queryValue = encodeURIComponent(quantity)
                }
                break
            }
            case "REGISTRATIONNO": {
                queryValue = workTask?.vehicle?.registrationNo || ""
                break
            }
            case "SEARCHQUERY": {
                if (location) {
                    queryValue = new URLSearchParams(location.search).get("query") || queryValue
                } else {
                    return
                }
                break
            }
            case "SUPPLIERARTICLENUMBER": {
                if (supplierArticleNumber) {
                    queryValue = encodeURIComponent(supplierArticleNumber)
                }
                break
            }
            case "SUPPLIERNUMBER": {
                if (supplierNumber) {
                    queryValue = encodeURIComponent(supplierNumber)
                }
                break
            }
            case "TOKEN": {
                // NEXT portal token
                const authorization = getStoredAuthorization()
                if (authorization) {
                    queryValue += `${authorization.type} ${authorization.credentials}`
                }
                break
            }
            case "TSN": {
                if (kbaNumber) {
                    const tsn = kbaNumber.replace(" ", "")
                    queryValue = tsn.substr(4, 3)
                }
                break
            }
            case "URLAPPENDIX": {
                if (location) {
                    queryValue = new URLSearchParams(location.search).get("frameUrlAppendix") || queryValue
                }
                break
            }
            case "VSN": {
                if (kbaNumber) {
                    const vsn = kbaNumber.replace(" ", "")
                    if (vsn.length > 7) {
                        queryValue = vsn.substr(7)
                    }
                }
                break
            }
            case "WHOLESALERARTICLENUMBER": {
                if (wholesalerArticleNumber) {
                    queryValue = encodeURIComponent(wholesalerArticleNumber)
                }
                break
            }
            case "WORKTASKID": {
                if (workTaskId) {
                    queryValue += workTaskId
                }
                break
            }
            case "WORKTASK_CUSTOMER_ID": {
                queryValue = workTask?.customer?.id || ""
                break
            }
            case "WORKTASK_VEHICLE_ID": {
                queryValue = workTask?.vehicle?.id || ""
                break
            }
            case "WORKTASK_VEHICLE_TYPE": {
                queryValue = workTask?.vehicle?.vehicleType?.toString() || ""
                break
            }
            default: {
                // if (Object.keys(props.state).some(x => x == tag)) {
                //     const val = (props.state as Dictionary<any>)[tag]
                //     queryValue = val || "";
                // }

                // External authentication tokens
                if (tag.toUpperCase().startsWith("TOKEN:")) {
                    const tokenKey = tag.substr(6)
                    const { tokenHandler } = Morpheus.getParams<ConfigParams>()
                    const tokenKeyAlt = tokenHandler
                        ? Object.entries(tokenHandler).find(([key]) => key.toUpperCase() === tokenKey.toUpperCase())?.[1]
                        : undefined

                    // First look in the session storage ...
                    const tokenStorage = sessionStorage.getItem(`token://${tokenKey}`) ?? sessionStorage.getItem(`token://${tokenKeyAlt}`)
                    if (tokenStorage) {
                        try {
                            queryValue = JSON.parse(tokenStorage).token
                        } catch {}
                    }

                    if (!queryValue && userContext) {
                        // ...then in the userContext
                        const token = Object.entries(userContext.externalAuthenticationTokens).find(
                            ([key]) => key.toUpperCase() === tokenKey.toUpperCase()
                        )?.[1]
                        if (token) {
                            queryValue = token
                        } else if (tokenKeyAlt) {
                            const tokenAlt = Object.entries(userContext.externalAuthenticationTokens).find(
                                ([key]) => key.toUpperCase() === tokenKeyAlt.toUpperCase()
                            )?.[1]
                            if (tokenAlt) {
                                queryValue = tokenAlt
                            }
                        }
                    }

                    error = !queryValue
                }
            }
        }

        url = url.replace(`{#${tag}}`, queryValue)
    }

    return error ? undefined : url
}

import { Component, PropsWithChildren, SyntheticEvent } from "react"
import { bindMethodsToContext } from "../../helper/bindObjectContext"
import { SizedProps } from "../../models/SharedModels"

export type HeadlineProps = PropsWithChildren<
    SizedProps & {
        onClick?(target: Headline): void
    }
>

export default class Headline extends Component<HeadlineProps> {
    constructor(props: HeadlineProps) {
        super(props)
        bindMethodsToContext(this, ["get"])
    }

    handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
        const { onClick } = this.props
        onClick && onClick(this)
    }

    render() {
        return (
            <div className={this.getClassName()} onClick={this.handleClick}>
                {this.props.children}
            </div>
        )
    }

    getClassName() {
        const { size, className } = this.props
        const baseName = "headline"
        let qualifiedClassName = `${baseName}`

        if (className) {
            qualifiedClassName = `${qualifiedClassName} ${className}`
        }

        if (size) {
            qualifiedClassName = `${qualifiedClassName} ${baseName}--${size}`
        }

        return qualifiedClassName
    }
}

import { useMemo } from "react"
import { useUser } from "../user/consumer/useUser"
import { SystemType } from "@tm/models"
import { useShowNewArticleList } from "./useShowNewArticleList"

export function useDisplayListV2() {
    const { userContext } = useUser()
    const showNewArticleList = useShowNewArticleList()
    const isNext = useMemo(() => userContext.system.systemType === SystemType.Next, [userContext])

    return isNext && showNewArticleList
}

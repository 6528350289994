import { ResizableBox, ResizableBoxProps } from "react-resizable"
import { styled } from "@mui/material"
import { ComponentType } from "react"

// Exported Styles from package because the css file couldnt be correctly interpreted. even when adding a node modules css handler
export const RezisablePopoverBox = styled(ResizableBox)(() => {
    return {
        overflow: "hidden",
        position: "relative",
        width: 700,
        height: 500,
        maxHeight: "95vh",

        ".react-resizable-handle": {
            position: "absolute",
            width: "20px",
            height: "20px",
            backgroundRepeat: "no-repeat",
            backgroundOrigin: "content-box",
            boxSizing: "border-box",
            backgroundImage: `url("data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2024%2024'%3E%3Cg%20transform='rotate(-45%2012%2012)'%3E%3Cpath%20d='M10.9%2C16c0.6%2C0.6%2C1.6%2C0.6%2C2.2%2C0l1.4-1.4l5-5c0.5-0.5%2C0.5-1.2%2C0-1.7c-0.5-0.5-1.2-0.5-1.7%2C0l-5%2C5c-0.5%2C0.5-1.2%2C0.5-1.7%2C0l-5-5c-0.5-0.5-1.2-0.5-1.7%2C0c-0.5%2C0.5-0.5%2C1.2%2C0%2C1.7l5%2C5l1.4%2C1.4z'/%3E%3C/g%3E%3C/svg%3E")`,
            backgoundPosition: "1px 1px",
            backgroundSize: 22,
            padding: "0 3px 3px 0",
        },

        ".react-resizable-handle-sw": {
            bottom: 0,
            left: 0,
            cursor: "sw-resize",
            transform: "rotate(90deg)",
        },
        ".react-resizable-handle-se": {
            bottom: 0,
            right: 0,
            cursor: "se-resize",
        },
        ".react-resizable-handle-nw": {
            top: 0,
            left: 0,
            cursor: "nw-resize",
            transform: "rotate(180deg)",
        },
        ".react-resizable-handle-ne": {
            top: 0,
            right: 0,
            cursor: "ne-resize",
            transform: "rotate(270deg)",
        },
        ".react-resizable-handle-w": {
            left: 0,
            transform: "rotate(90deg)",
            backgroundImage: `url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22enable-background%3Anew%200%200%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Crect%20x%3D%223%22%20y%3D%2211.001%22%20width%3D%2218%22%20height%3D%222%22/%3E%3C/svg%3E");`,
        },
        ".react-resizable-handle-e": {
            right: 0,
            transform: "rotate(90deg)",
            backgroundImage: `url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22enable-background%3Anew%200%200%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Crect%20x%3D%223%22%20y%3D%2211.001%22%20width%3D%2218%22%20height%3D%222%22/%3E%3C/svg%3E");`,
        },
        ".react-resizable-handle-n, .react-resizable-handle-s": {
            left: "50%",
            marginLeft: "-10px",
            cursor: "ns-resize",
            backgroundImage: `url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20style%3D%22enable-background%3Anew%200%200%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Crect%20x%3D%223%22%20y%3D%2211.001%22%20width%3D%2218%22%20height%3D%222%22/%3E%3C/svg%3E");`,
        },
        ".react-resizable-handle-n": {
            top: 0,
        },
        ".react-resizable-handle-s": {
            bottom: 0,
        },
        ".react-resizable-handle-w, .react-resizable-handle-e": {
            top: "50%",
            marginTop: "-10px",
            cursor: "ew-resize",
        },
    }
}) as ComponentType<ResizableBoxProps>

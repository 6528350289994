import "@tm/utils"

export default function formatCurrency(value: number, currency: string): string {
    if (value == null) {
        value = 0
    } else {
        value = Math.round(parseFloat(value.toFixed(3)) * 100) / 100
    }

    const decimal = 2
    const browserLanguage = navigator.language
    switch (browserLanguage) {
        case "de":
        case "de-AT":
        case "de-DE":
            return `${Intl.NumberFormat(browserLanguage, { minimumFractionDigits: decimal, maximumFractionDigits: decimal }).format(value)} ${currency ? ` ${currency}` : ""}`
        default:
            return `${currency ? ` ${currency}` : ""} ${Intl.NumberFormat(browserLanguage, { minimumFractionDigits: decimal, maximumFractionDigits: decimal }).format(value)}`
    }
}

import { Box, styled } from "@mui/material"
import { Image } from "../../../Image"
import { TileSeasonIconWithTyreEfficiency } from "./TileSeasonIconWithTyreEfficiency"
import { ArticleNumbers } from "../../../ArticleNumbers"
import { Supplier } from "../../../Supplier"
import { Descriptions } from "../../../Descriptions"
import { useDefaultArticleItemState } from "../../../ContextProviderStates/DefaultArticleItemState"
import { DefaultArticleErpContainer } from "../../../ErpContainer"
import { Transactions } from "../../../Transactions"

const StyledTopBox = styled(Box)(() => ({
    width: "100%",
    padding: "1rem 0.5rem 1rem 1rem",
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "space-between",
}))

const RightInnerContainer = styled(Box, {
    shouldForwardProp: (prop: string) => !["showExtendedAvailabilityContainer"].includes(prop),
})<{ showExtendedAvailabilityContainer?: boolean }>(({ showExtendedAvailabilityContainer }) => ({
    gap: 6,
    flexDirection: "column",
    alignItems: "end",
    display: "flex",
    ".availabilityContainer": {
        width: showExtendedAvailabilityContainer ? 104 : undefined,
        ".availability-icon-with-tour": {
            minWidth: 0,
        },
    },
    ".erpContainerGrid": {
        padding: "1rem 2rem 1rem 0",
        minHeight: "4.5rem",
        "& > div:last-of-type": {
            minWidth: "5.2em",
        },
    },
    ".transactions": {
        ".basketAmountField": {
            ".MuiInputBase-input": {
                paddingLeft: 4,
                width: 40,
            },
            "&[data-has-amountinfo] .MuiInputBase-input": {
                width: 56,
            },
        },
        ".addToBasketButton": {
            width: "5rem",
        },
        ".costEstimationButton .MuiButton-startIcon>svg.icon": {
            width: "auto",
        },
        justifyContent: "left",
        paddingBottom: ".5rem",
    },
}))

export function TyresTile() {
    const showExtendedAvailabilityContainer = useDefaultArticleItemState((x) => x.showExtendedAvailabilityContainer)

    return (
        <>
            <StyledTopBox>
                <Box display="flex" flex={1} justifyContent="center">
                    <Image size={130} marginTop={1} boxShadow="unset" />
                </Box>
                <Box display="flex" flexDirection="column">
                    <TileSeasonIconWithTyreEfficiency />
                </Box>
            </StyledTopBox>
            <Box sx={{ padding: "0 0.5rem 0 1rem" }}>
                <Supplier compactView marginTop={0} fitContent />
                <ArticleNumbers uniteNumbers />
                <Descriptions oneLine />
                <RightInnerContainer showExtendedAvailabilityContainer={showExtendedAvailabilityContainer}>
                    <DefaultArticleErpContainer />
                    <Transactions displayMoreMenuButton />
                </RightInnerContainer>
            </Box>
        </>
    )
}

import { useMemo, useRef, useState } from "react"
import { ArticleImageViewer } from "./ArticleImageViewer"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Image as ComponentsImage } from "../../generics/image"
import { ImageContainer } from "./ImageContainer"

export type ImageStylings = {
    size: number
    marginTop: number
    boxShadow?: string
}

type ImageProps = {
    size?: number
    marginTop?: number
    boxShadow?: string
}

const IMAGE_SIZE_EXPANDED_LIST = 62
const IMAGE_SIZE_COMPACT_LIST = 38
const IMAGE_MARGIN_TOP_EXPANDED_LIST = -11
const IMAGE_MARGIN_TOP_COMPACT_LIST = -9

export function Image({ size, marginTop, boxShadow }: ImageProps) {
    const handleClickImage = useDefaultArticleItemState((x) => x.handleClickImage)
    const handleCloseImage = useDefaultArticleItemState((x) => x.handleCloseImage)
    const showImageViewer = useDefaultArticleItemState((x) => x.showImageViewer)
    const expandedList = useDefaultArticleItemState((x) => x.expanded)
    const article = useDefaultArticleItemState((x) => x.article)
    const { thumbnailUrl, thumbnailDescription, thumbnailFallbackUrl, customThumbnailComponent } = useDefaultArticleItemState(
        (x) => x.displayArticle.image
    )

    const [useFallbackThumbnail, setUseFallbackThumbnail] = useState(false)
    const [imageStylings, setImageStylings] = useState<ImageStylings>({ size: IMAGE_SIZE_EXPANDED_LIST, marginTop: IMAGE_MARGIN_TOP_COMPACT_LIST })
    const showArticleImages = useDefaultArticleItemState((x) => x.options.showArticleImages)

    const imageContainerRef = useRef<HTMLDivElement>(null)

    useMemo(() => {
        if (size && marginTop) {
            return setImageStylings({
                size,
                marginTop,
                boxShadow,
            })
        }
        setImageStylings({
            size: expandedList ? IMAGE_SIZE_EXPANDED_LIST : IMAGE_SIZE_COMPACT_LIST,
            marginTop: expandedList ? IMAGE_MARGIN_TOP_EXPANDED_LIST : IMAGE_MARGIN_TOP_COMPACT_LIST,
        })
    }, [expandedList])

    if (!showArticleImages) {
        return null
    }

    const handleThumbnailLoadError = () => {
        if (thumbnailFallbackUrl) {
            setUseFallbackThumbnail(true)
        }
    }

    const popoverTitle = `${article.supplier.name}${article.supplierArticleNo ? ` | ${article.supplierArticleNo}` : ""}`

    return (
        <div>
            <ImageContainer imageStylings={imageStylings} data-clickable ref={imageContainerRef}>
                <ComponentsImage
                    type="article"
                    src={!useFallbackThumbnail ? thumbnailUrl : (thumbnailFallbackUrl ?? "")}
                    title={thumbnailDescription}
                    onClick={handleClickImage}
                    onError={handleThumbnailLoadError}
                    loading="lazy"
                    height={`${imageStylings.size - 2}px`}
                    width={`${imageStylings.size - 2}px`}
                />
                {customThumbnailComponent}
            </ImageContainer>
            {showImageViewer && <ArticleImageViewer anchorEl={imageContainerRef.current} onClose={handleCloseImage} title={popoverTitle} />}
        </div>
    )
}

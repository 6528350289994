import { Box, Skeleton, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { TreeNode as TreeNodeModel } from "@tm/models"
import { useMemo } from "react"
import { Bestseller } from "./Bestseller"
import { TreeNode } from "./TreeNode"

const templateColumn2Row = "repeat(2, minmax(0, 1fr))"
const templateColumn3Rows = "repeat(3, minmax(0, 1fr))"

const NodesGridWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "gridTemplateColumns",
})<{ gridTemplateColumns: string }>(({ gridTemplateColumns }) => ({
    display: "grid",
    gridTemplateColumns,
    padding: "0.5em",
    height: "100%",
}))

const NodesGrid = styled("div", {
    shouldForwardProp: (prop) => prop !== "gridTemplateColumns",
})<{ gridTemplateColumns: string }>(({ gridTemplateColumns }) => ({
    display: "grid",
    gridTemplateColumns,
    gridAutoFlow: "columns",
    columnGap: "1em",
    alignContent: "start",
    rowGap: "0.5em",
    overflow: "auto",
    paddingTop: "0.2em",
    paddingBottom: "0.1em",
}))
type Props = {
    bestseller?: boolean
    selectedNode?: TreeNodeModel
    onNodeClick: (node: TreeNodeModel, fastClick?: boolean) => void
    isLoading?: boolean
    applyProductGroups?: (productGroupIds: number[]) => void
    fastCalculation?: (productGroupIds: number[]) => void
    hideFastCalcButton?: boolean
    maxSelectableProductGroups?: number
    variant: "default" | "small"
    nodeVariant?: "fastClick" | "default" | undefined
    fastCalculatorDisabled?: boolean
}

export function ListView(props: Props) {
    const { bestseller, selectedNode, isLoading, fastCalculatorDisabled } = props
    const { translateText } = useLocalization()
    const isSmallVariant = props.variant === "small"
    const maxElements = isSmallVariant ? 12 : 30

    const skeletons = useMemo(() => {
        return Array.from(Array(maxElements).keys()).map((_, index) => {
            return (
                <Box key={`SKel_${index}`} flexDirection="row" display="flex">
                    <Skeleton sx={{ mr: "6px" }} variant="circular" height={30} width={30} />
                    <Skeleton height="28px" width={Math.floor(Math.random() * (250 - 151) + (isSmallVariant ? 50 : 150))} />
                </Box>
            )
        })
    }, [maxElements, isSmallVariant])

    const nodesGridTemplateColumns = isSmallVariant ? templateColumn2Row : templateColumn3Rows

    const wrapperGridTemplateColumns = isSmallVariant ? "1fr" : "2fr 1fr"

    if (isLoading) {
        return (
            <NodesGrid gridTemplateColumns={nodesGridTemplateColumns}>
                {skeletons.map((skel) => {
                    return skel
                })}
            </NodesGrid>
        )
    }

    if (selectedNode?.hasChildNodes) {
        return (
            // Max height makes it possible to scroll in the best seller list when it has more items than the viewport can show.
            <NodesGridWrapper gridTemplateColumns={wrapperGridTemplateColumns} className="nodes-grid-wrapper" sx={{ maxHeight: "500px" }}>
                <NodesGrid gridTemplateColumns={nodesGridTemplateColumns} className="nodes-grid">
                    {props.selectedNode?.childNodes &&
                        props.selectedNode.childNodes.length > 0 &&
                        props.selectedNode?.childNodes.map((node) => {
                            return (
                                <TreeNode
                                    key={`listViewNode${node.id}`}
                                    node={node}
                                    onClick={props.onNodeClick}
                                    hideIcon
                                    variant={props.variant}
                                    nodeVariant={props.nodeVariant}
                                />
                            )
                        })}
                </NodesGrid>
                {!isSmallVariant && bestseller && props.selectedNode?.topProductGroups && props.selectedNode.hasChildNodes && (
                    <Bestseller
                        Bestseller={props.selectedNode?.topProductGroups}
                        onNodeClick={props.onNodeClick}
                        applyProductGroups={props.applyProductGroups}
                        fastCalculation={props.fastCalculation}
                        hideFastCalcButton={props.hideFastCalcButton}
                        maxSelectableProductGroups={props.maxSelectableProductGroups}
                        fastCalculatorDisabled={fastCalculatorDisabled}
                    />
                )}
            </NodesGridWrapper>
        )
    }

    return <Box>{translateText(12775)}</Box>
}

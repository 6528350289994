import { FC, HTMLAttributes, PropsWithChildren, useCallback, useEffect, useState } from "react"
import { SortDirection, Stack, StackProps, styled } from "@mui/material"
import { LEFT_ROW_INDICATOR, ZINDEX_BASE } from "./configs"
import { CellContentPosition, TableVariants } from "./interfaces"
import { Icon } from "../../generics/Icons"

type TableColumnHeaderProps = {
    variant: TableVariants
    minWidth?: number | string
    fontWeight?: "bold" | "default"
    alignContent?: CellContentPosition
    backgroundColor?: "default" | "paper" | string
    textColor?: string
}

type TableColumnSortProps = {
    sortOrder?: SortDirection
}

type TableColumnHeaderType = FC<TableColumnHeaderProps & TableColumnSortProps & HTMLAttributes<HTMLSpanElement>>

function shouldForwardProp(prop: PropertyKey) {
    return !["variant", "minWidth", "fontWeight", "alignContent", "backgroundColor", "textColor"].includes(prop as string)
}

const TableColumnHeaderComponent: FC<TableColumnSortProps & HTMLAttributes<HTMLSpanElement>> = (props) => {
    const { sortOrder, children, ...rest } = props

    if (sortOrder !== undefined) {
        return (
            <span {...rest}>
                <TableColumnHeaderSortContent sortOrder={sortOrder}>{children}</TableColumnHeaderSortContent>
            </span>
        )
    }
    return <span {...rest}>{children}</span>
}

export const TableColumnHeader: TableColumnHeaderType = styled(TableColumnHeaderComponent, { shouldForwardProp })<TableColumnHeaderProps>(({
    theme,
    variant,
    minWidth,
    fontWeight,
    alignContent,
    backgroundColor,
    sortOrder,
    textColor,
}) => {
    const elementListOverwrites = theme.overwrites?.components?.elementList ?? {}
    const overwritesBorderLeftWidth = elementListOverwrites?.backgroundColor?.borderLeftWidth
    const borderLeftWidth = overwritesBorderLeftWidth || LEFT_ROW_INDICATOR

    return {
        ...theme.typography.label,
        ...(textColor && {
            color: textColor,
        }),
        cursor: sortOrder !== undefined ? "pointer" : "inherit",
        zIndex: ZINDEX_BASE + 1,
        opacity: 1,
        alignItems: "center",
        backgroundColor:
            backgroundColor === "default" || backgroundColor === undefined
                ? theme.palette.background.default
                : backgroundColor === "paper"
                  ? theme.palette.background.paper
                  : backgroundColor,
        position: "sticky",
        top: 0,
        textAlign: alignContent || "start",
        padding: variant === "small" ? theme.spacing(1, 0.5, 0.5, 0.5) : theme.spacing(1),
        ...(minWidth && {
            minWidth: typeof minWidth === "number" ? `${minWidth}px` : minWidth,
        }),

        ...(fontWeight === "bold" && {
            fontWeight: 800,
        }),

        ...(variant === "small" && {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: "12px",
        }),

        [`&:first-of-type`]: {
            paddingLeft: variant === "small" ? theme.spacing(0.8) : theme.spacing(2),
            borderLeft: `${borderLeftWidth}px solid transparent`,
        },
    }
})

export function TableColumnHeaderSortContent(props: PropsWithChildren<{ sortOrder?: SortDirection } & StackProps>) {
    const { children, sortOrder, ...stackProps } = props
    return (
        <Stack direction="row" alignItems="center" sx={{ cursor: "pointer" }} {...stackProps}>
            {children}
            <Stack ml={1}>
                <Icon name="up" size="10px" color={sortOrder === "asc" ? "#000" : "#999"} sx={{ marginBottom: "-1px" }} />
                <Icon name="down" size="10px" color={sortOrder === "desc" ? "#000" : "#999"} sx={{ marginTop: "-2px" }} />
            </Stack>
        </Stack>
    )
}

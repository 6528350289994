import { CategoryType, GetArticlesRequest, GetArticlesResponse, TmaEModule, Vehicle } from "@tm/models"
import { TmaEventTracking, getCategoryTypeFromUrl } from "../.."
import { AnalyticsData, BaseTrackingModel, QuerySearchType } from "../../models/models"
import { ArticleListErpInfoShownPayload } from "../article-list-erp-info-shown/event-handle"
import { PayloadExtraData, SearchFilter, SearchFilterCount, VehicleIdentificationDto } from "./models"
import { determineKeywordSearchOrigin, determineKeywordSearchOriginFromDashboard, determineOrigin, determineSearchTreeTmaModule } from "./originDetection"
import { getCatalogOrigin } from "../../helper/origin"
import { getQuerySearchType } from "./helper"


const TMA_EVENT = "ARTICLE_LIST_FILTERED"
export interface ArticleListViewOptions {
    compactView: boolean
    showArticleImages: boolean
    showVehicleRecordsFilters: boolean
}

export const ArticleListFilteredEventHelper = {
    ArticleListFiltered: {
        Event: {
            IncreaseStepNumber: async () => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    bundle: "parts",
                    component: "searchtree",
                    action: "increase-step-number",
                    type: "click",
                    source: "selected-filter",
                    payload: true,
                })
            },
            SwapSearchType: async (category: CategoryType) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    bundle: "parts",
                    component: "area-swap",
                    action: "swap-category",
                    type: "click",
                    source: "selected-filter",
                    payload: { category },
                })
            },
        },
        List: {
            Results: async (
                request: GetArticlesRequest,
                result: GetArticlesResponse | undefined,
                worktaskId: string,
                url: string,
                searchedNodeId?: string
            ) => {
                const match = /nodeId=(\d+)/.exec(document.location.search)
                const latestSearchedNodeId = match?.last()
                const vehicleData = TmaEventTracking.getRequestData("VEHICLE_SELECTION")
                const vehicleIdentification: VehicleIdentificationDto|undefined = vehicleData && {
                    typeId: vehicleData.dataSourceId!,
                    key: vehicleData.searchTerm!
                }

                if (!searchedNodeId || searchedNodeId == latestSearchedNodeId) {
                    const origin = determineOrigin()
                    const activeCategory = getCategoryTypeFromUrl(document.location.pathname)
                    // getArticles will be executed on every node click (from article list tree) so every request get his node id, so only the one with the same as in the url will be handle, if no nodeid is given it should be a direct or synonym search
                    TmaEventTracking.dispatchTrack({
                        tmaEvent: "ARTICLE_LIST_FILTERED",
                        bundle: "parts",
                        component: "repository",
                        action: "get-articles", // ["get-articles", "send-event"],
                        type: "request",
                        payload: {
                            request,
                            result,
                            worktaskId,
                            origin,
                            categoryType: activeCategory,
                            query: request.query,
                            searchType: getQuerySearchType(activeCategory),
                            vehicleIdentification,
                        },
                        source: url,
                        contextData: request.pageIndex
                            ? {
                                  paging: {
                                      pageIndex: request.pageIndex,
                                  },
                              }
                            : undefined,
                    })
                }

                const payload: ArticleListErpInfoShownPayload = { request, result }
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ArticleList_ErpInfo_Shown",
                    bundle: "parts",
                    component: "repository",
                    action: "get-articles",
                    type: "request",
                    payload,
                    source: url,
                    contextData: request.pageIndex
                        ? {
                              paging: {
                                  pageIndex: request.pageIndex,
                              },
                          }
                        : undefined,
                })
            },
            FilterChanged: async (data: AnalyticsData, source: string) => {
                TmaEventTracking.dispatchTrack({
                    action: "trackAnalyticsData", // source == "getOeReferenceFilters" ? "trackAnalyticsData" : ["trackAnalyticsData", "increase-step-number"], // maybe refactor after artile list refactoring, *1
                    bundle: "parts",
                    component: "helper",
                    payload: data,
                    source,
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    type: "request",
                })
            },
            OePositionChanged: async (oeNumber: string) => {
                TmaEventTracking.dispatchTrack({
                    action: "oe-position-changed",
                    bundle: "parts",
                    component: "list",
                    payload: { oeNumber },
                    source: "button",
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    type: "click",
                })
            },
            ViewOptions: async (viewOptions: ArticleListViewOptions) => {
                const trackingData: BaseTrackingModel<{ viewOptions: ArticleListViewOptions }> = {
                    action: "viewoptions-set",
                    bundle: "parts",
                    component: "list",
                    source: "dropdown",
                    tmaEvent: TMA_EVENT,
                    type: "click",
                    payload: {
                        viewOptions,
                    },
                }

                TmaEventTracking.dispatchTrack(trackingData)
            },
            ViewOptionsChanged: async (viewOptions: ArticleListViewOptions) => {
                const trackingData: BaseTrackingModel<{ viewOptions: ArticleListViewOptions }> = {
                    // atm i dont know if we should do this. i like it a lot but complexity and array order is important, mb renaming to actionQueue would help 😣
                    action: "viewoptions-changed", // ["increase-step-number", "viewoptions-changed, "send-event"],
                    bundle: "parts",
                    component: "list",
                    source: "dropdown",
                    tmaEvent: TMA_EVENT,
                    type: "click",
                    payload: {
                        viewOptions,
                    },
                }

                TmaEventTracking.dispatchTrack(trackingData)
            },
        },
        Search: {
            AttachVehicle: (vehicle?: Vehicle) => {
                TmaEventTracking.dispatchTrack({
                    action: "cdm",
                    bundle: "parts",
                    type: "component",
                    source: "worktask-vehicle",
                    component: "list",
                    payload: vehicle,
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                })
            },
            Direct: (query: string, fromWidget: boolean = false) => {
                const origin = determineKeywordSearchOrigin("directSearch", fromWidget)

                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "keyword-search-context",
                    bundle: "parts",
                    component: "partslist",
                    payload: {
                        origin,
                        query,
                        categoryType: "directSearch",
                    },
                    source: "widget",
                    type: "render",
                })
            },
            Assistent: (targetUrl: string, query: string) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "keyword-search-redirect",
                    bundle: "parts",
                    component: "partslist",
                    payload: {
                        origin: TmaEModule.PREMIUMUNIPARTSSEARCH_SEARCH_ASSISTENT,
                        query,
                        categoryType: "directSearch",
                        targetUrl,
                    },
                    source: "widget",
                    type: "render",
                })
            },
            WithRedirect: (targetUrl: string, query: string, eventStartOrigin: TmaEModule) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "keyword-search-redirect",
                    bundle: "parts",
                    component: "partslist",
                    payload: {
                        origin: eventStartOrigin,
                        query,
                        categoryType: "directSearch",
                        targetUrl,
                    },
                    source: "widget",
                    type: "render",
                })
            },
            Parts: (query: string, fromWidget: boolean = false) => {
                const origin = determineKeywordSearchOriginFromDashboard("vehicleParts", fromWidget)
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "keyword-search-context",
                    bundle: "parts",
                    component: "partslist",
                    payload: {
                        origin,
                        query,
                        categoryType: "vehicleParts",
                    },
                    source: "widget",
                    type: "render",
                })
            },
            UtilityNoClick: (query: string) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "keyword-search-utilityno-click",
                    bundle: "parts",
                    payload: {
                        origin: TmaEModule.ARTICLE_LIST_UTILITY_NUMBER,
                        query,
                        categoryType: "directSearch",
                    },
                    source: "parts",
                    component: "articleitem",
                    type: "click",
                })
            },
            KeywordSearch: (query: string, categoryType: CategoryType, fromWidget: boolean = false, workTaskId?: string) => {
                const origin = determineKeywordSearchOrigin(categoryType, fromWidget)

                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "keyword-search-context",
                    bundle: "parts",
                    component: "navigation",
                    payload: {
                        origin,
                        query,
                        workTaskId,
                        searchType: categoryType === "directSearch" ? QuerySearchType.Direct : QuerySearchType.Synonym,
                    },
                    source: "widget",
                    type: "render",
                })
            },
            SetSearchContext: (categoryType: CategoryType, query: string, workTaskId?: string, fromWidget?: boolean) => {
                const origin = determineKeywordSearchOrigin(categoryType, fromWidget)
                const searchType = getQuerySearchType(categoryType || getCategoryTypeFromUrl(document.location.pathname))

                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "keyword-search-context",
                    bundle: "parts",
                    component: "navigation",
                    payload: {
                        searchType,
                        query,
                        workTaskId,
                        origin,
                    },
                    source: "widget",
                    type: "render",
                })
            },
            SetSearchContextV2: (origin: TmaEModule, query: string, searchType?: QuerySearchType) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "keyword-search-context",
                    bundle: "parts",
                    component: "navigation",
                    payload: {
                        origin,
                        query,
                        searchType,
                    },
                    source: "widget",
                    type: "render",
                })
            },
            Submit: (origin: TmaEModule, query: string, searchType?: QuerySearchType) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "handleSearch",
                    bundle: "parts",
                    component: "SearchComponent",
                    payload: {
                        query,
                        origin,
                        searchType,
                    },
                    source: "searchfield",
                    type: "request",
                })
            },
            SubmitExternal: (query?: string, eventStartOrigin?: TmaEModule, extraData?: PayloadExtraData) => {
                const origin = eventStartOrigin ?? getCatalogOrigin()

                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "handleExternalSearch",
                    bundle: "api",
                    component: "post-message-controller",
                    payload: {
                        query,
                        origin,
                        extraData,
                    },
                    source: "searchfield",
                    type: "request",
                })
            },
            TreeChanged: (mode?: "fastClick" | "fast-click-2.0", thumbnailClicked?: boolean) => {
                TmaEventTracking.dispatchTrack({
                    // move this to business actions? function changeBreadcrumbs(breadcrumbs: Array<Models.TreeNode>, startSearch?: boolean): AsyncAction<ComponentActionType, WidgetState> {
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    bundle: "parts",
                    component: "searchtree",
                    action: "handleChangeBreadcrumbs",
                    type: "search",
                    source: thumbnailClicked ? "thumbnail" : "label",
                    payload: {
                        mode: mode || "fast-click-2.0",
                    },
                })
            },
            NodeSelected: (data: {
                mode?: "fastClick" | "fast-click-2.0" | "buttons" | "hide"
                nodeId: number
                treeId?: number
                origin?: TmaEModule
            }) => {
                // not clear when and how and why to use this, it's implemented in handleSelectNode, but there is no further handling
                const eventOrigin = data.origin || determineSearchTreeTmaModule(getCatalogOrigin(), data.mode)
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "nodeSelected",
                    // source: data.mode || "fast-click-2.0",
                    source: "useless",
                    payload: {
                        ...data,
                        origin: eventOrigin,
                    },
                    bundle: "parts",
                    component: "shared/tree",
                    type: "commit",
                })
            },
            AutoCompleteResults: (hits: string[]) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "autocomplete",
                    bundle: "parts",
                    component: "repository",
                    payload: { hits },
                    source: "product-group-search",
                    type: "request",
                })
            },
            /**
             * this is used inside the old articlelist searchfulters component
             * transfers a qualified array of searchtypes
             * @param searchFilters
             */
            SetSearchFilters: async (searchFilters: Array<SearchFilter>) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "set-search-filters",
                    bundle: "parts",
                    component: "search",
                    payload: { searchFilters },
                    source: "directSearch",
                    type: "request",
                })
            },
            /**
             * used to catch the result of the searchfilter count request
             * @param searchFiltersCount response of the request
             */
            SetSearchFiltersCount: async (searchFiltersCount: Array<SearchFilterCount>) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "set-search-filters-count",
                    bundle: "parts",
                    component: "search",
                    payload: { searchFiltersCount },
                    source: "directSearch",
                    type: "request",
                })
            },
            InitSearchFilters: async (searchFilters: Array<SearchFilter>) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "init-search-filters",
                    payload: { searchFilters },

                    bundle: "parts",
                    component: "search",
                    source: "directSearch",
                    type: "request",
                })
            }
        },
    },
    Tree: {
        SelectNode: (props: any, node: any, group: any) => {
            TmaEventTracking.dispatchTrack({
                action: "handleSelectNode",
                bundle: "parts",
                component: "shared/tree",
                payload: {
                    props,
                    node,
                    group,
                },
                source: props.mode || "fast-click-2.0",
                tmaEvent: "ARTICLE_LIST_FILTERED",
                type: "commit",
            })
        },
    },
    Parts: {
        Search: {
            AutoCompleteResults: (hits: string[]) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "autocomplete",
                    bundle: "parts",
                    component: "repository",
                    payload: { hits },
                    source: "product-group-search",
                    type: "request",
                })
            },
        },
        /**
         * @deprecated use ArticleListFiltered namespace instead of Parts
         */
        List: {
            /**
             * @deprecated use ArticleListFiltered namespace instead of Parts
             */
            ViewOptions: (viewOptions: ArticleListViewOptions) => {
                const trackingData: BaseTrackingModel<{ viewOptions: ArticleListViewOptions }> = {
                    action: "viewoptions-set",
                    bundle: "parts",
                    component: "list",
                    source: "dropdown",
                    tmaEvent: TMA_EVENT,
                    type: "click",
                    payload: {
                        viewOptions,
                    },
                }

                TmaEventTracking.dispatchTrack(trackingData)
            },
        },
    },
}
/**
 * @deprecated ArticleListFilteredEvents was renamed to ArticleListFilteredEventHelper
 */
export const ArticleListFilteredEvents = ArticleListFilteredEventHelper

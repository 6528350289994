export enum TmaEModule {
    UNDEFINED = 0,
    ARTICLE_LIST = 1,
    ARTICLE_DETAILS = 2,
    ARTICLE_DETAILS_PARTSLIST = 3,
    AERTICLE_DETAILS_ACCESSORYLIST = 4,
    ARTICLE_COMPARISON = 5,
    ARTICLE_ERPINFOFORM = 6,
    REPTIMES_AWDOC = 7,
    REPTIMES_AUTODATA = 8,
    REPTIMES_AUDACON = 9,
    SERVICE_HAYNES = 10,
    SERVICE_AUTODATA = 11,
    SERVICE_AUDACON = 12,
    TECH_HAYNES = 13,
    TECH_AUTODATA = 14,
    TECH_AUDACON = 15,
    GPI_EUROTAX = 16,
    GPI_DAT = 17,
    GPI_TEMOT = 18,
    REPESTIMATE_DAT = 19,
    REPESTIMATE_EUROTAX = 20,
    PUETZUNDPARTNER = 21,
    TYRES_JFNETWORK = 22,
    GLASS_AUDACON = 23,
    GLASS_AUDATEX = 24,
    GLASS_SAINTGOBAIN = 25,
    SPECIALOFFER = 26,
    HELLAGUTMANNSCHNITTSTELLE = 27,
    ESI = 28,
    DOSI = 29,
    BARCODESCANNER = 30,
    MANUALDEFINEDARTICLE = 31,
    FILEIMPORT = 32,
    EXTERNALCATALOGUE = 33,
    DRIVERIGHT = 34,
    DIRECTBUY = 35,
    TRUCK = 36,
    OEREPLACEMENTCHAIN = 37,
    PREMIUMUNIPARTSSEARCH = 38,
    TRUCKVIN = 39,
    TRUCKGARAGE = 40,
    SHOPPING_CART = 41,
    COMPACT_SHOPPING_CART = 42,
    START_PAGE = 43,
    VEHICLE_GRAPHIC_SEARCH = 44,
    SIDE_PANE = 45,
    FAST_CLICK = 46,
    MINI_FAST_CLICK = 47,
    ARTICLE_SEARCH_TREE = 48,
    QUICK_START = 49,
    TOP_GENERIC_ARTICLE = 50,
    PRODUCT_GROUP_KEYWORD_SEARCH = 51,
    ARTICLES_HISTORY_SIDE_PANE = 52,
    VEHICLES_HISTORY_SIDE_PANE = 53,
    ARTICLES_HISTORY_START_PAGE = 54,
    VEHICLES_HISTORY_START_PAGE = 55,
    VEHICLE_SEARCH_TREE = 56,
    CROSS_SELLING = 57,
    TRUCKVIN_ALTERNATIVE_ARTICLE = 58,
    TRUCKVIN_REPLACE_ARTICLE = 59,
    LOGIN_HIDDEN = 60,
    ARTICLE_LIST_UTILITY_NUMBER = 61,
    CONTROL_FILE = 62,
    DLL_INTERFACE = 63,
    KFZ_WIN_INTERFACE = 64,
    GPI_AUDACON = 65,
    TYRES_AUDACON = 66,
    ARTICLE_RELATED_VEHICLE_SELECTION = 67,
    ARTICLE_DIRECT_SEARCH = 68,
    ARTICLE_DIRECT_SEARCH_START_PAGE = 69,
    ARTICLE_DIRECT_SEARCH_SIDE_PANE = 70,
    ARTICLE_DIRECT_SEARCH_ARTICLE_LIST = 71,
    PREMIUMUNIPARTSSEARCH_START_PAGE = 72,
    PREMIUMUNIPARTSSEARCH_SIDE_PANE = 73,
    PREMIUMUNIPARTSSEARCH_ARTICLE_LIST = 74,
    TYRE_SEARCH_START_PAGE = 75,
    CUSTOMER_INFORMATION_SYSTEM_GARAGE = 76,
    CUSTOMER_INFORMATION_SYSTEM_WHOLESALER = 77,
    TYRE_CONFIGURATOR = 78,
    DIRECTBUY_START_PAGE = 79,
    DIRECTBUY_SIDE_PANE = 80,
    UNIPARTSSEARCH = 81,
    UNIPARTSSEARCH_PRODUCT_GROUP_KEYWORD_SEARCH = 82,
    PREMIUMUNIPARTSSEARCH_SEARCH_ASSISTENT = 83,
    ARTICLE_DIRECT_SEARCH_SEARCH_ASSISTENT = 84,
    TYRE_SEARCH_SIDE_PANE = 85,
    ARTICLE_DIRECT_OE_SEARCH_SIDE_PANE = 86,
    BREAD_CRUMBS_HISTORY = 87,
    ARTICLE_DIRECT_WITH_ONLY_OE_SEARCH_SIDE_PANE = 88,
    PRODUCT_RECOMMENDATION = 89,
    ARTICLE_DETAILS_PARTSLIST_SENSITIVE = 90,
    MY_TRUCK = 91,
    REPTIMES_HAYNES = 92,
    FAST_CALCULATOR = 93,
    DASHBOARD = 94,
    ARTICLE_DIRECT_SEARCH_DASHBOARD = 95,
    PRODUCT_GROUP_KEYWORD_SEARCH_ARTICLE_LIST = 96,
    PRODUCT_GROUP_KEYWORD_SEARCH_DASHBOARD = 97,
    FAST_CLICK_ARTICLE_LIST = 98,
    FAST_CLICK_DASHBOARD = 99,
    FAST_CLICK_V2 = 100,
    FAST_CLICK_V2_ARTICLE_LIST = 101,
    FAST_CLICK_V2_DASHBOARD = 102,
    FAST_CHECK = 103,
    DAT_FAST_CALCULATION = 104,
    WHEEL_CONFIGURATOR = 105,
    FAST_CLICK_UNIPARTSSEARCH = 106,
    FAST_CLICK_V2_UNIPARTSSEARCH_DASHBOARD = 107,
    FAST_CLICK_V2_UNIPARTSSEARCH_ARTICLE_LIST = 108,
    ARTICLE_COMPILATIONS = 109,
    PARTS_INDICATOR = 110,
    TM_TYRE_SEARCH = 111,
    PREMIUMUNIPARTSSEARCH_DASHBOARD = 112,
    MY_TRUCK_PLUS = 113,
    GLASS_REPESTIMATE_DAT = 114,
    FAST_SERVICE = 115,
    FAST_DTC = 116,
    EXTERNAL_DATA_SOURCE = 117,
    ARTICLE_DETAILS_ALTERNATIVE_ARTICLE = 118,
}

import { Component } from "react"

export function bindMethodsToContext(component: Component, conditions: Array<string> = []) {
    const comp = component as any
    conditions.push("render", "handle")
    Object.getOwnPropertyNames(comp.__proto__).forEach((key) => {
        conditions.forEach((condition) => {
            if (key.indexOf(condition) == 0 && typeof comp[key] == "function") {
                comp[key] = comp[key].bind(comp)
            }
        })
    })
}

import { VrmLookupTextIds } from "@tm/models"
import { ButtonKeyDefinition, clone, getValue, registerOutsideClick, setValue } from "@tm/utils"
import { createRef, Component } from "react"
import ReactDOM from "react-dom"
import { Badge, Button, Icon, SubTitle, Text } from ".."
import { bindMethodsToContext } from "../../helper"
import Loader from "../loader"
import Scrollbar from "../scrollbar"
import Table from "../table"
import TextField, { TextFieldProps } from "../text-field"

export type SuggestionTextFieldProps = TextFieldProps & {
    getSuggestionData?: (query: string) => Array<any>
    getSuggestionDataAsync?: (query: string) => Promise<Array<any>>
    renderTableColumns?: () => Array<JSX.Element>
    translateText?(key: string | number): string
    validateVin?(): void
    maxHeight?: number
    usePortal?: boolean
    vinSearchRequired?: boolean
    vinSearchOptional?: boolean
    datVinSearchIsValid?: boolean
    datPricePerRequest?: string
    vinSearchInformalMessage?: VrmLookupTextIds
    checkVinLoading?: boolean
}

export type SuggestionTextFieldState = {
    isOpen?: boolean
    suggestions?: Array<any>
    marked?: any
    loading?: boolean
    requestCount?: number
    showVinSearchArea?: boolean
}

export default class SuggestionTextField extends Component<SuggestionTextFieldProps, SuggestionTextFieldState> {
    private _suggestionTimeout: any

    private _scrollbar: any

    private _element: HTMLDivElement | undefined

    private _unregisterOutsideClickHandler?: () => void

    suggestionBoxRef = createRef<HTMLDivElement>()

    /**
     *
     */
    constructor(props: SuggestionTextFieldProps) {
        super(props)

        this.state = {
            isOpen: false,
            suggestions: [],
            marked: null,
            loading: false,
            requestCount: 0,
            showVinSearchArea: false,
        }
        bindMethodsToContext(this)
    }

    componentWillUnmount() {
        clearTimeout(this._suggestionTimeout)
        this._unregisterOutsideClickHandler && this._unregisterOutsideClickHandler()
    }

    componentDidUpdate(prevProps: SuggestionTextFieldProps, prevState: SuggestionTextFieldState) {
        // Returns if maxHeight is negative 1 (no max height)
        if (!this._scrollbar || this.props.maxHeight == -1) {
            return
        }
        const maxHeight = this.props.maxHeight || 200
        let { height } = this._scrollbar.view.children[0].getBoundingClientRect()

        if (this.state.suggestions && this.state.suggestions.length) {
            // Every row should have a min height of 36px to not be invisible
            const totalHeight = this.state.suggestions.length * 36
            if (height < totalHeight) {
                height = totalHeight
            }
        }

        if (height > maxHeight) {
            height = maxHeight
            const scrollerWidth = 12
            this._scrollbar.view.children[0].style.marginRight = `${scrollerWidth}px`
        } else {
            this._scrollbar.view.children[0].style.marginRight = undefined
        }
        this._scrollbar.container.style.height = `${height}px`

        if (!prevState.isOpen && this.state.isOpen && this._element && this.suggestionBoxRef.current) {
            const { top, left, width } = this._element.getBoundingClientRect()
            this.suggestionBoxRef.current.style.position = "absolute"
            this.suggestionBoxRef.current.style.top = `${top}px`
            this.suggestionBoxRef.current.style.left = `${left}px`
            this.suggestionBoxRef.current.style.minWidth = `${width}px`
        }
    }

    getQuery(props: SuggestionTextFieldProps): string {
        const { model, path, value } = props
        return (model && path ? getValue(model, path) : value) || null
    }

    loadSuggestions(query: string) {
        const { getSuggestionData, getSuggestionDataAsync } = this.props

        if (getSuggestionDataAsync) {
            const requestCount = this.state.requestCount ? this.state.requestCount + 1 : 0

            this.setState({
                isOpen: true,
                loading: true,
                requestCount,
                marked: null,
            })

            this._element && (this._unregisterOutsideClickHandler = registerOutsideClick(this._element, this.handleClose))

            clearTimeout(this._suggestionTimeout)

            this._suggestionTimeout = setTimeout(
                () =>
                    getSuggestionDataAsync(query).then(
                        (data: any) => {
                            if (requestCount == this.state.requestCount) {
                                this.setState({
                                    suggestions: data || [],
                                    loading: false,
                                })
                            }
                        },
                        () => {
                            this.setState({
                                suggestions: [],
                                loading: false,
                            })
                        }
                    ),
                250
            )
        } else if (getSuggestionData) {
            this.setState({
                isOpen: true,
                suggestions: getSuggestionData(query) || [],
                marked: null,
            })

            this._element && (this._unregisterOutsideClickHandler = registerOutsideClick(this._element, this.handleClose))
        }
    }

    handleRef(div: HTMLDivElement) {
        this._element = div
    }

    handleClose() {
        this.setState({
            isOpen: false,
            marked: null,
            suggestions: [],
            showVinSearchArea: false,
        })
    }

    handleKeyDown(e: any) {
        switch (e.key) {
            case ButtonKeyDefinition.ArrowUp: {
                this.handleMarkPreviousSuggestion()
                e.preventDefault()
                break
            }
            case ButtonKeyDefinition.ArrowDown: {
                this.handleMarkNextSuggestion()
                e.preventDefault()
                break
            }
        }
    }

    handleKeyUp(e: any) {
        const { marked } = this.state

        switch (e.key) {
            case ButtonKeyDefinition.Escape:
            case ButtonKeyDefinition.Tab: {
                this.handleClose()
                break
            }
            case ButtonKeyDefinition.Enter: {
                if (marked) {
                    this.handleSuggestionSelect(marked)
                } else {
                    this.handleClose()
                }

                break
            }
        }
    }

    handleMarkNextSuggestion() {
        const { suggestions, marked } = this.state
        if (suggestions) {
            this.setState({ marked: suggestions.next(marked) })
            this.handleScrollDown()
        }
    }

    handleMarkPreviousSuggestion() {
        const { suggestions, marked } = this.state
        if (suggestions) {
            this.setState({ marked: suggestions.previous(marked) || null })
            this.handleScrollUp()
        }
    }

    handleScrollDown() {
        this.handleScrolling(1)
    }

    handleScrollUp() {
        this.handleScrolling(-1)
    }

    handleScrolling(direction: 1 | -1) {
        const { marked, suggestions } = this.state

        if (!marked || !suggestions) {
            return
        }

        // TODO: refactor this
        const containerHeight = this._scrollbar.container.offsetHeight
        // container > scrollbar__view > fancy-list > fancy-list__body > first child, if there are suggestions a child should always exists
        const item = this._scrollbar.container.children[0].children[0].children[0].children.item(0)

        const marginBottom = parseInt(window.getComputedStyle(item).marginBottom || "0", 10)
        const marginTop = parseInt(window.getComputedStyle(item).marginTop || "0", 10)

        const itemHeight = item.offsetHeight + marginBottom + marginTop
        const itemsPerView = Math.round(containerHeight / itemHeight)

        const minIndexOfItem = Math.round((itemsPerView + 0.5) / 2)
        const maxIndexOfItem = suggestions.length - Math.round((itemsPerView + 0.5) / 2)

        if (suggestions.indexOf(marked) >= minIndexOfItem && suggestions.indexOf(marked) <= maxIndexOfItem) {
            const currentScrollTop = this._scrollbar.getValues().scrollTop
            const nextScrollStep = itemHeight * direction

            const scrollPosition = currentScrollTop + nextScrollStep
            this._scrollbar.scrollTop(scrollPosition)
        }
    }

    handleSuggestionSelect(value: any) {
        const { model, path, onChangeConfirm } = this.props

        if (model && path) {
            const changedModel = clone(model)
            setValue(changedModel, path, value || null)

            if (onChangeConfirm) {
                onChangeConfirm(changedModel, path)
            }
        } else if (onChangeConfirm) {
            onChangeConfirm(value)
        }

        this.setShowVinSearchArea(value)
        this.handleClose()
    }

    handleChange(model: any, path?: Array<string>) {
        const { onChange } = this.props
        if (onChange) {
            onChange(model, path)
        }

        const value = model && path ? getValue(model, path) : model

        this.setShowVinSearchArea(value)
        this.loadSuggestions(value || null)
    }

    handleChangeConfirm(value: string, path?: Array<string>) {
        const { onChangeConfirm } = this.props
        if (onChangeConfirm) {
            onChangeConfirm(value, path)
        }

        this.setShowVinSearchArea(value)
    }

    handleFocus() {
        const { onFocus, value } = this.props
        if (onFocus) {
            onFocus()
        }

        this.setShowVinSearchArea(value)
        this.loadSuggestions(this.getQuery(this.props))
    }

    setShowVinSearchArea(value: any) {
        const { path } = this.props

        if (path?.first() == "vin") {
            const { vinSearchRequired, vinSearchOptional } = this.props
            const displayVinArea = (value as string)?.length == 17 && (vinSearchRequired || vinSearchOptional)

            this.setState({
                showVinSearchArea: displayVinArea,
            })
        }
    }

    renderTableColumns() {
        const { renderTableColumns } = this.props

        if (!renderTableColumns) {
            return [<Table.Column renderItemContent={(data: any) => <Table.Cell>{data}</Table.Cell>} />]
        }

        return renderTableColumns()
    }

    renderSuggestBox() {
        const { usePortal, size, modelState, path } = this.props
        const {
            vinSearchOptional,
            vinSearchRequired,
            datVinSearchIsValid,
            vinSearchInformalMessage,
            translateText,
            validateVin,
            datPricePerRequest,
            checkVinLoading,
        } = this.props
        const { marked, suggestions, loading, isOpen, showVinSearchArea } = this.state

        let className = "suggest__box "
        if (size) {
            className += ` suggest__box--${size}`
        }

        if (showVinSearchArea || (isOpen && suggestions && suggestions.length)) {
            className += " is-visible"
        }

        if (((vinSearchOptional || vinSearchRequired) && !datVinSearchIsValid) || (modelState && path && getValue(modelState, path)?.length)) {
            className += ` suggest__box--error`
        }

        const vinSearchInformalMessageStyle = vinSearchInformalMessage != VrmLookupTextIds.ResultSuccess ? "danger" : "success"

        const suggestBox = (
            <div className={className}>
                {(vinSearchOptional || vinSearchRequired) && !datVinSearchIsValid && !!validateVin && !!translateText && (
                    <div className="vin-search">
                        <Button
                            id="search-area__btn--vin"
                            className="search-area__btn vin-query-button"
                            onClick={() => validateVin()}
                            disabled={checkVinLoading}
                            icon="dat-vin-data"
                            size="l"
                        >
                            {checkVinLoading && <Badge skin="dark" value={<Loader />} />}
                            {translateText(1259)}

                            {!!datPricePerRequest && (
                                <>
                                    <br />
                                    <SubTitle size="l">{translateText(1260).replace("{0}", datPricePerRequest || "2€")}</SubTitle>
                                </>
                            )}
                        </Button>
                    </div>
                )}

                {(vinSearchOptional || vinSearchRequired) && vinSearchInformalMessage && translateText && (
                    <div className="message-area">
                        <Icon name="dat-vin-data" size="l" />
                        <Text className="message" modifiers={["block", vinSearchInformalMessageStyle]}>
                            {translateText(vinSearchInformalMessage)}
                        </Text>
                    </div>
                )}

                <Scrollbar onRef={(ref) => (this._scrollbar = ref)}>
                    <Table
                        data={suggestions || []}
                        columns={this.renderTableColumns()}
                        getRowClassName={(data: any) => (data == marked ? "is-selected" : "")}
                        onClickRow={this.handleSuggestionSelect}
                        getFocusedRowIndex={() => (suggestions || []).indexOf(marked)}
                    />
                </Scrollbar>
                <Loader visible={loading} />
            </div>
        )

        if (usePortal) {
            return ReactDOM.createPortal(
                <div className="suggest-box-wrapper" ref={this.suggestionBoxRef}>
                    {suggestBox}
                </div>,
                document.body
            )
        }

        return suggestBox
    }

    render() {
        const { layout } = this.props
        const { isOpen, suggestions, showVinSearchArea } = this.state

        let className = "suggest "
        if (showVinSearchArea || (isOpen && suggestions && suggestions.length)) {
            className += "suggest--open "
        }
        className += this.props.className || ""

        layout &&
            layout.forEach((element) => {
                if (element == "dropshadow") {
                    className += ` has-${element}`
                } else {
                    className += ` suggest--${element}`
                }
            })

        return (
            <div className={className} onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} ref={this.handleRef}>
                {this.renderSuggestBox()}

                <TextField
                    {...(this.props as TextFieldProps)}
                    onChange={this.handleChange}
                    onChangeConfirm={this.handleChangeConfirm}
                    onFocus={this.handleFocus}
                />
            </div>
        )
    }
}

import { forwardRef, useMemo } from "react"
import { SvgIcon, styled, SvgIconProps } from "@mui/material"
import { classes } from "@tm/utils"
import { DefaultSvg } from "./default-svg"

type ColorType = "primary" | "secondary" | "warning" | "error" | "info" | "success"
export type IconProps = {
    color?: ColorType | string
    size?: string
}

const SVG = styled("svg", {
    shouldForwardProp: (prop) => prop !== "color" && prop !== "size",
})(({ color, theme, height, width }) => {
    const paletteColors = ["primary", "secondary", "warning", "error", "info", "success", "highlight"]
    const themeColor = paletteColors.some((x) => x === color) ? theme.palette[color as ColorType]?.main : color

    return {
        fill: themeColor || "currentColor",
        minHeight: height || "20px",
        minWidth: width || "20px",
        height: height || "20px",
        width: width || "20px",
    }
})

export type ForwardRefIconProps = Omit<SvgIconProps<typeof SvgIcon>, "color"> & IconProps

export const Icon = forwardRef<SVGSVGElement, ForwardRefIconProps>((props, ref) => {
    const className = useMemo(() => {
        return classes(`icon-${props.name}`, props.className)
    }, [props])

    if (!props.name) {
        return <DefaultSvg ref={ref} {...(props as Omit<IconProps, "color">)} />
    }

    return (
        <SVG
            ref={ref}
            className={className}
            width={props.width || props.size || undefined}
            height={props.height || props.size || undefined}
            {...(props as Omit<IconProps, "name">)}
        >
            <use xlinkHref={`#${props.name}`} />
        </SVG>
    )
})

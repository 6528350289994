import { Authority } from "@tm/data"
import { AuthTokenPayload, channel, UserContext, UserSettingsDefaults } from "@tm/models"
import { bindSpecialReactMethods, concat, convertBase64ImageToByteArray, decodeJwtToken, getStoredAuthorization } from "@tm/utils"
import { Children, Component, PropsWithChildren } from "react"
import { BehaviorSubject, Subscription } from "rxjs"
import * as Data from "../data"
import {
    ChangeOrderOptionsRequest,
    CostEstimationOptions,
    DirectBuyOptions,
    HourlyRate,
    RepairTimeOptions,
    UpdateRepairShopRequest,
    User,
    UserSettings,
    UserSettingsKeys,
} from "../model"
import { UserProviderContext } from "../model/UserProviderContext"

type CreateUserProviderOptions = {
    authorityServiceUrl: string
    repairShopServiceUrl: string
    externalAuthentication?: Record<string, Record<string, string>>
    userContext?: UserContext
    userSettingsDefaults: UserSettingsDefaults
}

type UserProviderState = {
    userSettingsUpdating: boolean
}

export async function createUserProvider(options: CreateUserProviderOptions) {
    const { repairShopServiceUrl, userSettingsDefaults } = options

    // Load external tokens before getting the user context, because loading the external tokens will also add them to the user context
    const tokens = options.externalAuthentication ? await loadExternalTokens(options.externalAuthentication) : {}

    const initialUserContext = options.userContext ?? (await getUserContext(options.authorityServiceUrl))

    if (Object.keys(tokens).length) {
        initialUserContext.externalAuthenticationTokens = {
            ...initialUserContext.externalAuthenticationTokens,
            ...tokens,
        }
    }

    window.userContext = initialUserContext

    const contextSubject = new BehaviorSubject(initialUserContext)
    channel("GLOBAL").publish("USER/CONTEXT_LOADED", { context: initialUserContext })

    const settingsSubject = new BehaviorSubject<UserSettings | undefined>(undefined)

    return class UserProvider extends Component<PropsWithChildren, UserProviderState> {
        contextSubscription: Subscription | undefined = undefined

        settingsSubscription: Subscription | undefined = undefined

        state = {
            userSettingsUpdating: false,
        }

        constructor(props: any) {
            super(props)
            bindSpecialReactMethods(this)
        }

        componentDidMount() {
            this.contextSubscription = contextSubject.subscribe(() => this.forceUpdate())
            this.settingsSubscription = settingsSubject.subscribe(() => this.forceUpdate())

            this.getUserSettings()
        }

        componentWillUnmount() {
            if (this.contextSubscription && !this.contextSubscription.closed) {
                this.contextSubscription.unsubscribe()
            }

            if (this.settingsSubscription && !this.settingsSubscription.closed) {
                this.settingsSubscription.unsubscribe()
            }
        }

        getUserSettings = (): Promise<UserSettings> => {
            return Promise.all([
                Data.showAllOptions(repairShopServiceUrl, {
                    includeRepairShop: true,
                    includeHourlyRates: true,
                    includeOrderOptions: true,
                    includeRepairTimeOptions: true,
                    includeDirectBuyOptions: true,
                    includeCostEstimationOptions: true,
                }),
                Data.getUserSetting("SHOW_PURCHASE_PRICE"),
                Data.getUserSetting("ARTICLE_LIST_SETTINGS"),
                Data.getUserSetting("ACTIVE_VEHICLE_DATA_PROVIDERS"),
                Data.getUserSetting("DMS_SETTINGS"),
                Data.getUserSetting("AUTO_EXPAND_FILTERS"),
                Data.getUserSetting("ORDER_OPTIONS_EXPANDED"),
                Data.getUserSetting("EAN_NUMBER_SEARCH_ENABLED"),
                Data.getUserSetting("BASKET_MEMO_OPTIONS"),
                Data.getUserSetting("BASKET_ITEM_SORTING"),
                Data.getUserSetting("SHOW_PURCHASE_PRICE_IN_SUMMARY"),
                Data.getUserSetting("VRC_SCAN_OPTIONS"),
                Data.getUserSetting("HIDE_WHEELS_AVAILABILITY"),
                Data.getUserSetting("SINDRI_SETTINGS"),
                Data.getUserSetting("ARTICLE_LIST_DEFAULT_SORTING"),
                Data.getUserSetting("DRIVEMOTIVE_SETTINGS"),
                Data.getUserSetting("PARTSLIFE_SETTINGS"),
                Data.getUserSetting("FAST_SERVCE_SETTINGS"),
            ]).then((responses) => {
                const userSettings: UserSettings = {
                    repairShop: responses[0].repairShop,
                    hourlyRates: mapHourlyRates(responses[0].hourlyRates?.hourlyRates),
                    hourlyRatesCurrencyCode: responses[0].hourlyRates?.currencyCode || "EUR",
                    costEstimationOptions: responses[0].costEstimationOptions,
                    orderOptions: {
                        repairShopResponse: responses[0].orderOptions,
                        expandedByDefault: responses[6],
                        basketMemoContext: responses[8]?.context,
                        basketMemoSections: responses[8]?.sections,
                    },
                    repairTimeOptions: responses[0].repairTimeOptions,
                    directBuyOptions: {
                        repairShopResponse: responses[0].directBuyOptions,
                        eanNumberSearchEnabled: responses[7],
                    },
                    showPurchasePrice: getShowPurchasePriceFromResponse(responses[1], contextSubject.getValue()),
                    showPurchasePriceInSummary: responses[10], // No default here because we have to know if the setting is not yet set for a user!
                    articleListSettings: responses[2] || undefined,
                    activeVehicleDataProviders: responses[3] || {},
                    dmsSettings: responses[4] || {},
                    autoExpandFilters: responses[5],
                    itemSorting: responses[9] || false,
                    vrcScanOptions: {
                        customerEnabled: responses[11]?.customerEnabled,
                        vehicleEnabled: responses[11]?.vehicleEnabled,
                    },
                    hideWheelsAvailability: responses[12] ?? userSettingsDefaults?.hideWheelsAvailability,
                    sindriSettings: responses[13] || {},
                    articleListDefaultSorting: responses[14],
                    drivemotiveSettings: responses[15] || {},
                    partsLifeSettings: responses[16] || {},
                    fastServiceSettings: responses[17] || {},
                    // IMPORTANT: When adding or changing a setting here also change the according "case" in the "switch" of "setSetting" below!
                }

                settingsSubject.next(userSettings)
                return userSettings
            })
        }

        setSetting = async (key: UserSettingsKeys, value: any): Promise<UserSettings> => {
            this.setState({ userSettingsUpdating: true })

            await Data.setUserSetting(key, value)

            const response = await Data.getUserSetting(key)
            let userSettings = settingsSubject.getValue()

            if (!userSettings) {
                this.setState({ userSettingsUpdating: false })
                return this.getUserSettings()
            }

            userSettings = { ...userSettings }

            switch (key) {
                case "HIDE_WHEELS_AVAILABILITY":
                    userSettings.hideWheelsAvailability = response
                    break
                case "SHOW_PURCHASE_PRICE":
                    userSettings.showPurchasePrice = getShowPurchasePriceFromResponse(response, contextSubject.getValue())
                    break
                case "SHOW_PURCHASE_PRICE_IN_SUMMARY":
                    userSettings.showPurchasePriceInSummary = response
                    break
                case "AUTO_EXPAND_FILTERS":
                    userSettings.autoExpandFilters = response
                    break
                case "ARTICLE_LIST_SETTINGS":
                    userSettings.articleListSettings = response || undefined
                    break
                case "ACTIVE_VEHICLE_DATA_PROVIDERS":
                    userSettings.activeVehicleDataProviders = response || {}
                    break
                case "DMS_SETTINGS":
                    userSettings.dmsSettings = response || {}
                    break
                case "ORDER_OPTIONS_EXPANDED":
                    userSettings.orderOptions = {
                        ...userSettings.orderOptions,
                        expandedByDefault: response,
                    }
                    break
                case "EAN_NUMBER_SEARCH_ENABLED":
                    userSettings.directBuyOptions = {
                        ...userSettings.directBuyOptions,
                        eanNumberSearchEnabled: response,
                    }
                    break
                case "BASKET_MEMO_OPTIONS":
                    userSettings.orderOptions = {
                        ...userSettings.orderOptions,
                        basketMemoContext: response?.context,
                        basketMemoSections: response?.sections,
                    }
                    break
                case "VRC_SCAN_OPTIONS":
                    userSettings.vrcScanOptions = {
                        customerEnabled: response?.customerEnabled,
                        vehicleEnabled: response?.vehicleEnabled,
                    }
                    break
                case "BASKET_ITEM_SORTING": {
                    userSettings.itemSorting = response || false
                    break
                }
                case "SINDRI_SETTINGS": {
                    userSettings.sindriSettings = response || {}
                    break
                }
                case "ARTICLE_LIST_DEFAULT_SORTING": {
                    userSettings.articleListDefaultSorting = response
                    break
                }
                case "DRIVEMOTIVE_SETTINGS": {
                    userSettings.drivemotiveSettings = response || {}
                    break
                }
                case "PARTSLIFE_SETTINGS": {
                    userSettings.partsLifeSettings = response || { hasPartsLifeActive: false }
                    break
                }
                case "FAST_SERVCE_SETTINGS": {
                    userSettings.fastServiceSettings = response || { isTyresWheelsDisabled: false }
                    break
                }
            }

            settingsSubject.next(userSettings)

            this.setState({ userSettingsUpdating: false })

            return userSettings
        }

        changeLogo = async (logoData: string): Promise<UserSettings> => {
            const logoBytes = convertBase64ImageToByteArray(logoData)
            await Data.changeLogo(repairShopServiceUrl, logoBytes)
            return this.updateLogoState(logoData)
        }

        changePrintLogo = async (printLogo: boolean): Promise<UserSettings> => {
            await Data.changePrintLogo(repairShopServiceUrl, printLogo)

            let userSettings = settingsSubject.getValue()

            if (!userSettings?.repairShop) {
                return this.getUserSettings()
            }

            userSettings = {
                ...userSettings,
                repairShop: { ...userSettings.repairShop, printLogo },
            }

            settingsSubject.next(userSettings)
            return userSettings
        }

        removeLogo = async (): Promise<UserSettings> => {
            await Data.removeLogo(repairShopServiceUrl)
            return this.updateLogoState(undefined)
        }

        updateLogoState = async (logo: string | undefined): Promise<UserSettings> => {
            let userSettings = settingsSubject.getValue()

            if (!userSettings?.repairShop) {
                return this.getUserSettings()
            }

            userSettings = {
                ...userSettings,
                repairShop: { ...userSettings.repairShop, logo },
            }

            settingsSubject.next(userSettings)
            return userSettings
        }

        changeHourlyRates = async (hourlyRates: Array<HourlyRate>, currencyCode: string): Promise<UserSettings> => {
            await Data.changeHourlyRates(repairShopServiceUrl, hourlyRates, currencyCode)
            return this.updateUserSettingsState({ hourlyRates, hourlyRatesCurrencyCode: currencyCode })
        }

        changeDirectBuyOptions = async (directBuyOptions: DirectBuyOptions): Promise<UserSettings> => {
            const response = await Data.changeDirectBuyOptions(repairShopServiceUrl, directBuyOptions)

            return this.updateUserSettingsState((userSettings) => ({
                directBuyOptions: {
                    ...userSettings.directBuyOptions,
                    repairShopResponse: response,
                },
            }))
        }

        changeCostEstimationOptions = async (costEstimationOption: CostEstimationOptions): Promise<UserSettings> => {
            const response = await Data.changeCostEstimationOptions(repairShopServiceUrl, costEstimationOption)
            return this.updateUserSettingsState(() => ({
                costEstimationOptions: {
                    ...response,
                },
            }))
        }

        changeOrderOptions = async (orderOptions: ChangeOrderOptionsRequest): Promise<UserSettings> => {
            await Data.changeOrderOptions(repairShopServiceUrl, orderOptions)
            const response = await Data.showOrderOptions(repairShopServiceUrl)

            return this.updateUserSettingsState((userSettings) => ({
                orderOptions: {
                    ...userSettings.orderOptions,
                    repairShopResponse: response,
                },
            }))
        }

        updateRepairShop = async (data: UpdateRepairShopRequest): Promise<UserSettings> => {
            const response = await Data.updateRepairShop(repairShopServiceUrl, data)
            return this.updateUserSettingsState({ repairShop: response })
        }

        updateExternalAuthenticationTokens = async (forgetExistingTokens = false): Promise<UserContext> => {
            if (!options.externalAuthentication) {
                if (forgetExistingTokens) {
                    return this.updateUserContextState({
                        externalAuthenticationTokens: {},
                    })
                }

                return Promise.resolve(contextSubject.getValue())
            }

            const tokens = await loadExternalTokens(options.externalAuthentication)

            if (!Object.keys(tokens).length) {
                if (forgetExistingTokens) {
                    return this.updateUserContextState({
                        externalAuthenticationTokens: {},
                    })
                }

                return Promise.resolve(contextSubject.getValue())
            }

            return this.updateUserContextState((userContext) => ({
                externalAuthenticationTokens: {
                    ...(forgetExistingTokens ? {} : userContext.externalAuthenticationTokens),
                    ...tokens,
                },
            }))
        }

        changeRepairTimeOptions = async (options: RepairTimeOptions): Promise<UserSettings> => {
            const response = await Data.changeRepairTimeOptions(repairShopServiceUrl, options)
            return this.updateUserSettingsState({ repairTimeOptions: response })
        }

        updateUserSettingsState = async (
            updatedValuesOrUpdater: Partial<UserSettings> | ((settings: UserSettings) => Partial<UserSettings>)
        ): Promise<UserSettings> => {
            let userSettings = settingsSubject.getValue()

            if (!userSettings) {
                return this.getUserSettings()
            }

            userSettings = {
                ...userSettings,
                ...(typeof updatedValuesOrUpdater === "function" ? updatedValuesOrUpdater(userSettings) : updatedValuesOrUpdater),
            }

            settingsSubject.next(userSettings)
            return userSettings
        }

        updateUserContextState = async (
            updatedValuesOrUpdater: Partial<UserContext> | ((context: UserContext) => Partial<UserContext>)
        ): Promise<UserContext> => {
            let userContext = contextSubject.getValue()

            userContext = {
                ...userContext,
                ...(typeof updatedValuesOrUpdater === "function" ? updatedValuesOrUpdater(userContext) : updatedValuesOrUpdater),
            }

            contextSubject.next(userContext)
            return userContext
        }

        updateUserContextGeneralContactName = async (name: string): Promise<UserContext> => {
            return this.updateUserContextState((userContext) => ({
                generalContact: {
                    ...userContext.generalContact,
                    name,
                },
            }))
        }

        getContext(): User {
            return {
                userSettings: settingsSubject.getValue(),
                userContext: contextSubject.getValue(),
                userSettingsUpdating: this.state.userSettingsUpdating,
                reloadUserSettings: this.getUserSettings,
                setUserSetting: this.setSetting,
                changeLogo: this.changeLogo,
                changePrintLogo: this.changePrintLogo,
                removeLogo: this.removeLogo,
                changeHourlyRates: this.changeHourlyRates,
                updateRepairShop: this.updateRepairShop,
                updateExternalAuthenticationTokens: this.updateExternalAuthenticationTokens,
                changeRepairTimeOptions: this.changeRepairTimeOptions,
                changeOrderOptions: this.changeOrderOptions,
                changeDirectBuyOptions: this.changeDirectBuyOptions,
                changeCostEstimationOptions: this.changeCostEstimationOptions,
                updateUserContextGeneralContactName: this.updateUserContextGeneralContactName,
            }
        }

        render() {
            const { children } = this.props
            return <UserProviderContext.Provider value={this.getContext()}>{children && Children.only(children)}</UserProviderContext.Provider>
        }
    }
}

async function getUserContext(serviceUrl: string): Promise<UserContext> {
    let userContext = await Data.getUserContext(serviceUrl)

    // add isAnonym flag to userContext
    if (!userContext.isAnonym) {
        const auth = getStoredAuthorization()
        const { isanonym } = decodeJwtToken<AuthTokenPayload>(auth?.credentials ?? "")

        userContext = {
            ...userContext,
            isAnonym: isanonym,
        }
    }

    return userContext
}

async function loadExternalTokens(tokensToLoad: Record<string, Record<string, string>>): Promise<Record<string, string>> {
    const validTokensToLoad = Object.entries(tokensToLoad).filter(([key]) => key in Authority.Models.ETokenRepositories)

    if (!validTokensToLoad.length) {
        return {}
    }

    try {
        const response = await Authority.getTokens({
            tokenIdentifiers: validTokensToLoad.map<Authority.Models.TokenIdentifier>(([key, options]) => ({
                repository: Authority.Models.ETokenRepositories[key as keyof typeof Authority.Models.ETokenRepositories],
                options,
            })),
            refreshUserContext: true,
        })

        if (response.errors.length) {
            console.error("Error loading (some) external tokens", response.errors)
        }

        if (response.tokens.length) {
            const tokens: Record<string, string> = {}

            validTokensToLoad.forEach(([key]) => {
                const repository = Authority.Models.ETokenRepositories[key as keyof typeof Authority.Models.ETokenRepositories]
                if (!repository) {
                    return
                }

                const responseTokens = response.tokens.filter((x) => x.repository === repository)
                if (!responseTokens.length) {
                    return
                }

                responseTokens.forEach((token) => {
                    const tokenKey = concat("_", key, token.id, token.variant ? Authority.Models.ETokenVariants[token.variant] : undefined)

                    sessionStorage.setItem(
                        `token://${tokenKey}`,
                        JSON.stringify({
                            token: token.token,
                            expiration: token.expiryDate ? parseInt((token.expiryDate.getTime() / 1000).toFixed(0)) : undefined, // Convert to unix timestamp
                            schema: token.schema,
                        })
                    )

                    tokens[tokenKey] = token.token
                })
            })

            return tokens
        }
    } catch (error) {
        console.error("Error loading (some) external tokens", error)
    }

    return {}
}

function getShowPurchasePriceFromResponse(response: any, userContext: UserContext) {
    if (userContext.parameter.purchasePricesDisabled || response === false) {
        return false
    }

    return true
}

function mapHourlyRates(rates: Array<HourlyRate> | undefined): Array<HourlyRate> | undefined {
    if (!rates) {
        return rates
    }

    return rates.map((x) => {
        return {
            ...x,
            hourlyRate: x.hourlyRate ?? 0,
        }
    })
}

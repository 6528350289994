import { IRegisterModel, IModelContainer, ContainerAction } from "../../models"
import { UnregisteredContainerBundle } from "../proxy/unregistered-container"
import { BundleContainerFactory } from "../factories/BundleContainerFactory"
import { RegisteredContainerBundle, IRegisteredContainerBundle } from "../proxy/container-bundle"
import { encodeToBase64 } from "../../tools/base64"

/**
 * Register actions, load, save to a model or GetInstance of a model
 */
export class Container {
    private storage: Map<string, unknown>

    private static instance: Container

    private constructor() {
        this.storage = new Map<string, IModelContainer<unknown, ContainerAction>>()
    }

    /**
     * unregister a bundle
     */
    static unregister = (registeredBundle: string | number) => {
        const registeredContainer = Container.getInstance(registeredBundle)
        // notifyAll before delete

        if (registeredContainer) {
            // registeredContainer.notifyAll("unregister")
            Container.instance.storage.delete(encodeToBase64(registeredBundle.toString()))
        }
    }

    /**
     * register everything you need
     * @param registeredBundle name of the container, actions and/or models which needs to be registered
     */
    static register<StorageModel>(registeredBundle: IRegisterModel<StorageModel>) {
        const { modelActions, containerActions, name } = registeredBundle

        // If name is undefined log an error and do nothing
        /* MLE 11.03.2020 - This was and could be the case if
           a bundle containing a new "Container.register" call was updated
           but not the shared package, so "RegisteredModels.FOO"
           used as the name property returned undefined */
        if (name == undefined) {
            console.error("Container registration ('Container.register') aborted due to missing required parameter 'name'.")
            return
        }

        let container = Container.getInstance<StorageModel>(name)

        const { loadRequests } = container as UnregisteredContainerBundle<StorageModel>
        if (loadRequests) {
            container = Container.convertToRegisteredContainer(registeredBundle)
            ;(container as RegisteredContainerBundle<StorageModel>).resolveLoadRequets(loadRequests)
            return
        }

        if (modelActions) {
            Container.registerModelActions<StorageModel>(registeredBundle)
        }

        if (containerActions) {
            Container.registerContainerActions<StorageModel>(registeredBundle)
        }
    }

    /**
     * should only register model actions, which will be binded to each model
     */
    private static registerModelActions<StorageModel>(registrationData: IRegisterModel<StorageModel>) {
        const { modelActions, name } = registrationData
        if (!modelActions) {
            console.warn(`no model actions to register for ${name}`)
            return
        }

        const container = Container.getInstance<StorageModel>(name)
        ;(container as RegisteredContainerBundle<StorageModel>).registerModelActions(modelActions)
    }

    /**
     * internal container handling
     * @returns the registered container (maybe the return value can be removed)
     */
    private static convertToRegisteredContainer<StorageModel>(
        registeredBundle: IRegisterModel<StorageModel>
    ): IRegisteredContainerBundle<StorageModel> {
        const { name } = registeredBundle

        const container = Container.getInstance<StorageModel>(name) as UnregisteredContainerBundle<StorageModel>
        let registeredContainer = BundleContainerFactory.createRegisteredContainer<StorageModel>(registeredBundle)

        registeredContainer = container.merge(registeredContainer as any) // todo: not happy with this "inverted" merge, mb there is another way
        Container.instance.storage.set(encodeToBase64(name.toString()), registeredContainer)

        return registeredContainer
    }

    /**
     * should just register the container actions
     * @param registrationData IRegisterModel<StorageModel> - it's just the registration model
     */
    private static registerContainerActions<StorageModel>(registrationData: IRegisterModel<StorageModel>) {
        const { containerActions, name } = registrationData
        if (!containerActions) {
            console.warn(`no general actions (containerActions) to register for ${name}`)
            return
        }

        const actionContainer = BundleContainerFactory.createActionsContainer(containerActions)
        const container = Container.getInstance<StorageModel>(name)
        ;(container as RegisteredContainerBundle<StorageModel>).registerActionContainer(actionContainer)
    }

    /**
     * get the model container
     */
    private get(bundleIdentifier: string | number) {
        let container: IModelContainer<unknown, ContainerAction>
        const existingContainer = this.storage.get(encodeToBase64(bundleIdentifier.toString()))

        if (!existingContainer) {
            container = BundleContainerFactory.createUnregisteredContainer<unknown>(bundleIdentifier.toString())
            this.storage.set(encodeToBase64(bundleIdentifier.toString()), container)
        } else {
            container = existingContainer as IModelContainer<unknown, ContainerAction>
        }

        return container
    }

    public static getInstance<TModel>(model: string | number): IModelContainer<TModel, ContainerAction> {
        if (!Container.instance) {
            Container.instance = new Container()
        }

        ;(window as { [key: string]: any }).repositoryProxy = Container.instance

        return Container.instance.get(model) as IRegisteredContainerBundle<TModel>
    }
}

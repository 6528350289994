import { Divider as MuiDivider, DividerProps, styled } from "@mui/material"
import { FC } from "react"

const StyledDivider = styled(MuiDivider)(({ theme }) => ({
    borderColor: "#e2e2e2",
    borderBottomWidth: "3px",
    "&.MuiDivider-light": {
        borderBottomWidth: "thin",
    },
}))

export const Divider: FC<DividerProps> = (props: DividerProps) => {
    return <StyledDivider {...props} />
}

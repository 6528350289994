import { ThemeProvider as MuiThemeProvider, createTheme, createNextMuiTheme } from "@tm/components"
import { Children, PropsWithChildren, useMemo } from "react"
import { ThemeProviderContext } from "./ThemeProviderContext"
import { getStyleTheme } from "."

type ThemeProviderProps = PropsWithChildren

export function ThemeProvider(props: ThemeProviderProps) {
    const nextTheme = getStyleTheme() // theme was already loaded by the portal, before the Providers are rendered
    const muiTheme = useMemo(() => {
        try {
            return createNextMuiTheme(nextTheme)
        } catch {
            return createTheme()
        }
    }, [nextTheme])

    return (
        <ThemeProviderContext.Provider value={nextTheme}>
            <MuiThemeProvider theme={muiTheme}>{props.children ? Children.only(props.children) : null}</MuiThemeProvider>
        </ThemeProviderContext.Provider>
    )
}

import { useLocalization } from "@tm/localization"
import { Box, Tooltip } from "@mui/material"
import { EFilterNames, TyreArticleAttribute } from "@tm/models"
import { useMemo } from "react"
import { getIconByGroup, getTitleByGroup } from "@tm/utils"
import { Icon } from "../../../../../generics/Icons"
import { useTyresItemState } from "../../../ContextProviderStates/TyresItemState"
import TyresIconsV2 from "./TyresIconsV2"

export function TileSeasonIconWithTyreEfficiency() {
    const article = useTyresItemState((x) => x.article)
    const { translateText } = useLocalization()

    const allAttr = useMemo(() => {
        if (!article.attributes?.length) {
            return []
        }
        return [...article.attributes[0].topAttributes, ...article.attributes[0].articleAttributes]
    }, [article.attributes])

    const renderIcons = () => {
        const fuelEfficiency = allAttr.find((x) => x.group === EFilterNames.fuelEfficiency)
        const wetGripClass = allAttr.find((x) => x.group === EFilterNames.wetGripClass)
        const externalRolling = allAttr.find((x) => x.group === EFilterNames.externalRolling)
        const noiseLevel = allAttr.find((x) => x.group === EFilterNames.noiseLevel)

        const items: (TyreArticleAttribute & { selected?: boolean })[] = []
        if (fuelEfficiency) {
            items.push(fuelEfficiency)
        }
        if (wetGripClass) {
            items.push(wetGripClass)
        }
        if (externalRolling) {
            items.push(externalRolling)
        }

        return <TyresIconsV2 items={items} noiseLevel={noiseLevel} direction="column" />
    }

    return (
        <Box display="flex" flexDirection="column" gap=".2em" alignItems="flex-end">
            {article.productGroup?.season && (
                <Tooltip title={translateText(getTitleByGroup(article.productGroup.season))}>
                    <Icon name={getIconByGroup(article.productGroup.season)} size="1.2em" sx={{ margin: "0.25em" }} />
                </Tooltip>
            )}
            {renderIcons()}
        </Box>
    )
}

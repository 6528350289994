import { Component, ReactNode, Fragment, PropsWithChildren, Children } from "react"
import { bindMethodsToContext } from "../../helper"
import { SizedProps } from "../../models/SharedModels"

export type SubTitleProps = PropsWithChildren<SizedProps>

export default class SubTitle extends Component<SubTitleProps> {
    constructor(props: SubTitleProps) {
        super(props)
        bindMethodsToContext(this, ["get"])
    }

    render() {
        const fullClassName = this.getClassName()

        return <div className={fullClassName}>{Children.map(this.props.children, this.renderChildren)}</div>
    }

    renderChildren(child: ReactNode, index: number): ReactNode {
        return <Fragment key={`subtitle-${index}`}>{child}</Fragment>
    }

    getClassName() {
        const { size, className } = this.props
        const baseName = "subtitle"
        let fullClassName = `${baseName}`

        if (size) {
            fullClassName += ` ${baseName}--${size}`
        }

        if (className) {
            fullClassName += ` ${className}`
        }

        return fullClassName
    }
}

import { CategoryType, channel, GetArticlesRequest, GetArticlesResponse, Article, Vehicle, TmaEModule } from "@tm/models"
import { getCategoryTypeFromUrl, uniqueId } from "../../.."

import { Dictionary } from "../../../types"
import { Filter, QuerySearchType, TmaEvent, TrackingModel } from "../../models/models"
import { ArticleListViewOptionsType } from "../../models/requests"
import { TmaSender } from "../tma-sender"
import { ArticleListViewOptions } from "./event-helper"
import { EventSubmitDelay } from "./event-submit-delay"
import { compareContext, createEvent, getPageIndexMax, isModal } from "./helper"
import { AnalyticsData, ArticleListFilteredRequest, ProductGroupDataSupplier, SearchFilter, SearchFilterCount } from "./models"
import { StoreContext } from "../event-storage"
import { QuerySearch } from "../base-event-models"
import { cleanContextUrl } from "../../helper/context"
import { isArray } from "lodash"

type SearchQueryPayload = {
    query: string
    categoryType: CategoryType
    origin: TmaEModule
    searchType: QuerySearchType
}

export class ArticleListFilteredEvent extends TmaSender<ArticleListFilteredRequest> implements TmaEvent {
    key = "ARTICLE_LIST_FILTERED"

    static serviceEndpoint = "/articles/articleListFiltered"

    currentVehicleId?: string

    searchFilters: Array<SearchFilter> = []

    autocompleteResults: Array<string> = []

    additionalAnalyticsData: AnalyticsData

    categories: Dictionary<ArticleListFilteredRequest> = {}

    moduleMode: string | undefined

    plugin: EventSubmitDelay

    shouldSendEvent: boolean

    temporaryRequestBody: ArticleListFilteredRequest

    constructor(plugin: EventSubmitDelay) {
        // extending tma sender needs to set
        super(ArticleListFilteredEvent.serviceEndpoint)

        this.additionalAnalyticsData = {
            filter: {
                available: undefined,
            },
        }
        this.shouldSendEvent = false
        this.temporaryRequestBody = createEvent.initial()

        this.plugin = plugin.init(this)

        this.initializePortalEvents()
    }

    initializePortalEvents = () => {
        const globalChannel = channel("GLOBAL")
        globalChannel.subscribe("VEHICLE/RESET", () => {
            this.currentVehicleId = undefined
        })
        globalChannel.subscribe(
            "VEHICLE/LOADED",
            (vehicle: Vehicle) => {
                this.currentVehicleId = vehicle.id
            },
            true
        )
        globalChannel.subscribe("MVC/RESIZE", (data: any) => (this.moduleMode = data.moduleMode))
        globalChannel.subscribe("WORKTASK/ID_CHANGED", (worktask) => {
            if (worktask.id && worktask.previousId) {
                this.finalizeEventAndReset()
            }
        })
    }

    handle = (trackingData: TrackingModel<unknown>): boolean => {
        if (!trackingData.tmaEvent || trackingData.tmaEvent !== this.key || !trackingData.payload) {
            return false
        }

        const currentContext = this.storage.getContext()
        const tmpContext = this.temporaryRequestBody?.context

        if (tmpContext && tmpContext.contextId.indexOf("startpage") >= 0 && !compareContext(currentContext, tmpContext)) {
            this.contextChanged(currentContext)
        }

        if (!this.temporaryRequestBody.searchStep) {
            if (!this.requestBody.searchStep) {
                this.temporaryRequestBody = createEvent.initial()
                this.shouldSendEvent = false
            } else {
                // if no searchstep is available and there is a stored (sessionStorage) requestbody use this
                this.loadRequest()
            }
        }

        this.handleEvent(trackingData)

        return false
    }

    private handleEvent = (trackingData: TrackingModel<unknown>) => {
        switch (trackingData.bundle) {
            case "parts":
            case "api": {
                if (Array.isArray(trackingData.action)) {
                    trackingData.action.forEach((action) => this.fillRequest(trackingData, action))
                } else {
                    this.fillRequest(trackingData, trackingData.action)
                }

                if (this.shouldSendEvent) {
                    this.saveChanges(this.temporaryRequestBody) // to make sure the current state is saved to the sessionStorage

                    try {
                        this.sendEvent(this.temporaryRequestBody)
                    } catch (ex) {
                        console.error(ex)
                    }

                    this.shouldSendEvent = false
                }

                break
            }
            default: {
                break
            }
        }
    }

    private fillRequest = (trackingData: TrackingModel<any>, action: string) => {
        const { payload } = trackingData
        const { request, result } = payload

        switch (action) {
            case "get-articles": {
                this.temporaryRequestBody.workTaskId = payload.worktaskId
                this.temporaryRequestBody.vehicleIdentification = payload.vehicleIdentification
                this.temporaryRequestBody.extendedAssortment = request.extendedAssortment

                // before calling attachSearchQuery the event should always be resetted.
                // this was because in earlier version on reload the query was los
                // this.attachSearchQuery(payload)

                this.temporaryRequestBody.fittingSideFilter = request.fittingSideFilter as any
                this.attachQueryAndOrigin(payload)
                this.attachArticleListInfo(request, result)
                this.attachSearchTree(request)
                this.attachProductGroupsToSupplier(result)
                this.saveRequestForCategorySwap()
                this.setOriginIfUnset(payload.origin) // this is needed due to a reload of the articlelist

                if (this.temporaryRequestBody.vehicleId != this.currentVehicleId) {
                    this.temporaryRequestBody.vehicleId = this.currentVehicleId
                }

                this.shouldSendEvent = !!this.temporaryRequestBody.articleList
                break
            }

            case "cdm": {
                // component did mount
                const vehicle = payload
                if (vehicle) {
                    this.temporaryRequestBody.vehicleId = vehicle?.id?.toString()
                }

                break
            }

            case "autocomplete": {
                const { hits } = payload

                if (hits && Array.isArray(hits)) {
                    this.autocompleteResults = hits
                }
                break
            }

            case "trackAnalyticsData": {
                const { filter } = payload
                const currentContext = this.storage.getContext()
                if (this.temporaryRequestBody.context && !compareContext(currentContext, this.temporaryRequestBody.context)) {
                    this.finalizeEventAndReset()
                }

                if (filter) {
                    this.attachFilters(filter)
                }
                break
            }

            case "oe-position-changed": {
                const { oeNumber } = payload

                if (oeNumber?.length) {
                    this.temporaryRequestBody.querySearch = this.createQuerySearch(oeNumber, QuerySearchType.Direct)
                }
                break
            }

            case "nodeSelected":
            case "handleSelectNode":
            case "handleChangeBreadcrumbs":
                {
                    const { nodeId, treeId, origin } = payload

                    this.finalizeEventAndReset()

                    this.temporaryRequestBody.origin = this.temporaryRequestBody.origin || origin

                    if (treeId || nodeId) {
                        this.temporaryRequestBody.searchTree = {
                            nodeId,
                            treeId,
                        }
                    }
                }

                break

            case "keyword-search-utilityno-click": {
                this.finalizeEventAndReset() // this is a new event i don't know if the old event is still handled by the delayed submit
                // reset is function overwritten inside the delayed handler and the temporary request has to be resetted immediatly.
                // in the future both handler normal and delayed will be merged or completly rewritten
                this.attachQueryAndOrigin(payload)
                break
            }

            case "keyword-search-context": {
                if (this.temporaryRequestBody.eventStatus !== "initial" && this.temporaryRequestBody.querySearch?.query !== payload.query) {
                    this.finalizeEventAndReset()
                }

                this.attachQueryAndOrigin(payload)
                break
            }

            case "keyword-search-redirect": {
                const { query, origin, targetUrl } = payload

                this.resetTemporaryRequest()
                this.reset()

                this.temporaryRequestBody.context = {
                    identifier: this.serviceEndpoint,
                    contextId: cleanContextUrl(targetUrl),
                }
                this.attachQueryAndOrigin(query)
                this.setOrigin(origin)
                break
            }

            case "handleSearch": {
                const { query } = this.temporaryRequestBody.querySearch || {}
                if (query != payload.query) {
                    this.finalizeEventAndReset()
                }
                this.attachQueryAndOrigin(payload)
                break
            }

            case "handleExternalSearch":
            case "search-context": {
                // this one is duplicated. use keyword-search-context, inside attachSearchQeury the origin get set. could be moved to switch case
                this.resetTemporaryRequest()
                this.attachQueryAndOrigin(payload)
                this.setOrigin(payload.origin)
                this.temporaryRequestBody.vin = payload.extraData?.vin
                break
            }

            case "increase-step-number": {
                this.temporaryRequestBody.searchStep = {
                    number: !this.temporaryRequestBody.searchStep ? 1 : ++this.temporaryRequestBody.searchStep.number,
                    processId: !this.temporaryRequestBody.searchStep ? uniqueId() : this.temporaryRequestBody.searchStep.processId,
                }
                break
            }

            case "viewoptions-set": {
                // ARTLISTVIEWOPT
                // wird nicht aufgerufen
                const payload = trackingData.payload as { viewOptions: ArticleListViewOptions }
                if (payload && payload.viewOptions) {
                    const { viewOptions } = payload
                    this.temporaryRequestBody.viewOptions = viewOptions.compactView
                        ? ArticleListViewOptionsType.Compact
                        : ArticleListViewOptionsType.Detailed
                    this.temporaryRequestBody.viewOptions = viewOptions.showArticleImages
                        ? this.temporaryRequestBody.viewOptions | ArticleListViewOptionsType.Images
                        : this.temporaryRequestBody.viewOptions
                }
                break
            }

            case "viewoptions-changed": {
                // ARTLISTVIEWOPT
                // wird nicht aufgerufen
                const payload = trackingData.payload as { viewOptions: ArticleListViewOptions }
                if (payload && payload.viewOptions) {
                    const { viewOptions } = payload
                    this.temporaryRequestBody.viewOptions = viewOptions.compactView
                        ? ArticleListViewOptionsType.Compact
                        : ArticleListViewOptionsType.Detailed
                    this.temporaryRequestBody.viewOptions = viewOptions.showArticleImages
                        ? this.temporaryRequestBody.viewOptions | ArticleListViewOptionsType.Images
                        : this.temporaryRequestBody.viewOptions

                    if (this.temporaryRequestBody.searchStep) {
                        this.temporaryRequestBody.searchStep.number = this.temporaryRequestBody.searchStep.number + 1
                    }
                }
                break
            }
            case "init-search-filters": {
                this.searchFilters = payload.searchFilters
                this.temporaryRequestBody.searchFilters = this.searchFilters
                break
            }
            case "set-search-filters-count": {
                const isDirectSearch = this.temporaryRequestBody.querySearch?.searchType == QuerySearchType.Direct
                    || getCategoryTypeFromUrl(document.location.pathname) == "directSearch"

                if (isDirectSearch && isArray(payload?.searchFiltersCount)) {
                    payload.searchFiltersCount.forEach((searchFilterCount: SearchFilterCount) => {
                        const match = this.searchFilters.find((searchFilter) => searchFilter.id === searchFilterCount.filterType)

                        if (match) {
                            match.articleCount = searchFilterCount.count
                        }
                    })
                }

                this.temporaryRequestBody.searchFilters = this.searchFilters
                break
            }
            case "set-search-filters": {
                this.searchFilters = payload.searchFilters
                if (
                    this.temporaryRequestBody.querySearch?.searchType == QuerySearchType.Direct ||
                    getCategoryTypeFromUrl(document.location.pathname) == "directSearch"
                ) {
                    this.temporaryRequestBody.searchFilters = this.searchFilters
                }

                break
            }
            case "swap-category": {
                this.resetTemporaryRequest() // now resetprocess to generate a new process and stepnumber
                break
            }
        }
    }

    private setOriginIfUnset = (origin: TmaEModule) => {
        this.temporaryRequestBody.origin = this.temporaryRequestBody.origin || origin
    }

    saveRequestForCategorySwap = () => {
        const searchType = this.temporaryRequestBody.querySearch?.searchType

        switch (searchType) {
            case QuerySearchType.Direct: {
                this.categories.directSearch = this.temporaryRequestBody
                break
            }
            case QuerySearchType.Synonym: {
                this.categories.vehicleParts = this.temporaryRequestBody
                break
            }
        }
    }

    reset = () => {
        const { context, searchStep } = this.temporaryRequestBody
        if (context) {
            this.storage.delete(context)
            searchStep && this.storage.removeModifiedState(searchStep.processId)
        }
    }

    resetTemporaryRequest = () => {
        const initialEvent = createEvent.initial(this.temporaryRequestBody.vehicleId)
        this.temporaryRequestBody = initialEvent
        this.shouldSendEvent = false
    }

    finalizeEventAndReset = () => {
        this.reset()
        this.resetTemporaryRequest()
    }

    contextChanged = (newContext: StoreContext) => {
        if (!this.temporaryRequestBody.context) {
            return
        }

        const { contextId } = this.temporaryRequestBody.context

        if (contextId === "startpage" || contextId === "dashboard") {
            this.storage.delete(this.temporaryRequestBody.context)
            this.temporaryRequestBody.context = newContext
        }

        this.saveChanges(this.temporaryRequestBody)
    }

    initOrRestoreContent = (newContext: StoreContext) => {
        const storedRequest = this.storage.loadContent(newContext)
        if (storedRequest) {
            this.temporaryRequestBody = storedRequest
        } else {
            this.reset() // call reset because of the completed event
            this.temporaryRequestBody.context = newContext
        }

        if (this.temporaryRequestBody.context?.contextId.includes("modal")) {
            this.temporaryRequestBody.eventStatus = "completed"
        }
    }

    saveChanges = (newRequestBody: ArticleListFilteredRequest) => {
        if (newRequestBody.context && isModal(newRequestBody.context?.contextId)) {
            newRequestBody.eventStatus = "completed" // modal event s will not be saved and send directly
            return
        }
        // todo future changes, change this to something like this.storage.saveRequest()
        this.requestBody = newRequestBody
    }

    loadRequest = () => {
        return (this.temporaryRequestBody = this.requestBody || createEvent.initial())
    }

    private setOrigin = (origin: TmaEModule) => {
        this.temporaryRequestBody.origin = origin
    }

    private attachQueryAndOrigin = ({ query, origin, searchType }: SearchQueryPayload) => {
        this.temporaryRequestBody.origin = this.temporaryRequestBody.origin || origin

        if (query) {
            this.temporaryRequestBody.querySearch = this.mergeQuerySearch(this.createQuerySearch(query, searchType))
        }
    }

    private mergeQuerySearch = (querySearch: QuerySearch) => {
        if (!this.temporaryRequestBody.querySearch) {
            return querySearch
        }
        const tmpQuerySearch = this.temporaryRequestBody.querySearch
        tmpQuerySearch.autocompleteEntryExists = querySearch.autocompleteEntryExists
        tmpQuerySearch.query = tmpQuerySearch.query ?? querySearch.query
        tmpQuerySearch.searchType = tmpQuerySearch.searchType ?? querySearch.searchType
        return tmpQuerySearch
    }

    private createQuerySearch = (query: string, searchType: QuerySearchType) => {
        return {
            autocompleteEntryExists: !!this.autocompleteResults.find((item) => !!query && item.toLowerCase().startsWith(query.toLowerCase())),
            query,
            searchType,
        }
    }

    attachFilters = (filters: any) => {
        filters.available && this.attachSupplierFilters(filters.available.dataSupplierFilters || [])
        filters.available && this.attachProductGroupFilters(filters.available.productGroupFilters || [])
    }

    attachSupplierFilters = (suppliers: Array<Filter>) => {
        if (!suppliers || suppliers.length === 0) {
            return
        }

        this.temporaryRequestBody.dataSupplierFilters = suppliers.map(
            ({ articleCount, id, isSelected, priority }) => ({
                articleCount: articleCount || 0,
                id,
                isSelected: !!isSelected,
                priority
            })
        )
    }

    attachProductGroupFilters = (productGroupFilters: Array<Filter>) => {
        if (!productGroupFilters || productGroupFilters.length === 0) {
            return
        }

        this.temporaryRequestBody.productGroupFilters = productGroupFilters.map(
            ({ articleCount, id, isSelected, priority }) => ({
                articleCount: articleCount || 0,
                id,
                isSelected: !!isSelected,
                priority
            })
        )
    }

    attachArticleListInfo(request: GetArticlesRequest, result: GetArticlesResponse | undefined) {
        const articles = result?.articles ?? []

        const productGroupDataSuppliers: Array<ProductGroupDataSupplier> = []
        const productGroupDataSupplierSet = new Set<string>()

        let wholesalerArticleCount = 0

        for (const article of articles) {
            if (article.traderArticleNo) {
                wholesalerArticleCount++
            }

            if (article.supplier && article.productGroup) {
                const key = `${article.supplier.id}_${article.productGroup.id}`

                if (!productGroupDataSupplierSet.has(key)) {
                    productGroupDataSupplierSet.add(key)
                    productGroupDataSuppliers.push({
                        dataSupplierId: article.supplier.id,
                        productGroupId: article.productGroup.id
                    })
                }
            }
        }

        const maxArticleCount = result?.articleListCount ?? 0
        const pageSize = result?.pageSize ?? request.pageSize
        const pageIndexMax = getPageIndexMax(maxArticleCount, pageSize)

        const previousPageIndex = this.temporaryRequestBody.articleList?.paging.pageIndex ?? 1

        if (
            previousPageIndex < (result?.pageIndex ?? request.pageIndex ?? 1) &&
            this.temporaryRequestBody.searchStep
        ) {
            this.temporaryRequestBody.searchStep.number++
        }

        this.temporaryRequestBody.articleList = {
            articleCount: articles.length,
            maxArticleCount,
            paging: {
                pageIndex: result?.pageIndex ?? request.pageIndex,
                pageSize,
                pageIndexMax
            },
            wholesalerArticleCount,
            productGroupDataSuppliers
        }
    }

    attachSearchTree(request: GetArticlesRequest) {
        if (request.nodeId || request.treeId) {
            this.temporaryRequestBody.searchTree = {
                nodeId: request.nodeId,
                treeId: request.treeId,
            }
        }
    }

    augmentFiltersWithArticleCount = (result: GetArticlesResponse) => {
        if (!result || !result?.articles || result?.articles?.length == 0) {
            return
        }

        const { dataSupplierFilters, productGroupFilters } = this.temporaryRequestBody
        const { articles } = result

        if (dataSupplierFilters) {
            dataSupplierFilters.forEach((filter) => {
                const articleCount = articles.filter((article: Article) => article.supplier.id == filter.id).length
                filter.articleCount = articleCount
            })
        }

        if (productGroupFilters) {
            productGroupFilters.forEach((filter) => {
                const articleCount = articles.filter((article: Article) => article.productGroup.id == filter.id).length
                filter.articleCount = articleCount
            })
        }
    }

    attachProductGroupsToSupplier = (result: GetArticlesResponse) => {
        result.articles.forEach((article: Article) => {
            if (!article?.supplier?.id || !article?.productGroup?.id) {
                return
            }

            this.temporaryRequestBody.productGroupsPerSupplier[article.supplier.id] = article.productGroup.id
        })
    }
}

import { useContext } from "react"
import type { BundleMicroRenderer } from ".."
import { BundleMicroContext } from "../lib/BundleMicroContext"

export const useMicro = <T>() => {
    const renderMicro = useContext(BundleMicroContext)! as BundleMicroRenderer<T>
    return { renderMicro }
}

// TODO implement also a HOC for class components -> withMicros

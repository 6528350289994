import { elementId } from "@tm/utils"
import { useEffect, useState } from "react"
import { Text } from "../.."

export type DoubleLabelSwitchProps = {
    leftLabel: string
    rightLabel: string
    rightActive?: boolean
    disabled?: boolean
    onChange?(leftActive: boolean): void
    onClick?(e: React.MouseEvent<HTMLLabelElement>): void
}

export default function DoubleLabelSwitchComponent({ leftLabel, rightActive, rightLabel, disabled, onChange, onClick }: DoubleLabelSwitchProps) {
    const id = elementId("switch")
    const [leftActive, setLeftAcitve] = useState<boolean>(!!rightActive)

    useEffect(() => {
        if (leftActive !== !!rightActive) {
            setLeftAcitve(!!rightActive)
        }
    }, [rightActive])

    function handleChange(e: any) {
        setLeftAcitve(!leftActive)
        onChange?.(!leftActive)
    }

    function handleClick(e: React.MouseEvent<HTMLLabelElement>) {
        onClick?.(e)
    }

    let className = "switch switch-double-label"
    if (disabled) {
        className += " switch--disabled"
    }

    return (
        <label htmlFor={id} className={className} onClick={handleClick}>
            <Text size="xs" modifiers={!leftActive ? ["highlight"] : undefined}>
                {leftLabel}
            </Text>
            <input
                type="checkbox"
                id={id}
                value={undefined}
                className="input"
                disabled={!!disabled}
                checked={leftActive}
                onChange={handleChange}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            />
            <span className="switch__content">
                <div className="switch__icon" />
            </span>
            <Text size="xs" modifiers={leftActive ? ["highlight"] : undefined}>
                {rightLabel}
            </Text>
        </label>
    )
}

import {
    Customer,
    Vehicle,
    RegisteredModels,
    Version,
    WorkTaskContainer,
    VehicleContainer,
    CustomerContainer,
    AttachCustomerAndVehicleRequest,
} from "@tm/models"
import { Container } from "@tm/nexus"

let workTaskContainer: WorkTaskContainer
let vehicleContainer: VehicleContainer
let customerContainer: CustomerContainer

export function initialize() {
    workTaskContainer = Container.getInstance(RegisteredModels.Worktask)
    vehicleContainer = Container.getInstance(RegisteredModels.Vehicle)
    customerContainer = Container.getInstance(RegisteredModels.Customer)
}

export function loadWorktask(workTaskId: string) {
    return workTaskContainer.subscribe(workTaskId).load()
}

export function loadCustomer(customerId: string) {
    return customerContainer.subscribe(customerId).load()
}

export function loadVehicle(vehicleId: string) {
    return vehicleContainer.subscribe(vehicleId).load()
}

export function setWorktaskSelected(workTaskId: string) {
    return workTaskContainer.action("saveSelected")(workTaskId)
}

export function createWorktask(workTaskId: string, vehicleId?: string, customerId?: string) {
    return workTaskContainer.action("create")({ workTaskId, vehicleId, customerId })
}

export function createWorktaskAndReturnNewVersion(workTaskId: string, vehicleId?: string, customerId?: string) {
    return workTaskContainer.action("createAndReturnNewVersion")({ workTaskId, vehicleId, customerId })
}

export function saveCustomer(customer: Customer): Promise<Customer> {
    return new Promise((resolve) => {
        const contract = customerContainer.subscribe(customer.id)
        contract.addListener("updated", resolve)
        contract.save?.(customer)
    })
}

export function saveVehicle(vehicle: Vehicle): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
        const contract = vehicleContainer.subscribe(vehicle.id)
        contract.addListener("updated", resolve, reject)
        contract.save?.(vehicle)
    })
}

export function attachCustomerToVehicle(vehicleId: string, customerId: string) {
    return vehicleContainer.action("attachCustomer")(vehicleId, customerId)
}

export function attachCustomerAndVehicleAndReturnNewVersion(request: AttachCustomerAndVehicleRequest) {
    return workTaskContainer.action("attachCustomerAndVehicleAndReturnNewVersion")(request)
}

export function attachVehicleAndReturnNewVersion(workTaskId: string, vehicleId: string, version: Version | undefined) {
    return workTaskContainer.action("attachVehicleAndReturnNewVersion")({ workTaskId, vehicleId, version })
}

export function attachCustomerAndReturnNewVersion(workTaskId: string, customerId: string, version: Version | undefined) {
    return workTaskContainer.action("attachCustomerAndReturnNewVersion")({ workTaskId, customerId, version })
}

export function detachCustomerAndReturnNewVersion(workTaskId: string, version: Version | undefined) {
    return workTaskContainer.action("detachCustomerAndReturnNewVersion")({ workTaskId, version })
}

import cx from "bem-classnames"
import { Component } from "react"
import { Icon, Text } from ".."
import { Size } from "../../models/skins"
import { bindMethodsToContext } from "../../helper/bindObjectContext"
import { elementId } from "../../helper"

export type CheckboxProps = {
    label?: string
    checked?: boolean
    onToggle?(e?: React.MouseEvent<HTMLElement>): void
    /** size of the checkbox, Size */
    size?: Size
    disabled?: boolean
    labelTitle?: string
    onLabelClick?(e: React.MouseEvent<HTMLLabelElement>): void
}

const bemConfig = {
    name: "checkbox",
    modifiers: ["size", "disabled"],
}

export default class Checkbox extends Component<CheckboxProps> {
    private elementId = elementId()

    constructor(props: CheckboxProps) {
        super(props)
        bindMethodsToContext(this)
    }

    static get defaultProps(): Partial<CheckboxProps> {
        return {
            size: "m",
        }
    }

    handleChange(e?: React.MouseEvent<HTMLElement>) {
        const { onToggle, disabled } = this.props
        !disabled && onToggle && onToggle(e)
    }

    render() {
        const { checked, label, size, disabled, labelTitle, onLabelClick } = this.props

        return (
            <span className={cx(bemConfig, { size, disabled })}>
                <input
                    type="checkbox"
                    id={this.elementId}
                    className="input checkbox__input"
                    checked={checked}
                    onChange={this.handleChange.bind(this, undefined)}
                />
                <span className="checkbox__frame" onClick={this.handleChange}>
                    {checked && <Icon className="checkbox__icon" name="check" size={size} />}
                </span>
                {!!label && (
                    <label className="checkbox__label" htmlFor={this.elementId} title={labelTitle} onClick={onLabelClick}>
                        <Text size={size}>{label}</Text>
                    </label>
                )}
            </span>
        )
    }
}

import { PropsWithChildren, PureComponent } from "react"
import SubTitle from "../../subtitle"

export default class ToolbarTitle extends PureComponent<PropsWithChildren<unknown>> {
    render() {
        const baseName = "toolbar__title"
        const className = `${baseName}`

        return (
            <SubTitle size="xs" className={className}>
                {this.props.children}
            </SubTitle>
        )
    }
}

import { CSSProperties, Component, PropsWithChildren, ReactNode, RefObject, createRef } from "react"
import { Link } from "react-router-dom"

import cx from "bem-classnames"
import { getUIA } from "@tm/utils"
import { Size } from "../../models/skins"
import Icon from "../icon"

export type ButtonLayout = "none" | "rounded" | "ghost" | "shaded" | "holo" | "bordered" | "iconRight" | "circle" | "dropshadow"
export type ComponentSize = Size
export type ButtonSkins = "warning" | "danger" | "success" | "highlight" | "primary"

export type ButtonProps = PropsWithChildren<{
    /** name of the icon */
    icon?: string
    /** renders a seperate value next to the button */
    value?: string
    /** render button as link */
    linkTo?: string
    /** disables link check  */
    forceExternalLink?: boolean
    /** the target of the underlying anchor */
    target?: string
    /** defines the color of the button */
    skin?: ButtonSkins
    /** size of the button, Size */
    size?: ComponentSize
    /** defines the appearance, except the color, of the button */
    layout?: Array<ButtonLayout>
    /** hovering text */
    title?: string
    /** additional css classname */
    className?: string
    /** id classname */
    id?: string
    /** disable or enable the button */
    disabled?: boolean
    /** callback which gets executed on button click */
    onClick?(action: any): void
    /** doesn't use the html button element, instead take a div */
    fakeButton?: boolean
    /** You need the icon to scale up depending on the size, mb usefull for icon only buttons */
    scaleIcon?: boolean
    /** set the active state to the button */
    isActive?: boolean
    /** use this only if you need to add content as sibling to the content (like badge's which otherwise would inherit the opacity of the content container) */
    appendItem?: ReactNode
    style?: CSSProperties
}>

/**
 * This is the button component, it will render a brand new button. This description will be shown in the styleguide
 * @param skin defines the appearance
 */
export default class Button extends Component<ButtonProps> {
    private buttonElement: RefObject<HTMLButtonElement | HTMLDivElement>

    private button = {
        name: "btn",
        modifiers: ["value", "size", "skin", "rounded", "ghost", "shaded", "holo", "disabled", "bordered", "icon-only", "circle"],
        states: ["value", "icon", "icon-right", "dropshadow"],

        text: {
            name: "btn__text",
            modifiers: ["size"],
        },
    }

    constructor(props: ButtonProps) {
        super(props)
        this.buttonElement = createRef()
    }

    render() {
        const {
            value,
            icon,
            onClick,
            title,
            id,
            size,
            skin,
            children,
            linkTo,
            disabled,
            fakeButton,
            target,
            layout,
            isActive,
            scaleIcon,
            appendItem,
            forceExternalLink,
            ...buttonProperties
        } = this.props
        cx.prefixes.states = "has-"

        const hasChildren = Array.isArray(children) ? children.some((child) => !!child) : !!children
        const hasIconRight = layout && layout.indexOf("iconRight") >= 0

        const buttonLayout = {
            shaded: layout && layout.indexOf("shaded") >= 0,
            rounded: layout && layout.indexOf("rounded") >= 0,
            ghost: layout && layout.indexOf("ghost") >= 0,
            holo: layout && layout.indexOf("holo") >= 0,
            bordered: layout && layout.indexOf("bordered") >= 0,
            circle: layout && layout.indexOf("circle") >= 0,
            disabled,
            "icon-only": !hasChildren,
            "icon-right": hasIconRight,
            dropshadow: layout && layout.indexOf("dropshadow") >= 0,
        }

        const bemConfig = {
            ...buttonLayout,
            value: !!value,
            icon: !!icon && !hasIconRight && hasChildren,
            size,
            skin,
        }

        const btnClassName = cx(this.button, { ...bemConfig, value: false }, this.appendToClassName.bind(this)())
        const btnTitle = title || ""

        let btn = !fakeButton ? (
            <button
                type="button"
                disabled={!!disabled}
                onClick={onClick}
                title={btnTitle}
                className={btnClassName}
                id={id}
                style={this.props.style}
                ref={this.buttonElement as RefObject<HTMLButtonElement>}
                {...getUIA("Button", id || btnClassName || btnTitle)}
            >
                {this.renderChildren()}
            </button>
        ) : (
            <div
                className={btnClassName}
                id={id}
                title={btnTitle}
                onClick={onClick}
                ref={this.buttonElement as RefObject<HTMLDivElement>}
                style={this.props.style}
                {...getUIA("ButtonFake", id || btnClassName || btnTitle)}
            >
                {this.renderChildren()}
            </div>
        )

        if (linkTo && linkTo.length > 0) {
            if (/^\w+:\/\//.test(linkTo) || forceExternalLink) {
                btn = (
                    <a title={btnTitle} className={btnClassName} id={id} href={linkTo} target={target} onClick={onClick} style={this.props.style}>
                        {this.renderChildren()}
                    </a>
                )
            } else {
                btn = (
                    <Link className={btnClassName} id={id} to={linkTo} target={target} title={btnTitle} onClick={onClick}>
                        {this.renderChildren()}
                    </Link>
                )
            }
        }

        if (value) {
            btn = (
                <div className="btn-group">
                    {btn}
                    <button type="button" className={cx(this.button, bemConfig)} title={value}>
                        {value}
                    </button>
                </div>
            )
        }

        return btn
    }

    renderChildren() {
        const { layout, children, icon, scaleIcon, appendItem } = this.props
        const hasIconRight = layout && layout.indexOf("iconRight") >= 0

        let iconElement: ReactNode

        if (icon) {
            iconElement = <Icon key="button__icon" name={icon} size={scaleIcon ? this.props.size : "m"} className="btn__icon" />
        }

        return (
            <>
                {!hasIconRight && iconElement && iconElement}
                {children && <div className="btn__content">{children}</div>}
                {appendItem}
                {hasIconRight && iconElement && iconElement}
            </>
        )
    }

    appendToClassName() {
        const { isActive, className, value } = this.props

        let extendedClassName = ""

        if (value) {
            extendedClassName = `has-value`
        }

        if (className) {
            if (extendedClassName) {
                extendedClassName = `${extendedClassName} ${className}`
            } else {
                extendedClassName = `${className}`
            }
        }

        if (isActive) {
            if (extendedClassName) {
                extendedClassName = `${extendedClassName} is-active`
            } else {
                extendedClassName = `is-active`
            }
        }

        return extendedClassName
    }

    focus() {
        setTimeout(() => {
            this.buttonElement.current && this.buttonElement.current.focus()
        }, 0)
    }
}

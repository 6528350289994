import { Component, PropsWithChildren } from "react"
import Icon from "../icon"

import { GrayishSkins, DefaultSkins } from "../../models/skins"
import { bindMethodsToContext } from "../../helper"
import { BaseProps } from "../../models/SharedModels"

export type CardProps = PropsWithChildren<
    BaseProps & {
        skin?: "highlight" | GrayishSkins
        open?: boolean
        indicator?: boolean
        onClick?(): void
        onDblClick?(): void
    }
>

export default class Card extends Component<CardProps, any> {
    static baseName = "card"

    constructor(props: CardProps) {
        super(props)
        bindMethodsToContext(this, ["getClassName"])
    }

    render() {
        const className = this.getClassName(Card.baseName)

        return (
            <div className={className}>
                <div className={`${Card.baseName}__inner`} onClick={this.handleClick} onDoubleClick={this.handleDblClick}>
                    {this.props.children}
                </div>
            </div>
        )
    }

    handleClick() {
        const { onClick } = this.props
        if (onClick) {
            onClick()
        }
    }

    handleDblClick() {
        const { onDblClick } = this.props
        if (onDblClick) {
            onDblClick()
        }
    }

    getClassName(baseClassName: string): string {
        const { skin, open, indicator, className } = this.props
        let fullClassName = `${baseClassName}`

        if (className) {
            fullClassName = `${fullClassName} ${className}`
        }
        if (skin) {
            fullClassName = `${fullClassName} ${baseClassName}--${skin}`
        }

        if (indicator) {
            fullClassName = `${fullClassName} ${baseClassName}--indicator`
        }

        if (open) {
            fullClassName = `${fullClassName} is-open`
        }

        return fullClassName
    }
}

import { forwardRef } from "react"
import { Paper, PaperProps, styled } from "@mui/material"

export type Props = PaperProps & {
    isSelected?: boolean
}

export const ListItemPaper = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <StyledPaper {...props} ref={ref}>
            {props.children}
        </StyledPaper>
    )
})

const StyledPaper = styled(Paper)<Props>(({ theme, isSelected }) => ({
    padding: theme.spacing(1),
    border: "1px solid transparent",
    borderLeftWidth: "5px",
    borderLeftColor: theme.palette.divider,
    borderRadius: "2px",
    boxShadow: "#00000022 0 1px 5px 0",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
        boxShadow: "#0000008A 0 3px 8px 0",
    },
    ...(isSelected && {
        borderColor: theme.palette.primary.main,
    }),
}))

import { Theme } from "@mui/material"

export const getFuelEfficiencyLabelColor = (value: string, theme: Theme) => {
    switch (value) {
        case "A":
            return "#00a651"
        case "B":
            return "#9aca3c"
        case "C":
            return "#fff200"
        case "D":
            return "#fdb913"
        case "E":
            return "#ed1c24"
        default:
            return theme.palette.primary.main
    }
}

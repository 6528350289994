import { OAuthTokenIds, UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { useUser } from "../../user"

export function useCostEstimationModuleParameters() {
    const { userContext } = useUser()

    return useMemo(() => {
        const costEstimationModule = userContext.modules?.find((x) => x.type === UserModuleType.CostEstimation)
        return {
            hasCostEstimation: !!costEstimationModule,
            disabledCostEstimation: !costEstimationModule || userContext.parameter.catalogLight,
            customerGroup: costEstimationModule?.parameters?.find((x) => x.key === "CustomerGroup")?.value,
            priceModus: costEstimationModule?.parameters?.find((x) => x.key === "PriceModus")?.value,
            priceTypes: costEstimationModule?.parameters?.find((x) => x.key === "PriceTypes")?.value,
            showExportToExternalBasket: costEstimationModule?.parameters?.find((x) => x.key === "ShowExportToExternalBasket")?.value === "1",
            showImportFromExternalBasket: costEstimationModule?.parameters?.find((x) => x.key === "ShowImportFromExternalBasket")?.value === "1",
            requiredAuthModeForExternalBasket: costEstimationModule?.parameters?.find((x) => x.key === "RequiredAuthModeForExternalBasket")
                ?.value as OAuthTokenIds,
            useSendCostEstimation: costEstimationModule?.parameters?.find((x) => x.key === "UseSendCostEstimation")?.value === "1",
            useSendOrder: costEstimationModule?.parameters?.find((x) => x.key === "UseSendOrder")?.value === "1",
        }
    }, [userContext])
}

import * as uuid from "uuid"

import { MessageBus } from "./messageBus"
import { Channel } from "./models"
import { ChannelConfig, Message, MessageContentType, Subscription, SubscriptionHandler, TMessageKeys } from "./models/internal"

export class ChannelClass<MBC, C extends keyof MBC> implements Channel<MBC, C> {
    messages: Array<Message<MBC, C, TMessageKeys<MBC, C>>> = []

    groupedMessages: { [key: string]: Array<Message<MBC, C, TMessageKeys<MBC, C>>> } = {}

    subscriptions: Array<Subscription<MBC, C>> = []

    config: ChannelConfig = {
        // countOverall: 50,
        countPerType: 3,
    }

    type: C

    messageBus: MessageBus<MBC>

    constructor(type: C, messageBus: MessageBus<MBC>) {
        this.type = type
        this.messageBus = messageBus
    }

    add<M extends TMessageKeys<MBC, C>>(topic: M, content: MessageContentType<MBC, C, M>) {
        const entry = { topic, content }

        const group = this.groupedMessages[topic as string] || []
        this.groupedMessages[topic as string] = group
        group.unshift(entry)
        const msgToRemove = group.splice(this.config.countPerType)

        this.messages.unshift(entry)
        if (msgToRemove.length) {
            this.messages.splice(this.messages.indexOf(msgToRemove[0]), 1)
        }
    }

    publish<M extends TMessageKeys<MBC, C>>(topic: M, content: MessageContentType<MBC, C, M>) {
        const topicMatches = this.topicMatches.bind(this, topic)
        const handlerFunc = (subscription: Subscription<MBC, C>) => {
            try {
                subscription.handler(content, topic)
            } catch (ex) {
                this.messageBus.throwError(this.type as string, topic as string, content, ex)
            }
        }

        const entry = { topic, content }

        const group = this.groupedMessages[topic as string] || []
        this.groupedMessages[topic as string] = group
        group.unshift(entry)
        const msgToRemove = group.splice(this.config.countPerType)

        this.messages.unshift(entry)
        if (msgToRemove.length) {
            this.messages.splice(this.messages.indexOf(msgToRemove[0]), 1)
        }
        // this.messages.splice(this.config.countOverall)
        this.subscriptions.filter(topicMatches).forEach(handlerFunc)
    }

    subscribe<M extends TMessageKeys<MBC, C>>(topic: M, handler: SubscriptionHandler<MBC, C, M>, publishLast?: boolean) {
        const id = uuid.v4()
        const subscription: Subscription<MBC, C> = { id, filter: topic, handler }
        this.subscriptions.push(subscription)
        if (publishLast) {
            const message = this.messages.filter((x) => x.topic == topic)[0] as Message<MBC, C, M>
            if (message) {
                setTimeout(() => handler(message.content, topic), 0)
            }
        }
        return () => (this.subscriptions = this.subscriptions.filter((x) => x.id != id))
    }

    subscribeOnce<M extends TMessageKeys<MBC, C>>(topic: M, handler: SubscriptionHandler<MBC, C, M>, publishLast?: boolean) {
        let unsubscribe: () => void

        const handlerWithUnsubscribe = (content: MessageContentType<MBC, C, M>, topic: TMessageKeys<MBC, C>) => {
            const eventAccepted = handler(content, topic) // is accepted when 'void' or 'true'

            // this is the case when subscriber rejects the message, so he will wait for the next one
            if (typeof eventAccepted === "boolean" && eventAccepted === false) {
                return
            }

            unsubscribe?.()
        }

        unsubscribe = this.subscribe(topic, handlerWithUnsubscribe, publishLast)

        return unsubscribe
    }

    last<M extends TMessageKeys<MBC, C>>(count?: number, topic?: M): Array<Message<MBC, C, TMessageKeys<MBC, C>>> {
        if (topic) {
            const topicMatches = (message: Message<MBC, C, TMessageKeys<MBC, C>>) => message.topic == topic
            const list = this.messages.filter(topicMatches)
            return [...list.slice(0, count || list.length)]
        }
        return [...this.messages.slice(0, count || this.messages.length)]
    }

    /**
     * Delete messages from a channel
     * @param topic optional
     * if it's passed all the messages with this topic will be deleted
     */
    clear<M extends TMessageKeys<MBC, C>>(topic?: M) {
        if (topic) {
            this.messages = this.messages.filter((x) => x.topic != topic)
            this.groupedMessages[topic as string] = []
        } else {
            this.messages = []
            this.groupedMessages = {}
        }
    }

    /**
     * Delete subscritions from a channel
     * @param topic optional
     * if it's passed all the subscritions  on this topic will be deleted
     */
    clearSubscriptions<M extends TMessageKeys<MBC, C>>(topic?: M) {
        if (topic) {
            this.subscriptions = this.subscriptions.filter((x) => x.filter != topic)
        } else {
            this.subscriptions = []
        }
    }

    clearAndSubscribeOnce<M extends TMessageKeys<MBC, C>>(topic: M, handler: SubscriptionHandler<MBC, C, M>, publishLast?: boolean) {
        this.clear(topic)
        this.clearSubscriptions(topic)
        return this.subscribeOnce(topic, handler, publishLast)
    }

    clearAndSubscribe<M extends TMessageKeys<MBC, C>>(topic: M, handler: SubscriptionHandler<MBC, C, M>, publishLast?: boolean) {
        this.clear(topic)
        this.clearSubscriptions(topic)
        return this.subscribe(topic, handler, publishLast)
    }

    configure(config: Partial<ChannelConfig>) {
        this.config = {
            ...this.config,
            ...config,
        }
    }

    private topicMatches(topic: TMessageKeys<MBC, C>, subscription: Subscription<MBC, C>) {
        // if (subscription.filter.indexOf("*")!=-1) {
        //     const regex = new RegExp(subscription.filter.replace(wildcardChars, ".*").replace(escapeChars, "\\$1"))
        //     return regex.test(topic)
        // }
        return subscription.filter == topic
    }
}

const wildcardChars = /\*/g
const escapeChars = /([\/\\])/g

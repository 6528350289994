import { KeysByValue } from "@tm/models"
import { styled, Typography, Palette, PaletteColor } from "@mui/material"

import { Icon } from "../Icons"

export type StatePillState = "accept" | "rejected" | "done" | "open"

export type StatePillProps = {
    text?: string
    state: StatePillState
}

type Colors = KeysByValue<Palette, PaletteColor>

const Container = styled("div")(({ theme }) => ({
    display: "inline-flex",
    overflow: "hidden", // because of border radius and background-color of IconBox
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "2px",
    alignItems: "center",
}))

const IconBox = styled("div")<{ color: Colors }>(({ theme, color }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px",
    backgroundColor: theme.palette[color].main,
}))

const ColorizedIcon = styled(Icon)<{ fill: Colors }>(({ theme, fill }) => ({
    width: "18px",
    height: "18px",
    fill: theme.palette[fill].contrastText,
}))

const StyledText = styled(Typography)({
    padding: "0 0.5em",
    maxWidth: "20em",
    wordBreak: "break-word",
})

const getIconData = (state: StatePillState): { icon: string; color: Colors } => {
    switch (state) {
        case "accept":
            return { icon: "check", color: "success" }
        case "rejected":
            return { icon: "close", color: "error" }
        case "done":
            return { icon: "summary", color: "warning" }
        case "open":
            return { icon: "help", color: "info" }
        default:
            return { icon: "", color: "primary" }
    }
}

export function StatePill({ text, state }: StatePillProps) {
    const { color, icon } = getIconData(state)

    return (
        <Container>
            <IconBox color={color}>
                <ColorizedIcon fill={color} name={icon} />
            </IconBox>
            {!!text && <StyledText variant="body2">{text}</StyledText>}
        </Container>
    )
}

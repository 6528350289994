import { useMemo } from "react"
import { useSessionStorage } from "@tm/utils"

const STARTPAGE = "startpage"

type SelectedLookupType = {
    lookupTypeId?: number
    countryCode?: string
}

export function useSelectedVehicleLookup(): {
    selectedVehicleLookup: SelectedLookupType
} {
    const workTaskId = window.__NEXT_WORKTASKID__ || STARTPAGE

    const { sessionStorageData } = useSessionStorage<{ [workTaskId: string]: SelectedLookupType }>("useSelectedVehicleLookup")

    const selectedVehicleLookup = useMemo(() => {
        // previous lookupselection from startpage if no lookuptype is bound to the worktask id
        return (
            sessionStorageData?.[workTaskId] ??
            sessionStorageData?.[STARTPAGE] ?? {
                lookupTypeId: undefined,
                countryCode: undefined,
            }
        )
    }, [sessionStorageData, workTaskId])

    return { selectedVehicleLookup }
}

import { Component, ComponentClass, ComponentType, createElement } from "react"
import { Omit } from "@tm/utils"
import { WorkTask } from "../model"
import { WorkTaskProviderContext } from "../model/context"

export type WithWorkTaskConflictProps = Pick<WorkTask, "triggerWorkTaskConflict" | "workTaskConflict">

export function withWorkTaskConflict<P extends WithWorkTaskConflictProps>(
    component: ComponentType<P>
): ComponentClass<Omit<P, keyof WithWorkTaskConflictProps>> {
    return class extends Component<Omit<P, keyof WithWorkTaskConflictProps>> {
        showError() {
            console.error("withWorkTaskConflict: No wrapping WorkTask Provider was found!", component)
        }

        renderInnerComponent(workTask?: WorkTask) {
            const currentProps: any = this.props || {}

            const props: P & WithWorkTaskConflictProps = {
                ...currentProps,
            }

            if (!workTask) {
                this.showError()
                props.triggerWorkTaskConflict = () => this.showError()
            } else {
                props.triggerWorkTaskConflict = workTask.triggerWorkTaskConflict
                props.workTaskConflict = workTask.workTaskConflict
                // props.workTaskLoading = workTask.workTaskLoading
            }

            return createElement(component, props)
        }

        render() {
            return <WorkTaskProviderContext.Consumer>{(workTask?: WorkTask) => this.renderInnerComponent(workTask)}</WorkTaskProviderContext.Consumer>
        }
    }
}

import { DefaultArticleItem } from "./variants/Default/DefaultArticleItem"
import { OeInformationItem } from "./variants/OeInformationItem"
import { OeArticleItem } from "./variants/OeArticleItem"
import { WholesalerArticleItem } from "./variants/WholesalerArticleItem"

export { ArticleItemStateProvider, useArticleItemState } from "./ContextProviderStates"
export { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
export { useOeArticleItemState } from "./ContextProviderStates/OeArticleItemState"
export { useOeInformationItemState } from "./ContextProviderStates/OeInformationItemState"
export { useWholesalerArticleItemState } from "./ContextProviderStates/WholesalerArticleItemState"
export { useTyresItemState } from "./ContextProviderStates/TyresItemState"

export * from "./Transactions/ReplaceButton"
export { DmsArticleInformation } from "./Dms/DmsArticleInformation"
export { ImageContainer } from "./ImageContainer"

/**
 * Based on the `variant` the properties are used from the underlying component.
 * If `variant` is not supplied it is assumed to be `PartsList`.
 */
type ArticleItemProps = { variant: "default" | "KVA" | "Basket" | "OE" | "OeInformation" | "WholesalerArticle" | "Tyres" }

export function ArticleItem({ variant }: ArticleItemProps) {
    switch (variant) {
        case "KVA":
            return null
        case "Basket":
            return null
        case "OE":
            return <OeArticleItem />
        case "OeInformation":
            return <OeInformationItem />
        case "WholesalerArticle":
            return <WholesalerArticleItem />
        case "Tyres":
        case "default":
        default:
            return <DefaultArticleItem />
    }
}

import { UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { getParameterFromUserContextModule } from "../../helper"
import { useUser } from "../../user"

export function useTyresV2() {
    const { userContext } = useUser() || {}
    const tyresVersionParameter = getParameterFromUserContextModule(userContext, UserModuleType.TMTires, "Version")

    const tyresV2 = useMemo(() => tyresVersionParameter === "2", [tyresVersionParameter])

    return tyresV2
}

import { useMemo } from "react"
import { useKeyValue } from "../statemanagement"

export type WorkTaskTruckData = {
    vehicleNr?: string
    plateNr?: string
    vinNr?: string
    customerName?: string
    vehicleName?: string
}

export function useWorkTaskTruckData(workTaskId: string | undefined) {
    const [value, setValue] = useKeyValue(workTaskId ? { key: `${workTaskId}_TRUCK-DATA`, ownedByRepairShop: true } : undefined)

    const workTaskTruckData = useMemo(() => {
        return value ? (JSON.parse(value) as WorkTaskTruckData) : undefined
    }, [value])

    const setWorkTaskTruckData = useMemo(() => {
        if (!setValue) {
            return
        }
        return (data: WorkTaskTruckData | undefined) => {
            return setValue(data ? JSON.stringify(data) : undefined)
        }
    }, [setValue])

    return { workTaskTruckData, setWorkTaskTruckData }
}

import { PostMessageSourceId, TmaEModule, UserInteractionLog } from "@tm/models"
import { mapSourceIdToTmaModule } from "./mapper"
import { uniqueId } from "../.."

export const PostMessageHelper = {
    AddToBasket: {
        GetUserInteractionLog: (sourceId?: PostMessageSourceId): UserInteractionLog => {
            return {
                origin: mapSourceIdToTmaModule(TmaEModule.EXTERNALCATALOGUE, sourceId),
                eventId: uniqueId(),
            }
        },
    },
    SearchTyre: {
        GetOrigin: (sourceId?: PostMessageSourceId): TmaEModule => {
            return mapSourceIdToTmaModule(TmaEModule.TM_TYRE_SEARCH, sourceId)
        },
    },
}

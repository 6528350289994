import { ScreenSizeProps } from "./model"

export const getWindowSize = () => {
    const { innerWidth: width } = window
    let windowSize: ScreenSizeProps["screenSize"] = "m"
    if (width < 800) {
        windowSize = "xs"
    }
    if (width >= 800 && width < 1100) {
        windowSize = "s"
    }
    if (width >= 1100 && width < 1300) {
        windowSize = "m"
    }
    if (width >= 1300 && width < 1700) {
        windowSize = "l"
    }
    if (width >= 1700) {
        windowSize = "xl"
    }

    return windowSize
}

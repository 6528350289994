import { Component, createRef, PropsWithChildren, ReactNode, RefObject } from "react"
import { createPortal } from "react-dom"
import { registerOutsideClick } from "@tm/utils"
import { bindMethodsToContext } from "../../helper"
import { getTime } from "../../helper/date"
import { Colored } from "../../models/SharedModels"
import Button from "../button"
import Icon from "../icon"
import Text from "../text"
import Image from "../image"

export type DialogComponentProps = PropsWithChildren<
    Colored & {
        iconName?: string
        imagePath?: string
        time?: Date
        confirmButtonText?: string
        confirmButtonDisabled?: boolean
        cancelButtonText?: string
        skin?: "primary" | "warning" | "danger"
        layout?: "stretch"
        preText?: string
        text?: string | ReactNode
        onConfirm?(e?: Event): void
        onCancel?(e?: Event): void
        onClose?(e?: Event): void
        doNotCloseOnConfirm?: boolean
        closeOnlyWithDialogInteraction?: boolean
        customConfirmationButtonIcon?: string
        hideCloseButton?: boolean
    }
>

type DialogComponentState = {
    open: boolean
    dropIn: boolean
}

const baseName = "dialog-prompt"
export default class Dialog extends Component<DialogComponentProps, DialogComponentState> {
    innerRef: RefObject<HTMLDivElement>

    unreg?: () => void

    timeout?: number

    constructor(props: DialogComponentProps) {
        super(props)
        bindMethodsToContext(this, ["stop", "show", "hide"])

        this.state = {
            dropIn: false,
            open: false,
        }

        this.innerRef = createRef()
    }

    componentDidUpdate(prevProps: DialogComponentProps, prevState: DialogComponentState) {
        if (!this.state.dropIn) {
            this.timeout = window.setTimeout(() => {
                this.setState({ dropIn: true })
            }, 100)
        }
        if (prevState.open !== this.state.open) {
            this.innerRef.current?.focus()
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.timeout)
        document.removeEventListener("keydown", this.handleConfirmViaKeyPress)
    }

    handleClose(e?: Event) {
        e?.stopPropagation()
        e?.preventDefault()

        this.hide()
        this.props.onClose?.(e)
    }

    handleConfirm(e?: Event) {
        this.props.onConfirm?.(e)
        if (this.props.doNotCloseOnConfirm != true) {
            this.handleClose(e)
        } else {
            this.hide()
        }
    }

    handleConfirmViaKeyPress(event: KeyboardEvent) {
        if (event.key == "Enter" && !this.props.confirmButtonDisabled) {
            this.handleConfirm(event)
        }
    }

    handleDecline(e?: Event) {
        this.props.onCancel?.(e)
        this.handleClose(e)
    }

    show() {
        this.setState({ open: true })
        document.addEventListener("keydown", this.handleConfirmViaKeyPress)
    }

    hide() {
        this.unreg && this.unreg()
        this.setState({ dropIn: false, open: false })
    }

    render(): any {
        if (!this.state.open) {
            return null
        }

        const {
            time,
            text,
            children,
            cancelButtonText,
            confirmButtonText,
            iconName,
            preText,
            customConfirmationButtonIcon,
            hideCloseButton,
            imagePath,
        } = this.props

        let className = `${baseName} ${this.props.className || ""}`
        if (this.props.skin) {
            className += ` ${baseName}--${this.props.skin}`
        }
        if (this.props.layout) {
            className += ` ${baseName}--${this.props.layout}`
        }

        const preTextSize = this.props.layout == "stretch" ? "m" : "s"
        const textSize = this.props.layout == "stretch" ? "xl" : "l"
        const timeSize = "xs"

        const comp: ReactNode = (
            <div
                className={className}
                ref={(ref) => {
                    if (ref) {
                        this.unreg = registerOutsideClick(ref, this.handleClose, !this.props.closeOnlyWithDialogInteraction)
                    }
                }}
            >
                <div className={`dialog-prompt__inner ${this.state.dropIn ? "dialog-prompt__inner--drop" : ""}`} ref={this.innerRef} tabIndex={-1}>
                    <div className="dialog-prompt__content">
                        <div className="dialog-prompt__info">
                            {iconName && (
                                <div className="dialog-prompt__icon">
                                    <Icon size="xl" name={iconName} skin={this.props.skin} />
                                </div>
                            )}
                            {imagePath && (
                                <div className="dialog-prompt__image">
                                    <Image url={imagePath} />
                                </div>
                            )}
                            {(time || text) && (
                                <div className="dialog-prompt__title">
                                    {preText && (
                                        <Text className="dialog-prompt__pre-text" size={preTextSize}>
                                            {preText}
                                        </Text>
                                    )}
                                    {text && (
                                        <Text className="dialog-prompt__text" size={textSize}>
                                            {text}
                                        </Text>
                                    )}
                                    {time && (
                                        <Text className="dialog-prompt__time" modifiers="sub" size={timeSize}>
                                            {getTime(time)}
                                        </Text>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="dialog-prompt__buttons">
                            {!(cancelButtonText && confirmButtonText) && !hideCloseButton && (
                                <div className="dialog-prompt__close">
                                    <Button size="l" onClick={this.handleClose} layout={["ghost"]} icon="close" />
                                </div>
                            )}
                            {cancelButtonText && (
                                <Button size="l" onClick={this.handleDecline}>
                                    {cancelButtonText}
                                </Button>
                            )}
                            {confirmButtonText && (
                                <Button
                                    size="l"
                                    icon={customConfirmationButtonIcon ?? "check"}
                                    skin="success"
                                    onClick={this.handleConfirm}
                                    disabled={this.props.confirmButtonDisabled}
                                >
                                    {confirmButtonText}
                                </Button>
                            )}
                        </div>
                    </div>
                    {children && <div className="dialog-prompt__additional-content">{children}</div>}
                </div>
            </div>
        )
        return createPortal(comp, document.getElementsByTagName("body")[0])
    }
}

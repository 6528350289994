import { bindSpecialReactMethods, ButtonKeyDefinition, Omit, Overwrite, registerOutsideClick } from "@tm/utils"

import { Component, createRef, ReactNode, RefObject } from "react"
import { Scrollbar, SearchFieldProps, Table } from ".."
import { ControlsConfig } from "../../configuration"
import { SearchFieldConfig } from "../search-field"
import { ExternalTooltip } from "../search-field/external-hint"
import DefaultSearchField from "../search-field/index.default"

export type Props<TSuggestion> = Overwrite<
    Omit<SearchFieldProps, keyof { model: any; path: any; showClear: any }>,
    {
        value: string // value can just be a string, model and path are not available

        loading?: boolean
        suggestions: Array<TSuggestion>
        requestSuggestions(value: string): void

        onChange(value: string): void // overwrite to only support value
        onChangeConfirm(value: string): void // overwrite to only support value
        onSuggestionSelect?(suggestion: TSuggestion): void
        onClose?(): void

        renderTableColumns?(): Array<JSX.Element>

        additionalInputIcons?: ReactNode
        appendix?: JSX.Element | Array<JSX.Element>
        dropdownPrefix?: ReactNode

        maxHeight?: number
        suggestDelay?: number
        modifier?: "active" | "highlight"
        enableLeadingTrim?: boolean
        minCharactersToSuggest?: number

        ignoreConfig?: Array<keyof SearchFieldConfig>
        forceShowTooltipOnHover?: boolean

        buttonIcon?: string
    }
>

export type State = {
    open?: boolean
    markedIndex?: number
    props: SearchFieldConfig
}

const SELECTED_ITEM_CLASSNAME = "is-selected"

type ScrollbarRef = {
    container?: HTMLElement
    view?: HTMLElement
}

export default class SuggestionField<TSuggestion> extends Component<Props<TSuggestion>, State> {
    private suggestionTimeoutId: number | undefined

    private scrollBoxRef: RefObject<HTMLDivElement> = createRef()

    private searchFieldRef: RefObject<DefaultSearchField> = createRef()

    private scrollbarRef: ScrollbarRef | undefined

    private unregisterOutsideClick?: () => void

    private loadSuggestionsAfterFocus = true

    static defaultProps = {
        suggestionTimeout: 250,
    }

    constructor(props: Props<TSuggestion>) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            open: false,
            markedIndex: undefined,
            props: ControlsConfig.get<SearchFieldConfig>("SearchField"),
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.suggestionTimeoutId)
        this.unregisterOutsideClick && this.unregisterOutsideClick()
    }

    componentDidUpdate() {
        const { maxHeight } = this.props

        if (!this.scrollbarRef || !maxHeight) {
            return
        }

        const { view } = this.scrollbarRef
        if (!view) {
            return
        }

        const list: HTMLElement | null = view.querySelector(".fancy-list")
        if (!list) {
            return
        }

        let { height } = list.getBoundingClientRect()

        if (height > maxHeight) {
            height = maxHeight
            const scrollerWidth = 12
            list.style.paddingRight = `${scrollerWidth}px`
        } else {
            list.style.paddingRight = ""
        }

        const { container } = this.scrollbarRef
        if (!container) {
            return
        }
        container.style.height = `${height}px`
    }

    public focus(loadSuggestions = true) {
        this.loadSuggestionsAfterFocus = loadSuggestions
        this.searchFieldRef.current && this.searchFieldRef.current.focus()
    }

    private loadSuggestions(query: string, instant = false) {
        if (query.length < (this.props.minCharactersToSuggest || 2)) {
            return
        }

        const { requestSuggestions } = this.props

        this.setState({
            open: true,
            markedIndex: undefined,
        })

        if (this.scrollBoxRef.current) {
            this.unregisterOutsideClick = registerOutsideClick(this.scrollBoxRef.current, this.handleClose)
        }

        if (instant) {
            requestSuggestions(query)
        } else {
            window.clearTimeout(this.suggestionTimeoutId)
            this.suggestionTimeoutId = window.setTimeout(() => requestSuggestions(query), this.props.suggestDelay)
        }
    }

    private handleScrollbarRef(ref: any) {
        if (!ref) {
            return
        }

        // Scrollbar component has changed
        if (!(ref as ScrollbarRef).view || !(ref as ScrollbarRef).container) {
            console.warn("Some functions (height of suggestions, automated scrolling) are not possible due to changes in the Scrollbar component")
            return
        }

        this.scrollbarRef = ref
    }

    private handleClose() {
        const { onClose } = this.props

        this.setState({
            open: false,
            markedIndex: undefined,
        })

        onClose && onClose()
    }

    private handleKeyStroke(e: React.KeyboardEvent<HTMLElement>) {
        const { markedIndex } = this.state

        switch (e.key) {
            case ButtonKeyDefinition.ArrowUp: {
                e.preventDefault()
                this.handleMarkPreviousSuggestion()
                break
            }
            case ButtonKeyDefinition.ArrowDown: {
                e.preventDefault()
                this.handleMarkNextSuggestion()
                break
            }
        }
    }

    private handleKeyUp(e: React.KeyboardEvent<HTMLElement>) {
        const { markedIndex } = this.state

        switch (e.key) {
            case ButtonKeyDefinition.Escape:
            case ButtonKeyDefinition.Tab: {
                this.handleClose()
                break
            }
            case ButtonKeyDefinition.Enter: {
                e.preventDefault()

                if (markedIndex != undefined) {
                    this.handleSuggestionSelect(this.props.suggestions[markedIndex])
                } else {
                    this.props.onChangeConfirm(this.props.value)
                    this.handleClose()
                }

                break
            }
        }
    }

    private handleMarkNextSuggestion() {
        const { suggestions } = this.props
        if (!suggestions.length) {
            return
        }

        this.setState(
            (prevState) => {
                let { markedIndex = -1 } = prevState
                markedIndex++

                if (markedIndex < suggestions.length) {
                    return { markedIndex }
                }

                return { markedIndex: undefined }
            },
            () => this.handleScrolling()
        )
    }

    private handleMarkPreviousSuggestion() {
        const { suggestions } = this.props
        if (!suggestions.length) {
            return
        }

        this.setState(
            (prevState) => {
                let { markedIndex = suggestions.length } = prevState
                markedIndex--

                if (markedIndex >= 0) {
                    return { markedIndex }
                }

                return { markedIndex: undefined }
            },
            () => this.handleScrolling()
        )
    }

    private handleScrolling() {
        const { suggestions } = this.props
        const { markedIndex } = this.state
        if (markedIndex == undefined || !suggestions.length || suggestions.length == 1 || !this.scrollbarRef || !this.scrollbarRef.container) {
            return
        }

        const selectedItem = this.scrollbarRef.container.querySelector(`.fancy-list__item.${SELECTED_ITEM_CLASSNAME}`)
        if (!selectedItem) {
            return
        }

        selectedItem.scrollIntoView({ behavior: "smooth", block: "end" })
    }

    private handleChange(query: string) {
        const { onChange } = this.props
        onChange && onChange(query)

        const value = this.prepareSuggestValue(query)

        if (value.length >= (this.props.minCharactersToSuggest || 2)) {
            this.loadSuggestions(value)
        }
    }

    prepareSuggestValue = (value: string): string => {
        const checkLeadingOrOnlyWhitespaces = /(\s+)\w+|^\s+/
        const leadingWhitespaces = /^\s/

        if (this.props.enableLeadingTrim && checkLeadingOrOnlyWhitespaces.test(value)) {
            value = value.replace(leadingWhitespaces, "")
        }

        return value
    }

    private handleChangeConfirm(value: string) {
        const { onChangeConfirm } = this.props
        onChangeConfirm && onChangeConfirm(value)
        this.handleClose()
    }

    private handleSuggestionSelect(suggestion: TSuggestion) {
        const { onSuggestionSelect } = this.props
        onSuggestionSelect && onSuggestionSelect(suggestion)

        this.focus(false)

        this.setState({
            markedIndex: undefined,
        })
    }

    private handleFocus() {
        const { value, onFocus } = this.props
        onFocus && onFocus()

        if (!this.loadSuggestionsAfterFocus) {
            this.loadSuggestionsAfterFocus = true
            return
        }

        // don't reload suggestions if the suggest box is already opened and the input gets focus
        // e.g. the user selects a suggestion, the input box is focused again
        if (this.state.open) {
            return
        }

        this.loadSuggestions(value, true)
    }

    private renderTableColumns() {
        const { renderTableColumns } = this.props
        if (renderTableColumns) {
            return renderTableColumns()
        }

        return [<Table.Column renderItemContent={(item: any) => <Table.Cell>{item}</Table.Cell>} />]
    }

    private renderSuggestions() {
        const { suggestions } = this.props
        const { markedIndex } = this.state

        if (!suggestions.length) {
            return
        }

        return (
            <Scrollbar onRef={this.handleScrollbarRef}>
                <Table
                    data={suggestions}
                    columns={this.renderTableColumns()}
                    onClickRow={this.handleSuggestionSelect}
                    getRowClassName={(_item, idx) => (idx == markedIndex ? SELECTED_ITEM_CLASSNAME : "")}
                    getFocusedRowIndex={() => markedIndex || 0}
                />
            </Scrollbar>
        )
    }

    render() {
        const { suggestions, appendix, size, dropdownPrefix, modifier, tooltip, ignoreConfig, buttonIcon } = this.props
        const {
            open,
            props: { showHintAsTooltip },
        } = this.state

        const showSuggestions = open && (suggestions.length || dropdownPrefix)

        const tooltipText = showHintAsTooltip ? tooltip : this.props.forceShowTooltipOnHover ? tooltip : undefined
        const ignoreHintAsTooltip = ignoreConfig && ignoreConfig.indexOf("showHintAsTooltip") >= 0

        let className = "suggest suggest--new "
        if (showSuggestions) {
            className += "suggest--open "
        }
        if (modifier) {
            className += `suggest--${modifier} `
        }
        if (this.props.className) {
            className += this.props.className
        }

        let boxClassName = "suggest__box "
        if (size) {
            boxClassName += `suggest__box--${size} `
        }
        if (showSuggestions) {
            boxClassName += "is-visible "
        }

        return (
            <>
                <div className={className} onKeyDown={this.handleKeyStroke} onKeyUp={this.handleKeyUp} ref={this.scrollBoxRef}>
                    <div className={boxClassName}>
                        {dropdownPrefix}
                        {this.renderSuggestions()}
                    </div>

                    <DefaultSearchField
                        {...this.props}
                        tooltip={showSuggestions ? tooltip : tooltipText}
                        autoComplete="off"
                        ref={this.searchFieldRef}
                        onChange={this.handleChange}
                        onChangeConfirm={undefined}
                        onFocus={this.handleFocus}
                        showClear
                        buttonIcon={buttonIcon}
                    />

                    <div className="suggest__appendix">{appendix}</div>
                </div>
                {!this.props.forceShowTooltipOnHover && !ignoreHintAsTooltip && !showHintAsTooltip && <ExternalTooltip tooltip={tooltip} />}
            </>
        )
    }
}

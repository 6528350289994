import { FC } from "react"
import { IconButtonProps, styled } from "@mui/material"
import { IconButton } from "../button"

export const ArrowUp: FC<IconButtonProps> = styled(IconButton)(({ theme }) => ({
    padding: 0,
    margin: 0,
    overflow: "hidden",
    svg: {
        transform: "scale(1.2) translateY(1px)",
    },
    "&:hover": {
        svg: {
            fill: theme.palette.primary.main,
        },
    },
}))

export const ArrowDown: FC<IconButtonProps> = styled(IconButton)(({ theme }) => ({
    padding: 0,
    margin: 0,
    overflow: "hidden",
    svg: {
        transform: "scale(1.2) translateY(-1px)",
    },
    "&:hover": {
        background: theme.palette.highlight.dark,
        backgroundColor: theme.palette.highlight.main,
        svg: {
            fill: theme.palette?.primary.main,
        },
    },
}))

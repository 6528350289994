import { Stack } from "@mui/material"
import { useMemo } from "react"
import { CostEstimationButton } from "./CostEstimationButton"
import { AddOeToBasket } from "./AddOeToBasket"
import { useOeArticleItemState } from "../ContextProviderStates/OeArticleItemState"
import { ReplaceButton } from "./ReplaceButton"
import { ReplaceButtonWithQuantity } from "./ReplaceButtonWithQuantity"
import { useArticleItemState } from "../ContextProviderStates"

export default function OeTransactions() {
    const { hideCostEstimationButton, hideAddToBasketButton, hideReplaceButtonWithQuantity } = useOeArticleItemState((x) => x.options)
    const showReplaceButton = useOeArticleItemState((x) => x.showReplaceButton)
    const handleReplaceArticle = useOeArticleItemState((x) => x.handleReplaceArticle)
    const article = useOeArticleItemState((x) => x.article)
    const handleChangeQuantity = useOeArticleItemState((x) => x.handleChangeQuantity)
    const articleErpInfo = useArticleItemState((x) => x.articleErpInfos?.default)
    const division = useMemo(() => (articleErpInfo?.state === "success" ? articleErpInfo.response.quantity?.division : 1) || 1, [articleErpInfo])

    return (
        <Stack spacing={0.5} direction="row" justifyContent="end">
            {!showReplaceButton ? !hideAddToBasketButton && <AddOeToBasket /> : <ReplaceButton onClick={handleReplaceArticle} />}
            {!hideReplaceButtonWithQuantity && !showReplaceButton && hideCostEstimationButton && hideAddToBasketButton && (
                <ReplaceButtonWithQuantity
                    originalQuantity={article.quantity ?? 1}
                    handleChangeQuantity={handleChangeQuantity}
                    handleReplaceArticle={handleReplaceArticle}
                    division={division}
                />
            )}
            {!hideCostEstimationButton && (
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                <CostEstimationButton onAddToCostEstimation={() => {}} disabled />
            )}
        </Stack>
    )
}

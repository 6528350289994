import { Component, ComponentType } from "react"
import { bindMethodsToContext } from "../../../helper"

export type DropdownListItemProps = {
    isListItem?: boolean
}

export type DropdownItemProps<T extends {}> = DropdownListItemProps & {
    className: string
    onClick(e: React.MouseEvent<HTMLDivElement>, item: T): void
    View: ComponentType<T>
    item: T & { disabled?: boolean }
}

export default class DropdownItem<T extends {}> extends Component<DropdownItemProps<T>> {
    constructor(props: any) {
        super(props)
        bindMethodsToContext(this)
    }

    render() {
        const { View, item, isListItem } = this.props
        const ViewComponent = View as ComponentType<T & { isListItem?: boolean }>

        let className = this.props.className || ""

        if (item.disabled) {
            className += " is-disabled"
        }

        return (
            <div className={className} onClick={!item.disabled ? this.handleClick : undefined}>
                <ViewComponent {...item} isListItem={isListItem} />
            </div>
        )
    }

    handleClick(e: React.MouseEvent<HTMLDivElement>) {
        const { item, onClick } = this.props
        onClick && onClick(e, item)
    }
}

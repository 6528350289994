import { WorkTaskStatus, WorkTaskDetailsReadModel, channel } from "@tm/models"
import { decodeUniqueId, setCoopMemberId, uniqueId } from "@tm/utils"
import { WorkTaskProvider } from "./WorkTaskProvider"
import * as Data from "../data"

export function loadWorkTaskInfo(this: WorkTaskProvider, workTaskString?: string, alreadyLoadedWorkTask?: WorkTaskDetailsReadModel) {
    const handleNotAccesWorktask = (workTaskId?: string) => {
        const { location } = this.props
        const subPage = location.pathname.replace(`/${workTaskString}`, "") + location.search
        this.openWorkTask(uniqueId(), subPage)
        channel("WORKTASK", workTaskId).publish("WORKTASK/CLOSE", undefined)
        channel("APP").publish("TOAST_MESSAGE/SHOW", {
            message: "{{12530}}",
            icon: "attention-dark",
            skin: "warning",
            closeDelay: 10 * 1000,
        })
    }

    return new Promise<WorkTaskDetailsReadModel>((resolve, reject) => {
        let workTaskId: string | undefined
        if (workTaskString) {
            if (workTaskString.length === 36) {
                workTaskId = workTaskString
            } else if (workTaskString.length === 22) {
                workTaskId = decodeUniqueId(workTaskString)
            }
        }

        if (workTaskId !== this.state.workTaskId) {
            channel("GLOBAL").publish("WORKTASK/ID_CHANGED", { id: workTaskId, previousId: this.state.workTaskId })
            channel("GLOBAL").publish("VEHICLE/RESET", undefined)
            channel("GLOBAL").publish("CUSTOMER/RESET", undefined)
            setCoopMemberId(undefined)
        }

        const workTaskInfo = this.state.workTaskInfo && this.state.workTaskInfo.id === workTaskId ? this.state.workTaskInfo : undefined
        this.setState({ workTaskInfo, workTaskId: workTaskInfo?.id, conflict: undefined })

        if (workTaskId) {
            this.setState({ loading: true, loadingNewWorkTask: workTaskId != this.state.workTaskId }) // semicolon is needed
            ;(alreadyLoadedWorkTask?.workTaskId === workTaskId ? Promise.resolve(alreadyLoadedWorkTask) : Data.loadWorktask(workTaskId))
                .then((workTask) => {
                    resolve(workTask)
                    setCoopMemberId(workTask?.cooperationMemberId)
                    Data.setWorktaskSelected(workTaskId!)
                    if (workTask && !(workTask as any).error) {
                        // NEXT-19545 don't open closed worktasks
                        const { hasTelesales } = window.userContext
                        if (!hasTelesales && workTask.statusValue === WorkTaskStatus.Completed) {
                            handleNotAccesWorktask(workTaskId)
                            this.setState({ loading: false })
                            reject()
                        } else {
                            channel("WORKTASK", workTaskId).publish("WORKTASK/LOADED", workTask)

                            // Look if there's not a new worktask loading. In this case, throw the old one away.
                            const currentWorkTaskId = window.__NEXT_WORKTASKID__
                            if (workTaskId != currentWorkTaskId) {
                                return
                            }

                            if (workTask.customer) {
                                channel("WORKTASK", workTaskId).publish("CUSTOMER/LOADED", workTask.customer)
                                channel("GLOBAL").publish("CUSTOMER/LOADED", workTask.customer)
                            }
                            if (workTask.vehicle) {
                                channel("WORKTASK", workTaskId).publish("VEHICLE/LOADED", workTask.vehicle)
                                channel("GLOBAL").publish("VEHICLE/LOADED", workTask.vehicle)
                            }

                            this.setState({
                                workTaskInfo: {
                                    id: workTask.workTaskId,
                                    no: workTask.workTaskNo,
                                    refId: workTask.refId,
                                    statusValue: workTask.statusValue,
                                    customer: workTask.customer,
                                    vehicle: workTask.vehicle,
                                    version: workTask.version,
                                    telesalesStatusValue: workTask.telesalesStatusValue,
                                    workTaskReadModel: workTask,
                                },
                                workTaskId: workTask.workTaskId,
                                loading: false,
                                loadingNewWorkTask: false,
                            })

                            if (workTask.vehicle) {
                                this.loadVehicleDataAvailabilities(workTask.vehicle)
                            }
                        }
                    } else {
                        channel("WORKTASK", workTaskId).publish("WORKTASK/LOADED", {
                            workTaskId: workTaskId!,
                            statusValue: WorkTaskStatus.Undefined,
                            telesalesStatusValue: WorkTaskStatus.Undefined,
                        })
                        this.setState({
                            workTaskInfo: {
                                id: workTaskId!,
                                statusValue: WorkTaskStatus.Undefined,
                                telesalesStatusValue: WorkTaskStatus.Undefined,
                            },
                            workTaskId,
                            loading: false,
                            loadingNewWorkTask: false,
                        })
                    }
                })
                .catch((error) => {
                    if (error?.type === "DataAccessViolationException") {
                        handleNotAccesWorktask(workTaskId)
                    }
                    this.setState({ loading: false, loadingNewWorkTask: false })
                    reject()
                })
        }
    })
}

export function loadWorkTaskInfoModal(this: WorkTaskProvider, workTaskString?: string) {
    return new Promise<WorkTaskDetailsReadModel>((resolve, reject) => {
        let workTaskId: string | undefined
        if (workTaskString) {
            if (workTaskString.length === 36) {
                workTaskId = workTaskString
            } else if (workTaskString.length === 22) {
                workTaskId = decodeUniqueId(workTaskString)
            }
        }

        if (workTaskId) {
            this.setState({ loading: true, loadingNewWorkTask: workTaskId != this.state.workTaskId })
            Data.loadWorktask(workTaskId).then((workTask) => {
                resolve(workTask)
                setCoopMemberId(workTask?.cooperationMemberId)

                channel("WORKTASK", workTaskId).publish("WORKTASK/LOADED", workTask)

                // Look if there's not a new worktask loading. In this case, throw the old one away.
                const currentWorkTaskId = window.__NEXT_WORKTASKID__
                if (workTaskId != currentWorkTaskId) {
                    return
                }

                if (workTask.customer) {
                    channel("WORKTASK", workTaskId).publish("CUSTOMER/LOADED", workTask.customer)
                    channel("GLOBAL").publish("CUSTOMER/LOADED", workTask.customer)
                }
                if (workTask.vehicle) {
                    channel("WORKTASK", workTaskId).publish("VEHICLE/LOADED", workTask.vehicle)
                    channel("GLOBAL").publish("VEHICLE/LOADED", workTask.vehicle)
                }

                this.setState({
                    workTaskInfo: {
                        id: workTask.workTaskId,
                        no: workTask.workTaskNo,
                        refId: workTask.refId,
                        statusValue: workTask.statusValue,
                        customer: workTask.customer,
                        vehicle: workTask.vehicle,
                        version: workTask.version,
                        telesalesStatusValue: workTask.telesalesStatusValue,
                        workTaskReadModel: workTask,
                    },
                    workTaskId: workTask.workTaskId,
                    loading: false,
                    loadingNewWorkTask: false,
                })
            })
        }
    })
}

import { forwardRef, SyntheticEvent } from "react"
import { Skeleton, styled } from "@mui/material"

import { ArticleImage } from "@tm/models"
import { Image } from "../../../generics/image"

type Props = {
    image: ArticleImage
    isLoading: boolean
    onImageLoad?: (e?: SyntheticEvent<HTMLImageElement>) => void
}

const IMAGEHEIGHT = "420px"
const IMAGEWIDTH = "400px"

const StyledImage = styled(Image)(() => ({
    transition: "none",
    maxWidth: "1060px",
    height: IMAGEHEIGHT,
}))

export const ViewerImage = forwardRef<HTMLImageElement, Props>(({ image, onImageLoad, isLoading }, ref) => {
    return !image || isLoading ? (
        <Skeleton variant="rectangular" height={IMAGEHEIGHT} width={IMAGEWIDTH} sx={{ ml: 8, mr: 8, mt: 2 }} />
    ) : (
        <StyledImage src={image.imageUrl} title={image.description} ref={ref} onLoad={onImageLoad} />
    )
})

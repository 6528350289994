import { memo, useMemo } from "react"
import { Box, Grid, Stack, Typography, styled } from "@mui/material"
import { AddToBasket } from "../Transactions/AddToBasket"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { WholesalerErpContainer } from "../ErpContainer"
import { useWholesalerArticleItemState } from "../ContextProviderStates/WholesalerArticleItemState"
import { ArticleContainer, ArticleWrapper } from "./Default/DefaultArticleItem"
import { StyledGridItem } from "./Default/ExpandedArticleContent"
import { GridItem } from "./Default/ArticleItemComponents"

const ImageContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    border: `1px solid transparent`,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "60px",
    height: "60px",
    zIndex: 2,
    img: {
        objectFit: "contain",
    },
    margin: "auto 2.5em",
    padding: "3px",
}))

const StyledButton = styled(Button)(() => ({}))
StyledButton.defaultProps = { variant: "outlined" }

export const WholesalerArticleItem = memo(() => {
    const quantity = useWholesalerArticleItemState((x) => x.quantity)
    const addToBasketButtonDisabled = useWholesalerArticleItemState((x) => x.addToBasketButtonDisabled)
    const articleErpInfo = useWholesalerArticleItemState((x) => x.articleErpInfos?.default)
    const division = useMemo(() => (articleErpInfo?.state === "success" ? articleErpInfo.response.quantity?.division : 1) || 1, [articleErpInfo])
    const basketQuantity = useWholesalerArticleItemState((x) => x.basketQuantity?.articleQuantities?.totalQuantity)
    const handleChangeQuantity = useWholesalerArticleItemState((x) => x.handleChangeQuantity)
    const handleRemoveFromBasket = useWholesalerArticleItemState((x) => x.handleRemoveFromBasket)
    const handleAddToBasket = useWholesalerArticleItemState((x) => x.handleAddToBasket)
    const article = useWholesalerArticleItemState((x) => x.article)

    const additionalInformation = article.additionalInformationExtended || (
        articleErpInfo?.state === "success" ? articleErpInfo?.response?.additionalInformation : undefined
    )

    return (
        <ArticleWrapper>
            <ArticleContainer>
                <Grid container direction="row" wrap="nowrap" alignItems="start">
                    {/* Expand Icon */}
                    <StyledGridItem>
                        {/* Article Image, Supplier */}
                        <Grid item>
                            <Stack alignItems="center">
                                <ImageContainer>
                                    <Icon name="supplier" width="100%" height="100%" />
                                </ImageContainer>
                            </Stack>
                        </Grid>
                    </StyledGridItem>

                    {/* Article Infos */}
                    <GridItem item xs p={1} overflow="hidden">
                        <Stack spacing={1}>
                            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                <Stack>
                                    <Typography sx={{ color: "primary.main", fontWeight: "bold" }}>{article.wholesalerArticleNumber}</Typography>
                                    <Typography>{additionalInformation}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </GridItem>

                    {/* Actions, ERP Infos */}
                    <Grid item flex="0 0 240px" justifyContent="end" paddingX={1.5} paddingY={1}>
                        <Stack spacing={2}>
                            <Box m="auto 50px auto auto">
                                <AddToBasket
                                    originalQuantity={quantity}
                                    division={division}
                                    basketQuantity={basketQuantity}
                                    onAddToBasket={handleAddToBasket}
                                    handleChangeQuantity={handleChangeQuantity}
                                    onRemoveFromBasket={handleRemoveFromBasket}
                                    disableButton={addToBasketButtonDisabled}
                                />
                                <Box mt=".5em">
                                    <WholesalerErpContainer />
                                </Box>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </ArticleContainer>
        </ArticleWrapper>
    )
})

import { Radio as MuiRadio, radioClasses, RadioProps, styled } from "@mui/material"

const StyledRadioButton = styled(MuiRadio)<RadioProps>(({ theme }) => ({
    [`&.${radioClasses.checked}`]: {
        "& span > svg:nth-of-type(1)": {
            color: theme.palette.grey[800],
        },
    },
    [`&.${radioClasses.root}`]: {
        padding: "4px 4px 4px 9px",

        "& span > svg:nth-of-type(1)": {
            color: theme.palette.grey[800],
        },
    },
}))

export function RadioButton(props: RadioProps) {
    return <StyledRadioButton {...props} />
}

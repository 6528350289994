import { Box, BoxProps, styled } from "@mui/material"
import { sanitize } from "@tm/utils"

type BoxAdvancedProps = BoxProps & {
    innerHTML?: string
}

const StyledBox = styled(Box)({
    lineHeight: 1.3,
    overflow: "hidden",
    b: {
        fontWeight: 700,
    },
})

export function HTMLBox(props: BoxAdvancedProps) {
    const sanitizedHTML = props.innerHTML ? sanitize(props.innerHTML, { ALLOWED_TAGS: ["b", "br", "u", "#text", "p"], ALLOWED_ATTR: [] }) : undefined

    return <StyledBox dangerouslySetInnerHTML={{ __html: `${sanitizedHTML}` }} />
}

import { bindActionCreators, Dispatch, Action } from "redux"
import { connect } from "react-redux"
import { ComponentType } from "react"

type ConnectProps<T> = { actions: T; state: unknown }

export function connectComponent<T, P>(actions: T, component: ComponentType<P>): ComponentType<Omit<P, keyof ConnectProps<T>>> {
    function mapStateToProps(state: {}, props: {}) {
        return {
            ...props,
            state,
        }
    }

    function mapDisptachToProps(dispatch: Dispatch<Action<{}>>, props: {}) {
        return {
            ...props,
            actions: bindActionCreators((actions as any) || {}, dispatch),
        }
    }

    return connect(mapStateToProps, mapDisptachToProps)(component as any)
}

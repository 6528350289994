import { FC, ReactElement } from "react"
import { Button, Icon, Text } from ".."

export type MessageUpdateCheckProps = {
    icon?: string
    title: string
    message: string
    onCancel?(): void
    buttons?: ReactElement
}

export const MessageUpdateCheck: FC<MessageUpdateCheckProps> = (props) => {
    return (
        <div className="message-toast message-toast--warning message-toast--update-check">
            <div className="message-toast__icon">
                <Icon name={props.icon ?? "attention-dark"} size="xl" />
            </div>
            <div className="message-toast__text-wrapper">
                <Text modifiers={["block", "strong"]} size="l" className="message-toast__message">
                    {props.title}
                </Text>
                <Text modifiers={["block"]} size="m" className="message-toast__description">
                    {props.message}
                </Text>
                {props.buttons && <div className="message-toast__actions">{props.buttons}</div>}
            </div>
            {props.onCancel && <Button size="xl" className="message-toast__close-button" icon="close" layout={["ghost"]} onClick={props.onCancel} />}
        </div>
    )
}

import { Article, ErpPartsModifiers, AvailabilityStatus, TmaEModule } from "@tm/models"
import { CatalogPartAddedRequest } from "./models"
import { TmaSender } from "../tma-sender"
import { EventStorage } from "../event-storage"
import { ArticleListFilteredEvent } from "../article-list-filtered/event-handle"
import { ArticleListFilteredRequest } from "../article-list-filtered/models"
import { TmaEvent, TrackingModel } from "../../models/models"

export type AddToBasketEventPayload = {
    article: Article
    vehicleId?: string
    foundBySearchTerm?: string
    quantityModifiers?: ErpPartsModifiers

    vehicleEngineCode?: string
}

export class AddToBasketEvent extends TmaSender<CatalogPartAddedRequest> implements TmaEvent {
    key = "ADD_TO_BASKET"

    autocompleteResults: Array<string> = []

    constructor() {
        // extending tma sender needs to set
        super(`/basket/catalogPartAdded`)
        this.requestBody = {}
    }

    handle = (trackingData: TrackingModel<AddToBasketEventPayload>): boolean => {
        if (trackingData.tmaEvent && trackingData.tmaEvent == this.key && trackingData.payload) {
            // check needed props
            // check if previous request is fullfilled
            const { article, foundBySearchTerm, quantityModifiers, vehicleEngineCode, vehicleId } = trackingData.payload

            this.requestBody.origin = TmaEModule.SHOPPING_CART

            if (article) {
                this.requestBody.catalogPart = {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupIds: [article.productGroup.id],
                    wholesalerArticleNumber: article.traderArticleNo,
                    availabilityState: {
                        id:
                            (quantityModifiers?.availability &&
                                quantityModifiers.availability !== AvailabilityStatus.Unknown &&
                                AvailabilityStatus[quantityModifiers.availability]) ||
                            "",
                        description: quantityModifiers?.availability?.toString() || "",
                    },
                }
            }

            if (vehicleId) {
                this.requestBody.vehicleId = vehicleId
            }

            if (vehicleEngineCode) {
                this.requestBody.vehicleIdentification = {
                    key: "", // TODO ADD
                    typeId: 0, // TODO ADD
                }
            }

            if (foundBySearchTerm) {
                this.requestBody.foundByQuery = foundBySearchTerm
            }

            this.extendStepNumber()
        }

        return false
    }

    extendStepNumber = () => {
        const articleFilteredEventStore = new EventStorage<ArticleListFilteredRequest>(ArticleListFilteredEvent.serviceEndpoint)
        const requestBody = articleFilteredEventStore.get()
        if (requestBody && requestBody.searchStep) {
            this.requestBody.searchStepContext = {
                pageNumber: (requestBody.articleList && requestBody.articleList.paging.pageIndex) || 0,
                searchStep: {
                    processId: requestBody.searchStep.processId,
                    number: requestBody.searchStep.number,
                },
            }
        }
    }
}

import { useMemo } from "react"
import { UserModuleType } from "@tm/models"
import { useUser } from "../../user"

export function useWorkTaskOrderModuleParameters() {
    const { userContext } = useUser()

    return useMemo(() => {
        const workTaskOrderModule = userContext.modules?.find((x) => x.type === UserModuleType.WorkTaskOrder)
        return {
            hasWorkTaskOrder: !!workTaskOrderModule,
            useSendCostEstimation: workTaskOrderModule?.parameters?.find((x) => x.key === "UseSendCostEstimation")?.value === "1",
            useSendOrder: workTaskOrderModule?.parameters?.find((x) => x.key === "UseSendOrder")?.value === "1",
        }
    }, [userContext])
}

import { useCallback } from "react"
import { Box, Stack } from "@mui/material"
import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { ArticleNumberCopyButton } from "./ArticleNumberCopyButton"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Divider } from "../../generics/divider"
import { ArticleNumber } from "../articleNumber"

type ArticleNumbersProps = {
    uniteNumbers?: boolean
}

export function ArticleNumbers({ uniteNumbers }: ArticleNumbersProps) {
    const { traderArticleNo, hideDealerPartNumber, copyButtonSupplier, supplierArticleNo, copyButtonTrader, oeArticleNo } =
        useDefaultArticleItemState((x) => x.displayArticle.numbers)

    const handleClickDetailsIntern = useDefaultArticleItemState((x) => x.handleClickDetails)
    const handleCopyArticleNumber = useDefaultArticleItemState((x) => x.handleCopyArticleNumber)

    const positionChangeEArtNrHArtNr = useDefaultArticleItemState((x) => x.positionChangeEArtNrHArtNr)

    const handleClickDetails = useCallback(() => {
        handleClickDetailsIntern()
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaTraderArticleNumber)
    }, [handleClickDetailsIntern])

    const handleCopyToClipboard = useCallback(
        (number: string) => {
            handleCopyArticleNumber?.(number)
        },
        [handleCopyArticleNumber]
    )

    return (
        <Stack
            direction={positionChangeEArtNrHArtNr ? "row-reverse" : "row"}
            justifyContent="start"
            spacing={1}
            alignItems="center"
            divider={!uniteNumbers && <Divider orientation="vertical" flexItem />}
        >
            {oeArticleNo && <ArticleNumber articleNumber={oeArticleNo} onClick={handleClickDetails} articleNumberType="oe" />}
            {!oeArticleNo && traderArticleNo && !hideDealerPartNumber && (
                <Box sx={{ minWidth: !uniteNumbers ? "130px" : undefined }}>
                    <Stack direction="row" spacing={1}>
                        <ArticleNumber
                            size="medium"
                            articleNumber={traderArticleNo}
                            omitDefaultStyles={positionChangeEArtNrHArtNr}
                            onClick={handleClickDetails}
                            articleNumberType="wholesaler"
                        />
                        {copyButtonTrader && <ArticleNumberCopyButton copyToClipboard={() => handleCopyToClipboard(traderArticleNo)} />}
                    </Stack>
                </Box>
            )}
            {supplierArticleNo && (
                <Stack direction="row" spacing={1}>
                    <ArticleNumber
                        articleNumber={supplierArticleNo}
                        onClick={handleClickDetails}
                        articleNumberType="supplier"
                        omitDefaultStyles={positionChangeEArtNrHArtNr}
                    />
                    {copyButtonSupplier && <ArticleNumberCopyButton copyToClipboard={() => handleCopyToClipboard(supplierArticleNo)} />}
                </Stack>
            )}
        </Stack>
    )
}

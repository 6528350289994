export class ControlsConfig {
    private constructor() {}

    static config: {
        [key: string]: {
            [key: string]: string
        }
    }

    static get<T>(control: string): Partial<T> {
        return (ControlsConfig.config[control.toLowerCase()] as any as Partial<T>) || {}
    }
}

ControlsConfig.config = {}

/** *
 * @param appConfig {AppConfig} required, parsed AppConfig, if not parsed it will try to parse
 */
export function appendControlsConfig(appConfig: any) {
    let config = appConfig

    if (typeof config === "string") {
        try {
            config = JSON.parse(config)
        } catch (e) {
            console.warn("controls, parsing config failed")
        }
    }

    if (config.controls) {
        ControlsConfig.config = config.controls
        ;(window as any).__NEXT_CONTROLS__ = config.controls
    }
}

import { styled } from "@mui/material"
import { FC, HTMLAttributes } from "react"

export const ActionsContainer: FC<HTMLAttributes<HTMLDivElement>> = styled("div")(({ theme }) => ({
    display: "flex",
    height: 30,
    position: "absolute",
    width: "276px",
    zIndex: 1,
    background: `rgba(255, 255, 255, ${theme.opacity?.disabled})`,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "6px",
    marginLeft: theme.margin?.m,
    marginTop: ".5em",
    alignCOntent: "center",
    "&:hover, &:active": {
        background: `rgba(255, 255, 255, ${theme.opacity?.primary})`,
    },
}))

import { ChangeEventHandler, FocusEventHandler, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
    alpha,
    filledInputClasses,
    FilledTextFieldProps,
    formHelperTextClasses,
    inputLabelClasses,
    StandardTextFieldProps,
    styled,
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
    colors,
    outlinedInputClasses,
    FilledInputProps,
    Stack,
    CSSObject,
    stackClasses,
    inputBaseClasses,
    tooltipClasses,
} from "@mui/material"
import { useLocalization } from "@tm/localization"
import { Decimal } from "decimal.js-light"
import { IconButton } from "../button"
import { Icon } from "../Icons"
import { Typography } from "../typographie"
import { ArrowUp, ArrowDown } from "./Arrows"
import { Tooltip } from "../tooltip"

export type TextFieldProps = (MuiTextFieldProps | StandardTextFieldProps | FilledTextFieldProps) & {
    variant?: "filled" | "standard" | "outlined" | undefined
    inputCounter?: boolean
    clearButton?: boolean
    hideNumericCounter?: boolean
    errorHandling?: ErrorHandling[]
    validateNow?: boolean
    onValidationChange?(hasValidationError: boolean): void
}

export type ErrorHandling = {
    pattern?: string
    errorMessage: string
}

const DEFAULT_INPUTPROPS = {
    max: 9999,
    min: 1,
    step: 1,
}

const StyledTextField = styled(MuiTextField, {
    shouldForwardProp: (prop) =>
        !["onValidationChange", "errorHandling", "inputCounter", "clearButton", "endAdornment", "disableUnderline"].includes(prop as string),
})<TextFieldProps>(({ theme, size, hideNumericCounter, hiddenLabel }) => {
    const styles: CSSObject = {
        boxSizing: "border-box",
        marginTop: "14px",

        ...(size === "large" && {
            marginTop: "0",
        }),

        // Size Extralarge
        ...(size === "extralarge" && {
            marginTop: "0",
        }),

        // Hide Counter on type="number"
        ...(hideNumericCounter && {
            "& input[type=number]": {
                MozAppearance: "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
            },
        }),

        // Display error Helpertext
        "&:hover": {
            [`.${formHelperTextClasses.root}`]: {
                [`&.${formHelperTextClasses.error}`]: {
                    display: "block",
                },
            },

            [` .${stackClasses.root}`]: {
                visibility: "visible",
            },
        },

        // Counter
        "& .inputCounter": {
            position: "absolute",
            top: "-17px",
            fontSize: "10px",
            right: "6px",

            // Size small
            ...(size === "small" && {
                top: "-19px",
            }),

            // Size Large
            ...(size === "large" && {
                top: "-5px",
            }),

            // Size Extralarge
            ...(size === "extralarge" && {
                top: "-2px",
            }),
        },

        // Close Icon, EndAdornment
        "& .inputButtons": {
            right: "5px",
            padding: "4px",

            // Size Large
            ...(size === "large" && {
                top: "8px",
            }),

            // Size Extralarge
            ...(size === "extralarge" && {
                top: "14px",
            }),
        },

        // Label
        [`.${inputLabelClasses.filled}`]: {
            fontSize: "10px",
            transform: "translate(4px,-14px) scale(1)",
            textTransform: "uppercase",
            opacity: 0.54,

            "&.Mui-focused, &.MuiFormLabel-filled": {
                transform: "translate(4px,-14px) scale(1)",
            },

            // Size small
            ...(size === "small" && {
                fontSize: "9px",
                transform: "translate(2px,-12px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(2px,-12px) scale(1)",
                },
            }),

            // Size Large
            ...(size === "large" && {
                fontSize: "10px",
                transform: "translate(9px,2px) scale(0.87)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(9px,2px) scale(0.87)",
                },
            }),

            // Size Extralarge
            ...(size === "extralarge" && {
                fontSize: "10px",
                transform: "translate(12px,3px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                    transform: "translate(12px,3px) scale(1)",
                },
            }),
        },

        // Input
        [`.${filledInputClasses.input}`]: {
            background: "none",

            // Size Medium
            padding: "8px 9px",
            fontSize: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
            lineHeight: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
            height: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",

            // Size Small
            ...(size === "small" && {
                padding: "6px",
                fontSize: theme.overwrites?.components?.textfield?.fontSize?.small || "12px",
                lineHeight: theme.overwrites?.components?.textfield?.fontSize?.small || "12px",
                height: theme.overwrites?.components?.textfield?.fontSize?.small || "12px",
            }),

            // Size Large
            ...(size === "large" && {
                padding: hiddenLabel ? "11px 9px" : "16px 9px 6px",
                fontSize: theme.overwrites?.components?.textfield?.fontSize?.large || "14px",
                lineHeight: theme.overwrites?.components?.textfield?.fontSize?.large || "14px",
                height: theme.overwrites?.components?.textfield?.fontSize?.large || "14px",
            }),

            // Size Extralarge
            ...(size === "extralarge" && {
                padding: hiddenLabel ? "15px 11px" : "21px 11px 9px",
                fontSize: theme.overwrites?.components?.textfield?.fontSize?.extralarge || "16px",
                lineHeight: theme.overwrites?.components?.textfield?.fontSize?.extralarge || "16px",
                height: theme.overwrites?.components?.textfield?.fontSize?.extralarge || "16px",
            }),

            "&.MuiSelect-select": {
                height: "17px",
            },

            "&.MuiInputBase-inputMultiline": {
                padding: 0,
            },
        },

        // Variant Outline
        [`.${outlinedInputClasses.input}`]: {
            padding: "6px 11px",
        },

        // Filled Root
        [`.${filledInputClasses.root}`]: {
            border: `1px solid ${theme.overwrites?.components?.textfield?.border?.color || "#e2e2e1"}`,
            borderRadius: theme.radius?.default || "3px",
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),

            "&:hover": {
                backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
            },
            [`&.${filledInputClasses.focused}`]: {
                backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
                borderColor: theme.palette.primary.main,
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            },
            [`&.${filledInputClasses.error}`]: {
                borderColor: theme.palette.error.main,
                borderBottom: `4px solid ${theme.palette.error.main}`,
                marginBottom: "0px",
                [`&.${filledInputClasses.focused}`]: {
                    boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 2px`,
                },
            },
        },

        ".MuiInputBase-multiline": {
            paddingTop: "14px",
            [`&.MuiInputBase-sizeSmall`]: {
                paddingTop: "6px",
            },
            ...(size === "large" && {
                paddingTop: "28px",
            }),
        },

        // Helper Text / Error Text
        [`.${formHelperTextClasses.root}`]: {
            [`&.${formHelperTextClasses.contained}`]: {
                display: "none",
                [`&.${formHelperTextClasses.error}`]: {
                    [`&.${formHelperTextClasses.focused}`]: {
                        display: "block",
                    },
                    position: "absolute",
                    bottom: 0,
                    marginLeft: "0",
                    marginTop: "0",
                    borderRadius: "0 0 4px 4px",
                    color: theme.palette.error.contrastText,
                    backgroundColor: theme.palette.error.main,
                    width: "100%",
                    fontSize: "11px",
                    paddingLeft: "5px",
                    transform: "translateY(15px)",
                },
            },
        },

        "input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            appearance: "none",
            MozAppearance: "textfield",
            margin: 0,
        },
        "input[type=number]": {
            MozAppearance: "textfield",
        },
        ".MuiInputLabel-asterisk": {
            color: theme.palette.error.main,
        },
    }
    return styles
})

const valueToNumber = (value: string): number | null => {
    return /^-?[\d\.]+$/.test(value) ? parseFloat(value) : null
}

const ArrowStack = styled(Stack)(() => ({
    visibility: "hidden",
    [`.${inputBaseClasses.root}:hover .${inputBaseClasses.input}~&, .${inputBaseClasses.input}:active~&, .${inputBaseClasses.input}:focus~&`]: {
        visibility: "visible",
    },
}))

const StyledTooltip = styled(Tooltip)({
    [`.${tooltipClasses.tooltipPlacementBottom}`]: {
        marginTop: "8px !important",
        textAlign: "center",
    },
})

/**
 * The `TextField` is a NOT the real Material TextBox.
 * It overrides a lot of styles to looks like our well known TextField
 *
 * return <TextField id="time" type="time" inputProps={inputProps} />;
 * ```
 *
 * API:
 * - [TextField API](https://mui.com/api/text-field/)
 * - inherits [FormControl API](https://mui.com/api/form-control/)
 */
export const TextField = forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
    const { translateText } = useLocalization()
    const inputRefLocal = useRef<HTMLInputElement>(null)
    const inputRef = (typeof props.inputRef !== "function" ? props.inputRef : undefined) ?? inputRefLocal
    const { onBlur, onChange, error, ...restProps } = props
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [validateNow, setValidateNow] = useState(false)

    useEffect(() => {
        setValidateNow(!!props.validateNow)
    }, [props.validateNow])

    useEffect(() => {
        setValidateNow(true)
    }, [props.errorHandling, props.value, props.required])

    const errorHandling = useMemo<ErrorHandling[]>(() => {
        if (props.required && !props.errorHandling?.length) {
            return [{ pattern: "\\w+", errorMessage: translateText(13831) }]
        }
        return props.errorHandling ?? []
    }, [props.errorHandling, props.required])

    const validateMinMaxValue = useCallback(
        (value: number): string => {
            const { max = DEFAULT_INPUTPROPS.max, min = DEFAULT_INPUTPROPS.min } = props.inputProps || DEFAULT_INPUTPROPS
            const preValidatedValue = value > max ? max : value

            return (preValidatedValue < min ? min : preValidatedValue).toString()
        },
        [props.inputProps]
    )
    function validateNumber(value: number) {
        const { max = DEFAULT_INPUTPROPS.max, min = DEFAULT_INPUTPROPS.min } = props.inputProps || DEFAULT_INPUTPROPS
        return value <= max && value >= min
    }

    const setRawInputValue = useCallback((val: string) => {
        if (inputRef.current) {
            // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js/46012210#46012210
            // React doesn't recognize the setting of  ref.value = ""
            const inputPrototype = props.multiline ? window.HTMLTextAreaElement.prototype : window.HTMLInputElement.prototype

            const currentInputRef = inputRef.current.node ?? inputRef.current

            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(inputPrototype, "value")?.set
            nativeInputValueSetter?.call(currentInputRef, val)
            currentInputRef.dispatchEvent(new Event("input", { bubbles: true, cancelable: true }))
            currentInputRef.focus({})
        }
    }, [])

    const resetValue = () => {
        setRawInputValue("")
    }

    const increaseValue = () => {
        if (!inputRef.current || props.type !== "number") {
            return
        }

        const possibleValue = valueToNumber(inputRef.current.value)
        const step = inputRef.current?.step || DEFAULT_INPUTPROPS.step
        const value = possibleValue ? new Decimal(possibleValue).add(step).toNumber() : step

        setRawInputValue(validateMinMaxValue(value))
    }

    const decreaseValue = () => {
        if (!inputRef.current || props.type !== "number") {
            return
        }

        const possibleValue = valueToNumber(inputRef.current.value)
        const step = inputRef.current?.step || DEFAULT_INPUTPROPS.step
        const minVal = inputRef.current?.min < step ? inputRef.current?.min : step
        const value = possibleValue ? new Decimal(possibleValue).sub(step).toNumber() : minVal

        setRawInputValue(validateMinMaxValue(value))
    }

    function validate() {
        setValidateNow(false)
        if (errorHandling.length) {
            if (props.type === "number") {
                const value = Number(props.value ?? -1)
                if (!validateNumber(value)) {
                    setErrorMessage(translateText(13831))
                    return false
                }
            }

            const value = String(props.value ?? "")
            const errorWithoutPattern = errorHandling.find((handling) => !handling.pattern && !!handling.errorMessage)

            if (errorWithoutPattern) {
                setErrorMessage(errorWithoutPattern.errorMessage)
                return false
            }

            const errorWithPattern = errorHandling.find((val) => !!val.pattern && !value.match(val.pattern))

            if (errorWithPattern) {
                setErrorMessage(errorWithPattern.errorMessage)
                return false
            }
        }

        setErrorMessage(undefined)
        return true
    }

    useEffect(() => {
        if (validateNow) {
            validate()
        }
    }, [validateNow])

    const hasValidationError = error || !!errorMessage
    const requireEndAdornment =
        props.type === "number" || props.InputProps?.endAdornment || props.inputCounter || props.clearButton || hasValidationError

    useEffect(() => {
        props.onValidationChange?.(hasValidationError)
    }, [hasValidationError, props.onValidationChange])

    const overwrittenInputProps: Partial<FilledInputProps> = {
        disableUnderline: true,
        ...props.InputProps,
        endAdornment: requireEndAdornment ? (
            <Stack direction="row" gap="0.5rem">
                {props.inputCounter && (
                    <Typography className="inputCounter" variant="label">
                        {(props.value as string)?.length ?? 0}
                        {!!props.inputProps?.maxLength && `/${props.inputProps.maxLength}`}
                    </Typography>
                )}
                {(props.InputProps?.endAdornment || props.clearButton) && (
                    <Stack direction="row" gap={0.5} alignItems="center" className="inputButtons">
                        {props.InputProps?.endAdornment}
                        {props.clearButton && (
                            <IconButton
                                size="small"
                                sx={{ padding: props.inputCounter ? "0 2px 2px 0" : undefined, visibility: props.value ? "visible" : "hidden" }}
                                disableTouchRipple
                                onClick={resetValue}
                            >
                                <Icon name="close" />
                            </IconButton>
                        )}
                    </Stack>
                )}
                {props.type === "number" && (
                    <ArrowStack pr={0.5}>
                        <ArrowUp className="amount_up" onClick={increaseValue} disableRipple tabIndex={-1}>
                            <Icon width="15px" height="15px" name="up" />
                        </ArrowUp>
                        <ArrowDown className="amount_down" onClick={decreaseValue} disableRipple tabIndex={-1}>
                            <Icon width="15px" height="15px" name="down" />
                        </ArrowDown>
                    </ArrowStack>
                )}
                {hasValidationError && (
                    <StyledTooltip title={errorMessage}>
                        <Icon name="alert_W_warning" color="error" />
                    </StyledTooltip>
                )}
            </Stack>
        ) : undefined,
    }

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            const currentTargetValue = e.currentTarget?.value ?? e.target.value
            const value = valueToNumber(currentTargetValue)
            const validatedValue = props.type === "number" && value ? validateMinMaxValue(value) : currentTargetValue

            setRawInputValue(validatedValue)
            onChange?.({
                ...e,
                target: {
                    ...e.target,
                    value: validatedValue,
                },
            })
        },
        [onChange, validateMinMaxValue, setRawInputValue, props.type]
    )

    const onBlurHandler: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
        (e) => {
            setValidateNow(true)
            if (!(e.relatedTarget && e.currentTarget?.parentElement?.contains(e.relatedTarget)) && onBlur) {
                onBlur(e)
            }
        },
        [onBlur]
    )

    return (
        <StyledTextField
            ref={ref}
            inputRef={inputRef}
            variant={props.variant || "filled"}
            autoComplete="off"
            size={props.size || "medium"}
            {...restProps}
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            InputProps={overwrittenInputProps}
            value={props.value ?? ""}
            error={hasValidationError}
        />
    )
})

import { filledInputClasses, lighten, styled, Select as MuiSelect, SelectProps as MuiSelectProps, selectClasses } from "@mui/material"
import { PropsWithChildren, forwardRef } from "react"
import { Icon } from "../Icons"
import { Loader } from "../loader/Loader"

type Props = PropsWithChildren<
    MuiSelectProps & {
        isloading?: boolean
        size?: "small" | "medium" | "large" | "extralarge"
        iconOnTheRight?: boolean
    }
>

const StyledSelect = styled(MuiSelect)<Props>(({ color, theme, size, variant, iconOnTheRight }) => {
    const { getContrastText } = theme.palette
    const paletteColorMain = color && theme.palette[color]?.main
    const paletteColorLight = color && theme.palette[color]?.light

    const { button } = theme.overwrites?.components ?? {}
    const primaryButton = button?.primary

    const borderColor = primaryButton?.borderColor
    const fontSize = primaryButton?.fontSize ?? "12px"
    const defaultBackgroundColor = primaryButton?.backgroundColor ?? theme.palette.grey[300]
    const backgroundColorHover = primaryButton?.hover?.backgroundColor ?? lighten(theme.palette.primary.main, 0.5)
    const focusedColor = primaryButton?.focus ?? theme.palette.primary.light

    const fontWeight = primaryButton?.fontWeight

    const typo = {
        fontSize,
        fontWeight,
    }

    // color has a higher priority than overwrite?
    const defaultColor = (paletteColorMain && {
        backgroundColor: paletteColorMain,
        color: getContrastText(paletteColorMain),
    }) || {
        backgroundColor: defaultBackgroundColor,
        color: getContrastText(defaultBackgroundColor),
    }

    const iconColor = {
        color: getContrastText(paletteColorMain || defaultBackgroundColor),
        ...(variant === "outlined" && {
            color: borderColor || "black",
        }),
    }

    const iconColorFocus = {
        color: getContrastText(focusedColor),
        ...(variant === "outlined" && {
            color: borderColor || "black",
        }),
    }

    const iconColorHovered = (paletteColorMain && {
        color: getContrastText(paletteColorMain),
    }) || {
        color: getContrastText(backgroundColorHover),
        ...(variant === "outlined" && {
            color: borderColor || "black",
        }),
    }

    const colorFocused = (paletteColorLight && {
        backgroundColor: paletteColorLight,
        color: getContrastText(paletteColorLight),
    }) || { backgroundColor: focusedColor, color: getContrastText(focusedColor) }

    const backgroundColorHovered = (paletteColorLight &&
        paletteColorMain && {
            backgroundColor: paletteColorLight,
            color: getContrastText(paletteColorMain),
        }) || {
        backgroundColor: backgroundColorHover,
        color: getContrastText(backgroundColorHover),
    }

    const filledInputTypo = {
        ...(size === "medium" && {
            fontSize: "14px",
            lineHeight: "20px",
            padding: "8px 13px 8px 28px !important",
            ...(iconOnTheRight && {
                padding: "8px 28px 8px 13px !important",
            }),
        }),
        ...(size === "large" && {
            fontSize: "14px",
            lineHeight: "20px ",
            padding: "11px 14px 11px 32px !important",
            ...(iconOnTheRight && {
                padding: "11px 32px 11px 14px !important",
            }),
        }),
        ...(size === "extralarge" && {
            fontSize: "16px",
            lineHeight: "16px !important",
            padding: "15px 18px 15px 38px !important",
            ...(iconOnTheRight && {
                padding: "15px 38px 15px 18px !important",
            }),
        }),
    }

    const selectedOutlined = {
        ...(size === "medium" && {
            fontSize: "14px",
            lineHeight: "20px ",
            padding: "8px 13px 8px 28px !important",
            ...(iconOnTheRight && {
                padding: "8px 28px 8px 13px !important",
            }),
        }),
        ...(size === "large" && {
            fontSize: "14px",
            lineHeight: "20px ",
            padding: "11px 14px 11px 32px !important",
            ...(iconOnTheRight && {
                padding: "11px 32px 11px 14px !important",
            }),
        }),
        ...(size === "extralarge" && {
            fontSize: "16px",
            lineHeight: "16px !important",
            padding: "15px 18px 15px 38px !important",
            ...(iconOnTheRight && {
                padding: "15px 38px 15px 18px !important",
            }),
        }),
    }

    const selectIconSizes = {
        ...(size === "small" && {
            minHeight: "unset",
            minWidth: "unset",
            height: "1em",
            width: "1em",
            left: "5px",
            ...(iconOnTheRight && {
                left: "unset",
                right: "5px",
            }),
            top: "calc(50% - 5px) !important",
        }),

        ...(size === "medium" && {
            minHeight: "unset",
            minWidth: "unset",
            width: "1em",
        }),
        ...(size === "large" && {}),
        ...(size === "extralarge" && {
            left: "10px",
            ...(iconOnTheRight && {
                left: "unset",
                right: "10px",
            }),
        }),
    }

    const transition = theme.transitions.create(["color", "background-color"])

    return {
        opacity: 1,
        verticalAlign: "top",
        border: borderColor ? `1px solid ${borderColor}` : "none",

        [`.${filledInputClasses.input}`]: {
            width: "100%",
            minHeight: "unset !important",
            fontSize,
            fontWeight,
            border: "1px solid transparent",
            padding: "6px 10px 6px 22px !important",
            ...(iconOnTheRight && {
                padding: "6px 22px 6px 10px !important",
            }),
            lineHeight: "12px !important",
            ...filledInputTypo,
            borderRadius: theme.radius?.default || "3px",
            justifyContent: "center",
            ...defaultColor,
            transition,
            ":focus": {
                ...defaultColor,
            },
        },
        ".MuiSelect-icon": {
            height: "1.2em",
            width: "1.2em",
            left: "7px",
            ...(iconOnTheRight && {
                left: "unset",
                right: "7px",
            }),
            top: "calc(50% - 8px) !important",
            ...selectIconSizes,
            opacity: ".87",
            ...iconColor,
            transition,
        },
        ".MuiSelect-outlined": {
            lineHeight: "12px !important",
            padding: "6px 10px 6px 22px !important",
            ...(iconOnTheRight && {
                padding: "6px 22px 6px 10px !important",
            }),
            minHeight: "unset !important",
            border: "1px solid transparent",
            borderRadius: theme.radius?.default || "3px",
            width: "100%",
            justifyContent: "center",
            transition,
            ...typo,
            ...selectedOutlined,
            ...(paletteColorMain && {
                backgroundColor: paletteColorMain,
                color: getContrastText(paletteColorMain),
            }),
        },
        [`.Mui-focused`]: {
            [`.${filledInputClasses.input}`]: {
                transition,
                borderRadius: theme.radius?.default || "3px",
                colorFocused,
            },
        },
        [`.${selectClasses.iconOpen}`]: {
            transition,
            ...iconColorFocus,
        },
        ":hover": {
            [`.${filledInputClasses.input}`]: {
                transition,
                ...backgroundColorHovered,
                "&.Mui-disabled": {
                    backgroundColor: defaultBackgroundColor,
                },
            },
            ".MuiSelect-icon": {
                transition,
                ...iconColorHovered,
            },
        },
        "&.Mui-disabled": {
            cursor: "default",
            opacity: 0.38,
            [`.${filledInputClasses.input}`]: {
                color: theme.palette.getContrastText(theme.palette.grey[300]),
                WebkitTextFillColor: theme.palette.getContrastText(theme.palette.grey[300]),
                ...(color && {
                    color: theme.palette.getContrastText(theme.palette[color]?.main),
                    WebkitTextFillColor: theme.palette.getContrastText(theme.palette[color]?.main),
                }),
            },
        },
    }
})

export const Select = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { isloading, children, ...rest } = props

    return (
        <StyledSelect
            size={props.size || "medium"}
            ref={ref}
            variant="filled"
            MenuProps={{
                PaperProps: {
                    sx: (theme) => ({
                        backgroundColor: theme.overwrites?.components?.dropdown?.menu?.backgroundColor || "#fff",
                        border: 1,
                        borderColor: theme.overwrites?.components?.dropdown?.menu?.borderColor || theme.palette.primary.main,
                    }),
                },
            }}
            IconComponent={isloading ? () => <Loader p="5px" pr="10px" size="extrasmall" /> : (iconProps) => <Icon {...iconProps} name="down" />}
            {...rest}
        >
            {children}
        </StyledSelect>
    )
})

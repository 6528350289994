import { useState } from "react"
import { Stack, TextFieldProps } from "@mui/material"
import { isValidDate } from "@tm/utils"
import { sub, add, isBefore, isAfter } from "date-fns"
import { DatePicker } from "../date/datePicker"
import { LocalizationType } from "../helper/locale"

export type DateRangeProps = TextFieldProps & {
    initialDate: { startDate?: Date; endDate?: Date }
    rangeInDays: number
    startLabel?: string
    endLabel?: string
    onRangeChange: (startDate: Date, endDate: Date) => void
    localisation: LocalizationType
}

export function DateRange(props: DateRangeProps) {
    const { initialDate, rangeInDays } = props

    const [startDate, setStartDate] = useState<Date>(initialDate.startDate || sub(new Date(), { days: rangeInDays }))
    const [endDate, setEndDate] = useState<Date>(initialDate.endDate || new Date())

    const handleStartDateChange = (date: Date | null) => {
        if (date === null) {
            return
        }

        setStartDate(date)
        const newPeriod = add(date, { days: rangeInDays })

        if (isBefore(newPeriod, endDate)) {
            setEndDate(newPeriod)
            props.onRangeChange(date, newPeriod)
        } else {
            let newEndDate = endDate

            if (isAfter(date, endDate)) {
                newEndDate = add(date, { days: rangeInDays })
            }

            setEndDate(newEndDate)
            props.onRangeChange(date, newEndDate)
        }
    }

    const handleEndDateChange = (date: Date | null) => {
        if (date === null) {
            return
        }

        setEndDate(date)
        const newPeriod = sub(date, { days: rangeInDays })

        if (isAfter(newPeriod, startDate)) {
            setStartDate(newPeriod)
            props.onRangeChange(newPeriod, date)
        } else {
            props.onRangeChange(startDate, date)
        }
    }

    const textFieldProps: TextFieldProps = {
        variant: "filled",
        sx: { width: 160 },
    }

    return (
        <Stack direction="row" gap={0.5}>
            <DatePicker
                size="large"
                textfieldProps={{ ...textFieldProps }}
                label={props.startLabel}
                value={startDate}
                onChange={handleStartDateChange}
                localisation={props.localisation}
            />
            <DatePicker
                size="large"
                textfieldProps={{ ...textFieldProps }}
                label={props.endLabel}
                value={endDate}
                onChange={handleEndDateChange}
                minDate={isValidDate(startDate) ? startDate : undefined}
                localisation={props.localisation}
            />
        </Stack>
    )
}

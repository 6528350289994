import { FC } from "react"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

const isDebug = localStorage.getItem("ENV") == "development"

/**
 * @deprecated This function uses typestyle which we don't want to use anymore. Please use `styled` from `@tm/components` instead.
 */
export function useStyle<Names extends string = any>(classes: Record<Names, NestedCSSProperties>) {
    return function (this: Record<Names, NestedCSSProperties>, comp: FC<any>): Record<Names, string> {
        const classNames = Object.getOwnPropertyNames(this)
        const result: any = {}
        for (let _i = 0, classNames_1 = classNames; _i < classNames_1.length; _i++) {
            const className = classNames_1[_i]
            const classDef = classes[className as Names]
            if (classDef) {
                if (isDebug) {
                    classDef.$debugName = `${(comp?.name ?? (comp as any)?.type?.name)?.toLowerCase()}--${className}`
                }
                result[className] = style(classDef)
            }
        }
        return result as Record<Names, string>
    }.bind(classes)
}

import { Component, PropsWithChildren } from "react"
import { initDateHelper, formatDate, setLocaleToLocalStorage, loadModule, parseQueryString } from "@tm/utils"
import { Subscription, BehaviorSubject } from "rxjs"
import { registerLocale } from "react-datepicker"
import { LocalizationConfig, ILocalizationContext, LanguagesConfig } from "./models"
import formatCurrency from "./libs/formatCurrency"
import formatNumber from "./libs/formatNumber"
import { translate, translateText, isTranslationId } from "./libs/translate"
import { LocalizationContext } from "./LocalizationContext"
import { getWeekDays, getMonths } from "./libs/helper"

export type GetConfigFunction = {
    (locale: string): Promise<LocalizationConfig>
}

export type GetCatalogTextsFunction = {
    (languageId: string): Promise<Partial<Pick<LocalizationConfig, "translation"> | undefined>>
}

// eslint-disable-next-line import/no-mutable-exports
export let showTextIds = parseQueryString(window.location.search?.toLowerCase())?.showtextids === "1"

export function createLocalizationProvider(
    startConfig: LocalizationConfig,
    getConfig: GetConfigFunction,
    availableLanguages: LanguagesConfig,
    dateLocalePath: string,
    getCatalogTexts?: GetCatalogTextsFunction
) {
    const _configSubject = new BehaviorSubject<LocalizationConfig>(startConfig)
    setLocaleToLocalStorage(startConfig.language, startConfig.id)

    function loadAndRegisterDateLocale(language: string) {
        loadModule(`${dateLocalePath}/${language}.min.js`).then((localeData) => {
            registerLocale(language, localeData)
        })
    }

    loadAndRegisterDateLocale(startConfig.language)

    return class LocalizationProvider extends Component<PropsWithChildren> {
        _configSubscription: Subscription | undefined = undefined

        constructor(props: any) {
            super(props)
            const config = _configSubject.getValue()
            this.setCatalogTexts(config.id)
            initDateHelper(getWeekDays(config), getMonths(config))
            this.changeDomLanguage()
        }

        componentDidMount() {
            this._configSubscription = _configSubject.subscribe(() => {
                this.forceUpdate()
            })

            window.__NEXT_DEVTOOLS_EXTENSION__?.showTextIdsSubject?.subscribe({
                next: (val: boolean) => {
                    showTextIds = val
                    this.forceUpdate()
                },
            })
        }

        componentWillUnmount() {
            if (this._configSubscription && !this._configSubscription.closed) {
                this._configSubscription.unsubscribe()
            }

            window.__NEXT_DEVTOOLS_EXTENSION__?.showTextIdsSubject?.unsubscribe()
        }

        getLocalizationContext(): ILocalizationContext {
            const config = _configSubject.getValue()
            const context = {
                language: config.language,
                languageId: config.id,
                currency: formatCurrency.bind(undefined),
                date: formatDate,
                number: formatNumber.bind(undefined, config),
                translate: translate.bind(undefined, config),
                translateText: translateText.bind(undefined, config),
                changeConfig: this.changeConfig.bind(this),
                changeLocale: this.changeLocale.bind(this),
                isTranslationId: isTranslationId.bind(undefined),
                availableLanguages,
            }
            window.__LOCALIZATION__ = context
            return context
        }

        changeConfig(config: LocalizationConfig) {
            _configSubject.next(config)

            this.setCatalogTexts(config.id)

            loadAndRegisterDateLocale(config.language)
            initDateHelper(getWeekDays(config), getMonths(config))
            setLocaleToLocalStorage(config.language, config.id)
            // Inform the NEXT Shell about the changed language
            if (typeof tmJSEvents != "undefined" && typeof tmJSEvents.languageChanged == "function") {
                tmJSEvents.languageChanged(config.id)
            }
        }

        changeLocale(locale: string) {
            getConfig(locale).then((config) => {
                this.changeConfig(config)
                this.changeDomLanguage()
            })
        }

        changeDomLanguage() {
            const { language } = _configSubject.getValue()
            document.documentElement.lang = language
        }

        setCatalogTexts = (languageId: string) => {
            // TODO: Remove the optional chaining question mark, once the catalog texts are ready for production use.
            getCatalogTexts?.(languageId).then((res) => {
                let config = _configSubject.getValue()
                if (config.id === languageId && res && res.translation && Object.keys(res.translation).length) {
                    config = {
                        ...config,
                        translation: {
                            ...config.translation,
                            ...res.translation,
                        },
                    }

                    _configSubject.next(config)
                    initDateHelper(getWeekDays(config), getMonths(config))
                }
            })
        }

        render() {
            const { children } = this.props
            return <LocalizationContext.Provider value={this.getLocalizationContext()}>{children}</LocalizationContext.Provider>
        }
    }
}

import { createElement, Component } from "react"
import { Text } from "../.."

export type ListProps<T extends {}> = {
    className?: string
    view: any
    items: Array<ListItem<T>>
    onItemHover?(item: T | undefined): void
}

export type ListItem<T extends {}> = T | ListGroupItem<T>

export type ListGroupItem<T extends {}> = {
    groupTitle: string
    listItems: Array<T>
}

export let listCounter = 0

export default class List<T extends {}> extends Component<ListProps<T>> {
    constructor(props: ListProps<T>) {
        super(props)
        listCounter++
    }

    renderListItem = (listItem: T, index: number) => {
        return (
            <div
                className="list__item"
                key={`list-item-${index}`}
                onMouseEnter={this.props.onItemHover ? () => this.props.onItemHover?.(listItem) : undefined}
                onMouseLeave={this.props.onItemHover ? () => this.props.onItemHover?.(undefined) : undefined}
            >
                {createElement(this.props.view, listItem)}
            </div>
        )
    }

    renderList = (item: ListItem<T>, index: number) => {
        const listGroupItem = item as ListGroupItem<T>

        if (listGroupItem.groupTitle != undefined && Array.isArray(listGroupItem.listItems)) {
            return (
                <div className="list-group" key={`group-${index}`}>
                    <div className="list-group__description">
                        <Text size="l">{listGroupItem.groupTitle}</Text>
                    </div>
                    {listGroupItem.listItems.map(this.renderList)}
                </div>
            )
        }

        return this.renderListItem(item as T, index)
    }

    render() {
        let className = "list"
        if (this.props.className) {
            className += ` ${this.props.className}`
        }

        return <div className={className}>{(this.props.items ?? []).map(this.renderList)}</div>
    }
}

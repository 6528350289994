import { Component } from "react"
import { bindMethodsToContext } from "../../helper"
import Button from "../button"

export type MoreMenuItem = {
    id?: any
    icon?: string
    text?: string
    link?: string
    disabled?: boolean
}

export type ItemProps = {
    item: MoreMenuItem
    onSelect(item: MoreMenuItem): void
}

export class MenuItem extends Component<ItemProps> {
    constructor(props: ItemProps) {
        super(props)
        bindMethodsToContext(this)
    }

    private handleClick() {
        this.props.onSelect(this.props.item)
    }

    render() {
        const { item } = this.props

        return (
            <li className="more-menu__item">
                <Button icon={item.icon} layout={["holo"]} size="xs" disabled={item.disabled} onClick={this.handleClick} linkTo={item.link}>
                    {item.text}
                </Button>
            </li>
        )
    }
}

import { GetErpConfigResponse, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { selector, useRecoilValue, useRecoilValueLoadable } from "recoil"

const erpConfigSelector = selector({
    key: "erp_getErpConfig",
    get: () =>
        Container.getInstance<GetErpConfigResponse>(RegisteredModels.ERP_GetErpConfig)
            .subscribe()
            .load()
            .then((response) => {
                window.erpConfig = response
                return response
            }),
})

export function useErpConfig() {
    return useRecoilValue(erpConfigSelector)
}

export function useErpConfigLoadable() {
    const getErpConfigResponse = useRecoilValueLoadable(erpConfigSelector).valueMaybe()
    const erpSystemConfigs = getErpConfigResponse?.erpSystemConfigs
    const isErpInfoEnabled = erpSystemConfigs?.some((erpSystem) => erpSystem.useForGetErpInformation) ?? false
    return { erpSystemConfigs, isErpInfoEnabled }
}

import { bindSpecialReactMethods, decodeUniqueId, encodeUniqueId, withRouter, RouteComponentProps, uniqueId, setCoopMemberId } from "@tm/utils"
import { RegisteredModels, channel, HistoryVehicle, getCurrentWorkTaskId, WorkTaskStatus } from "@tm/models"
import { Route, matchPath } from "react-router"
import { UnregisterCallback, Location } from "history"
import { Container, IModelContainer } from "@tm/nexus"
import { Children, Component, PropsWithChildren } from "react"
import { WorkTaskInfo, WorkTask, WorkTaskConflict } from "../model"
import { WorkTaskProviderContext } from "../model/context"
import * as Data from "../data"

import { loadVehicleDataAvailabilities } from "./loadVehicleDataAvailabilities"
import { attachToWorkTask } from "./attachToWorkTask"
import { loadWorkTaskInfo, loadWorkTaskInfoModal } from "./loadWorkTaskInfo"
import { createWorkTask } from "./createWorkTask"
import { openWorkTask } from "./openWorkTask"

export type WorkTaskProviderPropsWithRoute = RouteComponentProps<{ workTaskId?: string }> & WorkTaskProviderProps

export type WorkTaskProviderProps = PropsWithChildren<{
    globalPages: string[]
}>

export type WorkTaskProviderState = {
    workTaskId?: string
    workTaskInfo?: WorkTaskInfo
    conflict?: WorkTaskConflict
    loading: boolean
    loadingNewWorkTask: boolean
    vehicleHistory?: HistoryVehicle[]
}

const GUID_REGEX = /[({]?[a-zA-Z0-9]{8}[-]?([a-zA-Z0-9]{4}[-]?){3}[a-zA-Z0-9]{12}[})]?/

export class WorkTaskProvider extends Component<WorkTaskProviderPropsWithRoute, WorkTaskProviderState> {
    unregisterHistoryListener?: UnregisterCallback

    BasketActivityDoneContainer: IModelContainer<void>

    unregisterBasketActivityDone?: Function

    openWorkTask = openWorkTask.bind(this)

    createWorkTask = createWorkTask.bind(this)

    attachToWorkTask = attachToWorkTask.bind(this)

    loadWorkTaskInfo = loadWorkTaskInfo.bind(this)

    loadWorkTaskInfoModal = loadWorkTaskInfoModal.bind(this)

    loadVehicleDataAvailabilities = loadVehicleDataAvailabilities.bind(this)

    constructor(props: WorkTaskProviderPropsWithRoute) {
        super(props)
        const { workTaskId } = props.match.params

        window.__NEXT_WORKTASKID__ = undefined
        if (workTaskId && !this.props.globalPages.includes(workTaskId)) {
            window.__NEXT_WORKTASKID__ = decodeUniqueId(workTaskId)
        }

        bindSpecialReactMethods(this)
        this.state = {
            loading: false,
            loadingNewWorkTask: false,
        }

        this.handleChangeDialogCancel = this.handleChangeDialogCancel.bind(this)
        this.reloadWorkTask = this.reloadWorkTask.bind(this)
        this.historyListener = this.historyListener.bind(this)
        this.triggerWorkTaskConflict = this.triggerWorkTaskConflict.bind(this)
        this.injectVehicleHistory = this.injectVehicleHistory.bind(this)

        this.BasketActivityDoneContainer = Container.getInstance(RegisteredModels.Worktask_BasketActivityDone)

        Data.initialize()
        this.initListeners()
    }

    UNSAFE_componentWillMount() {
        const { match, location } = this.props
        let { workTaskId } = match.params

        // Whenever a global page () has been called, the workTaskId should be null
        if (workTaskId && this.props.globalPages.includes(workTaskId)) {
            workTaskId = undefined
        }

        if (workTaskId) {
            if (GUID_REGEX.test(workTaskId)) {
                const encodedId = encodeUniqueId(workTaskId)
                // this.props.history.push(location.pathname.replace(workTaskId, encodedId) + location.search)
                window.location.href = location.pathname.replace(workTaskId, encodedId) + location.search
                return
            }
            if (workTaskId == "0000000000000000000000") {
                const encodedId = encodeUniqueId(uniqueId())
                window.location.href = location.pathname.replace(workTaskId, encodedId) + location.search
                return
            }
            this.loadWorkTaskInfo(workTaskId)
        }

        if (!workTaskId) {
            this.setState({ workTaskId })
        }
    }

    initListeners() {
        this.unregisterHistoryListener = this.props.history.listen(this.historyListener)

        const subscription = this.BasketActivityDoneContainer.subscribe()
        this.unregisterBasketActivityDone = subscription.addListener("loaded", () => {
            if (this.state.loadingNewWorkTask) {
                const currentWorkTaskId = getCurrentWorkTaskId()

                channel("WORKTASK", currentWorkTaskId).subscribeOnce(
                    "WORKTASK/LOADED",
                    (workTask) => {
                        if (workTask.statusValue == WorkTaskStatus.Undefined) {
                            this.createWorkTask({ workTaskId: currentWorkTaskId, skipRedirect: true })
                        }
                    },
                    true
                )
            } else if (!this.state.workTaskInfo || !this.state.workTaskInfo.no) {
                this.createWorkTask({ workTaskId: this.state.workTaskId, skipRedirect: true })
            }
        })
    }

    componentWillUnmount() {
        this.unregisterHistoryListener && this.unregisterHistoryListener()
        this.unregisterBasketActivityDone && this.unregisterBasketActivityDone()
    }

    historyListener(location: Location) {
        const match = matchPath<{ workTaskId?: string }>(location.pathname, { path: "/:workTaskId?" })
        let workTaskId = match && match.params.workTaskId
        let workTaskIdFromMorpheus = false

        // Whenever a global page () has been called, the workTaskId should be null
        if (workTaskId && this.props.globalPages.includes(workTaskId)) {
            workTaskId = undefined
        }

        if (!workTaskId) {
            const urlParams = new URLSearchParams(location.search)
            if (urlParams.has("(1)")) {
                const morpheusPath = urlParams.get("(1)")
                const morpheusMatch = matchPath<{ workTaskId?: string }>(morpheusPath || "", { path: "/:workTaskId?" })
                workTaskId = morpheusMatch && morpheusMatch.params.workTaskId
                workTaskId = workTaskId?.replace("^", "")
                workTaskIdFromMorpheus = true
            }
        }

        if (workTaskId) {
            if (GUID_REGEX.test(workTaskId)) {
                const encodedId = encodeUniqueId(workTaskId)
                // this.props.history.push(location.pathname.replace(workTaskId, encodedId) + location.search)
                window.location.href = location.pathname.replace(workTaskId, encodedId) + location.search
                return
            }

            if (workTaskId == "0000000000000000000000") {
                const encodedId = encodeUniqueId(uniqueId())
                window.location.href = location.pathname.replace(workTaskId, encodedId) + location.search
                return
            }

            if (decodeUniqueId(workTaskId) !== this.state.workTaskId) {
                window.__NEXT_WORKTASKID__ = decodeUniqueId(workTaskId)
                if (workTaskIdFromMorpheus) {
                    this.loadWorkTaskInfoModal(workTaskId)
                } else {
                    this.loadWorkTaskInfo(workTaskId || undefined, undefined)
                }
            }
        } else if (this.state.workTaskId || this.state.workTaskInfo) {
            window.__NEXT_WORKTASKID__ = undefined
            setCoopMemberId(undefined)
            this.setState({ workTaskId: undefined, workTaskInfo: undefined })
            channel("GLOBAL").publish("WORKTASK/ID_CHANGED", { id: undefined })
        }
    }

    reloadWorkTask() {
        const { workTaskInfo } = this.state

        if (workTaskInfo) {
            return this.loadWorkTaskInfo(workTaskInfo.id)
        }

        return Promise.reject()
    }

    triggerWorkTaskConflict(conflict: Omit<WorkTaskConflict, "onCancel">): void {
        this.setState({ conflict: { ...conflict, onCancel: this.handleChangeDialogCancel } })
    }

    injectVehicleHistory(vehicleHistory: HistoryVehicle[]) {
        if (this.state.workTaskInfo) {
            this.setState({ vehicleHistory })
        }
    }

    handleChangeDialogCancel() {
        this.setState({ conflict: undefined })
    }

    getContext(): WorkTask {
        const { workTaskInfo, conflict, loading, loadingNewWorkTask, workTaskId, vehicleHistory } = this.state

        return {
            workTaskId: window.__NEXT_WORKTASKID__,
            workTask: workTaskInfo ? { ...workTaskInfo, vehicleHistory } : undefined,
            createWorkTask: this.createWorkTask,
            attachToWorkTask: this.attachToWorkTask,
            openWorkTask: this.openWorkTask,
            reloadWorkTask: this.reloadWorkTask,
            triggerWorkTaskConflict: this.triggerWorkTaskConflict,
            workTaskConflict: conflict,
            workTaskLoading: loading,
            newWorkTaskLoading: loadingNewWorkTask,
            injectVehicleHistory: this.injectVehicleHistory,
        }
    }

    render() {
        const { children, match } = this.props

        const { workTaskId } = match.params

        // A GUID has to be encoded, so that the children not begin to load and render their stuff. Otherwise, all further links are wrong and the worktask manager will reset all states :(
        if (workTaskId && GUID_REGEX.test(workTaskId)) {
            return null
        }

        return (
            <WorkTaskProviderContext.Provider value={this.getContext()}>{children ? Children.only(children) : null}</WorkTaskProviderContext.Provider>
        )
    }
}

const WrappedWorkTaskProvider = withRouter(WorkTaskProvider)

export default function (props: WorkTaskProviderProps) {
    return <Route path="/:workTaskId?" render={() => <WrappedWorkTaskProvider {...props} />} />
}

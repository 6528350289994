import { VehicleType } from "@tm/models"
import { ComponentProps, forwardRef, memo, SyntheticEvent, useCallback, useEffect, useState } from "react"
import { Image } from "../../generics/image"
import { getImageTypeByVehicleType } from "./helper"

type Props = Omit<ComponentProps<typeof Image>, "src" | "type"> & {
    modelImage: string
    modelSeriesImage: string
    vehicleType: VehicleType
}

export const VehicleImage = memo<Props>(
    forwardRef(({ modelImage, modelSeriesImage, vehicleType, onError, ...rest }, ref) => {
        const [shouldUseModelSeriesImage, setShouldUseModelSeriesImage] = useState(false)

        useEffect(() => {
            setShouldUseModelSeriesImage(false)
        }, [modelImage])

        const handleError = useCallback(
            (e: SyntheticEvent<HTMLImageElement, Event>) => {
                if (!shouldUseModelSeriesImage && modelImage !== modelSeriesImage && vehicleType === VehicleType.PassengerCar) {
                    setShouldUseModelSeriesImage(true)
                }
                onError?.(e)
            },
            [shouldUseModelSeriesImage, modelImage, modelSeriesImage, vehicleType, onError]
        )

        return (
            <Image
                {...rest}
                ref={ref}
                src={shouldUseModelSeriesImage ? modelSeriesImage : modelImage}
                type={getImageTypeByVehicleType(vehicleType)}
                onError={handleError}
            />
        )
    })
)

import { ReactElement, useCallback, useEffect, useRef, useState, FC } from "react"
import { Box, ClickAwayListener, Tooltip as MuiTooltip } from "@mui/material"
import { TooltipProps } from "./index"
import { useDebounce } from "../../hooks/useDebounce"

export const TOOLTIP_DEFAULT_DELAY = 500

function isFunctionComponent(component: ReactElement<any, any>) {
    return (
        !component ||
        typeof component.type === "string" ||
        (!!component.type.$$typeof &&
            (!component.type.__emotion_base || typeof component.type.__emotion_base === "string" || !!component.type.__emotion_base.$$typeof))
    )
}

export const TooltipComponent: FC<TooltipProps> = (props) => {
    const { className, onClickAway, noDelay, children: childrenProp, ...rest } = props
    const tooltipRef = useRef<HTMLDivElement>(null)
    const [disabledHover, setDisabledHover] = useState(false)

    // Check if it's an old class component and wrap it with a div, if it's so. Otherwise the tooltip causes problems setting listener on an class component ref
    const children = isFunctionComponent(childrenProp) ? childrenProp : <div style={{ display: "inline-block" }}>{childrenProp}</div>
    // console.log("TOOLTIP CHILDREN", childrenProp)

    const wheelEventHandler = useDebounce<WheelEvent>((e) => onClickAway?.(e), 50)

    const scrollEventHandler = useCallback(
        (e: WheelEvent) => {
            if (tooltipRef.current && !tooltipRef.current?.contains(e.target as Node)) {
                wheelEventHandler(e)
            }
        },
        [tooltipRef, wheelEventHandler]
    )

    useEffect(() => {
        if (window.addEventListener && props.open) {
            if (!props.preventCloseOnScroll) {
                window.addEventListener("wheel", scrollEventHandler)
            }
        }

        return () => {
            window.removeEventListener("wheel", scrollEventHandler)
        }
    }, [scrollEventHandler, props.preventCloseOnScroll, props.open])

    const tooltipProps = {
        ...rest,
        enterDelay: noDelay ? undefined : (props.enterDelay ?? TOOLTIP_DEFAULT_DELAY),
        enterNextDelay: noDelay ? undefined : (props.enterNextDelay ?? TOOLTIP_DEFAULT_DELAY),
    }

    const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const element = e.currentTarget
        if (props.showOnlyOnOverflow && element) {
            if (element.offsetWidth >= element.scrollWidth) {
                setDisabledHover(true)
            }
        }
    }, [])

    const handleMouseLeave = () => setDisabledHover(false)

    if (!tooltipProps.title) {
        return props.children
    }

    if (onClickAway) {
        return (
            <ClickAwayListener onClickAway={onClickAway}>
                {/* Div shall not be removed since the click in one item will instantly close the tooltip. In the case of compilations a new one couldn't be created */}
                <Box ref={tooltipRef}>
                    <MuiTooltip
                        arrow
                        {...tooltipProps}
                        title={tooltipProps.title}
                        classes={{ popper: className }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        disableHoverListener={disabledHover}
                    >
                        {children}
                    </MuiTooltip>
                </Box>
            </ClickAwayListener>
        )
    }

    return (
        <MuiTooltip
            arrow
            {...tooltipProps}
            title={tooltipProps.title}
            classes={{ popper: className }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            disableHoverListener={disabledHover}
        >
            {children}
        </MuiTooltip>
    )
}

import { Checkbox } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

export function Selection() {
    const article = useDefaultArticleItemState((x) => x.article)
    const quantity = useDefaultArticleItemState((x) => x.quantity)
    const selection = useDefaultArticleItemState((x) => x.selection)
    const largeCheckbox = useDefaultArticleItemState((x) => x?.largeCheckbox)

    if (!selection) {
        return null
    }

    const { isArticleSelected, toggleSelectedArticle, maximumPartsToCompare, selectedArticles } = selection

    const handleArticleCheckBoxEvent = () => {
        const maxParts = maximumPartsToCompare || 25
        const isSelected = isArticleSelected(article)

        if (selectedArticles?.length >= maxParts && !isSelected) {
            return
        }

        toggleSelectedArticle({
            ...article,
            quantity,
        })

        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleCheckbox, !isSelected)
    }

    return (
        <Checkbox
            size={largeCheckbox ? ("large" as "small" | "medium") : "small"}
            sx={{ padding: 0 }}
            checked={isArticleSelected(article)}
            onChange={handleArticleCheckBoxEvent}
        />
    )
}

import { CategoryType, TmaEModule } from "@tm/models"
import { uniqueId } from "../../../helpers/id"
import { ArticleListFilteredRequest } from "./models"
import { StoreContext } from "../event-storage"
import { QuerySearchType } from "../.."

export function getSearchQueryFromUrl() {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get("query")
}

export function getPageIndexMax(maxArticleCount: number | undefined, pageSize: number | undefined): number | undefined {
    if (!pageSize || !maxArticleCount) {
        return
    }

    const pageIndexMax = maxArticleCount / pageSize

    if (isNaN(pageIndexMax)) {
        return
    }

    return Math.ceil(pageIndexMax)
}

export const createEvent = {
    initial: (vehicleId?: string): ArticleListFilteredRequest => {
        return {
            searchStep: {
                number: 1, // can be delete later on, but set searchStep.number to 1 when inside reset()
                processId: uniqueId(),
            },
            origin: TmaEModule.UNDEFINED,
            vehicleId,
            productGroupsPerSupplier: {},
            searchFilters: undefined,
            eventStatus: "initial"
        }
    },
}

/**
 *
 * @param a ArticleListFilteredRequest
 * @param b ArticleListFilteredRequest
 * @returns true if it's equal, false if it's unequal
 */
export function compareEvents(a: ArticleListFilteredRequest, b: ArticleListFilteredRequest) {
    if (a && b) {
        const storedContext = a.context
        const tempContext = b.context
        if (
            storedContext?.contextId === tempContext?.contextId &&
            storedContext?.identifier === tempContext?.identifier &&
            a.searchStep?.processId === b.searchStep?.processId &&
            a.searchStep?.number === b.searchStep?.number
        ) {
            return true
        }
    }
    return false
}

/**
 *
 * @param a StoreContext
 * @param b StoreContext
 * @returns true if it's equal, false if it's unequal
 */
export function compareContext(a: StoreContext, b: StoreContext) {
    //
    return (a || b) && a.contextId === b.contextId && a.identifier === b.identifier
}

/**
 *
 * @param contextId current context id
 * @returns true if its a modal
 */
export function isModal(contextId: string) {
    return contextId.includes("modal")
}

/**
 * use to get the correct keywordsearchtype
 * @param categoryType which search category is active, like "vehicleParts" | "universalParts" | "directSearch" | ...
 * @returns the keywordsearchtype
 */
export function getQuerySearchType(categoryType: CategoryType): QuerySearchType {
    let querySearchTyp: QuerySearchType = QuerySearchType.Undefined
    switch (categoryType) {
        case "partsIndicator":
        case "dtcSearch": {
            querySearchTyp = QuerySearchType.ProductGroup // QuerySearchType.ErrorCode
            break
        }
        case "directSearch": {
            querySearchTyp = QuerySearchType.Direct
            break
        }
        case "vehicleParts":
        case "universalParts": {
            querySearchTyp = QuerySearchType.Synonym
            break
        }
    }
    return querySearchTyp
}

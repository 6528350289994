import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { PropsWithChildren } from "react"
import { Button } from "../../generics/button"
import { Icon } from "../../generics/Icons"
import { ListItemPaper, Props as ListItemPaperProps } from "../../generics/ListItemPaper"

type Props = ListItemPaperProps & {
    item: string
}

export function DraggableListItem({ item, style, children, ...rest }: PropsWithChildren<Props>) {
    const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({ id: item })

    return (
        <ListItemPaper
            {...rest}
            ref={setNodeRef}
            style={{
                transform: CSS.Translate.toString(transform),
                transition,
                zIndex: isDragging ? 1 : undefined,
                display: "flex",
                alignItems: "center",
                gap: "10px",
                ...style,
            }}
        >
            {children}
            <Button
                ref={setActivatorNodeRef}
                startIcon={<Icon name="move" />}
                {...listeners}
                {...attributes}
                style={{ touchAction: "none", cursor: "grab", borderRadius: 0, padding: "4px" }}
            />
        </ListItemPaper>
    )
}

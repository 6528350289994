import { styled } from "@mui/material"
import { ComponentType, HTMLAttributes } from "react"

export const ThumbnailContainer = styled("div")(({ theme }) => {
    return {
        display: "block",
        width: "100%",
        height: 50,
        marginBottom: "0.2em",
        padding: "0.1em",
        objectPosition: "center",
        objectFit: "scale-down",
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius || 3,

        "&.selected-image": {
            borderColor: theme.palette.primary.main,
        },
    }
}) as ComponentType<HTMLAttributes<HTMLDivElement>>

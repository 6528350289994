import { PropsWithChildren, SyntheticEvent, useCallback, useLayoutEffect, useState } from "react"
import { ResizeCallbackData, ResizeHandle } from "react-resizable"
import { useWindowSize } from "@tm/utils"
import { RezisablePopoverBox } from "./RezisablePopoverBox"

export type Size = { width: number; height: number }

const DEFAULT_MIN_CONSTRAINTS: [width: number, height: number] = [500, 400]
const DEFAULT_MAX_CONSTRAINTS: [width: number, height: number] = [1200, 1000]
const DEFAULT_SIZES: Size = { width: 700, height: 500 }
const RESIZEHANDLES: ResizeHandle[] = ["se", "e"]
const SCALE_AXIS = "both"
const HANDLESSIZE: [number, number] = [16, 16]
const WITH_INDEX = 0
const HEIGHT_INDEX = 1

type ResizablePopoverProps = {
    fullscreen: boolean
    onResize?: () => void
    onResizeStop?: (size: Size) => void
}

export function ResizableContainer({ children, onResize, onResizeStop, fullscreen }: PropsWithChildren<ResizablePopoverProps>): JSX.Element | null {
    const [imageSizes, setImageSizes] = useState(DEFAULT_SIZES)
    const [popoverBoxSize, setPopoverBoxSize] = useState({ height: 0, width: 0 })
    const handleResize = useCallback(
        (_: SyntheticEvent, { size }: ResizeCallbackData) => {
            setImageSizes(size)
            onResize && onResize()
        },
        [setImageSizes, onResize]
    )

    const handleResizeStop = useCallback(
        (_: SyntheticEvent, { size }: ResizeCallbackData) => {
            onResizeStop && onResizeStop(size)
        },
        [onResizeStop]
    )

    const windowSize = useWindowSize()

    useLayoutEffect(
        function getPopoverSize() {
            let updatedSize = {
                height: imageSizes.height || DEFAULT_SIZES.height,
                width: imageSizes.width || DEFAULT_SIZES.width,
            }
            if (fullscreen) {
                const viewportWidth = (windowSize[WITH_INDEX] || document.documentElement.clientWidth) - 40 // -32px from mui popover maxwidth 100%-32px
                const viewportHeight = windowSize[HEIGHT_INDEX] || document.documentElement.clientHeight
                updatedSize = {
                    height: viewportHeight,
                    width: viewportWidth,
                }
            }
            setPopoverBoxSize(updatedSize)
            onResize && onResize()
        },
        [imageSizes.height, imageSizes.width, onResize, fullscreen, windowSize]
    )

    return (
        <RezisablePopoverBox
            width={popoverBoxSize.width}
            height={popoverBoxSize.height}
            onResize={handleResize}
            onResizeStop={handleResizeStop}
            resizeHandles={RESIZEHANDLES}
            minConstraints={DEFAULT_MIN_CONSTRAINTS}
            maxConstraints={DEFAULT_MAX_CONSTRAINTS}
            axis={SCALE_AXIS}
            handleSize={HANDLESSIZE}
        >
            {children}
        </RezisablePopoverBox>
    )
}

import { ComponentType, memo } from "react"
import { useLocalization } from "@tm/localization"
import { BoxProps } from "@mui/system"
import { Box, Skeleton, Stack, styled } from "@mui/material"
import { classes } from "@tm/utils"
import { Prices } from "../ErpContainer/Prices"
import { AddOeToBasket } from "../Transactions/AddOeToBasket"
import { OeInfoAttributes } from "../Attributes/OeInfoAttributes"
import { useOeInformationItemState } from "../ContextProviderStates/OeInformationItemState"
import { OeInfoDescription } from "../OeInfoDescription"
import { Button } from "../../../generics/button"
import { Image } from "../../../generics/image"
import { ArticleNumber } from "../../articleNumber"
import { Typography } from "../../../generics/typographie"
import { OePartErpContainer } from "../ErpContainer"

export const ArticleContainer = styled(Box)(() => ({
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "100%",
    borderBottom: "3px #c3c3c3 solid",
})) as ComponentType<BoxProps>

const ImageContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    border: `1px solid transparent`,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "45px",
    height: "45px",
    zIndex: 2,
    img: {
        objectFit: "contain",
    },
}))

const StyledButton = styled(Button)(() => ({}))
StyledButton.defaultProps = { variant: "outlined" }

export const OeInformationItem = memo(() => {
    const { translateText } = useLocalization()

    const thumbnailUrl = useOeInformationItemState((x) => x.thumbnailUrl)
    const oeArticleNo = useOeInformationItemState((x) => x.oeArticleNo)
    const showAddToBasket = useOeInformationItemState((x) => x.showAddToBasket)

    return (
        <ArticleContainer>
            <Stack spacing={1} mb={0.5} direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <ImageContainer>
                        <Image type="article" src={thumbnailUrl} width="45px" height="45px" loading="eager" />
                    </ImageContainer>
                    <StyledButton>
                        <ArticleNumber articleNumber={oeArticleNo} articleNumberType="oe" />
                    </StyledButton>
                    <StyledButton color="highlight">
                        <Typography>{translateText(803)}</Typography>
                    </StyledButton>
                    <OeInfoDescription />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <OeInfoItemPrice />
                    {showAddToBasket && (
                        <Box padding=".5em">
                            <AddOeToBasket />
                        </Box>
                    )}
                </Stack>
            </Stack>
            <OeInfoAttributes />
        </ArticleContainer>
    )
})

function LoadingOeInfoItemOePrices({ className }: { className?: string }) {
    return (
        <Stack direction="row" justifyContent="space-between" className={classes("erpContainer", "erpContainer-loading", className)}>
            <Stack spacing="2px">
                <Skeleton variant="rounded" width={80} height={15} />
                <Skeleton variant="rounded" width={80} height={15} />
            </Stack>
        </Stack>
    )
}

function OeInfoItemPrice() {
    const oePrices = useOeInformationItemState((x) => x.oePrices)
    const articleErpInfo = useOeInformationItemState((x) => x.articleErpInfos)?.default

    let displayPrice = <Prices prices={oePrices} />
    if (articleErpInfo?.state === "success" && articleErpInfo.response?.prices?.length) {
        displayPrice = <OePartErpContainer />
    } else if (articleErpInfo?.state === "queued" || articleErpInfo?.state === "loading") {
        displayPrice = <LoadingOeInfoItemOePrices />
    }

    return displayPrice

    // (<Prices prices={oePrices} />)
}

import { registerOutsideClick, ButtonKeyDefinition } from "@tm/utils"

import ReactDOM from "react-dom"
import { Component, createRef, ReactElement } from "react"
import { bindMethodsToContext } from "../../helper"
import { Table, Loader, Scrollbar, ButtonProps } from ".."
import DefaultSearchField, { DefaultSearchFieldProps } from "../search-field/index.default"
import { ControlsConfig } from "../../configuration"
import { SearchFieldConfig } from "../search-field"
import { ExternalTooltip } from "../search-field/external-hint"

export type SuggestionSearchFieldProps = DefaultSearchFieldProps & {
    tooltip?: string
    getSuggestionData?: (query: string) => Array<any>
    getSuggestionDataAsync?: (query: string) => Promise<Array<any>>
    renderTableColumns?: () => Array<JSX.Element>
    onSuggestionSelect: (suggestion: any) => void
    onSingleSuggestionSelect?: (suggestion: any) => void
    onToLessCharacters?: (query: string) => void
    button?: ReactElement<ButtonProps>
    usePortal?: boolean
    forceShowTooltipOnHover?: boolean

    maxHeight?: number
    enableLeadingTrim?: boolean
    minCharactersToSuggest?: number
    minCharactersToSearch?: number
}

type State = {
    isOpen?: boolean
    query: string
    suggestions: Array<any>
    marked?: any
    loading?: boolean
    requestCount?: number
    props: SearchFieldConfig
}

// TODO: refactor this.state.marked to be the index of the given items
export default class SuggestionSearchField extends Component<SuggestionSearchFieldProps, State> {
    private suggestionTimeoutId: number

    private showSuggestionsAfterFocus = true

    private suggestionFieldIsMounted = false

    private inputRef: any

    private _scrollbar: any

    private _element: HTMLDivElement | undefined

    private _unregisterOutsideClick?: () => void

    suggestionBoxRef = createRef<HTMLDivElement>()

    static defaultProps: Partial<SuggestionSearchFieldProps> = {
        enableLeadingTrim: true,
        minCharactersToSearch: 1,
        minCharactersToSuggest: 2,
        maxHeight: 200,
    }

    constructor(props: SuggestionSearchFieldProps) {
        super(props)
        bindMethodsToContext(this)

        this.state = {
            isOpen: false,
            query: props.value || "",
            suggestions: [],
            marked: null,
            loading: false,
            requestCount: 0,
            props: ControlsConfig.get<SearchFieldConfig>("SearchField"),
        }
    }

    componentDidMount() {
        this.suggestionFieldIsMounted = true
    }

    UNSAFE_componentWillReceiveProps(nextProps: SuggestionSearchFieldProps) {
        if (nextProps.value != undefined && this.props.value != nextProps.value) {
            this.setState({ query: nextProps.value })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.suggestionTimeoutId)
        this.suggestionFieldIsMounted = false
        this._unregisterOutsideClick?.()
    }

    componentDidUpdate(prevProps: SuggestionSearchFieldProps, prevState: State) {
        const { maxHeight } = this.props
        // Returns if maxHeight is negative 1 (no max height)
        if (!this._scrollbar || maxHeight == -1) {
            return
        }

        let { height } = this._scrollbar.view.children[0].getBoundingClientRect()

        if (height > maxHeight!) {
            height = maxHeight
            const scrollerWidth = 12
            this._scrollbar.view.children[0].style.marginRight = `${scrollerWidth}px`
        } else {
            this._scrollbar.view.children[0].style.marginRight = undefined
        }

        this._scrollbar.container.style.height = `${height}px`

        if (!prevState.isOpen && this.state.isOpen && this._element && this.suggestionBoxRef.current) {
            const { top, left, width } = this._element.getBoundingClientRect()
            this.suggestionBoxRef.current.style.position = "absolute"
            this.suggestionBoxRef.current.style.top = `${top}px`
            this.suggestionBoxRef.current.style.left = `${left}px`
            this.suggestionBoxRef.current.style.minWidth = `${width}px`
        }
    }

    closeSuggestions() {
        this.setState({ isOpen: false })
    }

    loadSuggestions(query: string) {
        const { getSuggestionData, getSuggestionDataAsync } = this.props
        const { requestCount } = this.state

        if (getSuggestionDataAsync && requestCount !== undefined && requestCount >= 0) {
            const currentRequestCount = requestCount + 1

            this.setState({
                isOpen: true,
                query,
                loading: true,
                requestCount: currentRequestCount,
                marked: null,
            })

            if (this._element) {
                this._unregisterOutsideClick = registerOutsideClick(this._element, this.handleClose)
            }

            window.clearTimeout(this.suggestionTimeoutId)
            this.suggestionTimeoutId = window.setTimeout(
                () =>
                    getSuggestionDataAsync(query).then(
                        (data) => {
                            if (this.suggestionFieldIsMounted && currentRequestCount == this.state.requestCount) {
                                this.setState({
                                    suggestions: data || [],
                                    loading: false,
                                })
                            }
                        },
                        () => {
                            if (this.suggestionFieldIsMounted) {
                                this.setState({
                                    suggestions: [],
                                    loading: false,
                                })
                            }
                        }
                    ),
                250
            )
        } else if (getSuggestionData) {
            if (this.suggestionFieldIsMounted) {
                this.setState({
                    isOpen: true,
                    query,
                    suggestions: getSuggestionData(query) || [],
                    marked: null,
                })
            }

            this._element && (this._unregisterOutsideClick = registerOutsideClick(this._element, this.handleClose))
        }
    }

    focus(showSuggestions = true) {
        if (this.inputRef) {
            this.showSuggestionsAfterFocus = showSuggestions

            setTimeout(() => {
                this.inputRef.focus()
            }, 0)
        }
    }

    checkSearchConditions(query: string): boolean {
        return query.length >= this.props.minCharactersToSearch!
    }

    checkSuggestConditions(query: string): boolean {
        return query.length >= this.props.minCharactersToSuggest!
    }

    checkSingleSuggestion() {
        return this.props.onSingleSuggestionSelect && this.state.suggestions.length == 1
    }

    prepareSuggestValue(value: string): string {
        const checkLeadingOrOnlyWhitespaces = /(\s+)\w+|^\s+/
        const leadingWhitespaces = /^\s/

        if (this.props.enableLeadingTrim && checkLeadingOrOnlyWhitespaces.test(value)) {
            value = value.replace(leadingWhitespaces, "")
        }

        return value
    }

    handleInputRef(ref: any) {
        this.inputRef = ref
        this.props.onRef?.(ref)
    }

    handleClose() {
        this.setState({
            isOpen: false,
            marked: null,
            suggestions: [],
        })
    }

    handleKeyStroke(e: any) {
        // const { query, marked } = this.state

        switch (e.key) {
            case ButtonKeyDefinition.ArrowUp: {
                this.handleMarkPreviousSuggestion()
                e.preventDefault()
                break
            }
            case ButtonKeyDefinition.ArrowDown: {
                this.handleMarkNextSuggestion()
                e.preventDefault()
                break
            }
            case ButtonKeyDefinition.Escape:
            case ButtonKeyDefinition.Tab: {
                this.handleClose()
                break
            }
        }
    }

    handleMarkNextSuggestion() {
        const { suggestions, marked } = this.state

        if (suggestions.length) {
            this.setState({ marked: suggestions.next(marked) })
            this.handleScrollDown()
        }
    }

    handleMarkPreviousSuggestion() {
        const { suggestions, marked } = this.state

        if (suggestions.length) {
            this.setState({ marked: suggestions.previous(marked) || null })
            this.handleScrollUp()
        }
    }

    handleScrollDown() {
        this.handleScrolling(1)
    }

    handleScrollUp() {
        this.handleScrolling(-1)
    }

    handleScrolling(direction: 1 | -1) {
        const { marked, suggestions } = this.state
        if (!marked || !suggestions.length || this.checkSingleSuggestion()) {
            return
        }

        // TODO: refactor this
        const containerHeight = this._scrollbar.container.offsetHeight
        // container > scrollbar__view > fancy-list > fancy-list__body > first child, if there are suggestions a child should always exists
        const item = this._scrollbar.container.children[0].children[0].children[0].children.item(0)

        const marginBottom = parseInt(window.getComputedStyle(item).marginBottom || "0")
        const marginTop = parseInt(window.getComputedStyle(item).marginTop || "0")

        const itemHeight = item.offsetHeight + marginBottom + marginTop
        const itemsPerView = Math.round(containerHeight / itemHeight)

        const minIndexOfItem = Math.round((itemsPerView + 0.5) / 2)
        const maxIndexOfItem = suggestions.length - Math.round((itemsPerView + 0.5) / 2)

        if (suggestions.indexOf(marked) >= minIndexOfItem && suggestions.indexOf(marked) <= maxIndexOfItem) {
            const currentScrollTop = this._scrollbar.getValues().scrollTop
            const nextScrollStep = itemHeight * direction

            const scrollPosition = currentScrollTop + nextScrollStep
            this._scrollbar.scrollTop(scrollPosition)
        }
    }

    handleSingleSuggestion() {
        const { onSingleSuggestionSelect } = this.props
        if (!onSingleSuggestionSelect) {
            return
        }

        const { suggestions, marked } = this.state

        this.handleMarkNextSuggestion()

        if (suggestions.length) {
            onSingleSuggestionSelect(suggestions.next(marked))
        }
    }

    handleNotEnoughCharacters(query: string) {
        // call action to dispatch SEARCH_NOT_ENOUGH_CHARACTERS
        this.props.onToLessCharacters?.(query)
    }

    handleSuggestionSelect(suggestion: any) {
        this.props.onSuggestionSelect(suggestion)
        this.handleClose()
    }

    handleChange(value: string) {
        this.props.onChange?.(value)

        const preparedQuery = this.prepareSuggestValue(value)

        if (this.checkSuggestConditions(preparedQuery)) {
            this.loadSuggestions(preparedQuery)
        } else {
            this.setState({ query: preparedQuery })
            this.handleClose()
        }
    }

    handleChangeConfirm(value: string) {
        const { query, marked } = this.state

        if (this.checkSingleSuggestion()) {
            this.handleSingleSuggestion()
        } else if (marked) {
            this.handleSuggestionSelect(marked)
        } else if (!this.checkSearchConditions(query)) {
            this.handleNotEnoughCharacters(query)
        } else {
            this.props.onChangeConfirm?.(value)
            this.handleClose()
        }
    }

    handleFocus() {
        this.props.onFocus?.()

        if (!this.showSuggestionsAfterFocus) {
            this.showSuggestionsAfterFocus = true
            return
        }

        const preparedQuery = this.prepareSuggestValue(this.state.query)

        if (this.checkSuggestConditions(preparedQuery)) {
            this.loadSuggestions(preparedQuery)
        }

        this.setState({ marked: null })
    }

    handleRef(div: HTMLDivElement) {
        this._element = div
    }

    renderTableColumns() {
        const { renderTableColumns } = this.props

        if (!renderTableColumns) {
            return [<Table.Column renderItemContent={(data: any) => <Table.Cell title={data}>{data}</Table.Cell>} />]
        }

        return renderTableColumns()
    }

    renderSuggestBox() {
        const { usePortal, size } = this.props
        const { isOpen, marked, suggestions, loading } = this.state

        let className = "suggest__box "
        if (size) {
            className += ` suggest__box--${size}`
        }
        if (isOpen && suggestions.length) {
            className += " is-visible"
        }

        const suggestBox = (
            <div className={className}>
                <Scrollbar onRef={(ref) => (this._scrollbar = ref)}>
                    <Table
                        data={suggestions}
                        columns={this.renderTableColumns()}
                        getRowClassName={(data: any) => (data == marked ? "is-selected" : "")}
                        onClickRow={this.handleSuggestionSelect.bind(this)}
                        // scrollable
                        getFocusedRowIndex={() => suggestions.indexOf(marked)}
                    />
                </Scrollbar>
                <Loader visible={loading} />
            </div>
        )

        if (usePortal) {
            return ReactDOM.createPortal(
                <div className="suggest-box-wrapper" ref={this.suggestionBoxRef}>
                    {suggestBox}
                </div>,
                document.body
            )
        }

        return suggestBox
    }

    render() {
        const { button, tooltip, forceShowTooltipOnHover, ...otherProps } = this.props
        const {
            isOpen,
            suggestions,
            query,
            props: { showHintAsTooltip },
        } = this.state

        let className = "suggest "
        if (isOpen && suggestions.length) {
            className += "suggest--open "
        }
        if (this.props.className) {
            className += this.props.className
        }
        const tooltipAsHint = showHintAsTooltip ? tooltip : forceShowTooltipOnHover ? tooltip : undefined
        return (
            <>
                <div className={className} onKeyDown={this.handleKeyStroke} ref={this.handleRef}>
                    {this.renderSuggestBox()}

                    <DefaultSearchField
                        {...otherProps}
                        onRef={this.handleInputRef}
                        value={query}
                        onChange={this.handleChange}
                        onChangeConfirm={this.handleChangeConfirm}
                        onFocus={this.handleFocus}
                        tooltip={isOpen && suggestions.length > 0 ? tooltip : !showHintAsTooltip ? tooltipAsHint : tooltip} // show i icon always if suggestion box is open, otherwise it's not readable
                    />

                    {button}
                </div>
                {!forceShowTooltipOnHover && !tooltipAsHint && <ExternalTooltip tooltip={tooltip} />}
            </>
        )
    }
}

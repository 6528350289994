import { FunctionComponent, useCallback, useEffect, memo, useRef, PropsWithChildren } from "react"

import { Positioning } from "../../models/SharedModels"
import { GrayishSkins } from "../../models/skins"

export type PopoverProps = PropsWithChildren<{
    alignArrow?: Positioning
    skin?: GrayishSkins
    active?: boolean
    className?: string
    popupModifierClass?: string
    onElementRef?: (el: HTMLElement) => void
    onOutsideInteraction?: (el: Event) => void
}>

const baseName = `popover`

/**
 * If you want to use a popover in combination with a button, use the appendItem property of the button
 */
const Popover: FunctionComponent<PopoverProps> = memo(
    ({ children, alignArrow, skin, active, className, popupModifierClass, onElementRef, onOutsideInteraction }) => {
        const ref = useRef<HTMLDivElement>(null)

        useEffect(() => {
            if (onElementRef && ref.current) {
                onElementRef(ref.current)
            }
        }, [ref, onElementRef])

        useEffect(() => {
            if (!ref.current || !onOutsideInteraction || !active) {
                return
            }

            const handleOutsideInteraction = (e: Event): void => {
                if (!(ref.current as any).contains(e.target)) {
                    onOutsideInteraction(e)
                }
            }

            document.addEventListener("mousedown", handleOutsideInteraction)
            document.addEventListener("wheel", handleOutsideInteraction)

            return () => {
                document.removeEventListener("mousedown", handleOutsideInteraction)
                document.removeEventListener("wheel", handleOutsideInteraction)
            }
        }, [ref, onOutsideInteraction])

        const getArrowPositionClassname = useCallback((): string => {
            let arrowPositionClassName = `${baseName}__arrow`

            if (Array.isArray(alignArrow)) {
                alignArrow.forEach((arrow) => {
                    arrowPositionClassName += ` ${baseName}__arrow--${arrow}`
                })
            } else {
                if (!alignArrow || alignArrow != "bottom") {
                    arrowPositionClassName += ` ${baseName}__arrow--top`
                }

                if (alignArrow && alignArrow != "top") {
                    arrowPositionClassName += ` ${baseName}__arrow--${alignArrow}`
                }
            }

            return arrowPositionClassName
        }, [alignArrow])
        const arrowClassName = getArrowPositionClassname()
        const arrowBottom = arrowClassName.indexOf("bottom") >= 0
        const popoverClassname = `${baseName} ${active ? `is-active` : ""} ${skin ? `${baseName}--${skin}` : ""} ${className || ""} ${
            arrowBottom ? `${baseName}--top` : ""
        } ${popupModifierClass || ""}`

        return (
            <div className={popoverClassname} ref={ref}>
                {!arrowBottom && <div className={arrowClassName} />}
                <div className={`${baseName}__inner`}>{children}</div>
                {arrowBottom && <div className={arrowClassName} />}
            </div>
        )
    }
)

export default Popover

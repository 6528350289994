import { channel, RegisteredModels, Vehicle } from "@tm/models"
import { Container } from "@tm/nexus"

import { ShowAvailabilitiesRequest, ShowAvailabilitiesResponse } from "../data/data-availabilities"
import { WorkTaskProvider } from "./WorkTaskProvider"

export function loadVehicleDataAvailabilities(this: WorkTaskProvider, vehicle: Vehicle) {
    // Update the component state
    const updateState = (vehicle: Vehicle) => {
        this.setState(
            (prevState) => {
                if (!prevState.workTaskInfo) {
                    return null
                }

                return {
                    ...prevState,
                    workTaskInfo: {
                        ...prevState.workTaskInfo,
                        vehicle,
                    },
                }
            },
            () => {
                if (this.state.workTaskId) {
                    channel("WORKTASK", this.state.workTaskId).publish("VEHICLE/UPDATED", vehicle)
                }
            }
        )
    }

    const request: ShowAvailabilitiesRequest = {
        modelId: vehicle.tecDocTypeId,
        vehicleType: vehicle.vehicleType,
        countryCode: vehicle.countryCode,
    }

    Container.getInstance<ShowAvailabilitiesResponse | undefined>(RegisteredModels.Vehicle_DataAvailabilities)
        .subscribe(request)
        .load()
        .then((response) => {
            if (!response) {
                throw new Error()
            }

            updateState({
                ...vehicle,
                dataAvailabilities: {
                    gpi: {
                        dat: response.gpi.hasDat || false,
                        eurotaxV3: response.gpi.hasEurotaxV3 || false,
                        temot: response.gpi.hasTemot || false,
                        tecRMI: response.gpi.hasTecRmi || false,
                    },
                    repairTimes: {
                        awDoc: response.repairTimes.hasAwDoc || false,
                        autodata: response.repairTimes.hasAutodata || false,
                        haynesPro: response.repairTimes.hasHaynesPro || false,
                        tecRMI: response.repairTimes.hasTecRmi || false,
                    },
                    inspectionData: {
                        autodata: response.inspectionData.hasAutodata || false,
                        haynesPro: response.inspectionData.hasHaynesPro || false,
                        tecRMI: response.inspectionData.hasTecRmi || false,
                    },
                    technicalData: {
                        autodata: response.technicalData.hasAutodata || false,
                        haynesPro: response.technicalData.hasHaynesPro || false,
                        tecRMI: response.technicalData.hasTecRmi || false,
                    },
                    tyres: {
                        driveRight: response.tyres.hasDriveRight || false,
                        tecRMI: response.tyres.hasTecRmi || false,
                    },
                    fastCalculator: {
                        hasAny: response.fastCalculator.hasAny,
                        hasAutodata: response.fastCalculator.hasAutodata || false,
                        hasHaynesPro: response.fastCalculator.hasHaynesPro || false,
                        hasTecRmi: response.fastCalculator.hasTecRmi || false,
                        activeServiceDataProviders: response.fastCalculator.activeServiceDataProviders,
                    },
                    toolRental: {
                        mobilityMarket: response.toolRental.hasMobilityMarket || false,
                    },
                },
            })
        })
        .catch(() => {
            updateState({
                ...vehicle,
                dataAvailabilities: {
                    gpi: { dat: null, eurotaxV3: null, temot: null, tecRMI: null },
                    repairTimes: { awDoc: null, autodata: null, haynesPro: null, tecRMI: null },
                    inspectionData: { autodata: null, haynesPro: null, tecRMI: null },
                    technicalData: { autodata: null, haynesPro: null, tecRMI: null },
                    tyres: { driveRight: null, tecRMI: null },
                    fastCalculator: { hasAny: null, hasAutodata: null, hasHaynesPro: null, hasTecRmi: null, activeServiceDataProviders: null },
                    toolRental: { mobilityMarket: null },
                },
            })
        })
}

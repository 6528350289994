import { Box, Popover, PopoverOrigin, Stack, styled } from "@mui/material"
import { forwardRef, PropsWithChildren, useState, ReactNode, MouseEvent, useLayoutEffect, CSSProperties } from "react"

type Props = {
    disabled?: boolean
    defaultPopoverPosition?: "top" | "bottom"
    popoverVariant?: PopoverVariant
    popoverContent?: ReactNode
}

type PositionObj = {
    bottom: { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin }
    top: { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin }
}

type DynamicStylesProp = {
    itself: CSSProperties
    after: CSSProperties
    before: CSSProperties
}

export enum PopoverVariant {
    OUTLINE = "outline",
}

const OutlineBox = styled(Box, { shouldForwardProp: (prop) => prop !== "popoverPosition" })<{ popoverPosition?: "bottom" | "top" }>(({
    theme,
    popoverPosition = "bottom",
}) => {
    const borderWidthInnerArrow = 8
    const borderWidthOuterArrow = borderWidthInnerArrow + 1

    // Remove any and replace by a proper type
    let dynamicStyles: DynamicStylesProp = {
        itself: {
            marginTop: "10px",
        },
        after: {
            borderColor: `transparent transparent ${theme.palette.background.paper}`,
            top: `-${2 * borderWidthInnerArrow}px`,
        },
        before: {
            borderColor: `transparent transparent ${theme.palette.grey[800]}`,
            top: `-${2 * borderWidthOuterArrow}px`,
        },
    }

    if (popoverPosition === "top") {
        dynamicStyles = {
            itself: {
                marginBottom: "18px", // arrow needs some more spacing
            },
            after: {
                borderColor: `${theme.palette.background.paper} transparent transparent`,
                bottom: `-${2 * borderWidthInnerArrow}px`,
            },
            before: {
                borderColor: `${theme.palette.grey[800]} transparent transparent`,
                bottom: `-${2 * borderWidthOuterArrow}px`,
            },
        }
    }

    return {
        ...dynamicStyles.itself,
        position: "relative",
        border: `1px solid ${theme.palette.grey[800]}`,
        padding: "8px",
        backgroundColor: theme.palette.background.paper,
        "&::after": {
            ...dynamicStyles.after,
            content: "''",
            borderStyle: "solid",
            borderWidth: `${borderWidthInnerArrow}px`,
            width: 0,
            height: 0,
            position: "absolute",
            left: `calc(50% - ${borderWidthInnerArrow}px)`,
        },
        "&::before": {
            ...dynamicStyles.before,
            content: "''",
            borderStyle: "solid",
            borderWidth: `${borderWidthOuterArrow}px`,
            width: 0,
            height: 0,
            position: "absolute",
            left: `calc(50% - ${borderWidthOuterArrow}px)`,
        },
    }
})

/*
We currently use a mui-popover because:
popper:
- does not support border and border arrow
- we have to write our own backdrop click handling
- scroll is enabled when opened
 */
export const PopoverMenu = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
    const { children, disabled, popoverVariant, popoverContent, defaultPopoverPosition = "bottom" } = props
    const [anchorElement, setAnchorElement] = useState<HTMLElement>()
    const [contentElement, setContentElement] = useState<HTMLElement>()
    const [popoverPosition, setPopoverPosition] = useState(defaultPopoverPosition)

    const openPopover = (e: MouseEvent) => {
        if (disabled) {
            return
        }
        setAnchorElement(e.target as HTMLElement)
    }

    const handleClosePopover = () => {
        setAnchorElement(undefined)
    }

    useLayoutEffect(() => {
        if (!anchorElement || !contentElement) {
            return
        }
        if (popoverPosition === "top" || popoverPosition === "bottom") {
            if (doesPopoverFit()) {
                setPopoverPosition(getInvertedPosition(defaultPopoverPosition))
            } else {
                // make sure to reset the value to default when there is enough space
                setPopoverPosition(defaultPopoverPosition)
            }
            // add left / right here in future when needed
        }
    }, [contentElement])

    function getInvertedPosition(prevPosition: "top" | "bottom") {
        if (prevPosition === "top") {
            return "bottom"
        }
        return "top"
    }

    function doesPopoverFit() {
        if (!anchorElement || !contentElement) {
            return
        }
        if (defaultPopoverPosition === "bottom") {
            return anchorElement.getBoundingClientRect().y + anchorElement.clientHeight + contentElement.clientHeight >= window.innerHeight
        }
        if (defaultPopoverPosition === "top") {
            return anchorElement.getBoundingClientRect().y - contentElement.clientHeight <= 0
        }
    }

    const positionObj: PositionObj = {
        bottom: {
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            transformOrigin: { vertical: "top", horizontal: "center" },
        },
        top: {
            anchorOrigin: { vertical: "top", horizontal: "center" },
            transformOrigin: { vertical: "bottom", horizontal: "center" },
        },
    }

    return (
        <>
            <Stack direction="row" gap={0.5} onClick={openPopover}>
                {children}
            </Stack>
            <Popover
                anchorEl={anchorElement}
                anchorOrigin={positionObj[popoverPosition].anchorOrigin}
                transformOrigin={positionObj[popoverPosition].transformOrigin}
                open={!!anchorElement}
                onClose={handleClosePopover}
                PaperProps={
                    popoverVariant === PopoverVariant.OUTLINE
                        ? {
                              style: {
                                  backgroundColor: "transparent",
                                  boxShadow: "none",
                                  borderRadius: 0,
                              },
                          }
                        : undefined
                }
            >
                <Box ref={setContentElement} onClick={handleClosePopover}>
                    {popoverVariant === PopoverVariant.OUTLINE ? (
                        <OutlineBox popoverPosition={popoverPosition}>{popoverContent}</OutlineBox>
                    ) : (
                        <Box>{popoverContent}</Box>
                    )}
                </Box>
            </Popover>
        </>
    )
})

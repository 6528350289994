import { PropsWithChildren } from "react"
import { styled, Typography } from "@mui/material"
import { Button } from "./button"
import { Icon } from "../Icons"

const ContentWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(0.5, 1),
}))

const DownloadButton = styled(Button)({
    display: "flex",
    flexDirection: "row",
    flex: 0,
})

const Headline = styled("div")({
    fontSize: "14px",
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
})

export type AttachmantButtonProps = PropsWithChildren<{
    fileName: string
    fileType: "PICTURE" | "PDF" | "KVA" | string
    iconName: string
    fileSize?: string
    href?: string
    onClick?: () => void
}>

export function AttachmentButton({ href, fileName, fileType, fileSize, iconName, onClick }: AttachmantButtonProps) {
    return (
        <div>
            <DownloadButton href={href} target="_blank" onClick={onClick}>
                <Icon name={iconName} width="20px" height="20px" />
                <ContentWrapper>
                    <Headline>{`${fileName}`}</Headline>
                    <Typography variant="label" color="inherit">
                        {fileType}
                        {fileSize && ` | ${fileSize}`}
                    </Typography>
                </ContentWrapper>
                <Icon name="download" width="20px" height="20px" />
            </DownloadButton>
        </div>
    )
}

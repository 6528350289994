import { NestedCSSProperties } from "typestyle/lib/types"

export function css<T extends { [name: string]: NestedCSSProperties }>(cfg: T) {
    return cfg
}

export function classes(...classes: (string | false | undefined | null | { [className: string]: any })[]): string {
    return classes
        .map((c) => (c && typeof c === "object" ? Object.keys(c).map((key) => !!c[key] && key) : [c]))
        .reduce((flattened, c) => flattened.concat(c), [] as string[])
        .filter((c) => !!c)
        .join(" ")
}

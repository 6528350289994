import Icon from "../icon"
import Text from "../text"

export function ExternalTooltip(props: { tooltip?: string }) {
    if (!props.tooltip) {
        return null
    }

    return (
        <div className="search-container__hint">
            <Icon name="search" />
            <Text size="s">{props.tooltip}</Text>
        </div>
    )
}

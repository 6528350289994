import { memo, useCallback } from "react"
import { ArticleImage } from "@tm/models"
import { ThumbnailImage } from "./ThumbnailImage"
import { ThumbnailContainer } from "./ThumbnailContainer"

type Props = {
    id: number
    image: ArticleImage
    isActive: boolean
    onClick(id: number): void
}
export const ImageViewerThumbnail = memo<Props>(({ id, image, isActive, onClick }) => {
    const handleClick = useCallback(() => {
        onClick(id)
    }, [onClick, id])

    return (
        <ThumbnailContainer onClick={handleClick} className={isActive ? "selected-image" : ""}>
            <ThumbnailImage src={image.thumbnailUrl} title={image.description} />
        </ThumbnailContainer>
    )
})

import { AvailabilityStatus } from "@tm/models"
import { mapAvailabilityStatusToLevel } from "@tm/utils"
import { PropsWithChildren } from "react"
import { Icon, Image } from "../.."
import { AvailabilityDescription } from "./components/availability-description"

export type AvailabilityIconWithDescriptionProps = PropsWithChildren<{
    status: AvailabilityStatus
    description?: string
    iconname?: string
    imageUrl?: string
    onClick?(): void
}>

export default function AvailabilityIconWithDescription({
    status,
    iconname,
    imageUrl,
    onClick,
    description,
    children,
}: AvailabilityIconWithDescriptionProps) {
    const baseName = "availability-icon-with-description"

    let className = `${baseName} ${baseName}--level-${mapAvailabilityStatusToLevel(status)} ${baseName}--status-${status}`

    if (onClick) {
        className += ` ${baseName}--clickable`
    }

    let imageElement
    if (imageUrl) {
        imageElement = <Image url={imageUrl} className={`${baseName}__image`} />
    } else if (iconname) {
        imageElement = <Icon name={iconname} size="xl" />
    }

    return (
        <div className={className} onClick={onClick}>
            <div className={`${baseName}__description`}>
                {children || <AvailabilityDescription baseName={baseName} imageUrl={imageUrl} description={description} />}
            </div>
        </div>
    )
}

import { ArticleActionIcon, ErpIconType } from "@tm/models"
import { styled } from "@mui/material"
import { useMemo } from "react"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ImsIcon } from "../ImsIcon"
import { sendTmaEvent } from "./ActionsTmaHelper"
import { IconButton } from "../IconButton"
import { Image } from "../../../generics/image"

type IconActionProps = {
    action: ArticleActionIcon
}

const StyledIconButton = styled(IconButton)({
    objectFit: "contain",
    height: 20,
    padding: "0",
    margin: "0",

    svg: {
        width: "100%",
        height: "100%",
    },
})

const StyledImage = styled(Image)({
    objectFit: "contain",
    height: 20,
})

export function IconAction({ action: { tooltip, handler, icon, isHighlight, iconType, useIconFromUrl } }: IconActionProps) {
    const imsIcon = useDefaultArticleItemState((x) => x.imsIcon)

    const handleClickEventWithTma = useMemo(() => {
        if (handler) {
            return (event: React.MouseEvent<HTMLElement>) => {
                handler(event)
                sendTmaEvent(ErpIconType[iconType])
            }
        }
    }, [handler, iconType])

    const content = useMemo(() => {
        if (useIconFromUrl) {
            return <StyledImage src={icon} />
        }
        if (icon === "trader-icon" && imsIcon) {
            return <ImsIcon />
        }
        return <Icon name={icon} color={isHighlight ? "primary" : undefined} size="20px" />
    }, [useIconFromUrl, icon, imsIcon, isHighlight])

    const wrappedContent = useMemo(() => {
        if (handleClickEventWithTma) {
            return (
                <StyledIconButton onClick={handleClickEventWithTma} hoverEffect>
                    {content}
                </StyledIconButton>
            )
        }
        return content
    }, [handleClickEventWithTma, content])

    return <Tooltip title={tooltip}>{wrappedContent}</Tooltip>
}

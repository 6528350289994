import { getCurrentWorkTaskId, UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { useUser, useWorkTask } from ".."
import { matchPath, useLocation } from "react-router"
import { decodeUniqueId } from "@tm/utils"

/**
 * Returns the useTelesalesCustomerNo and also returns collective customer when we aren't in a workTask and
 * returns a bool that can be used to determine if service calls can be started already or if we still expect a customer no.
 */
export function useTelesalesCustomerNumber(): { telesalesCustomerNo: string | undefined; enableServiceCalls: boolean; hasTelesales: boolean } {
    const { userContext } = useUser()
    const { workTask, workTaskId } = useWorkTask() || {}
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const refCustomerNo: string | null = queryParams.get("refCustomerNo")

    const hasTelesales = !!userContext.hasTelesales

    const collectiveCustomerNo = useMemo((): string | undefined => {
        if (!hasTelesales) {
            return undefined
        }

        if (userContext.parameter.collectiveCustomer) {
            return userContext.parameter.collectiveCustomer
        }

        const telesalesModule = userContext.modules?.find((module) => module.type === UserModuleType.Telesales)
        return telesalesModule?.parameters?.find((x: any) => x.key == "CollectiveCustomer")?.value
    }, [hasTelesales, userContext.parameter.collectiveCustomer, userContext.modules])

    if (!hasTelesales) {
        return { telesalesCustomerNo: undefined, enableServiceCalls: true, hasTelesales }
    }
    if (!workTaskId) {
        // use the collective customer when we aren't in a worktask
        return { telesalesCustomerNo: refCustomerNo || collectiveCustomerNo, enableServiceCalls: true, hasTelesales }
    }

    if (!workTask?.customer) {
        return { telesalesCustomerNo: undefined, enableServiceCalls: false, hasTelesales }
    }

    const telesalesCustomerNo = workTask?.customer?.refCustomerNo ?? collectiveCustomerNo

    if (!collectiveCustomerNo) {
        // When there is no collective customer configured, we might have worktasks without a partner,
        // so we have to enable service calls (or we don't?) directly, but else ...
        return { telesalesCustomerNo, enableServiceCalls: true, hasTelesales }
    }

    // ... at some point the workTask will have a patner attached. Only then we need to enable the service calls.
    return { telesalesCustomerNo, enableServiceCalls: !!telesalesCustomerNo, hasTelesales }
}

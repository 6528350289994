import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core"
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { styled } from "@mui/material"
import { PropsWithChildren } from "react"
import { DraggableListItem } from "./DraggableListItem"

type Props = {
    items: string[]
    onReorder(from: string, to: string): void
}

export function DraggableList({ items, onReorder, children }: PropsWithChildren<Props>) {
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event

        if (!over || active.id === over?.id) {
            return
        }

        onReorder(active.id as string, over.id as string)
    }

    return (
        <Wrapper>
            <DndContext modifiers={[restrictToVerticalAxis, restrictToParentElement]} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {children}
                </SortableContext>
            </DndContext>
        </Wrapper>
    )
}

DraggableList.Item = DraggableListItem

const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
}))

import { useEffect, useState } from "react"
import { Text, Icon } from ".."
import { elementId } from "../../helper"

export type SwitchProps = {
    label?: string
    status?: boolean
    alignLabel?: "left" | "right"
    disabled?: boolean
    onChange?(state: boolean): void
    onClick?(e: React.MouseEvent<HTMLLabelElement>): void
}

export default function SwitchComponent({ label, status, alignLabel, disabled, onChange, onClick }: SwitchProps) {
    const id = elementId("switch")
    const [checked, setChecked] = useState<boolean>(!!status)

    useEffect(() => {
        if (checked !== !!status) {
            setChecked(!!status)
        }
    }, [status])

    function handleChange(e: any) {
        setChecked(!checked)
        onChange?.(!checked)
    }

    function handleClick(e: React.MouseEvent<HTMLLabelElement>) {
        onClick?.(e)
    }

    let className = "switch"
    if (alignLabel) {
        className += ` switch--label-${alignLabel}`
    }
    if (disabled) {
        className += " switch--disabled"
    }

    const mainLabelElement = <Text size="xs">{label}</Text>

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <label htmlFor={id} className={className} onClick={handleClick}>
            {alignLabel && alignLabel == "left" && mainLabelElement}
            <input
                type="checkbox"
                id={id}
                value={undefined}
                className="input"
                disabled={!!disabled}
                checked={checked}
                onChange={handleChange}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            />
            <span className="switch__content">
                <div className="switch__icon">
                    <Icon size="xs" name="check" />
                </div>
                {!checked && (
                    <Text className="switch__unchecked" modifiers={["sub"]} size="s">
                        0
                    </Text>
                )}
            </span>
            {(!alignLabel || alignLabel == "right") && mainLabelElement}
        </label>
    )
}

import { buttonClasses, styled } from "@mui/material"
import { Icon } from "../Icons"

import { Button, ButtonProps } from "./button"

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => {
    const customColor = theme.overwrites?.components?.button?.searchButtonColor
    if (!customColor) {
        return
    }
    return {
        [`&.${buttonClasses.contained}`]: {
            backgroundColor: customColor,
            "&:hover": {
                backgroundColor: customColor,
            },
        },
    }
})

export function SearchButton(props: ButtonProps) {
    return (
        <ColorButton startIcon={<Icon name="search" />} {...props}>
            {props.children}
        </ColorButton>
    )
}

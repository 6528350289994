import { Component, createRef, RefObject } from "react"
import DefaultSearchField, { DefaultSearchFieldProps } from "./index.default"
import { ExternalTooltip } from "./external-hint"

export default class SearchFieldWithExternalHintField extends Component<DefaultSearchFieldProps, any> {
    private _inputRef: RefObject<DefaultSearchField>

    constructor(props: DefaultSearchFieldProps) {
        super(props)
        this._inputRef = createRef()
    }

    focus() {
        this._inputRef.current && this._inputRef.current.focus && this._inputRef.current.focus()
    }

    render() {
        const { tooltip, ...rest } = this.props
        return (
            <>
                <DefaultSearchField {...rest} ref={this._inputRef} />
                {tooltip && <ExternalTooltip tooltip={tooltip} />}
            </>
        )
    }
}

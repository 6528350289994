import { Route } from "react-router"
import { Subject } from "rxjs"
import { map, filter } from "rxjs/operators"
import { createElement, ReactNode } from "react"
import { type IContext, type Transmission, type ModuleConfig, type Dictionary, ModuleComponent } from ".."
// eslint-disable-next-line
import { createSwitch } from "./createSwitch"
// eslint-disable-next-line
import { createComponent } from "./createComponent"
import { createAbsoluteRoute } from "./createAbsoluteRoute"
import { ModuleChildConfig } from "../models/configuration"
import { dictionaryToArray, validType } from "../helpers"

// Erstellen eines Moduls, das ein Layout und die darin enthaltenen Komponenten und Submodule umfasst
export function createModule(
    context: IContext,
    key: string,
    moduleConfig: ModuleConfig,
    parentActions$: Subject<Transmission>,
    parentKey?: string,
    parentRoute?: string
): ReactNode {
    const moduleActions$ = new Subject<Transmission>()
    // moduleActions$.subscribe(console.log.bind(null, config.name)) // Remove before Flight
    const layout = context.layouts[moduleConfig.layout]
    if (!layout) {
        console.warn(`Layout not found: ${moduleConfig.layout}`)
        return
    }

    const moduleKey = `${parentKey || ""}/${key}`

    const route = createAbsoluteRoute(moduleConfig.route, parentRoute)
    context.routes.push(route)
    const children = createChildren(context, moduleConfig, moduleActions$, moduleKey, route.path)

    // Intermodular communication

    // take all parentAction and put it into this moduleActions stream
    parentActions$.pipe(map((x) => ({ ...x, incoming: true }))).subscribe(moduleActions$)

    const { parentTransit } = moduleConfig

    if (parentTransit) {
        if (parentTransit === true) {
            // send all messages to parent
            moduleActions$.pipe(filter((x) => !x.incoming)).subscribe(parentActions$)
        } else if (!Array.isArray(parentTransit)) {
            console.error(`Module [${key}]: parentTransmit must be an Array!`)
        } else if (parentTransit.length) {
            moduleActions$
                .pipe(
                    filter(
                        (x) =>
                            !x.incoming &&
                            !!parentTransit &&
                            parentTransit.some((y) => {
                                if (y.bundle != x.bundle) {
                                    return false
                                }
                                if (Array.isArray(y.action)) {
                                    return (y.action as Array<string>).some((z) => z == x.action.type)
                                }
                                return (y.action as string) == x.action.type
                            })
                    )
                )
                .subscribe(parentActions$)
        }
    }

    const module = () => (
        <ModuleComponent
            children={children}
            config={moduleConfig}
            layout={layout}
            context={context}
            moduleKey={moduleKey}
            route={route}
            props={moduleConfig.props}
        />
    )

    return createElement(Route, {
        ...route,
        component: module,
        key,
    })
}

// Erstellen aller Views die in einem Layout verwendet werden
function createChildren(
    context: IContext,
    moduleConfig: ModuleConfig,
    moduleActions$: Subject<Transmission>,
    parentKey: string,
    parentRoute: string
): Dictionary<Array<ReactNode>> {
    const slots: Dictionary<Array<{ key: string; value: ModuleChildConfig }>> = {}

    dictionaryToArray(moduleConfig.children)
        .filter(validType)
        .orderBy((x) => (x.value.sort != null ? x.value.sort : Number.MAX_VALUE))
        .forEach((x) => {
            const slotIndex = x.value.slot || 0
            if (!slots[slotIndex]) {
                slots[slotIndex] = [x]
                return
            }
            slots[slotIndex].push(x)
        })

    const children: Dictionary<Array<ReactNode>> = {}

    dictionaryToArray(slots).forEach((slot) => {
        children[slot.key] = slot.value
            .filter((keyValue) => {
                const child = keyValue.value
                if (child.type == "component" && (child.disabled || !child.bundle || !context.bundles[child.bundle])) {
                    return false
                }
                return true
            })
            .map((keyValue) => {
                const { key } = keyValue
                const child = keyValue.value
                const { props } = moduleConfig
                switch (child.type) {
                    case "switch": {
                        const switchConfig = {
                            ...child,
                            moduleProps: {
                                ...(child.props || {}),
                                ...props,
                            },
                        }
                        return createSwitch(context, key, switchConfig, moduleActions$, parentKey, parentRoute)
                    }
                    case "component": {
                        const componentConfig = {
                            ...child,
                            moduleProps: {
                                ...(child.moduleProps || {}),
                                ...props,
                            },
                        }
                        return createComponent(context, key, componentConfig, moduleActions$, parentKey, parentRoute)
                    }
                    case "module": {
                        const moduleConfig = {
                            ...child,
                            props: {
                                ...(child.props || {}),
                                ...props,
                            },
                        }
                        return createModule(context, key, moduleConfig, moduleActions$, parentKey, parentRoute)
                    }
                }
            })
    })

    return children
}

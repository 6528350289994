import { useMemo } from "react"
import { useUser } from "../user/consumer/useUser"
import { ErpSettings } from "../user/model/UserSettings"

type ErpCredentials =
    | {
          showErpSettings: boolean
          erpModules: { [key: string]: ErpSettings }
      }
    | undefined

// If we have to adjust this position more often, please move it to the UI-Configs. Currently there was only this one request
// https://jira.dvse.de/browse/NEXT-29178
const TRADER_WITH_CUSTOMER_NUMBER = [
    517, // WM NL
]

/**
 * @memberof Context-Distribution
 */
export function useErpCredentials(): ErpCredentials {
    const { userContext } = useUser()
    const externalErpModules = userContext.externalModules?.filter((x) => x.accessDataFromCatalogue === true)
    return useMemo(() => {
        if (externalErpModules?.length) {
            const erpModules: { [key: string]: ErpSettings } = {}
            externalErpModules.forEach((element) => {
                erpModules[element.id] = {
                    userName: element?.username,
                    customerNumber: element?.customerNo,
                    isPasswordSet: element?.isPasswordSet,
                    isCustomerNoRequired: element?.isCustomerNoRequired || TRADER_WITH_CUSTOMER_NUMBER.includes(element.tradeReferenceId),
                }
            })

            return { erpModules, showErpSettings: true }
        }
    }, [externalErpModules])
}

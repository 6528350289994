import { DisplayBehaviour } from "../enumerations"
import { Paging } from "../paging"

export type ShowReturnsTotalNumbersRequest = {
    customerNo?: string
}

export type ShowReturnsTotalNumbersResponse = {
    numberOfParts: number
    numberOfPositions: number
    numberOfPartsInReturn: number
    numberOfPositionsInReturn: number
}

export type CisShowCustomerRequest = {
    customerNo?: string
}

export type CisCustomerResponse = {
    account?: CisAccount
    addresses?: CisAddress[]
    applicationInformation: CisApplicationInformation
    companyName: string
    companyNameAddition1?: string
    companyNameAddition2?: string
    conceptCustomers?: CisConceptCustomer[]
    contacts?: CisContact[]
    cooperationGroup?: string
    customerGroup?: string
    customerNo: string
    customerNumberToShow?: string
    electronicAddresses?: CisElectronicAddress[]
    information?: string
    nextTourInfo?: string
    notes?: CisNote[]
    requestedCustomerNo?: string // In some cases (PV/Neimcke) is the requested customerNo not equals to the real customerNo, but we need to compare the requested one later.
    salesOutlets?: CisSalesOutlet[]
    shippingTypes?: CisShippingType[]
    state?: CisCustomerState
    stateDescription?: string
    logos?: CisLogo[]
}

export type CisLogo = {
    type: number // Undefined = 0,   CompanyLogo = 1
    url: string
    description: string
}

export type CisFindCustomersRequest = {
    query: string
    queryTypeId: number
}

export enum CisCustomerState {
    Undefined = 0, // Undefined State
    Normal = 1, // Normal customer without any special state
    Suspended = 2, // Attention! Do not sell any parts to this customer.
    Critical = 3, // Attention! Check details for this customer carefully.
    TSMBlocked = 4, // Attention! User is TSM Blocked and do not sell any parts to this customer.
    Notification = 5, // Display only notifications
}

export type CisFindCustomerDto = {
    partnerNo: string
    customerNumberToShow?: string
    company: string
    street: string
    zip: string
    city: string
    contactFirstName?: string
    contactLastName?: string
    phone?: string
    information?: string
    state?: CisCustomerState
    stateDescription?: string
    companyNameAddition1: string
    companyNameAddition2: string
}

export type CisQueryType = {
    description: string
    id: number
    showSingleDateFilter?: boolean
}

export type CisConceptCustomer = {
    id: string
    description?: string
    iconUrl: string
    redirectUrl?: string
}

export type CisAccount = {
    accountId: string
    creditInfo?: CisCreditInfo
    balanceDue?: string
    billingType?: CisBillingType
    currencyCode: string
    information?: string
}

export type CisCreditInfo = {
    limit: string
    drawn: string
}

export type CisBillingType = {
    typeId: number
    description: string
}

export type CisAddress = {
    description: string
    street: string
    streetNo?: string
    addressAddition?: string
    city: string
    zip: string
    postOfficeBox?: string
    country: string
    phone?: string
}

export type CisElectronicAddress = {
    type: number
    description: string
    value: string
}

export type CisShippingType = {
    uniqueId: string
    description: string
    price: number
    currencyCode: string
    isDefault: boolean
}

export type CisNote = {
    description: string
    iconUrl: string
    url: string
    urlText: string
    imageUrl: string
    text: string
    type?: CisNoteType
}

export enum CisNoteType {
    Undefined = 0,
    Warning = 1,
    Information = 2,
    WarehouseInformation = 3,
}

export type CisSalesOutlet = {
    name: string
    address: CisAddress
    contacts: CisContact[]
    electronicAddresses?: CisElectronicAddress[]
    description: string
    id: string
    notes: CisNote[]
    lastOrderDateTime: Date
    isDefault: boolean
    businessTimes: CisBusinessTime[]
    businessTimeDescriptions: BusinessTimeDescription[]
    isTireWarehouse: boolean
    shopHoursToday: string
}

export type CisContact = {
    id: string
    contactDescription: string
    lastName: string
    firstName: string
    jobDescription: string
    electronicAddresses?: CisElectronicAddress[]
}

export type CisBusinessTime = {
    weekdayAbbreviation: string
    worktimes?: WorkHours[]
    info?: string
}

export type WorkHours = {
    openingTime: string
    closingTime: string
}

export type BusinessTimeDescription = {
    days: string
    times: string
}

export type CisCustomerVoucherType = {
    typeId: number
    description: string
}

export type CisTour = {
    tourNumber: string
    tourDescription: string
    orderAcceptanceUntil?: Date
    tourStartTime?: Date
    messageId: string
    messageDescription: string
    status: string
}

export type CisApplicationInformation = {
    edition?: string
    mode?: string
}

export type ShowOrdersByArticleRequest = {
    pageIndex?: number
    pageSize?: number
    itemId?: string
    orderDate?: Date
    orderNumber?: string
    orderStatus?: string
    customerNo?: string
}

export type ShowOrdersByArticleResponse = Paging & {
    ordersByArticle: OrderByArticleListItem[]
    messageId: string
    messageDescription: string
}

export type OrderByArticleListItem = {
    voucherId: string
    voucherType: CisCustomerVoucherType
    orderNumber: string
    orderType: string
    orderStatus: string
    wholesalerArticleNumber: string
    itemDescription: string
    orderQuantity: string
    positionStatus: string
    positionStatusVoucherTypeId: number
    orderDate?: Date
    orderSubNumber: string
    warehouseId: string
}

export type ReturnReason = {
    id?: number
    description?: string
    isReturnInfoRequired?: boolean
}

export type ShowCisOptionsRequest = {
    customerNo?: string
}

export type ShowCisOptionsResponse = {
    dateFilterDayPreset?: number
    displayBehaviour: DisplayBehaviour
    queryTypes?: CisQueryType[]
    returnReasons?: ReturnReason[]
    voucherTypes?: CisCustomerVoucherType[]
}

export type AddReturnItemRequest = {
    dataSupplierId?: number
    dataSupplierName?: string
    dataSupplierArticleNumber?: string
    wholesalerArticleNumber?: string
    productGroupId?: number
    productGroupName?: string
    description?: string
    thumbnailUrl?: string
    returnQuantity: number
    deliveryNote?: string
    invoice?: string
    orderNo?: string
    positionNumber?: number
    orderDate?: Date
    voucherItemId?: string
    voucherId?: string
    voucherTypeId?: number
}

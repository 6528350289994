import { memo } from "react"
import { Box, styled } from "@mui/material"
import { keyframes } from "@mui/system"

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg);
  }
`

const ModernSpinner = styled(`div`)(({ theme }) => ({
    width: "40px",
    height: "40px",
    border: `solid 6px ${theme.palette.primary.main}`,
    borderRadius: "50%",
    borderTopColor: "transparent",
    borderBottomColor: "transparent",
    animation: `${spin} 1s infinite`,
}))

const CenterWrapper = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
}))

export const Spinner = memo(() => {
    return (
        <CenterWrapper>
            <ModernSpinner />
        </CenterWrapper>
    )
})

import { Icon, Image } from "../../.."

export type AvailabilityDescriptionProps = {
    baseName: string
    iconname?: string
    imageUrl?: string
    description?: string
}

export function AvailabilityDescription({ baseName, iconname, imageUrl, description }: AvailabilityDescriptionProps) {
    if (!description && !iconname && !imageUrl) {
        return null
    }

    let imageElement
    if (imageUrl) {
        imageElement = <Image url={imageUrl} className={`${baseName}__image`} />
    } else if (iconname) {
        imageElement = <Icon name={iconname} size="xs" />
    }

    return (
        <>
            {!!imageElement && <div className={`${baseName}__icon`}>{imageElement}</div>}
            {description && <div className={`${baseName}__text`}>{description}</div>}
        </>
    )
}

import { Stack } from "@mui/material"
import { BasketAmountField } from "../AddToBasket/BasketAmountField"
import { ReplaceButton } from "../ReplaceButton"

type ReplaceButtonWithQuantityProps = {
    originalQuantity: number
    handleChangeQuantity: (value: number, loadErpInfo?: boolean) => void
    handleReplaceArticle: () => void
    hasSuggestedQuantity?: boolean
    division?: number
}

export function ReplaceButtonWithQuantity(props: ReplaceButtonWithQuantityProps) {
    const { handleChangeQuantity, originalQuantity, hasSuggestedQuantity, handleReplaceArticle, division } = props

    return (
        <Stack direction="row" className="addToBasket">
            <BasketAmountField
                originalQuantity={originalQuantity}
                division={division ?? 1}
                handleChangeQuantity={handleChangeQuantity}
                hasSuggestedQuantity={hasSuggestedQuantity}
                loadErpInfos
                showQuantityWithDecimals={originalQuantity % 1 !== 0}
            />
            <ReplaceButton onClick={handleReplaceArticle} />
        </Stack>
    )
}

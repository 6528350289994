import { Grid, styled } from "@mui/material"
import { StyledComponent } from "@emotion/styled"
import { CustomIconButtonProps, IconButton } from "../../IconButton"

export const ExpandIconButton = styled(IconButton)(({ theme, hoverEffect }) => ({
    border: "1px solid #b4b4b4",
    height: "auto",
    width: "auto",
    borderRadius: "3px",
    padding: "2px",

    svg: {
        width: "16px",
        height: "16px",
        minHeight: "16px",
        minWidth: "16px",
    },

    ...(hoverEffect && { "&:hover": { borderColor: theme.palette.primary.main } }),
})) as StyledComponent<CustomIconButtonProps>

export const GridItem = styled(Grid)({
    position: "relative",
    paddingRight: "12px",
    alignSelf: "stretch",
    "::after": {
        content: '" "',
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        borderRight: "solid 1px #c8c6c6",
    },
}) as typeof Grid

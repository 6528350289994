import { RepairTimeProvider, TmaEModule, UserInteractionLog } from "@tm/models"
import { TmaEventTracking } from "../.."
import { uniqueId } from "../../.."
import { getCatalogOrigin } from "../../helper/origin"

function getCatalogOriginByRepairTimeProvider(repairTimeProvider: RepairTimeProvider) {
    switch (repairTimeProvider) {
        case RepairTimeProvider.AwDocCar:
            return TmaEModule.REPTIMES_AWDOC
        case RepairTimeProvider.Autodata:
            return TmaEModule.REPTIMES_AUTODATA
        case RepairTimeProvider.HaynesProCar:
            return TmaEModule.REPTIMES_HAYNES
        case RepairTimeProvider.TecRmiCar:
            return TmaEModule.REPTIMES_AUDACON
        default:
            return TmaEModule.UNDEFINED
    }
}

function isRepairTimeModule(repairTimeProvider: RepairTimeProvider, origin?: TmaEModule) {
    origin = origin ?? getCatalogOrigin()

    return origin === getCatalogOriginByRepairTimeProvider(repairTimeProvider)
}

export const AddRepairTimeListEventHelper = {
    AddRepairTimeList: {
        GetUserInteractionLog: (repairTimeProvider: RepairTimeProvider): UserInteractionLog => {
            const origin = getCatalogOrigin()

            if (isRepairTimeModule(repairTimeProvider, origin)) {
                return <UserInteractionLog>{
                    eventId: uniqueId(),
                    origin,
                }
            }

            const articleListFilteredRequest = TmaEventTracking.getRequestData("ARTICLE_LIST_FILTERED")

            if (articleListFilteredRequest?.searchStep) {
                return <UserInteractionLog>{
                    eventId: uniqueId(),
                    id: articleListFilteredRequest.searchStep!.processId,
                    stepNumber: articleListFilteredRequest.searchStep!.number,
                    fromPageNumber: articleListFilteredRequest.articleList?.paging.pageIndex,
                    fromPositionNumber: undefined,
                    origin: articleListFilteredRequest.origin ?? origin,
                }
            }

            return <UserInteractionLog>{
                eventId: uniqueId(),
                origin,
            }
        },
    },
}

import { Omit } from "@tm/utils"
import { Component, ComponentClass, ComponentType, createElement } from "react"
import { UserProviderContext } from "../model/UserProviderContext"
import { User } from "../model"

export type WithChangeUserSettingProps = Pick<
    User,
    | "userSettings"
    | "reloadUserSettings"
    | "setUserSetting"
    | "changeLogo"
    | "changePrintLogo"
    | "removeLogo"
    | "changeHourlyRates"
    | "updateRepairShop"
    | "changeRepairTimeOptions"
    | "changeOrderOptions"
    | "changeDirectBuyOptions"
>

export function withChangeUserSetting<P extends WithChangeUserSettingProps>(
    component: ComponentType<P>
): ComponentClass<Omit<P, keyof WithChangeUserSettingProps>> {
    return class extends Component<Omit<P, keyof WithChangeUserSettingProps>> {
        constructor(props: Omit<P, keyof WithChangeUserSettingProps>) {
            super(props)
            this.renderInnerComponent = this.renderInnerComponent.bind(this)
        }

        renderInnerComponent(user: User) {
            const currentProps: any = this.props || {}

            const props: P & WithChangeUserSettingProps = {
                ...currentProps,
                userSettings: user.userSettings,
                reloadUserSettings: user.reloadUserSettings,
                setUserSetting: user.setUserSetting,
                changeLogo: user.changeLogo,
                changePrintLogo: user.changePrintLogo,
                removeLogo: user.removeLogo,
                changeHourlyRates: user.changeHourlyRates,
                updateRepairShop: user.updateRepairShop,
                changeRepairTimeOptions: user.changeRepairTimeOptions,
                changeOrderOptions: user.changeOrderOptions,
                changeDirectBuyOptions: user.changeDirectBuyOptions,
                changeCostEstimationOptions: user.changeCostEstimationOptions,
            }

            return createElement(component, props)
        }

        render() {
            return <UserProviderContext.Consumer>{this.renderInnerComponent}</UserProviderContext.Consumer>
        }
    }
}

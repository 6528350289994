import { SubscriptionEvents, IModelSubscription, IContainerBundleLoader, IContainerBundleUpdater, IMergable } from "../../models"
import { base64ToObject } from "../../tools/base64"

interface IMergableModelSubscription<SCModel>
    extends IModelSubscription<SCModel>,
        IMergable<ModelSubscription<SCModel>, IModelSubscription<SCModel>> {}

export type ListenerFunctions<SCModel> = {
    onSuccess: (object: SCModel) => void
    onError?: (object: any) => void
}

/**
 * Is the given contract for subscriptions for a specific model
 * Can be merged
 *
 * Rename to Contracts
 */
export class ModelSubscription<SCModel> implements IMergableModelSubscription<SCModel> {
    listeners: {
        [key: string]: Array<ListenerFunctions<SCModel> | undefined>
    }

    _id: string

    identifier: any[]

    _proceedLoad?: IContainerBundleLoader<SCModel>

    _proceedUpdate?: IContainerBundleUpdater<SCModel>

    _proceedLoadPromise?: Promise<SCModel>

    constructor(id: string, loader: IContainerBundleLoader<SCModel>, updater: IContainerBundleUpdater<SCModel>) {
        this.listeners = {
            loaded: [],
            updated: [],
            loading: [],
            updating: [],
        }

        this._id = id
        this._proceedLoad = loader
        this._proceedUpdate = updater
        this.identifier = base64ToObject(id)
    }

    addListener(event: SubscriptionEvents, onSuccess: (object: SCModel) => void, onError?: (object: any) => void): () => void {
        const eventIndex = this.listeners[event].push({ onSuccess, onError }) - 1 // push returning
        return () => {
            this.listeners[event][eventIndex] = undefined
        }
    }

    removeAllListeners(): void {
        Object.keys(this.listeners).forEach((eventKey) => {
            this.listeners[eventKey] = []
        })
    }

    save = (object: SCModel) => {
        if (!this._proceedUpdate) {
            return
        }

        try {
            const prom = this._proceedUpdate(this._id, object) // add promises to catch failed saving, reload old value and notify new udpate, mb
            if (prom && prom.then) {
                this.notifyUpdating(this._id)
                prom.then((response) => this.notifySave(response || object), this.notifySaveError.bind(this))
            } else {
                this.notifySave(object)
            }
        } catch (e) {
            this.notifySaveError(e)
        }
    }

    loadIfRequired = () => {
        if (Object.keys(this.listeners).some((key) => this.listeners[key].some((l) => !!l))) {
            this.load()
        }
    }

    load = () => {
        return new Promise<SCModel>((resolve, reject) => {
            if (!this._proceedLoad) {
                console.warn("model contract can't proceed load")
                reject()
            } else {
                const proceedLoadPromise = this._proceedLoad(this._id)

                if (!this._proceedLoadPromise) {
                    this._proceedLoadPromise = proceedLoadPromise

                    this.notifyLoading(this._id)
                    proceedLoadPromise.then(
                        (response) => {
                            this.notifyLoaded(response)
                            this._proceedLoadPromise = undefined
                        },
                        (e) => {
                            this.notifyLoadedError(e)
                            this._proceedLoadPromise = undefined
                        }
                    )
                }

                return proceedLoadPromise.then(resolve, reject)
            }
        })
    }

    notifyLoaded = (object: SCModel) => {
        this.notifyListenersSuccess(this.listeners.loaded, object)
    }

    notifySave(object: SCModel) {
        this.notifyListenersSuccess(this.listeners.updated, object)
    }

    notifyLoadedError = (e: any) => {
        this.notifyListenersError(this.listeners.loaded, e)
    }

    notifySaveError = (e: any) => {
        this.notifyListenersError(this.listeners.updated, e)
    }

    notifyLoading = (id: any) => {
        this.notifyListenersSuccess(this.listeners.loading, id)
    }

    notifyUpdating = (id: any) => {
        this.notifyListenersSuccess(this.listeners.updating, id)
    }

    notifyListenersSuccess = (listeners: Array<ListenerFunctions<SCModel> | undefined>, object: SCModel) => {
        listeners.forEach((listener) => {
            if (listener) {
                listener.onSuccess(object)
            }
        })
    }

    notifyListenersError = (listeners: Array<ListenerFunctions<SCModel> | undefined>, e: any) => {
        listeners.forEach((listener) => {
            if (listener && listener.onError) {
                listener.onError(e)
            }
        })
    }

    merge(sourceContract: ModelSubscription<SCModel>): IModelSubscription<SCModel> {
        this._proceedLoad = sourceContract._proceedLoad
        this._proceedUpdate = sourceContract._proceedUpdate

        return this
    }
}

import { useQuery } from "react-query"
import { DecimalAmountItem } from "./decimal/decimal-amount-field"

export function useDecimalItems(
    maxAmount: number,
    minAmount: number,
    division: number,
    unit: string | undefined,
    inputPrecision: number | undefined
) {
    const { data } = useQuery(
        ["controls_decimal-amount-field_items", maxAmount, minAmount, division, unit, inputPrecision],
        () => {
            return Promise.resolve() // Force async execution
                .then(() => {
                    const items: Array<DecimalAmountItem> = []
                    let numberDecimalPlaces = 2

                    const decimalParts = (inputPrecision || 0.01).toString().split(".")
                    numberDecimalPlaces = decimalParts.length > 1 ? (decimalParts.last() as string).length : numberDecimalPlaces
                    const division = inputPrecision || 1
                    for (let i = division || 1; i <= maxAmount; i += division) {
                        if (i >= minAmount) {
                            items.push({
                                maxAmount,
                                minAmount,
                                value: parseFloat(i.toFixed(numberDecimalPlaces)),
                                stepSize: 1,
                                inputPrecision: inputPrecision || 2,
                                unit,
                            })
                        }
                    }

                    return items
                })
        },
        { staleTime: Infinity }
    )

    return { items: data }
}

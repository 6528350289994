import { styled } from "@mui/material"
import { ComponentType } from "react"
import { Image, ImageProps } from "../../../../generics/image"

export const ThumbnailImage = styled(Image)(() => {
    return {
        display: "block",
        objectFit: "scale-down",
        userSelect: "none",
        transform: "none",
        transformOrigin: "top left",
        transition: "transform 0.1s ease",
        width: "100%",
        height: "100%",
        "&.error-image": {
            display: "block",
            textAlign: "center",

            svg: {
                width: "30%",
            },
        },
        "&:not(.image-viewer__thumbnail--selected)": {
            cursor: "pointer",
        },
    }
}) as ComponentType<ImageProps>

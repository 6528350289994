import { Box, Skeleton, Stack, styled } from "@mui/material"
import { ArticleListV2DisplayBehavior, PriceType, SuccessArticleErpInfo } from "@tm/models"
import { useMemo } from "react"
import { classes } from "@tm/utils"
import { useArticleItemState } from "../ContextProviderStates"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Availabilities } from "./Availabilities"
import { Costs } from "./Costs"
import { ExtendedDelivery } from "./ExtendedDelivery"
import { HTMLInformation } from "./HTMLInformation"
import { OtherPrices } from "./OtherPrices"
import { PriceUnit } from "./PriceUnit"
import { ErpPriceWithAdditionalInfo, Prices } from "./Prices"
import { ErpAreaActions } from "../Actions/ErpAreaActions"

const COMPACTMODE_ALIGN_BOTTOM = "end"

export const ErpIconsWrapper = styled(Stack)({
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "4px",

    "&:empty": {
        display: "none",
    },
})

export function DefaultArticleErpContainer() {
    const { requestErpInfo } = useDefaultArticleItemState((x) => x.article)

    if (!requestErpInfo) {
        return null
    }

    return <ErpContainer />
}

export function OePartErpContainer() {
    return <ErpContainer hideExtendedDelivery />
}

export function WholesalerErpContainer() {
    return <ErpContainer />
}

type ErpContainerProps = {
    hideExtendedDelivery?: boolean
}

function ErpContainer(props: ErpContainerProps) {
    const articleErpInfos = useArticleItemState((x) => x.articleErpInfos)
    const isExpanded = useDefaultArticleItemState((x) => x.expanded)
    const defaultArticleErpInfo = articleErpInfos?.default
    const alternativeArticleErpInfo = articleErpInfos?.alternative

    const className = useMemo(() => {
        return `defaultErp-${defaultArticleErpInfo?.state ?? "no"} altErp-${alternativeArticleErpInfo?.state ?? "no"}`
    }, [defaultArticleErpInfo?.state, alternativeArticleErpInfo?.state])

    if (!defaultArticleErpInfo && !alternativeArticleErpInfo) {
        return <Box height={32} />
    }

    if (
        (defaultArticleErpInfo?.state && ["queued", "loading"].includes(defaultArticleErpInfo?.state)) ||
        (!defaultArticleErpInfo && alternativeArticleErpInfo && ["queued", "loading"].includes(alternativeArticleErpInfo?.state))
    ) {
        return (
            <Stack direction="row" justifyContent="space-between" className={classes("erpContainer", "erpContainer-loading", className)}>
                <Stack spacing="2px">
                    <Skeleton variant="rounded" width={80} height={15} />
                    <Skeleton variant="rounded" width={80} height={15} />
                </Stack>
            </Stack>
        )
    }

    // in case of oeArticles, the isExpanded flag is set to undefined, i can't find out where it set to undefined...
    // and if it is set to undefined, the extraCost are not displayed!
    return (
        <ErpContainerComponent
            className={className}
            {...props}
            isExpanded={isExpanded ?? true}
            defaultArticleErpInfo={defaultArticleErpInfo?.state === "success" ? defaultArticleErpInfo : undefined}
            alternativeArticleErpInfo={alternativeArticleErpInfo?.state === "success" ? alternativeArticleErpInfo : undefined}
        />
    )
}

type ErpContainerComponentProps = ErpContainerProps & {
    isExpanded?: boolean
    defaultArticleErpInfo?: SuccessArticleErpInfo
    alternativeArticleErpInfo?: SuccessArticleErpInfo
    className?: string
}

function ErpContainerComponent({
    hideExtendedDelivery,
    defaultArticleErpInfo,
    alternativeArticleErpInfo,
    isExpanded,
    className,
}: ErpContainerComponentProps) {
    const isPriceTypeHidden = useDefaultArticleItemState((x) => x.options.isPriceTypeHidden)
    const displayTile = useDefaultArticleItemState((x) => x.displayBehavior) === ArticleListV2DisplayBehavior.Tile

    const defaultErpInfo = defaultArticleErpInfo?.response

    const mainPricesWithAdditionalInfo = useMemo(() => {
        const prices =
            defaultErpInfo?.prices
                ?.filter((x) => x.type)
                .map<ErpPriceWithAdditionalInfo>((x) => ({
                    ...x,
                    onlyShowInfo: isPriceTypeHidden(x.type),
                })) ?? []
        if (prices.length > 2 && !isExpanded) {
            // only show first 2 prices in compact view
            return prices.slice(0, 2)
        }
        return prices
    }, [defaultErpInfo?.prices, isPriceTypeHidden, isExpanded])

    const purchasePrice = useMemo(() => defaultErpInfo?.prices?.filter((price) => price.type === PriceType.Purchase)[0], [defaultErpInfo?.prices])

    const otherPrices = useMemo(() => defaultErpInfo?.prices?.filter((price) => !price.type && !!price.description) ?? [], [defaultErpInfo?.prices])

    const alignItems = isExpanded ? undefined : COMPACTMODE_ALIGN_BOTTOM

    return (
        <Stack flex={1} gap={1} className={classes("erpContainer", className)} sx={{ ...(isExpanded || displayTile ? {} : { minWidth: "15em" }) }}>
            <Stack className="erpContainerGrid" display="grid" gridTemplateColumns="auto auto" columnGap={1} rowGap="2px" alignItems={alignItems}>
                <Stack direction="row" className="availabilityContainer">
                    <Availabilities
                        defaultArticleErpInfo={defaultArticleErpInfo}
                        alternativeArticleErpInfo={alternativeArticleErpInfo}
                        isCompact={!isExpanded}
                    />
                </Stack>
                <Stack direction="row" justifyContent="end" alignItems="center" gap={1}>
                    {!isExpanded && <ErpAreaActions />}
                    {!!mainPricesWithAdditionalInfo.length && (
                        <Stack>
                            <Prices prices={mainPricesWithAdditionalInfo} isCompact={!isExpanded} />
                            {purchasePrice && <PriceUnit purchasePrice={purchasePrice} />}
                        </Stack>
                    )}
                </Stack>
            </Stack>
            {isExpanded && (
                <>
                    {!!otherPrices.length && (
                        <Box alignSelf="flex-end">
                            <OtherPrices prices={otherPrices} />
                        </Box>
                    )}
                    {!!defaultErpInfo?.costs?.length && <Costs costs={defaultErpInfo.costs} />}
                    {!hideExtendedDelivery && defaultErpInfo?.isTeccomRequestAvailable && <ExtendedDelivery />}

                    <ErpIconsWrapper>
                        <ErpAreaActions />
                    </ErpIconsWrapper>

                    {defaultErpInfo?.additionalInformationExtended && (
                        <HTMLInformation additionalInformationExtended={defaultErpInfo?.additionalInformationExtended} />
                    )}
                </>
            )}
        </Stack>
    )
}

import { PropsWithChildren, PureComponent, RefObject, createRef } from "react"
import { PromptProps } from "./models"
import Dialog from "."

export type QuestionPromptProps = PropsWithChildren<Omit<PromptProps, "ref">>

export default class QuestionPrompt extends PureComponent<QuestionPromptProps> {
    dialogRef: RefObject<Dialog> = createRef()

    show() {
        this.dialogRef.current?.show()
    }

    render() {
        return (
            <Dialog
                {...this.props}
                skin="primary"
                confirmButtonText={this.props.confirmationButtonText}
                cancelButtonText={this.props.cancelButtonText}
                iconName="question"
                closeOnlyWithDialogInteraction
                ref={this.dialogRef}
            >
                {this.props.children}
            </Dialog>
        )
    }
}

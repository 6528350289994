import { UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { getParameterFromUserContextModule } from "../../helper"
import { useUser } from "../../user"
import { useTyresV2 } from "./useTyresV2"

export function useNewTyreSearch() {
    const { userContext } = useUser() || {}
    const tyresV2 = useTyresV2()
    const tyresSearchParameter = getParameterFromUserContextModule(userContext, UserModuleType.TMTires, "TyreSearch")

    const hasTyreSearch = useMemo(() => tyresSearchParameter === "ListView", [tyresSearchParameter])
    return tyresV2 ? hasTyreSearch : true
}

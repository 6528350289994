import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { ArticleReferenceType } from "@tm/models"
import { Stack } from "@mui/material"
import { ReferenceGroup } from "./ReferenceGroup"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

export function References() {
    const { translateText } = useLocalization()

    const articleReferences = useDefaultArticleItemState((x) => x.displayArticle.references)
    const references = useMemo(() => articleReferences ?? [], [articleReferences])

    const refsAlternativeArticleNo = useMemo(
        () => references.filter((x) => x.referenceType === ArticleReferenceType.AlternativeArticleNo),
        [references]
    )
    const refsEurocode = useMemo(() => references.filter((x) => x.referenceType === ArticleReferenceType.Eurocode), [references])
    const refsUtility = useMemo(() => references.filter((x) => x.referenceType === ArticleReferenceType.UtilityNo), [references])
    const refsReplacement = useMemo(() => references.filter((x) => x.referenceType === ArticleReferenceType.ReplacementArticleNo), [references])
    const refsReplaced = useMemo(() => references.filter((x) => x.referenceType === ArticleReferenceType.ReplacedByArticleNo), [references])

    if (!refsAlternativeArticleNo.length && !refsEurocode.length && !refsUtility.length && !refsReplacement.length && !refsReplaced.length) {
        return null
    }

    return (
        <Stack gap={0.5} mt={1}>
            <ReferenceGroup references={refsAlternativeArticleNo} label={refsAlternativeArticleNo[0]?.description || ""} />
            <ReferenceGroup references={refsEurocode} label={refsEurocode[0]?.description ?? ""} />
            <ReferenceGroup references={refsUtility} label={translateText(132)} />
            <ReferenceGroup references={refsReplacement} label={translateText(685)} />
            <ReferenceGroup references={refsReplaced} label={translateText(686)} />
        </Stack>
    )
}

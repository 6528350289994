import { encodeUniqueId } from "../../helpers/id"

export function getWorkTaskId() {
    let wkid = (window as any).__NEXT_WORKTASKID__ && encodeUniqueId((window as any).__NEXT_WORKTASKID__)
    if (!wkid) {
        wkid = getWorkTaskIdFromLocation()
    }
    return wkid
}

export function getWorkTaskIdFromLocation(): string | undefined {
    const match = /^\/[\d\w]{22}\/?/.exec(document.location.pathname)
    let workTaskId: string | undefined

    if (match) {
        workTaskId = match[0].replace(/\//g, "")
    }

    return workTaskId
}

const RemovableParams = ["forceReload", "history", "searchFilter"]
/**
 * @description removes specific params, like forceReload from context url
 * @param contextId string
 * @returns qualified contextId
 */
export function cleanContextUrl(contextId: string): string {
    if (contextId.indexOf("?") < 0) {
        return contextId
    }

    const params = new URLSearchParams(contextId)
    RemovableParams.forEach((param) => params.delete(param))

    return decodeURIComponent(params.toString())
}

import { Stack, styled } from "@mui/material"
import { ImgHTMLAttributes } from "react"
import { ErpIconType } from "@tm/models"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { IconAction } from "./IconAction"
import { Image } from "../../../generics/image"

type ImageAreasIconImageProps = ImgHTMLAttributes<HTMLImageElement> & {
    isExpanded?: boolean
}
const ImageAreaImageIcon = styled(Image, { shouldForwardProp: (prop: string) => prop !== "isExpanded" })<ImageAreasIconImageProps>(
    ({ isExpanded }) => ({
        maxWidth: isExpanded ? 80 : 40,
        objectFit: "contain",
        display: "block",
    })
)

export function ImageAreaActions() {
    const articleActions = useDefaultArticleItemState((x) => x.articleActions)
    const isExpanded = useDefaultArticleItemState((x) => x.expanded)

    return (
        <Stack gap={1} direction="row">
            {articleActions?.imageArea.map((action) =>
                action.useIconFromUrl ? (
                    <ImageAreaImageIcon src={action.icon} isExpanded={isExpanded} />
                ) : (
                    <IconAction key={ErpIconType[action.iconType]} action={action} />
                )
            )}
        </Stack>
    )
}

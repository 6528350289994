import bem from "bem-classnames"
import { Component, ReactNode } from "react"
import Text from "../text"
import Icon from "../icon"
import { Size } from "../../models/skins"
import Button from "../button"

type Skins = "warning" | "danger" | "success" | "information" | "error"

export type MessageInlineProps = {
    skin?: Skins
    message: NonNullable<ReactNode>
    description?: ReactNode
    icon?: string
    size?: Size
    iconSize?: Size
    onButtonClick?(action: any): void
}

export default class MessageInline extends Component<MessageInlineProps, any> {
    private static defaultProps: Partial<MessageInlineProps> = {
        size: "m",
        iconSize: "xl",
    }

    render() {
        const { skin, description, message, onButtonClick, icon, iconSize, size } = this.props

        return (
            <div className={bem(style.default, { ...this.props, long: !!message })}>
                <div className={bem(style.icon, { ...this.props })}>
                    <Icon name={icon || this.setIcon(skin)} size={iconSize} />
                </div>
                <div className={bem(style.textWrapper, this.props)}>
                    <Text size={size} modifiers={["strong"]} className={bem(style.message, this.props)}>
                        {message}
                    </Text>
                    <Text size={size}>{description}</Text>
                </div>
                {onButtonClick && (
                    <Button className={bem(style.closeButton, this.props)} size={iconSize} icon="close" layout={["ghost"]} onClick={onButtonClick} />
                )}
            </div>
        )
    }

    setIcon(skin?: Skins) {
        let iconName
        switch (skin) {
            case "warning": {
                iconName = "attention-dark"
                break
            }
            case "danger": {
                iconName = "not"
                break
            }
            case "success": {
                iconName = "success-dark"
                break
            }
            case "error": {
                iconName = "not"
                break
            }
            case "information":
            default: {
                iconName = "information-light"
                break
            }
        }
        return iconName
    }
}

const componentClassName = "message-inline"

const style = {
    default: {
        name: `${componentClassName}`,
        modifiers: ["long", "skin", "size"],
    },
    icon: {
        name: `${componentClassName}__icon`,
        modifiers: ["iconSize", "icon"],
    },
    description: {
        name: `${componentClassName}__description`,
    },
    textWrapper: {
        name: `${componentClassName}__text-wrapper`,
    },
    message: {
        name: `${componentClassName}__message`,
    },
    closeButton: {
        name: `${componentClassName}__close-button`,
    },
}

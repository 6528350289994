import { LocalizationConfig } from "../models"
import { translate, translateText } from "./translate"

export function getWeekDays(config: LocalizationConfig): Array<string> {
    return [
        translateText(config, 533),
        translateText(config, 527),
        translateText(config, 528),
        translateText(config, 529),
        translateText(config, 530),
        translateText(config, 531),
        translateText(config, 532),
    ]
}

export function getMonths(config: LocalizationConfig): Array<string> {
    return [
        translateText(config, 507),
        translateText(config, 508),
        translateText(config, 509),
        translateText(config, 510),
        translateText(config, 511),
        translateText(config, 512),
        translateText(config, 513),
        translateText(config, 514),
        translateText(config, 515),
        translateText(config, 516),
        translateText(config, 517),
        translateText(config, 518),
    ]
}

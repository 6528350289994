import { forwardRef, ImgHTMLAttributes, useEffect, useState } from "react"

export type Props = ImgHTMLAttributes<HTMLImageElement> & {
    url: string
    className?: string
    type?: FallbackImageType
}

export type FallbackImageType = "article" | "manufacturer" | "car" | "bike"

export default forwardRef<HTMLImageElement, Props>(function Image(props, ref) {
    const [error, setError] = useState(false)

    useEffect(() => {
        setError(false)
    }, [props.url])

    function handleError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        setError(true)
        props.onError?.(e)
    }

    function renderErrorImage() {
        let fallback = FALLBACK_SVG

        switch (type) {
            case "article":
                fallback = FALLBACK_SVG_ARTICLE
                break
            case "manufacturer":
                fallback = FALLBACK_SVG_MANUFACTURER
                break
            case "car":
                fallback = FALLBACK_SVG_CAR
                break
            case "bike":
                fallback = FALLBACK_SVG_BIKE
                break
        }

        return (
            <span className={`error-image error-image__${type} ${className || ""}`} title={title}>
                {fallback}
            </span>
        )
    }

    // Pull out all know props and the rest ("img"-props) into an seperate variable
    const { url, className, type, title, ...rest } = props

    if (error || !url) {
        return renderErrorImage()
    }

    return <img {...rest} ref={ref} className={`image${className ? ` ${className}` : ""}`} src={url} onError={handleError} title={title} />
})

const FALLBACK_SVG_ARTICLE = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126 121">
        <path
            fill="#767676"
            fillRule="evenodd"
            opacity=".2"
            d="M24 100h78V51H24v49zm17-78h17v20H28l13-20zm57 20H68V22h17l13 20zm14 4.2a4.8 4.8 0 00-.4-1.6l-.1-.4-.3-.6-19.6-29.4a4.9 4.9 0 00-4.1-2.2h-49a5 5 0 00-4 2.2L14.7 43.6l-.3.6-.1.4a4.8 4.8 0 00-.4 1.6v58.9c0 2.7 2.2 4.9 4.9 4.9h88.2c2.7 0 4.9-2.2 4.9-4.9V46.3z"
        />
    </svg>
)

const FALLBACK_SVG_MANUFACTURER = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
            fillRule="evenodd"
            d="M20.52 16.11h2v-2.06h-2v2.06zm-3 0h2v-2.06h-2v2.06zm-3 0h1.98v-2.06h-1.99v2.06zm-4.65 1.52a1.66 1.66 0 01-.6.76l-.36.26a1.7 1.7 0 00-.3.3.87.87 0 00-.17.42v.32H7.3v-.38a1.74 1.74 0 01.4-1.02c.1-.12.22-.22.33-.3l.31-.25c.1-.09.17-.18.23-.28.06-.1.09-.23.08-.38 0-.26-.06-.45-.19-.58a.73.73 0 00-.52-.18.82.82 0 00-.4.09.79.79 0 00-.27.23c-.07.1-.12.22-.15.35-.04.13-.05.27-.05.43H5.82c.01-.3.06-.59.16-.84a1.95 1.95 0 011.06-1.1c.25-.1.53-.15.84-.15.4 0 .73.06 1 .17.26.1.48.24.64.4a1.5 1.5 0 01.46 1.06c0 .27-.04.5-.1.67zM7.2 21.5H8.5v-1.3H7.18v1.3zm16.88-4.6V8.48l-4.14 2.36V8.48l-4.58 2.36V8.48l-5.89 3.4H8.32V7H5.71v4.88H3v12.41h27V16.9h-5.93z"
        />
    </svg>
)

const FALLBACK_SVG_CAR = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
            fillRule="evenodd"
            d="M25.195 15.889a2.3 2.3 0 010 4.598 2.302 2.302 0 01-2.298-2.3 2.3 2.3 0 012.298-2.298zm-16.716 0a2.3 2.3 0 010 4.598 2.302 2.302 0 01-2.298-2.3 2.3 2.3 0 012.298-2.298zm16.716.874c-.785 0-1.425.639-1.425 1.424 0 .786.64 1.426 1.425 1.426a1.425 1.425 0 000-2.85zm-16.716 0a1.426 1.426 0 000 2.85 1.426 1.426 0 000-2.85zM14.872 11c5.197 0 7.97 2.985 7.97 2.985h2.288c2.367 0 4.224 1.23 4.557 1.417.543.304.214.969.214.969s-.717 1.823-.854 2.153c-.136.329-.465.405-.465.405h-.6a2.885 2.885 0 00-2.787-3.624 2.886 2.886 0 00-2.883 2.882c0 .455.131.887.32 1.27h-11.55c.18-.375.28-.827.28-1.27a2.885 2.885 0 00-2.883-2.882 2.886 2.886 0 00-2.722 3.834c-.465.014-1.317-.15-1.841-.402-.524-.253-.834-.93-.834-.93s-.185-1.31 0-1.493c.184-.185.38-.854.38-.854s-.07-.893-.07-1.357c0-.467.446-.446.446-.446h1.687c.349 0 1.222-.485 1.222-.485S9.675 11 14.872 11zm1.055 6.035H14.69v1.212h1.236v-1.212zm-.591-4.541c-.289 0-.55.048-.783.144a1.772 1.772 0 00-.599.405 1.88 1.88 0 00-.385.615c-.092.235-.14.497-.146.779h1.158c0-.14.015-.275.046-.398a.96.96 0 01.146-.322.736.736 0 01.252-.221.773.773 0 01.366-.082c.21 0 .374.058.492.173.118.116.177.294.177.535a.634.634 0 01-.075.355c-.054.093-.127.18-.216.26-.09.078-.187.156-.292.235-.105.08-.204.173-.299.28a1.563 1.563 0 00-.377.956v.354h1.063v-.298a.784.784 0 01.153-.393c.081-.106.174-.199.28-.28a9.41 9.41 0 01.334-.245 1.548 1.548 0 00.567-.712 1.67 1.67 0 00.098-.622 1.407 1.407 0 00-.424-.984 1.859 1.859 0 00-.603-.382c-.25-.103-.56-.152-.933-.152z"
        />
    </svg>
)

const FALLBACK_SVG_BIKE = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
            fillRule="evenodd"
            d="M24.33 22.46a2.98 2.98 0 01-2.96-2.99 2.96 2.96 0 112.96 2.99zm-6.09-7.38a1.91 1.91 0 01-.72.9l-.43.32a2 2 0 00-.35.36c-.1.13-.17.3-.2.5v.38H15.2v-.45c.02-.29.07-.53.16-.72a2.1 2.1 0 01.7-.86 6 6 0 00.37-.3c.11-.1.2-.21.28-.33.07-.12.1-.27.1-.45 0-.31-.08-.54-.23-.68a.86.86 0 00-.63-.22.98.98 0 00-.47.1.93.93 0 00-.32.28c-.08.12-.15.26-.19.41a2 2 0 00-.06.5h-1.47c0-.35.07-.68.18-.98a2.24 2.24 0 011.25-1.3 3.16 3.16 0 012.19.01c.33.12.58.29.78.48a1.77 1.77 0 01.54 1.26c0 .32-.04.58-.13.79zm-3.2 4.6h1.58v-1.54h-1.58v1.55zm-3.94 1.14c.15-.52.21-1.06.17-1.63l.68-.12c.15.57.24 1.16.27 1.75H11.1zm-3.35 1.43a2.96 2.96 0 01-2.62-5.3c1.27-.76 3-.44 3.81.73l.01.03-2.73 1.12.31 1.21 3.07-.55c.13.99-.73 2.3-1.85 2.76zm20.62-5.05a4.74 4.74 0 00-3.43-2.32c.26-.04.51-.06.76-.08.58-.03.94-.46.85-1.04a14.9 14.9 0 00-.15-.87c-.2-.97-.7-1.8-1.13-2.72a2.43 2.43 0 00-.79-.89c.04-.51.05-1.28-.24-1.28-.27 0-.5.4-.61.79-.6-.29-1.22-.48-1.62-.62a.82.82 0 00-1.05.5c-.16.42.04.87.46 1.07l1.38.51c.35.16.66.4.9.69-.18.07-.32.16-.47.2l-1.07.19-.63-1.13-.82.4a5.58 5.58 0 00-.52-.21c-1-.34-2.7-.43-3.75-.46-1.59-.04-4.16 1.01-4.61 2.45-.03.08-.01.23-.1.25C9.5 13 8.57 11.97 8 10.92a.84.84 0 00-.76-.47H6.7c-2-.02-1.18-.04-3.17-.06-.66 0-1.05.48-.88 1.12.09.33.93.11 1.13.39C4.6 13 5 13.47 6.35 13.64c.42.05.84.03 1.25.11a5.36 5.36 0 013.5 3.07l-.52.21a4.66 4.66 0 00-8.33 3.99 4.66 4.66 0 008.72.17l.84.91h6.58c.56 0 .72-.36.72-.94 0-.86.2-1.7.55-2.49l.13-.24a4.7 4.7 0 003.26 5.53 4.64 4.64 0 005.32-6.76z"
        />
    </svg>
)

const FALLBACK_SVG = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g fill="#767676" opacity=".2">
            <path d="M7 13c0 .5.1 1.1.3 1.6L9 12.8c.1-1.5 1.3-2.7 2.8-2.8l1.7-1.7c-.4-.2-1-.3-1.5-.3-2.8 0-5 2.2-5 5z" />
            <path d="M4 8h4.5l2-3h2.9l1.3 2 1.4-1.4L15 3.9c-.4-.6-1-.9-1.7-.9h-2.9c-.5 0-1.2.3-1.5.9L7.5 6H4a2 2 0 00-2 2v11c0 .2 0 .5.1.7L4 17.8V8zM20.3 6l2.2-2.2-1.1-1.1L2.9 21.2 4 22.4 5.4 21H20a2 2 0 002-2V8c0-1-.7-1.8-1.7-2zm-5.6 5.7c.2.4.3.8.3 1.3 0 1.7-1.3 3-3 3-.5 0-.9-.1-1.3-.3l4-4zM18.4 8H20v11H7.4l1.8-1.8c.8.5 1.8.8 2.8.8 2.8 0 5-2.2 5-5 0-1-.3-2-.8-2.8L18.4 8z" />
        </g>
    </svg>
)

import { PostMessageSourceId, TmaEModule } from "@tm/models"

export function mapSourceIdToTmaModule(fallback: TmaEModule, sourceId?: PostMessageSourceId): TmaEModule {
    switch (sourceId) {
        case "TM_MVC_AD_SERVICE_INTERVALS":
            return TmaEModule.SERVICE_AUTODATA
        case "TM_MVC_HP_AD":
        case "TM_MVC_HP_INSP":
            return TmaEModule.SERVICE_HAYNES
        case "TM_MVC_HP_COMMON":
        case "TM_MVC_HP_FASTTD":
        case "TM_MVC_HP_TD":
            return TmaEModule.TECH_HAYNES
        case "TM_MVC_HP_REPAIR_TIMES":
            return TmaEModule.REPTIMES_HAYNES
        case "TM_MVC_TECRMI_DIRECT_SEARCH":
            return TmaEModule.TYRES_AUDACON
        case "TM_MVC_TECRMI_INSP":
            return TmaEModule.SERVICE_AUDACON
        case "TM_MVC_TECRMI_REPAIR_ITEMS":
            return TmaEModule.REPTIMES_AUDACON
        case "TM_MVC_TECRMI_TD":
            return TmaEModule.TECH_AUDACON
        case "TM_MVC_TEMOT":
            return TmaEModule.GPI_TEMOT
        case "TM_MVC_TMTRUCK":
            return TmaEModule.TRUCKVIN
        case "TM_MVC_TRUCK_GARAGE":
            return TmaEModule.TRUCKGARAGE
        case "TM_MVC_WTC":
            return TmaEModule.WHEEL_CONFIGURATOR
        default:
            return fallback
    }
}

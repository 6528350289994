import { getCurrentWorkTaskId } from "@tm/models"
import { getStoredAuthorization } from "../../helpers/auth"
import { ajax } from "../../helpers/ajax"
import { uniqueId } from "../../helpers/id"
import { EventStorage } from "./event-storage"

export class TmaSender<TRequestBody> {
    storage: EventStorage

    serviceBaseUrl: string

    serviceEndpoint: string

    constructor(serviceEndpoint: string) {
        this.serviceBaseUrl = "/data/TM.Next.Logging"
        this.serviceEndpoint = `${serviceEndpoint.startsWith("/") ? serviceEndpoint : `/${serviceEndpoint}`}`
        this.storage = new EventStorage(this.serviceEndpoint)
    }

    set requestBody(request: TRequestBody) {
        this.storage.set(request)
    }

    get requestBody(): TRequestBody {
        return this.storage.get()
    }

    sendEvent = (requestCopy?: TRequestBody) => {
        const url = `${this.serviceBaseUrl}${this.serviceEndpoint}`
        const authorization = getStoredAuthorization()

        if (!authorization) {
            return Promise.reject("No authorization")
        }

        const body = {
            ...(requestCopy || this.requestBody),
            eventId: uniqueId(),
        }

        return ajax({ url, body, authorization, method: "POST" })
    }

    resetEvent = (data?: TRequestBody) => {
        this.requestBody = data || ({} as TRequestBody)
    }
}

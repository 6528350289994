import { PropsWithChildren } from "react"
import { Shadow, Wrapper } from "./elements"
import { TableVariants } from "./interfaces"

type TableRowProps = PropsWithChildren<{
    colCount: number
    active?: boolean
    onClick?: () => void
    onDoubleClick?: () => void
    rowIndicatorColor?: string
    rowCap?: number
    variant?: TableVariants
    singleRow?: boolean
    transparent?: boolean
    transparentHighlight?: boolean
    rowStyles?: React.CSSProperties
}>

export function TableRow(props: TableRowProps) {
    return (
        <Wrapper
            className={`row ${props.active ? "row-active" : ""}`}
            colCount={props.colCount}
            active={!!props.active}
            onClick={() => props.onClick?.()}
            onDoubleClick={() => props.onDoubleClick?.()}
            rowIndicatorColor={props.rowIndicatorColor}
            singleRow={props.singleRow}
            transparent={props.transparent}
            transparentHighlight={props.transparentHighlight}
            style={props.rowStyles}
        >
            {props.children}

            <Shadow
                colCount={props.colCount}
                rowCap={props.rowCap}
                active={props.active}
                variant={props.variant}
                rowIndicatorColor={props.rowIndicatorColor}
                transparent={props.transparent}
            />
        </Wrapper>
    )
}

import {
    ETyresCarType,
    ETyresSeason,
    GetArticleListByMatchCodeResponse,
    GetArticlesListMappedResponse,
    ETyreCountType,
    TyresSeason,
} from "@tm/models"
import { mapTyreArticleToArticle } from "../mappers"

export function mapArticleListResponse(response: GetArticleListByMatchCodeResponse, defaultQuantity?: number): GetArticlesListMappedResponse {
    return {
        ...response,
        uniArticles: response.uniArticles.map((x) => mapTyreArticleToArticle(x, defaultQuantity)),
    }
}

export const getIconByGroup = (group: ETyresCarType | TyresSeason | ETyreCountType | string) => {
    // eslint-disable-next-line default-case
    switch (group) {
        case ETyresSeason.summer:
            return "sun"
        case ETyresSeason.allSeason:
            return "all-weather"
        case ETyresSeason.winter:
            return "snow"
        case ETyreCountType.singleTire:
            return "tire"
        case ETyreCountType.carTires:
            return "axle-car"
        case ETyreCountType.motorcycleTires:
            return "axle-motorbike"
    }

    // eslint-disable-next-line default-case
    switch (+group) {
        case ETyresCarType.Agrar:
            return "tractor"
        case ETyresCarType.Anhanger:
            return "trailer"
        case ETyresCarType.EM:
            return "excavator"

        case ETyresCarType.Industrie:
            return "fork-lift"
        case ETyresCarType.LKW:
            return "truck2"
        case ETyresCarType.LLKW:
            return "transporter"
        case ETyresCarType.Militar:
            return "military"
        case ETyresCarType.Motorrad:
            return "bike"
        case ETyresCarType.PKW:
            return "car"
        case ETyresCarType.SUV:
            return "car"
    }

    return ""
}

export const getTitleByGroup = (value: ETyresCarType | TyresSeason | ETyreCountType | string) => {
    // eslint-disable-next-line default-case
    switch (value) {
        case ETyresSeason.summer:
            return 800
        case ETyresSeason.allSeason:
            return 725
        case ETyresSeason.winter:
            return 801
        case ETyreCountType.singleTire:
            return 13918
        case ETyreCountType.carTires:
        case ETyreCountType.motorcycleTires:
            return 13919
    }

    // eslint-disable-next-line default-case
    switch (+value) {
        case ETyresCarType.Agrar:
            return 12451
        case ETyresCarType.Anhanger:
            return 12457
        case ETyresCarType.EM:
            return 12452
        case ETyresCarType.Industrie:
            return 12453
        case ETyresCarType.LKW:
            return 12458
        case ETyresCarType.LLKW:
            return 683
        case ETyresCarType.Militar:
            return 12456
        case ETyresCarType.Motorrad:
            return 1447
        case ETyresCarType.PKW:
            return 12455
        case ETyresCarType.SUV:
            return 682
    }

    return ""
}

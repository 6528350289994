import { memo, useMemo, useState } from "react"
import { Box, Grid, Stack, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { useOeArticleItemState } from "../ContextProviderStates/OeArticleItemState"
import OeTransactions from "../Transactions/OeTransactions"
import { Button } from "../../../generics/button"
import { ArticleNumber } from "../../articleNumber"
import { Divider } from "../../../generics/divider"
import { Image } from "../../../generics/image"
import { ImageContainer } from "../ImageContainer"
import { Typography } from "../../../generics/typographie"
import { OeInfoAttributes } from "../Attributes/OeInfoAttributes"
import { ADDITIONAL_INFO_ICON_SIZE } from "../constants"
import { Tooltip } from "../../../generics/tooltip"
import { OePartErpContainer } from "../ErpContainer"
import { Selection } from "../Actions/Selection"

const ArticleWrapper = styled(Box)({
    marginBottom: 8,
})

const ArticleContainer = styled(Box)({
    margin: "8px 0 0 0",
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "100%",
    borderBottom: "3px #c3c3c3 solid",
})

const StyledButton = styled(Button)(() => ({}))
StyledButton.defaultProps = { variant: "outlined" }

const GridItem = styled(Grid)({
    position: "relative",
    alignSelf: "stretch",
    "::after": {
        content: '" "',
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        borderRight: "solid 1px #c8c6c6",
    },
})

const ManufacturerLogoBox = styled(Box)({
    "&&": {
        width: "60px",
        padding: "5px",
        border: "solid 1px #b4b4b4",
        borderRadius: "3px",
        display: "flex",
        marginBottom: "8px !important",
    },
})

const ManufacturerNameBox = styled(Box)(({ theme }) => ({
    maxHeight: 40,
    maxWidth: 60,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline",
    "-webkit-line-clamp": "2",
    borderRadius: theme.radius?.default,
    fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
    padding: 2,
}))

export const OeArticleItem = memo(() => {
    const { currency: formatCurrency } = useLocalization()
    const article = useOeArticleItemState((x) => x.article)
    const thumbnailUrl = useOeArticleItemState((x) => x.thumbnailUrl)
    const manufacturerThumbnail = useOeArticleItemState((x) => x.manufacturerThumbnail)
    const oeArticleNo = useOeArticleItemState((x) => x.oeArticleNo)
    const wholesalerArticleNumber = useOeArticleItemState((x) => x.wholesalerArticleNumber)
    const articleErpInfos = useOeArticleItemState((x) => x.articleErpInfos)
    const showArticleImages = useOeArticleItemState((x) => x.options.showArticleImages)
    const displaySelection = useOeArticleItemState((x) => x.selection?.toggleSelectedArticle)
    const displayPriceDependingOnQuantity = useOeArticleItemState((x) => x.options.displayPriceDependingOnQuantity)

    const showErpContainer = useMemo(
        () =>
            articleErpInfos?.default?.state === "success" &&
            (!!articleErpInfos.default.response.prices?.length || !!articleErpInfos.default.response.costs?.length),
        [articleErpInfos]
    )

    const [manufacturerImgLoadError, setManufacturerImgLoadError] = useState(false)

    function onManufacturerImgLoadError() {
        setManufacturerImgLoadError(true)
    }

    return (
        <ArticleWrapper>
            <ArticleContainer>
                <Grid container direction="row" wrap="nowrap" alignItems="start">
                    <GridItem item flexBasis="90px" overflow="hidden">
                        <Stack spacing={1} alignItems="center">
                            <ImageContainer>
                                {showArticleImages && <Image type="article" src={thumbnailUrl} loading="lazy" height="60px" width="60px" />}
                            </ImageContainer>
                            {manufacturerThumbnail && !manufacturerImgLoadError ? (
                                <ManufacturerLogoBox>
                                    <Image
                                        type="manufacturer"
                                        src={manufacturerThumbnail}
                                        loading="lazy"
                                        width="50px"
                                        height="20px"
                                        onError={onManufacturerImgLoadError}
                                    />
                                </ManufacturerLogoBox>
                            ) : (
                                <Tooltip title={`${article.manufacturerName}`}>
                                    <ManufacturerNameBox>{article.manufacturerName}</ManufacturerNameBox>
                                </Tooltip>
                            )}
                        </Stack>
                    </GridItem>

                    <GridItem item xs p={1} overflow="hidden">
                        <Stack spacing={1}>
                            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                <Stack style={{ alignItems: "center" }} direction="row">
                                    {!!wholesalerArticleNumber && (
                                        <ArticleNumber articleNumber={wholesalerArticleNumber} articleNumberType="wholesaler" />
                                    )}
                                    {!!wholesalerArticleNumber && !!oeArticleNo && (
                                        <Divider style={{ marginLeft: "6px", marginRight: "6px" }} orientation="vertical" flexItem />
                                    )}
                                    {!!oeArticleNo && <ArticleNumber articleNumber={oeArticleNo} articleNumberType="oe" />}
                                </Stack>
                            </Stack>
                            {!!article.description && (
                                <Typography variant="body2" style={{ margin: 0 }}>
                                    {article.description}
                                </Typography>
                            )}
                            <OeInfoAttributes />
                        </Stack>
                    </GridItem>

                    <Grid item flex="0 0 240px" justifyContent="end" paddingX={1.5} paddingY={1}>
                        <Stack spacing={2}>
                            {displaySelection && (
                                <Box
                                    sx={{
                                        "&&": {
                                            ml: "auto",
                                        },
                                    }}
                                >
                                    <Selection />
                                </Box>
                            )}
                            <OeTransactions />
                            {showErpContainer ? (
                                <OePartErpContainer />
                            ) : (
                                <Box display="grid" gridTemplateColumns="1fr auto" fontSize={14} columnGap="4px">
                                    {article.prices?.map(({ currency, value, description }) => (
                                        <>
                                            <Box textAlign="right">{description}</Box>
                                            <Box fontWeight={600} marginRight={`${ADDITIONAL_INFO_ICON_SIZE}px`}>
                                                {displayPriceDependingOnQuantity
                                                    ? formatCurrency(value * (article.quantity ?? 1), currency)
                                                    : formatCurrency(value, currency)}
                                            </Box>
                                        </>
                                    ))}
                                </Box>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </ArticleContainer>
        </ArticleWrapper>
    )
})

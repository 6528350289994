import { Box, styled } from "@mui/material"
import { ArticleInfoType } from "@tm/models"
import { Icon } from "../../generics/Icons"
import { Image } from "../../generics/image"

type Props = {
    thumbnailUrl: string | undefined
    isLinkedItem?: boolean
    articleInfoType?: ArticleInfoType
}

const ThumbnailIcon = styled(Icon)({
    color: "#AAAAAA",
    fontSize: 60,
})

export function ArticleThumbnailImage({ isLinkedItem, thumbnailUrl, articleInfoType }: Props) {
    let imageUrl = thumbnailUrl
    if (!imageUrl && articleInfoType === ArticleInfoType.OeArticle) {
        imageUrl = "/styles/base/images/oe-part-logo.svg"
    }

    if (isLinkedItem) {
        return <ThumbnailIcon name="deposit-article" />
    }
    if (articleInfoType === ArticleInfoType.CustomArticle && !isLinkedItem) {
        return <ThumbnailIcon name="individual-article" />
    }
    if (articleInfoType === ArticleInfoType.WholesalerArticle && !isLinkedItem) {
        return <ThumbnailIcon name="supplier" />
    }

    if ((articleInfoType !== ArticleInfoType.WholesalerArticle && articleInfoType !== ArticleInfoType.CustomArticle) || !articleInfoType) {
        return (
            <Box height="60px" width="60px" lineHeight="60px">
                <Image width="100%" height="100%" src={imageUrl || ""} type="article" style={{ objectFit: "contain", objectPosition: "center" }} />
            </Box>
        )
    }

    return null
}

import { styled, useTheme } from "@mui/material"
import { ReactElement } from "react"
import { Button } from "../../generics/button/button"
import { Typography } from "../../generics/typographie"

export type ArticleNumberType = "custom" | "linked" | "oe" | "supplier" | "wholesaler"

type Props = {
    articleNumber: string
    articleNumberType: ArticleNumberType
    size?: "medium" | "large"
    disabled?: boolean
    omitDefaultStyles?: boolean
    onClick?(): void
}

const StyledTypography = styled(Typography)({
    userSelect: "all",
    opacity: "1", // NEXT-23860 - to make sure that the value is readable and the opacity is not duplicated
    textTransform: "none",
    lineHeight: "1", // make sure everything is aligned in the middle
    textAlign: "left",
})

const StyledButton = styled(Button)({
    padding: "0 !important",
    opacity: "1 !important", // NEXT-23860 - to make sure that the selected color remains unchanged and is not displayed attenuated
})

export function ArticleNumber({ articleNumber, articleNumberType, omitDefaultStyles, disabled, size, onClick }: Props) {
    const articleNumbersStylesOverwrite = useTheme().overwrites?.components?.articleNumbers

    function wrapWithButton(content: ReactElement) {
        if (!onClick) {
            return content
        }

        return (
            <StyledButton disabled={disabled} variant="text" onClick={onClick}>
                {content}
            </StyledButton>
        )
    }

    switch (articleNumberType) {
        case "oe":
            return wrapWithButton(
                <StyledTypography
                    fontWeight={articleNumbersStylesOverwrite?.oe?.font?.fontWeight || (omitDefaultStyles ? "bold" : undefined)}
                    color={articleNumbersStylesOverwrite?.oe?.font?.color || (omitDefaultStyles ? "primary" : undefined)}
                    variant={size === "large" ? "h1" : omitDefaultStyles ? "body1" : "body2"}
                >
                    {articleNumber}
                </StyledTypography>
            )
        case "linked":
            return wrapWithButton(
                <StyledTypography
                    fontWeight={articleNumbersStylesOverwrite?.linked?.font?.fontWeight || (omitDefaultStyles ? undefined : "bold")}
                    color={articleNumbersStylesOverwrite?.linked?.font?.color || (omitDefaultStyles ? undefined : "primary")}
                    variant={size === "large" ? "h1" : "body1"}
                >
                    {articleNumber}
                </StyledTypography>
            )
        case "supplier":
            return wrapWithButton(
                <StyledTypography
                    fontWeight={articleNumbersStylesOverwrite?.supplier?.font?.fontWeight || (omitDefaultStyles ? "bold" : undefined)}
                    color={articleNumbersStylesOverwrite?.supplier?.font?.color || (omitDefaultStyles ? "primary" : undefined)}
                    variant={size === "large" ? "h1" : omitDefaultStyles ? "body1" : "body2"}
                >
                    {articleNumber}
                </StyledTypography>
            )
        case "wholesaler":
            return wrapWithButton(
                <StyledTypography
                    fontWeight={articleNumbersStylesOverwrite?.wholesaler?.font?.fontWeight || (omitDefaultStyles ? undefined : "bold")}
                    color={articleNumbersStylesOverwrite?.wholesaler?.font?.color || (omitDefaultStyles ? undefined : "primary")}
                    variant={size === "large" ? "h1" : omitDefaultStyles ? "body2" : "body1"}
                >
                    {articleNumber}
                </StyledTypography>
            )
        case "custom":
        default:
            return wrapWithButton(
                <StyledTypography fontWeight={omitDefaultStyles ? undefined : "bold"} variant={size === "large" ? "h1" : "body1"}>
                    {articleNumber}
                </StyledTypography>
            )
    }
}

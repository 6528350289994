import { Transition } from "@tm/morpheus"

export const transitionsToParts: Array<Transition> = [
    {
        transmit: { bundle: "erp", action: "LOAD_ARTICLE_LIST" },
        receive: { bundle: "parts", action: "LOAD_ARTICLE_LIST" },
    },
    {
        transmit: { bundle: "erp", action: "SHOW_ARTICLE_DETAILS_ERP_TABS" },
        receive: { bundle: "parts", action: "SHOW_ARTICLE_DETAILS_ERP_TABS" },
    },
    {
        transmit: { bundle: "vehicle", action: "LOAD_ARTICLE_LIST" },
        receive: { bundle: "parts", action: "LOAD_ARTICLE_LIST" },
    },
    {
        transmit: { bundle: "erp", action: "ERP_INFO_DETAILS_LOADED" },
        receive: { bundle: "parts", action: "ERP_INFO_DETAILS_LOADED" },
    },
]

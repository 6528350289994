import { ProductGroupFiltersModel, AttributeFilterModel } from "."
import { FittingPosition } from ".."

// ShowVehicleRecords
export type ShowVehicleRecordsRequest = {
    vehicleId?: string
    productGroupIds?: string
    fittingSide?: FittingPosition
    viewMode: ShowVehicleRecordsViewMode
}

export enum ShowVehicleRecordsViewMode {
    ShowEverything = 0,
    ShowRecordedProductGroupsOnly = 1,
    ShowRecordedValuesOnly = 2,
    ShowQuickFilter = 3,
}

export type ShowVehicleRecordsResponse = {
    productGroupFilters: Array<ProductGroupFiltersModel>
}

export type ShowFilterOptionsRequest = {
    modelId: number
    productGroupId: number
    attributeId: number
    fittingSide?: FittingPosition
}

export type ShowFilterOptionsResponse = {
    filterOptions: Array<AttributeFilterModel>
}

import { forwardRef } from "react"
import {
    Breadcrumbs as MuiBreadcrumbs,
    BreadcrumbsProps as MuiBreadcrumbsProps,
    buttonClasses,
    Skeleton,
    styled,
    Typography,
    useTheme,
} from "@mui/material"

import { NavigateNext } from "@mui/icons-material"
import { TreeNode } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Button, IconButton } from "../../generics/button"
import { Icon } from "../../generics/Icons"

export type BreadcrumbsProps = MuiBreadcrumbsProps & {
    breadCrumbs: TreeNode[]
    onBreadcrumbClick?: (node: TreeNode) => void
    isLoading?: boolean
    disabled?: boolean
}

const StyledBreadcrumb = styled(MuiBreadcrumbs)({
    ".MuiBreadcrumbs-separator": {
        margin: 0,
    },
})

const HomeButton = styled(IconButton)(({ theme }) => ({
    color: "#202020",
    ":hover": {
        color: theme.palette.primary.light,
        p: {
            color: theme.palette.primary.light,
        },
    },
}))

const BreadCrumbButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ active, theme }) => ({
    fontFamily: theme.font?.fontFamily?.secondary,
    opacity: theme.opacity?.primary,
    textTransform: "none",
    ...(active && {
        [`&.${buttonClasses.text}`]: {
            color: theme.palette.primary.main,
        },
    }),
    [`&.${buttonClasses.text}`]: {
        ":hover": { color: theme.palette.primary.light },
    },
    ":hover": {
        color: theme.palette.primary.light,
        backgroundColor: "unset",
    },
    "&:disabled": {
        ...(theme.overwrites?.components?.searchTree?.breadcrumb?.variant !== "button" && {
            color: theme.palette.primary.main,
        }),
        cursor: "default",
        opacity: theme.opacity?.primary,
    },
}))

export const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbsProps>((props, ref) => {
    const { translateText } = useLocalization()
    const onBreadCrumbClick = (node: TreeNode) => {
        if (props.onBreadcrumbClick) {
            props.onBreadcrumbClick(node)
        }
    }

    const theme = useTheme()
    const themeOverwrites = theme.overwrites?.components?.searchTree?.breadcrumb

    return (
        <StyledBreadcrumb
            ref={ref}
            aria-label="breadcrumb"
            separator={<NavigateNext sx={{ opacity: (theme) => theme.opacity?.primary || 0.85 }} fontSize="small" />}
        >
            <HomeButton
                key="key_Overview"
                onClick={() => onBreadCrumbClick({ id: 0, hasChildNodes: true, name: "OVERVIEW" })}
                disabled={props.disabled}
            >
                <Icon name={themeOverwrites?.overviewIcon || "overview"} />
                {themeOverwrites?.overviewWithLabel && <Typography pl="6px">{translateText(13263)}</Typography>}
            </HomeButton>
            {props.breadCrumbs.map((crumb, index) => {
                return (
                    <BreadCrumbButton
                        key={`key_${crumb.id}`}
                        active={index === props.breadCrumbs.length - 1}
                        onClick={() => onBreadCrumbClick(crumb)}
                        disabled={!crumb.hasChildNodes}
                        disableRipple
                        variant={index === props.breadCrumbs.length - 1 && themeOverwrites?.variant === "button" ? "contained" : "text"}
                        color={index === props.breadCrumbs.length - 1 ? "primary" : undefined}
                    >
                        {crumb.name}
                    </BreadCrumbButton>
                )
            })}

            {props.isLoading && <Skeleton variant="rectangular" height={30} width={100} />}
        </StyledBreadcrumb>
    )
})

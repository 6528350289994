import { useState } from "react"

export function useResizeObserver<T extends HTMLElement | null>(callback: (entry: ResizeObserverEntry) => void): [(el: T) => void, () => void] {
    const [observer, setObserver] = useState<ResizeObserver>()
    const [element, setElement] = useState<T>()

    function ref(el: T) {
        if (!el || el === element) {
            return
        }
        if (element && observer) {
            observer.unobserve(element)
        }
        let ob: ResizeObserver | undefined = observer
        if (!ob) {
            ob = new ResizeObserver((entries: ResizeObserverEntry[]) => entries.length && callback(entries[0]))
            setObserver(observer)
        }
        ob?.observe(el)
        setElement(el)
    }

    return [ref, () => observer?.disconnect()]
}

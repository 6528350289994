import { Component } from "react"
import { bindMethodsToContext } from "../../helper"

export type ListItem = {
    [key: string]: any // each list item can contain any additional properties
    label?: string
    value?: string
}

export type Props = {
    items: Array<ListItem>
    className?: string
    onRef?(ref: HTMLElement): void
    onValueSelect?(item: ListItem): void
    renderItem?(item: ListItem): JSX.Element
}

export default class SeparatorList extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)
    }

    handleListRef(el: HTMLElement | null) {
        const { onRef } = this.props
        el && onRef && onRef(el)
    }

    handleValueSelect(item: ListItem) {
        const { onValueSelect } = this.props
        onValueSelect && onValueSelect(item)
    }

    renderItemContent(item: ListItem) {
        return (
            <>
                {!!item.label && <span className="separator-list__item__label text text--s">{item.label}</span>}
                {!!item.value && (
                    <span className="separator-list__item__value text text--s" onClick={() => this.handleValueSelect(item)}>
                        {item.value}
                    </span>
                )}
            </>
        )
    }

    renderItem(item: ListItem, idx: number, items: Array<ListItem>) {
        const { renderItem } = this.props

        if (!item.label && !item.value && !renderItem) {
            return
        }

        return (
            <li key={idx} className="separator-list__item">
                {renderItem ? renderItem(item) : this.renderItemContent(item)}
            </li>
        )
    }

    render() {
        const { items } = this.props
        if (!items.length) {
            return null
        }

        let className = "separator-list "
        className += this.props.className || ""

        return (
            <ul className={className} ref={this.handleListRef}>
                {items.map(this.renderItem)}
            </ul>
        )
    }
}

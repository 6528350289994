import { PropsWithChildren, PureComponent, ReactNode, RefObject, createRef } from "react"
import Dialog from "."

export type WarningPromptProps = PropsWithChildren<{
    cancelButtonText?: string
    confirmationButtonText: string
    doNotCloseOnConfirm?: boolean
    layout?: "stretch"
    ref: any
    text: string | ReactNode
    time?: Date
    hideCloseButton?: boolean
    onCancel?(e: Event): void
    onClose?(e: Event): void
    onConfirm?(e: Event): void
}>

export default class WarningPrompt extends PureComponent<WarningPromptProps> {
    dialogRef: RefObject<Dialog> = createRef()

    show() {
        this.dialogRef.current?.show()
    }

    render() {
        return (
            <Dialog
                {...this.props}
                skin="warning"
                confirmButtonText={this.props.confirmationButtonText}
                cancelButtonText={this.props.cancelButtonText}
                iconName="attention-dark"
                ref={this.dialogRef}
                hideCloseButton={this.props.hideCloseButton}
            >
                {this.props.children}
            </Dialog>
        )
    }
}

import { mapAvailabilityStatusToIconName } from "@tm/utils"
import { Availability, AvailabilityStatus } from "@tm/models"
import { Icon, Text, Image } from "../.."

export type AvailabilityMultiQuantityProps = {
    availabilities: Array<{ availability: Omit<Availability, "shortDescription" | "description">; quantity: number }>
    additionalText?: string
    onClick?(): void
}

export default function AvailabilityMultiQuantity({ availabilities, additionalText, onClick }: AvailabilityMultiQuantityProps) {
    const baseName = "availability-multi-quantity"
    const itemBaseName = `${baseName}__item`

    const content: Array<JSX.Element> = []
    availabilities.map((a) => {
        const type = a.availability.type || AvailabilityStatus.Undefined

        let image
        if (a.availability.icon?.useIconFromUrl && a.availability.icon.url) {
            image = <Image url={a.availability.icon.url} />
        } else {
            image = <Icon name={mapAvailabilityStatusToIconName(type)} />
        }

        content.push(
            <div key={type} className={`${itemBaseName} ${itemBaseName}--status-${type}`}>
                <div className={`${itemBaseName}__image`}>{image}</div>
                <div className={`${itemBaseName}__text`}>
                    <Text>{a.quantity}</Text>
                </div>
            </div>
        )
    })

    let className = baseName

    if (onClick) {
        className = `${className} ${className}--clickable`
    }

    return (
        <div className={className} onClick={onClick}>
            <div className={`${baseName}__wrapper`}>{content}</div>
            {!!additionalText && (
                <div className={`${baseName}__additional-text`}>
                    <Text size="xs">{additionalText}</Text>
                </div>
            )}
        </div>
    )
}

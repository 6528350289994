import { memo } from "react"
import { Box, Stack } from "@mui/material"
import { DMS } from "@tm/models"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Typography } from "../../../generics/typographie"
import { Divider } from "../../../generics/divider"
import { TooltipContentText, TooltipWrapper } from "../Attributes/TooltipWrapper"
import { DmsArticleInformation } from "../Dms/DmsArticleInformation"
import { CompactNotification } from "../Notifications/CompactNotification"
import AlreadyOrderedArticle from "../Vouchers/AlreadyOrderedArticle"

export const CompactAdditionalInformations = memo(() => {
    const generalInformations = useDefaultArticleItemState((x) => x.displayArticle.additionalInformation?.generalInformation)
    const notifications = useDefaultArticleItemState((x) => x.notifications)
    const dmsInfo = useDefaultArticleItemState((x) => x.dmsArticleInfo)
    const expandedArticleList = useDefaultArticleItemState((x) => x.expanded)
    const alreadyOrdered = useDefaultArticleItemState((x) => x.alreadyOrdered)

    function showDmsInformation() {
        return (
            dmsInfo.stockItem &&
            (dmsInfo.stockItem.invoiced ||
                (dmsInfo.stockItem.availability !== undefined && dmsInfo.stockItem.availability !== DMS.Availability.Undefined))
        )
    }

    if (!generalInformations?.length && !notifications?.length && !showDmsInformation()) {
        return null
    }

    return (
        <TooltipWrapper color="warning" icon="alert_B_attention">
            <>
                {alreadyOrdered && <AlreadyOrderedArticle />}
                {generalInformations?.map(([title, contents]) => (
                    <Box key={`informationBox_${title}`} my={2}>
                        <Stack direction="row" alignItems="center" justifyContent="stretch" mb={1}>
                            <Typography variant="label" textTransform="uppercase" lineHeight={1}>
                                {title}
                            </Typography>
                            <Box ml={1} flex={1}>
                                <Divider light />
                            </Box>
                        </Stack>
                        {!!contents?.length && (
                            <Stack ml={3}>
                                {contents.map((content) => (
                                    <TooltipContentText key={`informationEntry_${content}`} variant="body2">
                                        {content}
                                    </TooltipContentText>
                                ))}
                            </Stack>
                        )}
                    </Box>
                ))}
                {notifications?.map((note) => (
                    <CompactNotification
                        key={`${note.link}-${note.message}`}
                        note={note}
                        hideDivider={!generalInformations?.length && notifications.length > 1}
                    />
                ))}
                {showDmsInformation() && (
                    <Stack my={1}>
                        <DmsArticleInformation dmsArticleInfo={dmsInfo} hideBorder={!expandedArticleList} />
                    </Stack>
                )}
            </>
        </TooltipWrapper>
    )
})

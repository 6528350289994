import { IconButton, styled, useTheme, useThemeProps } from "@mui/material"
import { ForwardedRef, forwardRef, MouseEventHandler, ReactNode, useMemo } from "react"
import { classes } from "@tm/utils"
import { Icon } from "../../generics/Icons"
import { Image } from "../../generics/image"
import { Typography } from "../../generics/typographie"
import { Tooltip } from "../../generics/tooltip/index"

/**
 * @property {string} tooltip text shown when hovered
 * @property {boolean}  active highlights the navigation item
 * @property {boolean}  noOffsetOnActive item should stay at the origin if active
 * @property {string}  subtitle renders a text under the icon
 * @property {MouseEventHandler<HTMLButtonElement> | undefined}  onIconClick called on click
 * @property {MouseEventHandler<HTMLButtonElement> | undefined}  onDoubleClick called on double click
 */
export type GlobalNavigationItemProps = (NavigationImageProps | NavigationIconProps | NavigationElementProps) & {
    tooltip?: string
    noOffsetOnActive?: boolean
    active?: boolean
    subtitle?: string
    className?: string
    onIconClick?: MouseEventHandler<HTMLButtonElement> | undefined
    onDoubleClick?: MouseEventHandler<HTMLButtonElement> | undefined
}

/**
 * @property {JSX.Element} children place your custom icon as a child
 * @property {string<"element"|"image"|icon>} componentType  highlights the navigation item
 */
export type NavigationElementProps = {
    children: ReactNode
    componentType: "element"
}

/**
 * @property {string} url the path to an icon
 * @property {string<"element"|"image"|icon>} componentType  highlights the navigation item
 */
export type NavigationImageProps = {
    url: string
    endAdornment?: JSX.Element
    componentType: "image"
}

/**
 * @property {string} name name of the icon
 * @property {string<"element"|"image"|icon>} componentType  highlights the navigation item
 */
export type NavigationIconProps = {
    name: string
    endAdornment?: JSX.Element
    componentType: "icon"
}

const GlobalButtonWrapper = styled(IconButton, {
    name: "GlobalNavigationItem",
    slot: "root",
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    color:
        theme.pageNavigation?.itemColor || (theme.colors?.header ? theme.palette.getContrastText(theme.colors.header) : theme.palette.common.white),
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0.5),
    borderRadius: theme.radius?.default,
    lineHeight: theme.typography.body1.lineHeight,
    flexFlow: "column",
    fontSize: "1em",

    "&.active": {
        borderColor: "transparent",
        backgroundColor: theme.colors?.modulBar || "#f6f6f6",
        color: theme.palette.getContrastText(theme.colors?.modulBar || "#f6f6f6"),
        " svg": {
            fill: theme.palette.getContrastText(theme.colors?.modulBar || "#f6f6f6"),
            opacity: 1,
        },
        "+ .badge": {
            transition: 0,
        },
        "&:not(.noActiveOffset):not(.global-navigation-flat)": {
            transform: `translateY(10px)`,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            "+ .badge": {
                transform: `translateY(10px)`,
            },
        },
    },
    "&:hover:not(.active)": {
        background: "hsla(0, 0%, 100%, .15)",
        " svg": {
            opacity: 1,
        },

        " img": {
            opacity: 1,
        },
    },
    "&.global-navigation-flat": {
        height: "4rem",
        width: "4rem",
        border: "unset",
        margin: "0",
        borderLeft: `1px solid ${theme.palette.grey.A400}60`,
        borderRadius: 0,

        "&.active": {
            background: theme.colors?.modulBar ?? theme.palette.common.white,
            " svg": {
                fill: theme.palette.getContrastText(theme.colors?.modulBar ?? theme.palette.common.white),
                opacity: 1,
            },
        },

        "&:hover:not(.active)": {
            backgroundColor: `${theme.palette.grey.A400}60`,
            " svg": {
                opacity: 1,
            },

            " img": {
                opacity: 1,
            },
        },

        " svg": {
            margin: "0 auto",
            fill: theme.pageNavigation?.itemColor || theme.palette.common.white,
        },

        " img": {
            margin: "0 auto",
        },
    },
}))

const GlobalIcon = styled(Icon, {
    name: "GlobalNavigationItem",
    slot: "icon",
    overridesResolver: (props, styles) => styles.icon,
})(({ theme }) => ({
    opacity: theme.opacity?.primary ?? 0.87,
    fill:
        theme.pageNavigation?.itemColor || (theme.colors?.header ? theme.palette.getContrastText(theme.colors?.header) : theme.palette.common.white),
    height: 24,
    width: 24,
}))

const GlobalImage = styled(Image, {
    name: "GlobalNavigationItem",
    slot: "image",
    overridesResolver: (props, styles) => styles.image,
})(({ theme }) => ({
    opacity: theme.opacity?.primary ?? 0.87,
    height: 24,
    width: 24,
}))

const SubtitleComponent = styled(Typography)(({ theme }) => ({
    color: theme.colors?.header ? theme.palette.getContrastText(theme.colors.header) : theme.pageNavigation?.itemColor || theme.palette.common.white,
    ".active &": {
        color: theme.colors?.modulBar
            ? theme.palette.getContrastText(theme.colors.modulBar)
            : theme.pageNavigation?.itemColor || theme.palette.common.black,
    },
}))

export const GlobalNavigationItem = forwardRef((inProps: GlobalNavigationItemProps, forwardedRef: ForwardedRef<HTMLButtonElement | null>) => {
    const props = useThemeProps({ props: inProps, name: "GlobalNavigationItem" })
    const theme = useTheme()

    const { tooltip, active, noOffsetOnActive, componentType, subtitle, className, onIconClick, onDoubleClick } = props

    const customClass = useMemo(() => {
        const themeId = theme.layout?.["global-navigation-style"]
        const themeName = themeId === 1 ? `global-navigation-flat ` : ""
        return classes(themeName, active && "active", noOffsetOnActive && "noActiveOffset", className)
    }, [active, noOffsetOnActive, theme.layout?.["global-navigation-style"], className])

    return (
        <Tooltip title={tooltip}>
            <GlobalButtonWrapper
                ref={forwardedRef}
                onClick={onIconClick}
                onDoubleClick={onDoubleClick}
                size="small"
                className={customClass}
                disableRipple
            >
                {componentType === "element" ? (
                    props.children
                ) : (
                    <>
                        {componentType === "icon" ? <GlobalIcon name={props.name} /> : <GlobalImage src={props.url} />}
                        {subtitle && <SubtitleComponent>{subtitle}</SubtitleComponent>}
                        {props.endAdornment}
                    </>
                )}
            </GlobalButtonWrapper>
        </Tooltip>
    )
})

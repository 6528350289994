import "@tm/utils"
import { LocalizationConfig } from "../models"

export default function formatNumber(config: LocalizationConfig, value: number, decimal: number = 2): string {
    if (value == null) {
        value = 0
    } else {
        value = parseFloat(value.toString())
    }
    switch (config.language) {
        case "de":
            return `${value.format(decimal, 3, ".", ",")}`
        default:
            return `${value.format(decimal, 3, ",", ".")}`
    }
}

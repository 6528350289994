import { Component } from "react"
import { Overwrite } from "@tm/utils"
import { FormElementProps, TextAutoComplete } from "../../models"
import { ControlsConfig } from "../../configuration"
import DefaultSearchField from "./index.default"
import SearchFieldWithExternalHintField from "./index.external-hint"

export { ExternalTooltip } from "./external-hint"

export type SearchFieldProps = Overwrite<
    FormElementProps,
    {
        autoComplete?: TextAutoComplete
        value?: string
        pattern?: RegExp
        placeholder?: string
        maxLength?: number
        showClear?: boolean
        showClearTooltip?: string
        showSearch?: boolean
        tooltip?: string
        loading?: boolean
        forceShowTooltipOnHover?: boolean

        onInputClick?(): void
        onChangeConfirm?(model: any, path?: Array<any>): void
        onChangeReset?(): void
    }
>

export type SearchFieldConfig = {
    showHintAsTooltip?: boolean
}

export type SearchFieldState = {
    props: SearchFieldProps
}

export default class SearchField extends Component<SearchFieldProps, any> {
    private searchField: any

    private searchFieldRef: any

    constructor(props: SearchFieldProps) {
        super(props)

        this.state = {
            props: ControlsConfig.get<SearchFieldConfig>("SearchField"),
        }
    }

    UNSAFE_componentWillMount() {
        this.searchField =
            this.props.forceShowTooltipOnHover || this.state.props.showHintAsTooltip ? DefaultSearchField : SearchFieldWithExternalHintField
    }

    focus = () => {
        const ref = this.searchFieldRef.current || this.searchFieldRef
        ref && ref.focus && ref.focus()
    }

    onRef = (ref: SearchField) => {
        this.props.onRef && this.props.onRef(ref)
        this.searchFieldRef = ref
    }

    render() {
        const SearchField = this.searchField
        return <SearchField {...this.props} ref={this.onRef} />
    }
}

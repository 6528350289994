import { Stack, StackProps } from "@mui/material"
import { forwardRef } from "react"

export const ShareButtonWrapper = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
    return (
        <Stack my={2} direction="row" spacing={2} {...props} ref={ref}>
            {props.children}
        </Stack>
    )
})

import { useLocalization } from "@tm/localization"
import { useCallback, useState } from "react"
import { Box, styled } from "@mui/material"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Image } from "../../generics/image"
import { Tooltip } from "../../generics/tooltip"

const SupplierBox = styled(Box, {
    shouldForwardProp: (prop: string) => !["selected", "showLogo", "compactView", "marginTop", "fitContent"].includes(prop),
})<{
    selected?: boolean
    showLogo?: boolean
    compactView?: boolean
    marginTop?: number
    fitContent?: boolean
}>(({ theme, selected, onClick, showLogo, compactView, marginTop, fitContent }) => ({
    marginTop: marginTop ?? "8px",
    ...(!showLogo && {
        maxHeight: 40,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        borderRadius: theme.radius?.default,
        fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
        textAlign: "center",
        padding: 2,
        ...(selected && {
            padding: "2px 4px",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
                color: theme.palette.primary.contrastText,
            },
        }),
        ...(compactView && {
            whiteSpace: "nowrap",
            display: "block",
            textAlign: "left",
            marginTop: undefined,
        }),
    }),
    ...(showLogo && {
        ...(selected && {
            border: `5px solid ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
        }),
        ...(!!onClick && {
            cursor: "pointer",
        }),
    }),
    ...(fitContent && {
        width: "fit-content",
    }),
}))

const SupplierImage = styled(Image)({
    maxWidth: 80,
    maxHeight: 32,
    display: "block",
})

type Props = {
    compactView?: boolean
    marginTop?: number
    // fixes tooltip position
    fitContent?: boolean
}

export function Supplier({ compactView, marginTop, fitContent }: Props) {
    const { translateText } = useLocalization()

    const { supplier, supplierLogo, selected } = useDefaultArticleItemState((x) => x.displayArticle.supplier)
    const toggleSupplierFilter = useDefaultArticleItemState((x) => x.toggleSupplierFilter)
    const expanded = useDefaultArticleItemState((x) => x.expanded)
    const [error, setError] = useState(false)

    const handleImageError = useCallback(() => {
        setError(true)
    }, [])

    const handleClick = useCallback(() => {
        toggleSupplierFilter?.(true)
    }, [toggleSupplierFilter])

    const showLogo = !error && !!supplierLogo && expanded

    return (
        <Tooltip title={`${translateText(1048)}: ${supplier.name}`}>
            <SupplierBox
                fitContent={fitContent}
                onClick={handleClick}
                selected={selected}
                showLogo={showLogo}
                compactView={compactView}
                marginTop={marginTop}
            >
                {showLogo ? <SupplierImage onError={handleImageError} src={supplierLogo} loading="lazy" /> : supplier.name}
            </SupplierBox>
        </Tooltip>
    )
}

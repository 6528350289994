import { PropsWithChildren, forwardRef, useState } from "react"
import { SortDirection } from "@mui/material"
import { TableProps } from "./interfaces"
import { TableColumnHeader } from "./TableColumnHeader"
import { ROWCAP, SMALL_ROWCAP } from "./configs"
import { TableGrid } from "./TableGrid"
import { TableRow } from "./TableRow"
import { CustomRowElement, ExtendedElement } from "./elements"
import { TableCell } from "./TableCell"

export const TableComponent = forwardRef<HTMLDivElement, PropsWithChildren<TableProps>>((props, ref) => {
    const {
        columns,
        rows,
        onRowClick,
        onRowDoubleClick,
        variant = "normal",
        headerBackground,
        headerStyle = "bold",
        children,
        rowCap = variant === "small" ? SMALL_ROWCAP : ROWCAP,
        rowStyle = "stripped",
        textContrast,
        transparentHighlight,
        fontSize,
        rowStyles,
    } = props
    const colCount = columns.length

    const [sortColumn, setSortColumn] = useState<number | undefined>()
    const [sortDirection, setSortDirection] = useState<SortDirection>(false)
    const transparent = rowStyle === "transparent"
    const textColor = textContrast === "white" ? "#FFF" : "#000"

    return (
        <TableGrid columns={columns} rowStyle={rowStyle} ref={ref}>
            {headerStyle !== "hidden" &&
                columns.map(({ header, minWidth, alignHeader, alignContent, startSortDirection: startSortOrder = "asc", onSort }, columnIndex) => (
                    <TableColumnHeader
                        key={`headline_${columnIndex}`}
                        variant={variant}
                        fontWeight={headerStyle}
                        backgroundColor={headerBackground}
                        alignContent={alignHeader || alignContent}
                        minWidth={minWidth}
                        onClick={() => {
                            if (onSort) {
                                let direction: SortDirection
                                if (sortColumn !== columnIndex) {
                                    setSortColumn(columnIndex)
                                    direction = startSortOrder
                                } else if (sortDirection === "asc") {
                                    direction = startSortOrder === "asc" ? "desc" : false
                                } else if (sortDirection === "desc") {
                                    direction = startSortOrder === "desc" ? "asc" : false
                                } else {
                                    direction = startSortOrder
                                }
                                setSortDirection(direction)
                                onSort(direction)
                            }
                        }}
                        sortOrder={onSort ? (sortColumn === columnIndex ? sortDirection : false) : undefined}
                        textColor={textContrast ?? textColor}
                    >
                        {header}
                    </TableColumnHeader>
                ))}

            {children ||
                rows?.map((row, rowIndex) => {
                    const rowId = row.id ?? rowIndex
                    if (row.replaceCustomRow) {
                        return (
                            <TableRow
                                key={`Row_${rowId}`}
                                colCount={columns.length}
                                active={row.active}
                                onClick={() => onRowClick?.(row)}
                                onDoubleClick={() => onRowDoubleClick?.(row)}
                                rowIndicatorColor={row.leftRowIndicatorColor}
                                rowCap={rowCap}
                                variant={variant}
                                singleRow={!!row.extendedContent}
                                transparent={transparent}
                                rowStyles={rowStyles}
                            >
                                <CustomRowElement colCount={colCount} rowIndicatorColor={row.leftRowIndicatorColor}>
                                    {row.customRow}
                                </CustomRowElement>
                            </TableRow>
                        )
                    }

                    return (
                        <TableRow
                            key={`Row_${rowId}`}
                            colCount={columns.length}
                            active={row.active}
                            onClick={() => onRowClick?.(row)}
                            onDoubleClick={() => onRowDoubleClick?.(row)}
                            rowIndicatorColor={row.leftRowIndicatorColor}
                            rowCap={rowCap}
                            variant={variant}
                            singleRow={!!row.extendedContent}
                            transparent={transparent}
                            transparentHighlight={transparentHighlight}
                            rowStyles={rowStyles}
                        >
                            {row.cells.slice(0, colCount).map((cell, cellIndex) => {
                                const cellId = cell.id ?? cellIndex
                                return (
                                    <TableCell
                                        fontSize={fontSize}
                                        key={`cell_${rowId}_${cellId}`}
                                        colCount={colCount}
                                        alignContent={columns[cellIndex]?.alignContent}
                                        maxWidth={cell.maxWidth}
                                        rowIndicatorColor={row.leftRowIndicatorColor}
                                        rowIndicatorWidth={row.rowIndicatorWidth}
                                        borderOnlyOnTheLeft={row.borderOnlyOnTheLeft}
                                        variant={variant}
                                        overflowCell={columns[cellIndex]?.overflowCell}
                                        transparent={transparent}
                                        textColor={textContrast ?? textColor}
                                        className={cell.className}
                                    >
                                        {cell.displayValue}
                                    </TableCell>
                                )
                            })}
                            {row.extendedContent && !row.extendedContentHidden && (
                                <ExtendedElement colCount={colCount} rowIndicatorColor={row.leftRowIndicatorColor}>
                                    {row.extendedContent}
                                </ExtendedElement>
                            )}
                        </TableRow>
                    )
                })}
        </TableGrid>
    )
})

import { MenuList, Popover, Stack } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ECounterType, ErpIconType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { useCallback, useState } from "react"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { IconButton } from "../IconButton"
import { ActionMenuItem } from "./ActionMenuItem"

export function MoreMenuButton() {
    const [moreMenuEl, setMoreMenuEl] = useState<HTMLButtonElement | null>(null)
    const { translateText } = useLocalization()

    const moreList = useDefaultArticleItemState((x) => x.articleActions?.menuArea)

    const handleClickMore = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        setMoreMenuEl(e.currentTarget)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleFurtherOptions)
    }, [])

    const handleCloseMore = useCallback(() => {
        setMoreMenuEl(null)
    }, [])

    return moreList?.length ? (
        <>
            <Stack direction="row" gap={0.5}>
                <Tooltip title={translateText(13775)}>
                    <IconButton onClick={handleClickMore} hoverEffect disableRipple sx={{ padding: "0 0.2rem" }}>
                        <Icon name="ellipsis" />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Popover anchorEl={moreMenuEl} anchorOrigin={{ horizontal: "left", vertical: "bottom" }} open={!!moreMenuEl} onClose={handleCloseMore}>
                <MenuList variant="menu">
                    {moreList.map((action) => (
                        <ActionMenuItem key={ErpIconType[action.iconType]} handleCloseMore={handleCloseMore} action={action} />
                    ))}
                </MenuList>
            </Popover>
        </>
    ) : null
}

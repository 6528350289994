import { forwardRef, useEffect, useMemo, useState } from "react"
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps as MuiDateTimePickerProps, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import enLocale from "date-fns/locale/en-GB"
import { TextField } from "../../textfield"
import { Icon } from "../../Icons"
import type { LocalizationType } from "../helper/locale"

export type DateTimePickerProps = Omit<MuiDateTimePickerProps<Date, Date>, "renderInput"> & {
    localisation: LocalizationType
}

export const DateTimePicker = forwardRef<HTMLDivElement, DateTimePickerProps>(({ localisation, ...rest }, ref) => {
    const [locale, setLocale] = useState(enLocale)

    useEffect(() => {
        const importLocaleFile = async () => {
            // This webpack option stops all of the date-fns files being imported and chunked.
            const localeToSet = await import(
                /* webpackMode: "lazy", webpackChunkName: "i18n/[request]", webpackExclude: /_lib/ */
                `date-fns/locale/${localisation.locale}/index.js`
            )
            setLocale(localeToSet.default)
        }

        importLocaleFile()
    }, [localisation.locale])

    const dateMask = useMemo((): { inputFormat: string; mask: string } => {
        /* use this to see how you should configure the date https://date-fns.org/v2.25.0/docs/format */
        switch (locale.code) {
            case "en-GB": {
                return {
                    inputFormat: "yyyy/MM/dd | HH:mm aaa",
                    mask: "____/__/__ | __:__ ___",
                }
            }

            default: {
                return {
                    inputFormat: "dd.MM.yyyy | HH:mm",
                    mask: "__.__.____ | __:__",
                }
            }
        }
    }, [locale])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <MuiDateTimePicker
                inputFormat={dateMask.inputFormat}
                mask={dateMask.mask}
                ref={ref}
                ampm={false}
                disableMaskedInput={false}
                renderInput={(params) => <TextField {...params} />}
                components={{
                    OpenPickerIcon: (props) => <Icon name="calendar" {...props} />,
                    ...(rest.components || {}),
                }}
                views={["year", "month", "day", "hours", "minutes"]}
                showToolbar
                openTo="year"
                {...rest}
            />
        </LocalizationProvider>
    )
})

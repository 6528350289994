import { CategoryType } from "@tm/models"

export function getCategoryTypeFromUrl(url: string): CategoryType {
    if (url.includes("/parts/direct")) {
        return "directSearch"
    }

    if (url.includes("/parts/vehicles")) {
        return "vehicleParts"
    }

    if (url.includes("/parts/universal")) {
        return "universalParts"
    }

    if (url.includes("/fastdtc")) {
        return "dtcSearch"
    }

    if (url.includes("/wheels")) {
        // todo tma : correct path?
        return "wheels"
    }
    if (url.includes("/basket")) {
        // todo tma : correct path?
        return "basket"
    }
    if (url.includes("/haynes")) {
        // todo tma : correct path?
        return "haynes"
    }
    if (url.includes("/tyres")) {
        // todo tma : correct path?
        return "tyres"
    }
    if (url.includes("/tyresTecRmi")) {
        // todo tma : correct path?
        return "tyresTecRmi"
    }

    if (url.includes("/partsIndicator")) {
        // todo tma : correct path?
        return "partsIndicator"
    }
    if (url.includes("/tiresSearch")) {
        // todo tma : correct path?
        return "tiresSearch"
    }
    if (url.includes("/predictive")) {
        // todo tma : correct path?
        return "predictive"
    }
    if (url.includes("/eurotaxSearch")) {
        // todo tma : correct path?
        return "eurotaxSearch"
    }
    if (url.includes("/basket")) {
        // todo tma : correct path?
        return "basket"
    }

    if (false) {
        // todo tma : correct path?
        return "partAlternatives"
    }

    return "directSearch"
}

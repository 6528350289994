import { createContext } from "react"
import { WorkTask } from "."

/**
 * Unfortunately the same trick as in UserProviderContext cannot be used here,
 * because the WorkTaskProvider is not always used as a wrapper,
 * so undefined is actually a possible value.
 * (see wrapWithWorktaskProvider function in Core.Portal/src/bootstrap/index.tsx)
 */
export const WorkTaskProviderContext = createContext<WorkTask | undefined>(undefined)

import { getFieldErrors, clone, getValue, setValue } from "@tm/utils"
import { style } from "typestyle"
import { ChangeEvent, Component } from "react"
import { FormElementProps, PasswordAutoComplete } from "../../models"
import { elementId, bindMethodsToContext } from "../../helper"
import { Button } from ".."

export type PasswordFieldProps = FormElementProps & {
    autoComplete?: PasswordAutoComplete
    placeholder?: string
    maxLength?: number
    showClear?: boolean

    onChangeConfirm?: (model: any, path?: Array<any>) => void
    onChangeReset?: (model: any, path?: Array<any>) => void
}

type State = {
    value: string
    inputValue: string
    id: string
    edit: boolean
    errors?: Array<string>
    passwordShown: boolean
    showPasswordVisible: boolean
}

export default class PasswordField extends Component<PasswordFieldProps, State> {
    private _inputRef: HTMLInputElement

    constructor(props: PasswordFieldProps) {
        super(props)

        const value = this.getValue(props)
        this.state = {
            value,
            inputValue: value,
            id: elementId(),
            edit: false,
            passwordShown: false,
            showPasswordVisible: false,
        }

        bindMethodsToContext(this)
        this.handleInputEvent = this.handleInputEvent.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps: PasswordFieldProps) {
        const value = this.getValue(nextProps)
        this.setState({ value, inputValue: value })
        if (value) {
            this._inputRef.value = value
        }

        if (nextProps.modelState && nextProps.path) {
            const errors = getFieldErrors(nextProps.modelState, nextProps.path)
            if (errors) {
                this.setState({ errors })
            }
        } else {
            this.setState({ errors: undefined })
        }
    }

    handleInputEvent(event: any) {
        if (event.inputType === "insertReplacementText" || !("data" in event)) {
            this.autocomplete(event.target)
        }
    }

    autocomplete(element: any) {
        if (element.hasAttribute("autocompleted")) {
            return
        }

        element.setAttribute("autocompleted", "")
    }

    componentDidMount() {
        if (this.props.autoFocus) {
            this.focus()
        }

        document.addEventListener("input", this.handleInputEvent, true)
    }

    componentWillUnmount() {
        document.removeEventListener("input", this.handleInputEvent, true)
    }

    componentDidUpdate(prevProps: PasswordFieldProps) {
        if (this.props.autoFocus && !prevProps.autoFocus) {
            this.focus()
        }
    }

    togglePasswordVisiblity = () => {
        this.setState({ passwordShown: !this.state.passwordShown })
    }

    getValue(props: PasswordFieldProps): string {
        const value = props.model && props.path ? getValue(props.model, props.path) : props.value
        return value != null ? value.toString() : ""
    }

    setValueToModel(value: any): any {
        const model = clone(this.props.model)

        // TODO: refactor, idk if this is fine
        if (!this.props.path) {
            return model
        }

        setValue(model, this.props.path, value)
        return model
    }

    handleRef(inputRef: HTMLInputElement) {
        this._inputRef = inputRef
        this.props.onRef?.(this)
    }

    handleChange(e?: ChangeEvent<HTMLInputElement>) {
        const value = e?.target.value || ""

        if (!value) {
            this.setState({ value, inputValue: value, showPasswordVisible: true })
            e?.target.removeAttribute("autocompleted")
        } else if (e?.target.hasAttribute("autocompleted")) {
            this.setState({
                value,
                inputValue: value,
                showPasswordVisible: false,
                passwordShown: false,
            })
        } else {
            this.setState({ value, inputValue: value, showPasswordVisible: true })
        }

        if (this.props.model && this.props.path) {
            const model = this.setValueToModel(value)
            this.props.onChange?.(model)
        } else {
            this.props.onChange?.(value)
        }
    }

    handleFocus() {
        if (this.props.readonly) {
            return
        }

        this.setState({ edit: true })
        this.props.onFocus?.()
    }

    handleBlur() {
        if (this.props.readonly) {
            return
        }

        this.setState({ edit: false })
        if (this.props.onChangeConfirm) {
            const { value } = this.state
            if (this.props.model && this.props.path) {
                const model = this.setValueToModel(value)
                this.props.onChangeConfirm(model)
            } else {
                this.props.onChangeConfirm(value)
            }
        }
    }

    handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
        const { readonly, model, path, value, onChangeReset } = this.props
        if (readonly) {
            return
        }

        switch (e.which) {
            case 13:
                this.handleBlur()
                break
            case 27:
                const originValue = model && path ? getValue(model, path) : value
                this.setState({
                    value: originValue,
                    inputValue: originValue,
                })
                if (onChangeReset) {
                    if (model && path) {
                        onChangeReset(model)
                    } else {
                        onChangeReset(value)
                    }
                }
                break
        }
    }

    handleClear(ev?: React.SyntheticEvent<HTMLButtonElement>) {
        ev && ev.preventDefault()
        this.handleChange()
    }

    focus() {
        if (this.props.readonly) {
            return
        }

        if (!this.state.edit) {
            this.setState({ edit: true })
        }

        if (this._inputRef) {
            setTimeout(() => {
                this._inputRef.focus()
            }, 0)
        }
    }

    render() {
        let className = `input input--password${this.props.className ? ` ${this.props.className}` : ""}`
        let title = ""

        if (this.state.errors && this.state.errors.length > 0) {
            className += " field-error"
            title = this.state.errors.join("\n")
        }

        className += this.props.readonly ? " readonly" : ""
        className += this.state.edit ? " is-active" : ""
        className += this.state.inputValue != "" ? " has-value" : ""
        className += this.props.showClear && !this.props.readonly ? " clearable" : ""
        className += this.props.floatingLabel ? " input--floating-label" : ""

        let inputClassName = "text input__field "
        inputClassName += this.state.inputValue ? " has-value" : ""

        this.props.layout?.forEach((element) => {
            if (element == "dropshadow") {
                className += ` has-${element}`
            } else {
                className += ` input--${element}`
            }
        })

        const label = this.props.label ? (
            <label className="input__label" htmlFor={this.state.id}>
                {this.props.label}
            </label>
        ) : (
            false
        )
        const { disabled, readonly, placeholder, maxLength } = this.props
        const tabIndex = readonly ? 0 : this.props.tabIndex

        return (
            <div className={className} title={title}>
                <div className="input__wrapper">
                    <div className="input__inner">
                        {label}
                        <input
                            autoComplete={this.props.autoComplete}
                            className={inputClassName}
                            type={this.state.passwordShown ? "text" : "password"}
                            placeholder={placeholder}
                            ref={this.handleRef}
                            onChange={this.handleChange}
                            onKeyUp={this.handleKeyUp}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            readOnly={!!readonly}
                            tabIndex={tabIndex}
                            disabled={!!disabled}
                            id={this.state.id}
                            maxLength={maxLength}
                            name={this.props.name}
                        />
                        {this.props.showClear && !this.props.readonly ? <button className="icon icon-close" onClick={this.handleClear} /> : null}
                    </div>
                    {this.state.showPasswordVisible && (
                        <Button
                            fakeButton
                            className={style({
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translatey(-50%)",
                                display: "flex",
                                alignItems: "center",
                            })}
                            icon="visible"
                            layout={["ghost"]}
                            onClick={this.togglePasswordVisiblity}
                        />
                    )}
                </div>
            </div>
        )
    }
}

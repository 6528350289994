import { useStyle } from "@tm/context-distribution"
import { FittingPosition, TirePressure } from "@tm/models"
import { bem } from "@tm/utils"
import { em } from "csx"
import { Component } from "react"
import { Text } from "../.."

export type Props = {
    selected: FittingPosition
    onChange(fittingPosition: FittingPosition): void
    isBikeAsVehicle?: boolean
    tirePressure?: TirePressure
}

export default class extends Component<Props> {
    getClassName(value: FittingPosition): string | undefined {
        const { selected } = this.props

        let className = "fitting-position__item"

        if (selected && (selected & value) == selected) {
            className += " is-active"
        }

        return className
    }

    handleSelect(fittingPosition: FittingPosition) {
        const { onChange, selected } = this.props
        onChange(fittingPosition != selected ? fittingPosition : FittingPosition.None)
    }

    renderItem(fittingPosition: FittingPosition, id: string, path: string, transform?: string) {
        return (
            <path
                className={this.getClassName(fittingPosition)}
                onClick={() => this.handleSelect(fittingPosition)}
                id={id}
                d={path}
                transform={transform}
            />
        )
    }

    renderImage() {
        if (this.props.isBikeAsVehicle) {
            return (
                <svg className="fitting-position" viewBox="0 0 252.3 438.12" version="1.1">
                    <g id="front">
                        {this.renderItem(
                            FittingPosition.FrontAxle,
                            "front-axle",
                            "M255,196a18.73,18.73,0,0,1-10-2.89v46l20-20.86V193.07A18.73,18.73,0,0,1,255,196Z",
                            "translate(-129.85 -36.88)"
                        )}
                        <g>
                            {this.renderItem(
                                FittingPosition.FrontAxle,
                                "front-axle",
                                "M255.46,152.18A50.59,50.59,0,0,0,272,149.5V49.89c0-7.19-7.18-13-16.5-13s-16.5,5.82-16.5,13v99.63A50.67,50.67,0,0,0,255.46,152.18Z",
                                "translate(-129.85 -36.88)"
                            )}
                            {this.renderItem(
                                FittingPosition.FrontAxle,
                                "front-axle",
                                "M257.44,177.07c-0.76.07-1.49,0.11-2.26,0.11a18.46,18.46,0,0,1-2-.11L239,175.72v1.19c0,7.19,7.18,13,16.5,13s16.5-5.82,16.5-13v-1.19Z",
                                "translate(-129.85 -36.88)"
                            )}
                        </g>
                        <rect
                            className={this.getClassName(FittingPosition.FrontAxle | FittingPosition.Right)}
                            onClick={() => this.handleSelect(FittingPosition.Right)}
                            id="front-right"
                            x="340.59"
                            y="148.25"
                            width="15.93"
                            height="69.83"
                            transform="translate(-125.25 337.95) rotate(-56.85)"
                        />
                        <rect
                            className={this.getClassName(FittingPosition.FrontAxle | FittingPosition.Left)}
                            onClick={() => this.handleSelect(FittingPosition.Left)}
                            id="front-left"
                            x="128.52"
                            y="175.2"
                            width="69.83"
                            height="15.93"
                            transform="translate(-203.41 82.3) rotate(-33.15)"
                        />
                        <g>
                            {this.renderItem(
                                FittingPosition.FrontAxle,
                                "front-axle",
                                "M211.42,150.94a4.13,4.13,0,1,0,4.13,4.13,4.13,4.13,0,0,0-4.13-4.13h0Z",
                                "translate(-129.85 -36.88)"
                            )}
                            {this.renderItem(
                                FittingPosition.FrontAxle,
                                "front-axle",
                                "M297.69,150.94a4.13,4.13,0,1,0,4.13,4.13,4.13,4.13,0,0,0-4.13-4.13h0Z",
                                "translate(-129.85 -36.88)"
                            )}
                            {this.renderItem(
                                FittingPosition.FrontAxle,
                                "front-axle",
                                "M255.14,174.26a13,13,0,0,1-1.55-.09l-43.75-5.24A14.4,14.4,0,1,1,218,141.76l17.15,8.59a44.62,44.62,0,0,0,40,0l17.15-8.59a14.39,14.39,0,1,1,8.16,27.16l-43.75,5.24A13.05,13.05,0,0,1,255.14,174.26Zm42.55-28.14a9,9,0,1,0,9,9A9,9,0,0,0,297.69,146.11Zm-86.27,0a9,9,0,1,0,9,9A9,9,0,0,0,211.42,146.11Z",
                                "translate(-129.85 -36.88)"
                            )}
                        </g>
                    </g>
                    <g id="rear">
                        {this.renderItem(
                            FittingPosition.RearAxle,
                            "rear-axle",
                            "M258.06,309H252.6c-12.75,0-22.6,10.63-22.6,23.38V451.75A23,23,0,0,0,252.88,475h-0.44A28.71,28.71,0,0,0,281,446.29V332.38A23.14,23.14,0,0,0,258.06,309Z",
                            "translate(-129.85 -36.88)"
                        )}
                        {this.renderItem(
                            FittingPosition.RearAxle,
                            "rear-axle",
                            "M252.6,300h5.46a25.94,25.94,0,0,1,6.94.7v-44l-20,20.86v23.18A36.4,36.4,0,0,1,252.6,300Z",
                            "translate(-129.85 -36.88)"
                        )}
                    </g>
                </svg>
            )
        }

        return (
            <svg className="fitting-position" viewBox="0 0 91 124" version="1.1">
                <g id="front">
                    {this.renderItem(
                        FittingPosition.FrontAxle | FittingPosition.Left,
                        "front-left",
                        "M2.14690257,27.3364232 C1.97200588,27.247082 1.78903314,27.1711168 1.62372675,27.0668854 C0.896126211,26.607056 0.479453134,25.9693014 0.47970551,25.0819467 C0.482481648,17.501071 0.478948382,9.92019518 0.483743529,2.33957179 C0.484500658,1.05018187 1.46700114,0.0732336702 2.76017669,0.0699527798 C5.74099179,0.0623814943 8.72205925,0.0623814943 11.7028743,0.0699527798 C12.9955452,0.0732336702 13.9777933,1.05018187 13.9785504,2.33982416 C13.9835979,9.92044756 13.9808218,17.5013233 13.9813265,25.0821991 C13.9815789,26.2451485 13.2973871,26.9000647 12.3156437,27.3364232 L2.14690257,27.3364232 Z"
                    )}
                    {this.renderItem(
                        FittingPosition.FrontAxle | FittingPosition.Right,
                        "front-right",
                        "M66.7077112,27.3364232 C66.5330669,27.247082 66.3498418,27.1711168 66.1845354,27.0668854 C65.4569348,26.607056 65.0402617,25.9693014 65.0407665,25.0819467 C65.0432903,17.501071 65.039757,9.92044756 65.0445521,2.33957179 C65.0453093,1.05018187 66.0280621,0.0732336702 67.3212377,0.0699527798 C70.3018004,0.0623814943 73.2828679,0.0623814943 76.263683,0.0699527798 C77.5563538,0.0732336702 78.5386019,1.05018187 78.5396114,2.33982416 C78.5444065,9.92044756 78.5418827,17.5013233 78.5423875,25.0821991 C78.5423875,26.2451485 77.858448,26.9000647 76.8764523,27.3364232 L66.7077112,27.3364232 Z"
                    )}
                    {this.renderItem(
                        FittingPosition.FrontAxle,
                        "front-axle",
                        "M47.6172433,9.20527932 L47.6172433,6.94054102 C47.6172433,6.19704079 47.014569,5.59411409 46.2708164,5.59411409 L32.7512766,5.59411409 C32.0077764,5.59411409 31.4048497,6.19704079 31.4048497,6.94054102 L31.4048497,9.20527932 C31.4048497,9.75756407 30.9571345,10.2052793 30.4048497,10.2052793 L26.2298761,10.2052793 C25.6163496,10.2052793 25.1189161,10.7027128 25.1189161,11.3159869 L25.1189161,16.5969585 C25.1189161,17.2102326 25.6163496,17.7076661 26.2298761,17.7076661 L30.4048497,17.7076661 C30.9571345,17.7076661 31.4048497,18.1553813 31.4048497,18.7076661 L31.4048497,20.4600807 C31.4048497,21.2038333 32.0077764,21.8065077 32.7512766,21.8065077 L34.5655235,21.8065077 C35.1178082,21.8065077 35.5655235,22.2542229 35.5655235,22.8065077 L35.5655235,32.4825249 C34.8298469,32.732125 33.8306211,32.8073622 33.4665107,33.4380212 C34.4022414,34.3916842 35.4462376,35.4356803 36.5984992,36.5700096 C37.439669,36.2353588 38.3562993,36.0493575 39.316843,36.0493575 C43.3811091,36.0493575 46.6761325,39.3441286 46.6761325,43.408647 C46.6761325,44.3694431 46.4901313,45.2855687 46.1554804,46.1269909 C47.129989,46.9412052 48.2172256,48.0284418 49.4171902,49.3887007 C50.433383,47.5936657 50.9905034,45.67019 50.9905034,43.5368541 C50.9905034,38.4022607 47.6742803,34.0452383 43.0679102,32.4825249 L43.0679102,22.8065077 C43.0679102,22.2542229 43.5156255,21.8065077 44.0679102,21.8065077 L46.2708164,21.8065077 C47.014569,21.8065077 47.6172433,21.2038333 47.6172433,20.4600807 L47.6172433,18.7076661 C47.6172433,18.1553813 48.0649585,17.7076661 48.6172433,17.7076661 L52.40381,17.7076661 C53.0173365,17.7076661 53.5145176,17.2102326 53.5145176,16.5969585 L53.5145176,11.3159869 C53.5145176,10.7027128 53.0173365,10.2052793 52.40381,10.2052793 L48.6172433,10.2052793 C48.0649585,10.2052793 47.6172433,9.75756407 47.6172433,9.20527932 Z"
                    )}
                </g>
                <g id="rear">
                    {this.renderItem(
                        FittingPosition.RearAxle | FittingPosition.Left,
                        "rear-left",
                        "M2.14690257,115.349822 C1.97200588,115.26048 1.78903314,115.184515 1.62372675,115.080031 C0.896126211,114.620202 0.479453134,113.9827 0.47970551,113.095093 C0.482481648,105.514217 0.478948382,97.9335936 0.483743529,90.3527179 C0.484500658,89.0635803 1.46700114,88.0866321 2.76017669,88.0833512 C5.74099179,88.0757799 8.72205925,88.0757799 11.7028743,88.0833512 C12.9955452,88.0866321 13.9777933,89.0635803 13.9785504,90.3529702 C13.9835979,97.933846 13.9808218,105.514469 13.9813265,113.095345 C13.9815789,114.258295 13.2973871,114.913463 12.3156437,115.349822 L2.14690257,115.349822 Z"
                    )}
                    {this.renderItem(
                        FittingPosition.RearAxle | FittingPosition.Right,
                        "rear-right",
                        "M66.7077112,115.349822 C66.5330669,115.26048 66.3498418,115.184515 66.1845354,115.080031 C65.4569348,114.620202 65.0402617,113.9827 65.0407665,113.095093 C65.0432903,105.514217 65.039757,97.9335936 65.0445521,90.3527179 C65.0453093,89.0633279 66.0280621,88.0866321 67.3212377,88.0833512 C70.3018004,88.0757799 73.2828679,88.0757799 76.263683,88.0833512 C77.5563538,88.0866321 78.5386019,89.0635803 78.5396114,90.3529702 C78.5444065,97.933846 78.5418827,105.514469 78.5423875,113.095345 C78.5423875,114.258295 77.858448,114.913463 76.8764523,115.349822 L66.7077112,115.349822 Z"
                    )}
                    {this.renderItem(
                        FittingPosition.RearAxle,
                        "rear-axle",
                        "M52.40381,97.9624655 L44.1786178,97.9624655 C43.5653437,97.9624655 43.0679102,97.465032 43.0679102,96.8517579 L43.0679102,83.1876066 C43.954003,82.8870266 44.7881063,82.4776724 45.5639107,81.984277 L44.433013,80.8531269 L42.3085103,78.7288766 C41.3941514,79.1362118 40.3823753,79.3648646 39.316843,79.3648646 C35.2523246,79.3648646 31.9573012,76.0698411 31.9573012,72.0053227 C31.9573012,70.9395381 32.1857016,69.927762 32.5930368,69.0134031 L30.4687865,66.8889004 L29.4605436,65.8809099 C28.3107177,67.6881757 27.6429303,69.8321114 27.6429303,72.1332774 C27.6429303,77.2678709 30.9591534,81.6248933 35.5655235,83.1876066 L35.5655235,96.8517579 C35.5655235,97.465032 35.0683424,97.9624655 34.4548159,97.9624655 L26.2298761,97.9624655 C25.6163496,97.9624655 25.1189161,98.4598989 25.1189161,99.073173 L25.1189161,104.353892 C25.1189161,104.967671 25.6163496,105.464852 26.2298761,105.464852 L52.40381,105.464852 C53.0173365,105.464852 53.5145176,104.967671 53.5145176,104.353892 L53.5145176,99.073173 C53.5145176,98.4598989 53.0173365,97.9624655 52.40381,97.9624655"
                    )}
                </g>
                <g id="arrow">
                    <path
                        d="M43.4569492,55.8913702 L39.3167168,52.0492067 L35.1764845,55.8913702 L31.7809353,55.8913702 L39.3167168,48.8981141 L46.8524984,55.8913702 L43.4569492,55.8913702 Z"
                        fill="#202020"
                        opacity="0.38"
                    />
                    <path
                        d="M43.4569492,62.8514206 L39.3167168,59.0092571 L35.1764845,62.8514206 L31.7809353,62.8514206 L39.3167168,55.8581645 L46.8524984,62.8514206 L43.4569492,62.8514206 Z"
                        fill="#202020"
                        opacity="0.38"
                    />
                </g>
            </svg>
        )
    }

    render() {
        const { tirePressure } = this.props

        if (tirePressure) {
            const { frontLeft, frontRight, rearLeft, rearRight } = tirePressure

            return (
                <div className={style.fittingPosition}>
                    {(frontLeft || rearLeft) && (
                        <div className={bem(style.lateral, "left")}>
                            {frontLeft && (
                                <Text className={style.textTop} modifiers={["highlight", "strong"]} size="m">
                                    {frontLeft}
                                </Text>
                            )}
                            {rearLeft && (
                                <Text modifiers={["highlight", "strong"]} size="m">
                                    {rearLeft}
                                </Text>
                            )}
                        </div>
                    )}
                    <div className={style.fittingPositionSvg}>{this.renderImage()}</div>
                    {(frontRight || rearRight) && (
                        <div className={bem(style.lateral, "right")}>
                            {frontRight && (
                                <Text className={style.textTop} modifiers={["highlight", "strong"]} size="m">
                                    {frontRight}
                                </Text>
                            )}
                            {rearRight && (
                                <Text modifiers={["highlight", "strong"]} size="m">
                                    {rearRight}
                                </Text>
                            )}
                        </div>
                    )}
                </div>
            )
        }

        return this.renderImage()
    }
}

const style = useStyle({
    fittingPosition: {
        display: "inline-flex",
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
    },
    lateral: {
        display: "flex",
        flex: 1,
        flexFlow: "column",
        $nest: {
            "&--left": {
                alignItems: "flex-end",
            },
            "&--right": {
                alignItems: "flex-start",
            },
        },
    },
    fittingPositionSvg: {
        marginLeft: em(0.75),
        paddingTop: em(0.75),
    },
    textTop: {
        display: "flex",
        flex: 1,
    },
})(() => null)

import { Box, Stack, styled } from "@mui/material"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Typography } from "../../generics/typographie"
import { Icon } from "../../generics/Icons"
import { Tooltip } from "../../generics/tooltip"

type DescriptionsProps = {
    oneLine?: boolean
}

const SingleLineTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "oneLine",
})<DescriptionsProps>(({ oneLine }) => ({
    ...(oneLine && {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
    }),
}))

export function Descriptions({ oneLine }: DescriptionsProps) {
    const { description, missingVehicleConnectionTitle, additionalDescriptions } = useDefaultArticleItemState((x) => x.displayArticle.description)

    return (
        <Box>
            {additionalDescriptions?.map((additionalDescription) => (
                <Stack key={additionalDescription} direction="row" gap={0.5}>
                    <Icon name="plus" />
                    <Typography variant="body2">{additionalDescription}</Typography>
                </Stack>
            ))}
            {!!description && !oneLine && (
                <SingleLineTypography oneLine={oneLine} variant="body2">
                    {description}
                </SingleLineTypography>
            )}
            {!!description && oneLine && (
                <Tooltip title={description}>
                    <SingleLineTypography oneLine={oneLine} variant="body2">
                        {description}
                    </SingleLineTypography>
                </Tooltip>
            )}
            {missingVehicleConnectionTitle && (
                <Box>
                    <Typography variant="body2">{missingVehicleConnectionTitle}</Typography>
                    <Icon name="missing-vehicle-connection" size="l" />
                </Box>
            )}
        </Box>
    )
}

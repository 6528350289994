import { memo } from "react"
import { Box, Grid, Stack, styled } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ArticleNumbers } from "../../ArticleNumbers"
import { DefaultArticleErpContainer } from "../../ErpContainer"
import { Image } from "../../Image"
import { Supplier } from "../../Supplier"
import { Transactions } from "../../Transactions"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { Icon } from "../../../../generics/Icons"
import { Divider } from "../../../../generics/divider"
import { CompactAttributes } from "../../Attributes/CompactAttributes"
import { ExpandIconButton, GridItem } from "./ArticleItemComponents"
import { CompactAdditionalInformations } from "../../AdditionalInformations/CompactAdditionalInformations"
import { Tooltip } from "../../../../generics/tooltip"
import { BonusSystem } from "../../BonusSystem"
import { useArticleItemState } from "../../ContextProviderStates"
import { SeasonIconWithTyreEfficiency } from "../Tyres/components/SeasonIconWithTyreEfficiency"
import { Descriptions } from "../../Descriptions"
import { InformationAreaActions } from "../../Actions/InformationAreaActions"
import { ImageAreaActions } from "../../Actions/ImageAreaActions"

const MainInformationContainer = styled(Stack)(({ theme }) => {
    return {
        justifyContent: "start",
        spacing: theme.spacing(),
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
    }
})

const RightContainer = styled(Box)({
    padding: "0 10px 5px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "row",
    gap: 10,
    justifyContent: "end",
    alignItems: "end",
})

const ImageContainer = styled(Box)({
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
}) as typeof Grid

const CompactArticleContainer = styled(Box)({
    display: "grid",
    gridTemplateColumns: "50px 1fr",
    minHeight: 85,
})

const SupplierSpacing = styled(Box)({
    width: "90px", // make sure to align the attribute number for all articles
})

export const DefaultCompactArticleContentComponent = memo(() => {
    const toggleExpand = useDefaultArticleItemState((x) => x.toggleExpand)
    const showDescriptionCompactView = useDefaultArticleItemState((x) => x.showDescriptionCompactView)
    const isTyres = useArticleItemState((x) => x.type === "Tyres")

    const { translateText } = useLocalization()

    const handleExpand = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListExpandArticle, false)
        toggleExpand()
    }

    return (
        <CompactArticleContainer className="compactArticleItem">
            <ImageContainer>
                {/* Article Image */}
                <Grid item>
                    <Stack spacing={1} alignItems="flex-start" maxWidth="36px">
                        <Image />
                        <BonusSystem />
                        <ImageAreaActions />
                    </Stack>
                </Grid>

                {/* Expand Icon */}
                <Grid item>
                    <Tooltip title={translateText(12569)}>
                        <ExpandIconButton onClick={handleExpand} hoverEffect disableRipple>
                            <Icon name="plus" />
                        </ExpandIconButton>
                    </Tooltip>
                </Grid>
            </ImageContainer>

            <Stack>
                <Stack direction="row" padding={1} justifyContent="space-between">
                    {/* Article Infos */}
                    <MainInformationContainer divider={<Divider orientation="vertical" flexItem />} gap={1}>
                        <SupplierSpacing>
                            <Supplier compactView />
                        </SupplierSpacing>
                        <ArticleNumbers />
                        {showDescriptionCompactView && <Descriptions />}
                    </MainInformationContainer>
                    {/* Article Icons and Buttons */}
                    <InformationAreaActions />
                </Stack>

                <Box display="grid" gridTemplateColumns="1fr auto" mt={1}>
                    {/* Article Attributes and other Infos */}
                    <GridItem overflow="hidden">
                        <Stack gap="4px" direction="row" key="vehicleAttribute" alignItems="center">
                            {!isTyres && (
                                <>
                                    <CompactAttributes />
                                    <CompactAdditionalInformations />
                                </>
                            )}
                            {isTyres && <SeasonIconWithTyreEfficiency isCompact />}
                        </Stack>
                    </GridItem>
                    {/* ERP Infos */}
                    <RightContainer className="rightColumn">
                        <DefaultArticleErpContainer />
                        <Transactions />
                    </RightContainer>
                </Box>
            </Stack>
        </CompactArticleContainer>
    )
})

import { Box, Button, Theme, Tooltip, useTheme, styled } from "@mui/material"
import { ArticleAttribute, EFilterNames, FilterNames } from "@tm/models"
import { bem, classes } from "@tm/utils"
import { useMemo } from "react"
import { Icon } from "../../../../../generics/Icons"
import { getFuelEfficiencyLabelColor } from "../helpers/getFuelEfficiencyLabelColor"
import { getOldTyreLabelColor } from "../helpers/getOldTyreLabelColor"
import { getWetGripClassLabelColor } from "../helpers/getWetGripClassLabelColor"

type SelectableArticleAttribute = ArticleAttribute & { selected?: boolean } & { group?: FilterNames }

type Direction = "row" | "column"

type Props = {
    items: SelectableArticleAttribute[]
    noiseLevel?: ArticleAttribute
    direction?: Direction
    onSelect?: (article: ArticleAttribute) => void
}

const iconMapping: { [key in FilterNames]?: string } = {
    fuelEfficiency: "gas-station",
    wetGripClass: "rain",
    externalRolling: "sound",
}

const backgroundColorMapping: { [key in FilterNames]?: (value: string, theme: Theme) => string } = {
    fuelEfficiency: getFuelEfficiencyLabelColor,
    wetGripClass: getWetGripClassLabelColor,
    externalRolling: () => "#aaaaaa",
}

export default function TyresIconsV2({ items, noiseLevel, direction = "row", onSelect }: Props) {
    const theme = useTheme()
    const isNewLabelTyre = useMemo(() => noiseLevel?.value === "A" || noiseLevel?.value === "B" || noiseLevel?.value === "C", [noiseLevel])

    const getLabelBackgroundColor = (attribute: SelectableArticleAttribute) => {
        if ((!isNewLabelTyre && attribute.group !== EFilterNames.externalRolling) || !attribute.group) {
            return getOldTyreLabelColor(attribute.value, theme)
        }

        return (backgroundColorMapping[attribute.group] ?? getOldTyreLabelColor)?.(attribute.value, theme)
    }

    const getLabelTextColor = (attribute: SelectableArticleAttribute) => {
        if (isNewLabelTyre && attribute.group === EFilterNames.wetGripClass) {
            return theme.palette.common.white
        }

        return theme.colors?.dark ?? "black"
    }

    const renderIcon = (attribute: SelectableArticleAttribute, key: number) => {
        return (
            <Tooltip key={key} title={attribute.description}>
                <StyledIconButton
                    size="small"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    className={classes(bem("buttonNumber", attribute.value), attribute.selected && "selected")}
                    startIcon={<Icon name={attribute.group ? iconMapping[attribute.group] : undefined} size="12px" />}
                    value={attribute.value}
                    onClick={() => onSelect?.(attribute)}
                    backgroundColor={getLabelBackgroundColor(attribute)}
                    sx={{ color: getLabelTextColor(attribute) }}
                >
                    {`${attribute.value} ${isNewLabelTyre && attribute.group === EFilterNames.externalRolling ? noiseLevel?.value : ""}`}
                </StyledIconButton>
            </Tooltip>
        )
    }

    return (
        <StyledIconsContainer flexDirection={direction}>
            {items.map(renderIcon)}
            {/* might be deleted later, depends if we want to remove image on tile */}
            {!items.length && direction === "column" && <Box width="3em" height="6.4em" />}
        </StyledIconsContainer>
    )
}

const StyledIconsContainer = styled(Box)(({ theme }) => ({
    ".selected": {
        outline: "1px",
        outlineColor: theme.palette.primary,
        outlineStyle: "auto",
    },
    display: "flex",
    gap: ".2em",
    alignItems: "flex-end",
}))

export const StyledIconButton = styled(Button)<{ backgroundColor?: string }>(({ backgroundColor }) => ({
    cursor: "default",
    padding: "0.5em",
    width: "auto",
    minWidth: "24px",
    height: "24px",
    backgroundColor,
    fontWeight: "600",
    "&:hover": {
        backgroundColor,
    },
    ".MuiButton-startIcon": {
        marginRight: "0.5em",
    },
}))

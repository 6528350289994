import { useQueryClient, UseQueryOptions, useQueries, QueryClient } from "react-query"
import { Container } from "@tm/nexus"
import { Article, ErpBundleParams, ErpContainer, ErpInformation, ErpInformationRequestItem, RegisteredModels } from "@tm/models"

import { useRecoilValue } from "recoil"
import Morpheus from "@tm/morpheus"
import { createErpInformationRequestItem } from "../../helpers/erp"
import { selectedExternalArticleDetailsState, selectedOrderOptionState } from "../../appState/parts/partDetails"

type Request = {
    articles: Article[]
    type?: "list" | "details"
    telesalesCustomerNo?: string
    distributorId?: number
    vehicleId?: string
    searchQuery?: string
}

function getQueryKey(
    { type, telesalesCustomerNo, distributorId, searchQuery }: Omit<Request, "articles" | "vehicleId">,
    requestItem: ErpInformationRequestItem
) {
    return ["ERP_INFORMATION", { type, telesalesCustomerNo, distributorId, searchQuery }, requestItem]
}

type QueryOptions = UseQueryOptions<ErpInformation, string | null>

function generateQueryBody(
    request: Omit<Request, "articles" | "vehicleId">,
    requestItem: ErpInformationRequestItem,
    queryOptions: QueryOptions,
    queryClient: QueryClient
): QueryOptions {
    const { erpCacheStaleTimeSeconds = 0 } = Morpheus.getParams<ErpBundleParams>("erp")

    // https://jira.dvse.de/browse/NEXT-24571
    // Problem liegt beim erstellen das Keys, hier wird immer der erste Artikel genommen, wenn dieser durch die verfügbarkeit weg gefiltert wird
    // wie im Ticket beschrieben, ändert sich der Key und der QUerry store wird invalide, das müssen wir abfangen / ändern, vielleicht die Filteroptionen als Key setzen?
    const queryKey = getQueryKey(request, requestItem)

    // console.log("ABCK: ", queryKey)

    return {
        queryKey,
        queryFn: () => {
            const Erp: ErpContainer = Container.getInstance(RegisteredModels.ERP)
            return Erp.action(request.type === "details" ? "getErpInfoDetails" : "getErpInfo")({
                item: requestItem,
                distributorId: request.distributorId,
                telesalesCustomerNo: request.telesalesCustomerNo,
                foundBySearchTerm: request.searchQuery,
            })
        },
        ...queryOptions,
        // The staleTime is being used to avoid a second request when the quantity is automatically changed to match the division
        // In combination with "setQueryData" in "onSuccess"
        staleTime: (queryOptions.staleTime ?? erpCacheStaleTimeSeconds > 0) ? erpCacheStaleTimeSeconds * 1000 : undefined,
        onSuccess: (data) => {
            queryOptions.onSuccess?.(data)

            const selectedShipmentId = data.specialProcurementErpInformation?.orderOptions.shipmentModes?.shipmentModes.find((e) => e.isSelected)?.id

            // Also store response with a different key in cache to avoid a second request when the shipment mode is automatically selected to match the default
            // In combination with "staleTime"
            if (requestItem && selectedShipmentId !== undefined) {
                queryClient.setQueryData(
                    getQueryKey(request, {
                        ...requestItem,
                        selectedShipmentModeId: selectedShipmentId,
                    }),
                    data
                )
            }

            // Also store response with a different key in cache to avoid a second request when the quantity is automatically changed to match the division
            // In combination with "staleTime"
            if (requestItem && requestItem.quantityValue === 1 && data.quantity?.division !== undefined && data.quantity.division !== 1) {
                queryClient.setQueryData(
                    getQueryKey(request, {
                        ...requestItem,
                        selectedShipmentModeId: selectedShipmentId,
                        quantityValue: data.quantity.division,
                    }),
                    data
                )
            }
        },
    }
}

export function useGetDetailedErpInfo(request: Request, queryOptions: QueryOptions = {}): { data: ErpInformation[] | undefined; isLoading: boolean } {
    const queryClient = useQueryClient()
    const selectedOrderOption = useRecoilValue(selectedOrderOptionState)
    const selectedExternalArticleDetails = useRecoilValue(selectedExternalArticleDetailsState)
    const { externalBasketItemId } = selectedExternalArticleDetails

    const result = useQueries(
        request.articles
            .filter((x) => x.requestErpInfo)
            .map((x) =>
                generateQueryBody(
                    request,
                    createErpInformationRequestItem(x, request.vehicleId, selectedOrderOption?.orderOption?.id, undefined, externalBasketItemId),
                    queryOptions,
                    queryClient
                )
            )
    )

    let isLoading = false
    let isFetching = false
    const data: ErpInformation[] = []

    result.forEach((x) => {
        isLoading ||= x.isLoading
        isFetching ||= x.isFetching

        if (x.data) {
            data.push(x.data)
        }
    })

    return {
        data: !isFetching && data.length ? data : undefined,
        isLoading: isLoading || isFetching,
    }
}

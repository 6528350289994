import { Stack, styled } from "@mui/material"
import { ArticleAttributeParams } from "@tm/models"
import { memo, useEffect, useLayoutEffect, useMemo, useRef } from "react"
import { debounce } from "lodash"
import { AttributeItem } from "./AttributeItem"

const CollapsedAttributesBlock = styled(Stack)({
    flexWrap: "nowrap",
    display: "flex",
    flexDirection: "row",
    maxWidth: "100%",
    flex: 1,
    lineHeight: 1,
    minWidth: "0",

    ".flex-shrink": {
        paddingRight: 0,
        flexShrink: 1,
        flexGrow: 1,
        flexBasis: "0px",
    },

    ".hidden": {
        display: "none",
    },
})

type Props = {
    visibleArticleAttributes?: ArticleAttributeParams[]
    handleClick?: (is: number, value?: string | undefined) => void
}

export const CompactAttributesBlock = memo(({ visibleArticleAttributes, handleClick }: Props) => {
    const compactAttributesBlockRef = useRef<HTMLDivElement>(null)

    const observer = useMemo(() => new ResizeObserver(debounce(shrinkContent, 100)), [])

    useEffect(() => {
        return () => observer.disconnect()
    }, [observer])

    useLayoutEffect(() => {
        if (compactAttributesBlockRef?.current) {
            observer.observe(compactAttributesBlockRef.current)
        }
    }, [observer])

    function shrinkContent(entries: ResizeObserverEntry[]) {
        if (!compactAttributesBlockRef?.current?.children?.length || !entries?.length) {
            return
        }
        const entry = entries[0]
        const parentReferenceValue = entry.contentRect.width + entry.target.getBoundingClientRect().left
        const children = Array.from(compactAttributesBlockRef.current.children)
        let shrinkedItemFound = false

        const lastChildRightOffset = children[children.length - 1].getBoundingClientRect().right

        // break when lastChild is visible (rightOffset > 0) and lastChild does not extent the parent
        if (lastChildRightOffset > 0 && lastChildRightOffset < entry.target.getBoundingClientRect().right) {
            return
        }

        children.forEach((item, index) => {
            // make sure to delete the classes (for expanding)
            item.classList.remove("flex-shrink")
            // only delete this class when no item was found yet, to avoid the case that we set this value with a previous item
            if (!shrinkedItemFound) {
                item.classList.remove("hidden")
            }

            // add 1 px for the divider between the attributes
            if (item.getBoundingClientRect().left + item.clientWidth + 1 >= parentReferenceValue) {
                item.classList.add("flex-shrink")
                shrinkedItemFound = true

                // all attributes after this one have to be hidden due to the shrinking priority of the children (label and value)
                // else every label of following will overlap the value of the last one that should be visible
                for (let i = index + 1; i < children.length; i++) {
                    children[i].classList.add("hidden")
                }
            }
        })
    }

    if (!visibleArticleAttributes?.length) {
        return <CollapsedAttributesBlock> - </CollapsedAttributesBlock>
    }

    return (
        <CollapsedAttributesBlock ref={compactAttributesBlockRef}>
            {visibleArticleAttributes?.map((attr, index) => (
                <AttributeItem
                    key={`${attr.id}:${attr.key}`}
                    attribute={attr}
                    onClickAttributeValue={handleClick}
                    hideDivider={index === 0}
                    dividerInFront
                />
            ))}
        </CollapsedAttributesBlock>
    )
})

import { OAuth2Client, generateCodeVerifier } from "@badgateway/oauth2-client"
import { OAuthConfig } from "@tm/models"
import jwtDecode from "jwt-decode"
import { Authorization, StoredLogin } from ".."

const CoopMemberIdKey = "CoopMemberId"

export function decodeJwtToken<T>(token: string) {
    return jwtDecode<T>(token)
}

export function removeStoredLogin() {
    sessionStorage.removeItem("login")
    sessionStorage.removeItem("readConfigNews")
    sessionStorage.removeItem("IFRAME_WIDGET_TOKEN_SeatbeltCrm")
    sessionStorage.removeItem(CoopMemberIdKey)

    // NEXT Shell should also be informed that the user is logged out
    if (typeof tmJSEvents != "undefined" && typeof tmJSEvents.logout == "function") {
        tmJSEvents.logout()
    }
}

export function setStoredLogin(login: StoredLogin) {
    sessionStorage.setItem("login", JSON.stringify(login))
}

export function hasValidStoredLogin() {
    const login = getStoredLogin()
    if (login) {
        login.expireDate = login.expireDate && new Date(login.expireDate)
    }

    return login && login.token && (!login.expireDate || login.expireDate > new Date())
}

export function getStoredLogin(): StoredLogin | undefined {
    const storedLogin = sessionStorage.getItem("login")

    if (storedLogin) {
        return JSON.parse(storedLogin)
    }
}

export function getStoredAuthorization(): Authorization | null {
    const login = getStoredLogin()

    if (login?.token) {
        return {
            type: "Bearer",
            credentials: login.token,
        }
    }

    return null
}

export function setLocaleToLocalStorage(language: string, languageId: string): void {
    localStorage.setItem("language", language)
    localStorage.setItem("languageId", languageId)
}

export function getLanguageFromLocalStorage(): string | null {
    return localStorage.getItem("language")
}

export function getLanguageIdFromLocalStorage(): string | null {
    return localStorage.getItem("languageId")
}

export function hasLocaleInLocalStorage(): boolean {
    const language = getLanguageFromLocalStorage()
    const languageId = getLanguageIdFromLocalStorage()
    return !!language && !!languageId
}

export function getCoopMemberId(): string | null {
    return sessionStorage.getItem(CoopMemberIdKey)
}

export function setCoopMemberId(memberId: string | undefined): void {
    if (memberId) {
        sessionStorage.setItem(CoopMemberIdKey, memberId)
    } else {
        sessionStorage.removeItem(CoopMemberIdKey)
    }
}

export type OAuthData = {
    redirectUri: string
    codeVerifier: string
    state?: string
    authorizationCode?: string
}

const OAUTH_DATA_STORAGE_KEY = "oAuthData"

export function storeOAuthData(data: OAuthData) {
    sessionStorage.setItem(OAUTH_DATA_STORAGE_KEY, JSON.stringify(data))
}

export function getStoredOAuthData(): OAuthData | undefined {
    const storedData = sessionStorage.getItem(OAUTH_DATA_STORAGE_KEY)

    if (storedData) {
        return JSON.parse(storedData)
    }
}

export function deleteStoredOAuthData() {
    sessionStorage.removeItem(OAUTH_DATA_STORAGE_KEY)
}

export function generatePKCECodeVerifier(): Promise<string> {
    return generateCodeVerifier()
}

export function getOAuthClient(config: OAuthConfig, isProd: boolean): OAuth2Client {
    const { server, discoveryEndpoint, clientId } = config

    return new OAuth2Client({
        server: getServerUrl(server, isProd),
        discoveryEndpoint,
        clientId,
    })
}

function getServerUrl(server: OAuthConfig["server"], isProd: boolean): string {
    if (typeof server === "string") {
        return server
    }

    if (server.overwrite && !server.overwrite.includes("Placeholder not found")) {
        return server.overwrite
    }

    if (!isProd) {
        return server.staging
    }

    return server.production
}

import { styled } from "@mui/material"
import { FC, HTMLAttributes, PropsWithChildren } from "react"

type WrapperProps = PropsWithChildren<{
    customRow?: boolean
    active: boolean
    colCount: number
    singleRow?: boolean
    rowIndicatorColor?: string
    transparent?: boolean
    transparentHighlight?: boolean
}>

type WrapperType = FC<WrapperProps & HTMLAttributes<HTMLDivElement>>

function shouldForwardProp(prop: PropertyKey) {
    return !["colCount", "customRow", "active", "singleRow", "rowIndicatorColor", "transparent", "transparentHighlight"].includes(prop as string)
}

export const Wrapper: WrapperType = styled("div", { shouldForwardProp })<WrapperProps>((props) => {
    const { theme, colCount, customRow, active, singleRow, rowIndicatorColor, transparent, transparentHighlight } = props

    const renderIfCellActiveStateIs = (status: boolean, value: string): string => {
        return active === status ? value : ""
    }

    return {
        display: "contents",
        "> div": {
            borderLeftColor: renderIfCellActiveStateIs(true, transparent ? "transparent" : rowIndicatorColor || theme.palette.primary.main),
            borderRight: renderIfCellActiveStateIs(
                true,
                `1px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.primary.main}`
            ),
            boxShadow: renderIfCellActiveStateIs(true, "0px 4px 4px rgba(0,0,0,0.05)"),

            "&:last-child": {
                borderBottom: renderIfCellActiveStateIs(
                    true,
                    `1px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.primary.main}`
                ),
            },
        },

        "> span": {
            backgroundColor: renderIfCellActiveStateIs(true, `${theme.palette.grey[50]} !important`),
            borderTop: renderIfCellActiveStateIs(true, `1px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.primary.main}`),
            borderRight:
                customRow &&
                renderIfCellActiveStateIs(true, `1px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.primary.main}`),

            [`&:nth-of-type(${colCount}n+1)`]: {
                borderLeftColor: renderIfCellActiveStateIs(true, transparent ? "transparent" : rowIndicatorColor || theme.palette.primary.main),
            },
            [`&:nth-of-type(${colCount}n)`]: {
                borderRight: renderIfCellActiveStateIs(
                    true,
                    `1px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.primary.main}`
                ),
            },
        },

        ":hover > span": {
            borderTop: renderIfCellActiveStateIs(
                false,
                `1px solid ${transparentHighlight ? "transparent" : rowIndicatorColor || theme.palette.primary.light}`
            ),

            [`&:nth-of-type(${colCount}n+1)`]: {
                borderLeftColor: renderIfCellActiveStateIs(
                    false,
                    transparentHighlight ? "transparent" : rowIndicatorColor || theme.palette.primary.light
                ),
            },
            [`&:nth-of-type(${colCount}n)`]: {
                borderRight: renderIfCellActiveStateIs(
                    false,
                    `1px solid ${transparentHighlight ? "transparent" : rowIndicatorColor || theme.palette.primary.light}`
                ),
            },

            ...(singleRow && {
                [`&:nth-of-type(${colCount + 1}n)`]: {
                    borderTop: "none",
                    borderRight: renderIfCellActiveStateIs(
                        false,
                        `1px solid ${transparentHighlight ? "transparent" : rowIndicatorColor || theme.palette.primary.light}`
                    ),
                },
            }),

            borderRight: customRow && !active ? `1px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.primary.light}` : "",
        },
        ":hover > div": {
            borderLeftColor: renderIfCellActiveStateIs(
                false,
                transparentHighlight ? "transparent" : rowIndicatorColor || theme.palette.primary.light
            ),
            borderRight: renderIfCellActiveStateIs(
                false,
                `1px solid ${transparentHighlight ? "transparent" : rowIndicatorColor || theme.palette.primary.light}`
            ),
            "&:last-child": {
                borderBottom: renderIfCellActiveStateIs(
                    false,
                    `1px solid ${transparentHighlight ? "transparent" : rowIndicatorColor || theme.palette.primary.light}`
                ),
            },
        },
    }
})

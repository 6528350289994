import { Component, PropsWithChildren } from "react"
import { getWindowSize } from "."
import { SizeContext } from "./context"

type Props = PropsWithChildren

type State = {
    windowSize: "xs" | "s" | "m" | "l" | "xl"
}

export class SizeProvider extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            windowSize: getWindowSize(),
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this))
    }

    updateDimensions() {
        const windowSize = getWindowSize()
        if (this.state.windowSize != windowSize) {
            this.setState({ windowSize })
        }
    }

    render() {
        const { children } = this.props
        const { windowSize } = this.state
        return <SizeContext.Provider value={windowSize}>{children}</SizeContext.Provider>
    }
}

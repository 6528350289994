import { styled } from "@mui/material"
import { Icon } from "../../generics/Icons"
import { MessageBubble, MessageBubbleProps } from "./chatMessageBubble"
import { AttachmentButton } from "../../generics/button"

const Stack = styled("div")({
    display: "flex",
    flexDirection: "column",
})

const MessageContainer = styled("div")(({ theme }) => ({
    flex: 1,
    padding: `0 0 ${theme.spacing(1)} 0`,
}))

type BorderBoxProps = {
    color: "success" | "error"
}

const BorderBox = styled("div", {
    shouldForwardProp: (prop) => prop !== "color",
})<BorderBoxProps>(({ theme, color }) => ({
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette[color].main}`,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    margin: `${theme.spacing(2)} ${theme.spacing(2)}`,
    height: "35px",
    fontSize: "12px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    svg: {
        marginRight: `${theme.spacing(1)}`,
    },
}))

type DateStateProps = {
    text: string
    accept: boolean
}

export function DateState({ text, accept }: DateStateProps) {
    const color = accept ? "success" : "error"
    return (
        <BorderBox color={color}>
            <Icon name={accept ? "check-filled" : "error-filled"} width="10px" height="10px" color={color} />
            {text}
        </BorderBox>
    )
}

type Props = MessageBubbleProps & {
    fileName: string
    fileType: "PICTURE" | "PDF" | "KVA"
    fileSize: string
    iconName: string
}

export function MessageFileAttachment(props: Props) {
    return (
        <MessageBubble {...props} headline="Rechnung">
            <Stack>
                {props.children && <MessageContainer> {props.children}</MessageContainer>}
                <div>
                    <AttachmentButton {...props} />
                </div>
            </Stack>
        </MessageBubble>
    )
}

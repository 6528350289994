import { EMatchCodeSearchType, UserModuleType } from "@tm/models"
import { getParameterFromUserContextModule } from "../../helper"
import { useUser } from "../../user"
import { useTyresV2 } from "./useTyresV2"

export function useTyresMatchCodeSearchType(isWidget?: boolean) {
    const { userContext } = useUser() || {}
    const tyresV2 = useTyresV2()
    const tyresMatchCodeSearchType = tyresV2
        ? getParameterFromUserContextModule(userContext, UserModuleType.TMTires, "MatchcodeSearch")
        : EMatchCodeSearchType.widgetAndCatalog

    if (!tyresMatchCodeSearchType) {
        return false
    }

    if (tyresMatchCodeSearchType === EMatchCodeSearchType.widgetAndCatalog) {
        return true
    }

    if (isWidget) {
        if (tyresMatchCodeSearchType === EMatchCodeSearchType.widget) {
            return true
        }
    } else if (tyresMatchCodeSearchType === EMatchCodeSearchType.catalog) {
        return true
    }

    return false
}

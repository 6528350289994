import { Omit } from "@tm/utils"
import { Component, ComponentClass, ComponentType, createElement } from "react"
import { UserProviderContext } from "../model/UserProviderContext"
import { User } from "../model"

export type WithUserContextProps = Pick<User, "userContext">

export function withUserContext<P extends WithUserContextProps>(component: ComponentType<P>): ComponentClass<Omit<P, keyof WithUserContextProps>> {
    return class extends Component<Omit<P, keyof WithUserContextProps>> {
        constructor(props: Omit<P, keyof WithUserContextProps>) {
            super(props)
            this.renderInnerComponent = this.renderInnerComponent.bind(this)
        }

        renderInnerComponent(user: User) {
            const currentProps: any = this.props || {}

            const props: P & WithUserContextProps = {
                ...currentProps,
                userContext: user.userContext,
            }

            return createElement(component, props)
        }

        render() {
            return <UserProviderContext.Consumer>{this.renderInnerComponent}</UserProviderContext.Consumer>
        }
    }
}

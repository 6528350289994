import { cloneElement, FC, isValidElement, PropsWithChildren, ReactElement, useContext } from "react"
import { EasyPopoverContext } from "./context"

export const PopoverActionButton: FC<PropsWithChildren> = ({ children }) => {
    const context = useContext(EasyPopoverContext)

    if (!context) {
        throw new Error("ActionButton should be used inside EasyPopover")
    }

    const { setAnchor } = context

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget)

        if (isValidElement(children) && children.props.onClick) {
            children.props.onClick(event)
        }
    }

    return cloneElement(children as ReactElement, {
        onClick: handleClick,
    })
}

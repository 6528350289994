import bem from "bem-classnames"
import { Component, ReactNode } from "react"
import Icon from "../icon"
import Text from "../text"
import Button from "../button"

export type MessageSystemProps = {
    title: string
    message?: string
    skin?: "warning" | "danger" | "success"
    icon?: string
    children?: ReactNode
    onButtonClick?(action: any): void
}

export class MessageSystem extends Component<MessageSystemProps, any> {
    private static defaultProps: Partial<MessageSystemProps> = {
        icon: "info",
        skin: undefined,
        message: "",
    }

    render() {
        const { skin, message, title, onButtonClick, children } = this.props

        return (
            <div className={bem(style.default, { ...this.props, long: !!message })}>
                <div className={bem(style.content, this.props)}>
                    <div className={bem(style.icon, { ...this.props, iconSize })}>
                        <Icon name={this.setIcon(skin)} size={iconSize} />
                    </div>
                    <div className={bem(style.textWrapper, this.props)}>
                        {title && (
                            <Text modifiers={["strong"]} className={bem(style.message, this.props)}>
                                {title}
                            </Text>
                        )}
                        {message && <Text>{message}</Text>}
                    </div>
                    {onButtonClick && (
                        <Button layout={["holo"]} className={bem(style.closeButton, this.props)} icon="close" onClick={onButtonClick} />
                    )}
                </div>
                {children && <div className={bem(style.additionalContent, this.props)}>{children}</div>}
            </div>
        )
    }

    setIcon(skin?: "warning" | "danger" | "success") {
        let iconName
        switch (skin) {
            case "warning": {
                iconName = "attention-dark"
                break
            }
            case "danger": {
                iconName = "attention-light"
                break
            }
            case "success": {
                iconName = "success-dark"
                break
            }
            default: {
                iconName = "information-light"
                break
            }
        }
        return iconName
    }
}

const componentClassName = "message-system"
const iconSize = "xl"

const style = {
    default: {
        name: `${componentClassName}`,
        modifiers: ["long", "skin"],
    },
    icon: {
        name: `${componentClassName}__icon`,
        modifiers: ["iconSize", "icon"],
    },
    description: {
        name: `${componentClassName}__description`,
    },
    textWrapper: {
        name: `${componentClassName}__text-wrapper`,
    },
    content: {
        name: `${componentClassName}__content`,
    },
    additionalContent: {
        name: `${componentClassName}__additional-content`,
    },
    message: {
        name: `${componentClassName}__message`,
    },
    closeButton: {
        name: `${componentClassName}__close-button`,
    },
}

import { PropsWithChildren } from "react"
import { Grid, GridProps } from "@mui/material"
import { ADDITIONAL_INFO_ICON_SIZE } from "../constants"

export function PriceGrid({ children, ...rest }: PropsWithChildren<GridProps>) {
    return (
        <Grid display="grid" gridTemplateColumns={`1fr auto ${ADDITIONAL_INFO_ICON_SIZE}px`} alignItems="center" columnGap="6px" {...rest}>
            {children}
        </Grid>
    )
}

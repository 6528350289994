import { styled } from "@mui/material"
import { ImgHTMLAttributes } from "react"

const Thumbnail = styled("img")<ImgHTMLAttributes<HTMLImageElement> & { hasDashboardIcon?: boolean }>(({ hasDashboardIcon }) => ({
    objectFit: "contain",
    position: "absolute",
    width: "100px",
    height: "40px",
    ...(hasDashboardIcon ? { left: "49px" } : { left: 0 }),
    clipPath: "inset(0 0 0 50%)",
    transform: "translateX(-50%)",
}))

export function VehicleThumbnail(props: ImgHTMLAttributes<HTMLImageElement> & { hasDashboardIcon?: boolean }) {
    return <Thumbnail {...props} />
}

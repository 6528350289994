import { TyreArticle, TyreArticleAttributes } from "@tm/models"
import { uniqueId } from "../helpers/id"

export function mapTyreArticleToArticle(item: TyreArticle, defaultQuantity?: number): TyreArticle {
    return {
        ...item,
        quantity: defaultQuantity ?? 4,
        id: uniqueId(),
        internalId: +item.id,
        initialQuantity: defaultQuantity ?? 4,
        attributes: mapArticleAttributes(item),
        lastUpdate: item.lastUpdate ? new Date(item.lastUpdate) : undefined,
        vehicleInformation: item.vehicleInformation || [],
    }
}

function mapArticleAttributes(data: any): TyreArticleAttributes[] {
    if (!data) {
        return []
    }

    const attributes: TyreArticleAttributes = {
        topAttributes: data.attributes || [],
        articleAttributes: data.articleAttributes || [],
        vehicleAttributes: data.vehicleAttributes || [],
        partsListAttributes: data.partsListAttributes || [],
    }

    return [attributes]
}

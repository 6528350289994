import VendorScrollbar from "react-custom-scrollbars-2"
import { style } from "typestyle"
import { Component, ComponentClass, ReactElement, ReactNode } from "react"
import { bindMethodsToContext } from "../../helper"
import Scrollbar from "../scrollbar/index"

export type RealTableProps<T> = {
    /**
     * Array of columns. Defines what should be displayed in the table header.
     */
    columns: Array<ReactElement<RealTableColumnProps> | false | undefined | null>
    /**
     * Array with data for each row that should be displayed.
     * Gets transferred to the renderItemContent function in the TableColumn props.
     * So that function has to create a TableCell, which contains children that should be displayed
     * in that row (to be decided by the rowdata) and column (depending on which TableColumn.renderItemContent function is called).
     */
    data?: Array<T>
    scrollable?: boolean
    className?: string
    height?: string
    tableHeadBackground?: string
    size?: string
    /**
     * Gets the data of one row, to give a custom class name for that row.
     */
    getRowClassName?(rowData: T, rowIndex: number): string
    onClickRow?(item: T): void
    onDoubleClickRow?(item: T): void

    onScrollBottom?(): void
}

export type RealTableColumnProps = {
    /**
     * The element in children will be displayed as Table Header for the column.
     */
    children?: ReactNode
    className?: string
    /**
     * This function has to create a TableCell, which contains children that should be displayed
     * in that row (to be decided by the rowdata) and column (depending on which TableColumn.renderItemContent function is called).
     */
    renderItemContent(rowData: any, rowIndex: number): ReactElement<RealTableCellProps>
}

export type RealTableCellProps = {
    /**
     * The element in children will be displayed as content for the cell.
     */
    children?: ReactNode
    className?: string
    title?: string
}

class RealTableColumn extends Component<RealTableColumnProps, any> {}
class RealTableCell extends Component<RealTableCellProps, any> {}

export default class RealTable<T> extends Component<RealTableProps<T>, any> {
    static Column: ComponentClass<RealTableColumnProps> = RealTableColumn

    static Cell: ComponentClass<RealTableCellProps> = RealTableCell

    private _scrollBarRef: VendorScrollbar | null

    constructor(props: RealTableProps<T>) {
        super(props)
        bindMethodsToContext(this)
    }

    handleClickRow(data: T, e: React.MouseEvent) {
        e && e.stopPropagation()
        this.props.onClickRow?.(data)
    }

    handleDoubleClickRow(data: T, e: React.MouseEvent) {
        e && e.stopPropagation()
        this.props.onDoubleClickRow?.(data)
    }

    handleScroll(e: any) {
        const { onScrollBottom } = this.props
        if (!onScrollBottom) {
            return
        }

        const el = e.target || e.view

        if (el.clientHeight + el.scrollTop >= el.scrollHeight - 20) {
            onScrollBottom()
        }
    }

    renderHead() {
        const { columns } = this.props

        if (!columns.some((c) => !!c && !!c.props.children)) {
            return null
        }

        return (
            <thead className="real-table__head">
                <tr>
                    {columns.map((column, idx) => {
                        if (!column) {
                            return null
                        }

                        return (
                            <th className={`real-table__cell ${column.props.className || ""}`} key={idx} scope="col">
                                {column.props.children}
                            </th>
                        )
                    })}
                </tr>
            </thead>
        )
    }

    renderBody() {
        const { data } = this.props

        return <tbody className="real-table__body">{data ? data.map(this.renderRow.bind(this)) : null}</tbody>
    }

    renderRow(data: T, rowIndex: number) {
        const { columns, getRowClassName } = this.props
        const rowClassName = `real-table__row ${getRowClassName?.(data, rowIndex) || ""}`

        const rowChildren = columns.map((column, colIdx) => {
            if (!column) {
                return null
            }

            const cellElement = column.props.renderItemContent(data, rowIndex)
            const cellClassName = `real-table__cell ${column.props.className || ""} ${cellElement.props.className || ""}`

            return (
                <td className={cellClassName} key={colIdx} title={cellElement.props.title}>
                    {cellElement.props.children}
                </td>
            )
        })

        return (
            <tr
                className={rowClassName}
                key={rowIndex}
                onClick={this.handleClickRow.bind(this, data)}
                onDoubleClick={this.handleDoubleClickRow.bind(this, data)}
            >
                {rowChildren}
            </tr>
        )
    }

    render() {
        const scrollable = style({
            borderCollapse: "initial",
            height: this.props.height ? this.props.height : "100%",
            $nest: {
                table: {
                    width: "100%",
                },
                "thead th": {
                    position: "sticky",
                    top: "4px",
                    zIndex: 99,
                    background: this.props.tableHeadBackground ? this.props.tableHeadBackground : "#ffffff",
                    overflow: "initial !important",
                    $nest: {
                        "&::before": {
                            background: this.props.tableHeadBackground ? this.props.tableHeadBackground : "#ffffff",
                            position: "absolute",
                            content: "''",
                            width: "2px",
                            left: "-2px",
                            top: "-1px",
                            bottom: 0,
                        },
                        "&::after": {
                            background: this.props.tableHeadBackground ? this.props.tableHeadBackground : "#ffffff",
                            position: "absolute",
                            content: "''",
                            height: "4px",
                            left: "-2px",
                            right: 0,
                            top: "-5px",
                        },
                    },
                },
            },
        })

        if (this.props.scrollable) {
            return (
                <table className={`real-table ${scrollable} ${this.props.className || ""}`}>
                    <tbody>
                        <tr>
                            <td>
                                <Scrollbar onScroll={this.handleScroll.bind(this)} onRef={(el) => (this._scrollBarRef = el)}>
                                    <div style={{ position: "relative" }}>
                                        <table className="real-table">
                                            {this.renderHead()}
                                            {this.renderBody()}
                                        </table>
                                    </div>
                                </Scrollbar>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        }
        return (
            <table className={`real-table ${this.props.className || ""}`}>
                {this.renderHead()}
                {this.renderBody()}
            </table>
        )
    }
}

import { Box, BoxProps, styled } from "@mui/system"
import { getUIA } from "@tm/utils"
import { Icon } from "../../../generics/Icons"

const Badge = styled(Box)(({ theme }) => ({
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    backgroundColor: theme.palette.highlight.main,
    svg: {
        fill: theme.palette.highlight.contrastText,
        marginBottom: "3px",
        marginLeft: "1px",
    },
}))

export function FastClickBadge(props: BoxProps) {
    return (
        <Badge {...props} {...getUIA("SearchtreeV2TreeNodeFastClick")}>
            <Icon name="next" size="15px" />
        </Badge>
    )
}

import { memo, useEffect, useMemo } from "react"
import { Dropdown } from ".."
import { AmountFieldItem } from "./components/amountfield-item"
import { ButtonLayout } from "../button"
import AmountFieldInput from "./components/amountfield-input"
import { useItems } from "./useItems"

export type AmountItem = {
    value: number
    unit?: string
    division: number
    maxAmount: number
    minAmount: number
}

export type AmountFieldProps = {
    className?: string
    value?: number
    unit?: string
    maxAmount?: number
    minAmount?: number
    stepSize?: number
    disabled?: boolean
    layout?: Array<ButtonLayout>
    onChange?: (item: AmountItem) => void
}

export const AmountField = memo<AmountFieldProps>((props) => {
    useEffect(() => {
        if (!props.disabled && !props.onChange) {
            console.warn("AmountField: onChange prop has to be set!")
        }
    }, [])

    let maxAmount = props.maxAmount || 9999
    const minAmount = props.minAmount || 1
    const division = !props.stepSize || props.stepSize <= 0 ? 1 : props.stepSize

    if (division > maxAmount) {
        maxAmount = division
    }

    const { items } = useItems(maxAmount, minAmount, division, props.unit)

    const activeItem = useMemo(() => {
        if (items?.length) {
            return items.find((item) => item.value === props.value) || items[0]
        }
    }, [items, props.value])

    useEffect(() => {
        if (activeItem && activeItem.value !== props.value && props.onChange) {
            props.onChange(activeItem)
        }
    }, [activeItem?.value, props.value])

    if (!items?.length) {
        return null
    }

    return (
        <Dropdown
            className={`amount-field ${props.className || ""}`}
            amountItemsToShow={5}
            items={items}
            itemView={AmountFieldItem}
            inputView={AmountFieldInput}
            value={activeItem}
            layout={[...(props.layout || []), "iconRight"]}
            disabled={props.disabled}
            onChange={props.onChange}
        />
    )
})

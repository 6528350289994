import { ButtonProps, styled } from "@mui/material"
import { ComponentType } from "react"
import { Button } from "../../generics/button"
import { Icon } from "../../generics/Icons"

export const StyledBasketButton = styled(Button, {
    name: "BasketButton",
    slot: "root",
    overridesResolver: (_, styles) => styles.root,
})({
    width: 90,
    "&.MuiButton-bordered": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
}) as ComponentType<ButtonProps>

StyledBasketButton.defaultProps = { // can be moved to mui theme as soon as the defaultProps are obsolete
    color: "highlight",
    variant: "bordered",
    startIcon: <Icon name="cart" />,
}

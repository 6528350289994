import { ContainerAction, IModelContainer, IRegisterActions, IRegisterModel } from "../../models"
import RegisteredActionsContainer from "../actions/RegisteredActionsContainer"
import { IRegisteredContainerBundle, RegisteredContainerBundle } from "../proxy/container-bundle"
import { UnregisteredContainerBundle } from "../proxy/unregistered-container"

export class BundleContainerFactory {
    static createRegisteredContainer<StorageModel>(brm: IRegisterModel<StorageModel>): IRegisteredContainerBundle<StorageModel> {
        return new RegisteredContainerBundle<StorageModel>(brm)
    }

    static createContainer(name: string): IRegisteredContainerBundle<any> {
        const bundleRepository = new RegisteredContainerBundle<any>({
            modelActions: {
                load: () => new Promise((resolve, reject) => reject("model action load not set")),
            },
            name,
        })

        return bundleRepository
    }

    static createActionsContainer(actions: IRegisterActions) {
        return new RegisteredActionsContainer(actions)
    }

    static createUnregisteredContainer<StorageModel>(bundleIdentifier: string): IModelContainer<StorageModel, ContainerAction> {
        return new UnregisteredContainerBundle<StorageModel>()
    }
}

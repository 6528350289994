import { useMemo } from "react"
import { Box, Stack } from "@mui/material"
import { AddToBasket } from "./AddToBasket"
import { PartsListButton } from "./PartsListButton"
import { CostEstimationButton } from "./CostEstimationButton"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ReplaceButtonWithQuantity } from "./ReplaceButtonWithQuantity"
import { MoreMenuButton } from "../Actions/MoreMenuButton"
import { BasketAmountField } from "./AddToBasket/BasketAmountField"

type TransactionsProps = {
    displayMoreMenuButton?: boolean
}

export function Transactions({ displayMoreMenuButton }: TransactionsProps) {
    const { hideCostEstimationButton, hideAddToBasketButton, hideReplaceButtonWithQuantity, displayOnlyQuantity } = useDefaultArticleItemState(
        (x) => x.options
    )
    const showAddToBasket = useDefaultArticleItemState((x) => x.article.showAddToBasket)
    const addToCostEstimationButtonDisabled = useDefaultArticleItemState((x) => x.addToCostEstimationButtonDisabled)
    const addToBasketButtonDisabled = useDefaultArticleItemState((x) => x.addToBasketButtonDisabled)
    const isPartsListAvailable = useDefaultArticleItemState((x) => x.article.isPartsListAvailable)
    const originalQuantity = useDefaultArticleItemState((x) => x.originalQuantity)
    const hasSuggestedQuantity = useDefaultArticleItemState((x) => x.options.hasSuggestedQuantity)
    const handleChangeQuantity = useDefaultArticleItemState((x) => x.handleChangeQuantity)
    const handleAddToBasket = useDefaultArticleItemState((x) => x.handleAddToBasket)
    const handleReplaceArticle = useDefaultArticleItemState((x) => x.handleReplaceArticle)
    const handleRemoveFromBasket = useDefaultArticleItemState((x) => x.handleRemoveFromBasket)
    const basketQuantity = useDefaultArticleItemState((x) => x.basketQuantity?.articleQuantities?.totalQuantity)
    const handleAddToCostEstimation = useDefaultArticleItemState((x) => x.handleAddToCostEstimation)
    const defaultArticleErpInfo = useDefaultArticleItemState((x) => x.articleErpInfos?.default)
    const showReplaceButton = useDefaultArticleItemState((x) => x.showReplaceButton)
    const expandedArticleList = useDefaultArticleItemState((x) => x.expanded)

    const division = useMemo(
        () => (defaultArticleErpInfo?.state === "success" ? defaultArticleErpInfo.response.quantity?.division : 1) || 1,
        [defaultArticleErpInfo]
    )

    return (
        <Stack spacing={0.5} gap={0.5} direction="row" justifyContent="end" className="transactions">
            {displayOnlyQuantity && hideAddToBasketButton && hideCostEstimationButton && (
                <BasketAmountField
                    originalQuantity={originalQuantity}
                    division={division}
                    handleChangeQuantity={handleChangeQuantity}
                    hasSuggestedQuantity={hasSuggestedQuantity}
                    loadErpInfos
                />
            )}
            {showAddToBasket && !hideAddToBasketButton && !showReplaceButton ? (
                <AddToBasket
                    originalQuantity={originalQuantity}
                    division={division}
                    basketQuantity={basketQuantity}
                    onAddToBasket={handleAddToBasket}
                    handleChangeQuantity={handleChangeQuantity}
                    onRemoveFromBasket={handleRemoveFromBasket}
                    hasSuggestedQuantity={hasSuggestedQuantity}
                    disableButton={addToBasketButtonDisabled}
                    compactView={!expandedArticleList}
                />
            ) : (
                isPartsListAvailable && !displayOnlyQuantity && <PartsListButton />
            )}
            {handleReplaceArticle &&
                (showReplaceButton ||
                    (!hideReplaceButtonWithQuantity && !showReplaceButton && hideCostEstimationButton && hideAddToBasketButton)) && (
                    <ReplaceButtonWithQuantity
                        originalQuantity={originalQuantity ?? 1}
                        handleChangeQuantity={handleChangeQuantity}
                        handleReplaceArticle={handleReplaceArticle}
                        division={division}
                    />
                )}
            {!hideCostEstimationButton && (
                <CostEstimationButton
                    onAddToCostEstimation={handleAddToCostEstimation}
                    disabled={!showAddToBasket || addToCostEstimationButtonDisabled}
                />
            )}
            {displayMoreMenuButton && (
                <Box
                    sx={{
                        "&&": {
                            marginLeft: "auto",
                            marginRight: "auto",
                        },
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    <MoreMenuButton />
                </Box>
            )}
        </Stack>
    )
}

import ReactDOM from "react-dom"
import bem from "bem-classnames"
import { Component, ReactNode } from "react"
import Icon from "../icon"
import Button from "../button"
import { Text } from ".."

export type MessageToastProps = {
    skin?: "warning" | "danger" | "success" | "primary"
    message: ReactNode
    description?: ReactNode
    icon?: string
    skipPortal?: boolean
    className?: string
    onButtonClick?(action: any): void
}
type State = {
    hidden: boolean
}

export default class MessageToast extends Component<MessageToastProps, State> {
    constructor(props: MessageToastProps) {
        super(props)
        this.state = { hidden: true }
    }

    componentDidMount() {
        if (this.state.hidden) {
            window.setTimeout(this.setState.bind(this, { hidden: false }), 0)
        }
    }

    render() {
        const { className, skipPortal, skin, description, message, onButtonClick, icon } = this.props
        const hiddenClass = this.state.hidden ? "hidden" : undefined

        const component = (
            <div className={`${bem(style.default, { ...this.props, skin: skin || "primary", long: !!message }, hiddenClass, className)}`}>
                <div className={bem(style.icon, { ...this.props, iconSize })}>
                    <Icon name={icon || this.setIcon(skin)} size={iconSize} />
                </div>
                <div className={bem(style.textWrapper, this.props)}>
                    <Text className={bem(style.message, this.props)}>{message}</Text>
                    <Text size="s" className={bem(style.description, this.props)}>
                        {description}
                    </Text>
                </div>
                {onButtonClick && (
                    <Button className={bem(style.closeButton, this.props)} size={iconSize} layout={["ghost"]} icon="close" onClick={onButtonClick} />
                )}
            </div>
        )

        const container = document.querySelector("#modals .modal__messages")
        if (!skipPortal && container) {
            return ReactDOM.createPortal(component, container)
        }
        return component
    }

    setIcon(skin?: "warning" | "danger" | "success" | "primary") {
        let iconName
        switch (skin) {
            case "warning": {
                iconName = "attention-dark"
                break
            }
            case "danger": {
                iconName = "not"
                break
            }
            case "success": {
                iconName = "success-dark"
                break
            }
            case "primary":
            default: {
                iconName = "info"
                break
            }
        }
        return iconName
    }
}

const componentClassName = "message-toast"
const iconSize = "l"

const style = {
    default: {
        name: `${componentClassName}`,
        modifiers: ["long", "skin"],
    },
    icon: {
        name: `${componentClassName}__icon`,
        modifiers: ["iconSize", "icon"],
    },
    description: {
        name: `${componentClassName}__description`,
    },
    textWrapper: {
        name: `${componentClassName}__text-wrapper`,
    },
    message: {
        name: `${componentClassName}__message`,
    },
    closeButton: {
        name: `${componentClassName}__close-button`,
    },
}

import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { ArticleReferenceType, EArticleModificationState } from "@tm/models"
import { Box, Stack, styled } from "@mui/material"
import { ReferenceGroup } from "../References/ReferenceGroup"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Typography } from "../../../generics/typographie"
import { Divider } from "../../../generics/divider"
import { ImsIcon } from "../ImsIcon"

const ContentText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.font?.fontFamily?.secondary,
    fontSize: theme.typography.body2.fontSize,
}))

const SpacedDivider = styled(Divider)({
    margin: "0 10px",
})

export function AdditionalInformations() {
    const { translateText } = useLocalization()

    const modificationState = useDefaultArticleItemState((x) => x.displayArticle.modificationState)
    const additionalInformation = useDefaultArticleItemState((x) => x.displayArticle.additionalInformation)

    const { references, textInformation, generalInformation } = useMemo(() => additionalInformation ?? {}, [additionalInformation])

    const refsReplacement = useMemo(() => references?.filter((x) => x.referenceType === ArticleReferenceType.ReplacementArticleNo), [references])
    const refsReplaced = useMemo(() => references?.filter((x) => x.referenceType === ArticleReferenceType.ReplacedByArticleNo), [references])
    const refsUtilityNo = useMemo(() => references?.filter((x) => x.referenceType === ArticleReferenceType.UtilityNo), [references])
    const refsOeNo = useMemo(() => references?.filter((x) => x.referenceType === ArticleReferenceType.OeReferenceNo), [references])

    const modificationStateText = useMemo(() => {
        switch (modificationState) {
            case EArticleModificationState.ArticleAdded:
                return translateText(888)
            case EArticleModificationState.VehicleLinkageAdded:
                return translateText(889)
            default:
        }
    }, [modificationState])

    return (
        <>
            {generalInformation?.map(([title, contents]) => {
                if (!title || !contents.length) {
                    return null
                }
                return (
                    <Box key={`informationBox_${title}`} my={2}>
                        <Stack direction="row" alignItems="center" justifyContent="stretch" mb={1}>
                            <Typography variant="label" textTransform="uppercase" lineHeight={1}>
                                {title}
                            </Typography>
                            <Box ml={1} flex={1}>
                                <Divider light />
                            </Box>
                        </Stack>
                        <Stack ml={3} flexDirection="row">
                            {contents.map((content, index) => (
                                <>
                                    <ContentText key={`informationEntry_${content}`} variant="body2">
                                        {content}
                                    </ContentText>
                                    {index < contents.length - 1 && <SpacedDivider light orientation="vertical" flexItem />}
                                </>
                            ))}
                        </Stack>
                    </Box>
                )
            })}
            {(!!textInformation?.length ||
                !!refsReplacement?.length ||
                !!refsReplaced?.length ||
                !!refsUtilityNo?.length ||
                !!refsOeNo?.length ||
                !!modificationStateText?.length) && (
                <Box key="references" my={2}>
                    <Stack direction="row" alignItems="center" justifyContent="stretch" mb={1} gap={1}>
                        <ImsIcon small />
                        <Typography variant="label" textTransform="uppercase" lineHeight={1}>
                            {translateText(526)}
                        </Typography>
                        <Box flex={1}>
                            <Divider light />
                        </Box>
                    </Stack>
                    {(!!refsReplacement?.length || !!refsReplaced?.length || !!refsUtilityNo?.length || !!refsOeNo?.length) && (
                        <Stack direction="row" spacing={2} mb={1}>
                            {!!refsReplacement?.length && <ReferenceGroup references={refsReplacement} label={translateText(685)} />}
                            {!!refsReplaced?.length && <ReferenceGroup references={refsReplaced} label={translateText(686)} />}
                            {!!refsUtilityNo?.length && <ReferenceGroup references={refsUtilityNo} label={refsUtilityNo[0].description ?? ""} />}
                            {!!refsOeNo?.length && <ReferenceGroup references={refsOeNo} label={refsOeNo[0].description ?? ""} />}
                        </Stack>
                    )}
                    {!!textInformation?.length && (
                        <Stack display="grid" gridTemplateColumns="1fr 1fr">
                            {!!textInformation?.length && (
                                <Stack gap={0.5}>
                                    {textInformation?.map(([title, values]) => (
                                        <ContentText key={`text_${title}`} variant="body2">
                                            <Stack display="grid" gap={1} gridTemplateColumns="160px auto">
                                                <Box>{title}</Box>
                                                <Stack direction="column">
                                                    {values.map((value) => (
                                                        <Box key={value}>{value}</Box>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        </ContentText>
                                    ))}
                                </Stack>
                            )}
                        </Stack>
                    )}
                    {!!modificationStateText?.length && (
                        <Box key="additionalBox" my={2}>
                            <Stack ml={3}>
                                <ContentText variant="body2">{modificationStateText}</ContentText>
                            </Stack>
                        </Box>
                    )}
                </Box>
            )}
        </>
    )
}

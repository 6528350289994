import { Component, RefObject } from "react"
import { getFieldErrors, plateCountryCodeMapper } from "@tm/utils"
import { TextField, TextFieldProps } from ".."
import { bindMethodsToContext } from "../../helper"

export type LicensePlateFieldProps = TextFieldProps & {
    shortCountryCode: string
    showCountryCode?: boolean
    attachShowErrorTo?: HTMLElement
    showErrorBorder?: boolean
    size?: "xs" | "s" | "m" | "l" | "xl"
    inputFieldRef?: RefObject<HTMLElement>
}

export default class LicensePlateField extends Component<LicensePlateFieldProps> {
    constructor(props: LicensePlateFieldProps) {
        super(props)
        bindMethodsToContext(this)
    }

    handleFormat(value: string) {
        let formatted = value.toUpperCase()
        if (this.props.formatter) {
            formatted = this.props.formatter(value)
        }

        return formatted
    }

    handleChange(model: any, path?: any[]) {
        const { onChange } = this.props
        onChange && onChange(model, path)
    }

    render() {
        const { type, shortCountryCode, showCountryCode = true, className, size, modelState, path, showErrorBorder, ...rest } = this.props

        let cName = `plate-id ${className || ""}`
        cName += shortCountryCode ? ` plate-id--${shortCountryCode.toLowerCase()}` : ""
        cName += size ? ` plate-id--${size}` : " plate-id--m"
        if (showErrorBorder && modelState && path) {
            cName += (getFieldErrors(modelState, path).length && " field-error") || ""
        }

        return (
            <div className={cName}>
                <div className="plate-id__fx">{showCountryCode && plateCountryCodeMapper(shortCountryCode)?.toUpperCase()}</div>
                <TextField
                    {...rest}
                    modelState={modelState}
                    path={path}
                    className="plate-id__value"
                    formatter={this.handleFormat}
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}

import { HotlineInfo } from "@tm/models"

export function useHotline(hotline: string): HotlineInfo | undefined {
    const mailReg = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
    const phoneReg = /(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{1,4}\)?[-.\s]?)?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/
    let headline: string | undefined
    let email: string | undefined
    let phone: string | undefined

    const splittedHotline = hotline.split("\r\n")

    splittedHotline.forEach((entry) => {
        // Check for phone number
        const phoneMatches = phoneReg.exec(entry)
        if (phoneMatches && phoneMatches.length === 1) {
            phone = phoneMatches[0]
            return
        }

        // Check for email
        const mailMatches = mailReg.exec(entry)
        if (mailMatches && mailMatches?.length === 1) {
            email = mailMatches[0]
            return
        }

        // String is a headline if it is not a phone number or a mail address
        headline = entry
    })

    return headline && (email || phone)
        ? {
              headline,
              email,
              phone,
          }
        : undefined
}

import { Component, ComponentClass, CSSProperties, ReactNode, Ref } from "react"
import { Size } from "../../models/skins"
import { bindMethodsToContext } from "../../helper"

export type TabControlSizes = Size
export type TabControlLayout = "bordered" | "bordered-bottom" | "round-border-bottom"
export type TabControlType = "worktask" | "flat" | "scoped"
export type TabSkin = "highlight" | "success" | "danger"

export type TabControlProps = {
    selectedTabIdentifier: string
    children: Array<any>
    className?: string
    size?: TabControlSizes
    type?: TabControlType
    layout?: TabControlLayout
    group?: boolean
    onTabSelect(tabIdentifier: string): void
    tabsRef?: Ref<HTMLDivElement | undefined>
}

export type TabControlState = {
    tabs?: Array<Tab>
    activeTab?: string
}

export type TabProps = {
    identifier: string
    skin?: TabSkin
    children?: ReactNode
    className?: string
    disabled?: boolean
    htmlId?: string
    renderContent?: (identifier: string) => JSX.Element | undefined
    style?: CSSProperties
}

// TODO: Warning: Each child in an array or iterator should have a unique "key" prop.

export class Tab extends Component<TabProps, any> {
    constructor(props: TabProps) {
        super(props)
        props.disabled = false
    }
}

export default class TabControl extends Component<TabControlProps, TabControlState> {
    static Tab: ComponentClass<TabProps> = Tab

    constructor(props: TabControlProps) {
        super(props)

        const tabs: Array<Tab> = (props.children || []).filter((x) => x && x.props)
        const activeTab = this.getActiveTab(tabs, props.selectedTabIdentifier)

        this.state = {
            activeTab,
            tabs,
        }

        bindMethodsToContext(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps: TabControlProps) {
        const tabs: Array<Tab> = (nextProps.children || []).filter((x) => x && x.props && x.props.identifier)
        const activeTab = this.getActiveTab(tabs, nextProps.selectedTabIdentifier)

        this.setState({
            activeTab,
            tabs,
        })
    }

    getActiveTab(tabs: Array<Tab>, selectedTabIdentifier: string): string {
        let tab: Tab | undefined

        if (selectedTabIdentifier) {
            tab = tabs.findFirst((x) => x.props.identifier == selectedTabIdentifier)

            if (!tab) {
                tab = tabs.first()
            }
        } else {
            tab = tabs.first()
        }

        return tab ? tab.props.identifier : ""
    }

    handleSelectTab(tab: Tab) {
        const { onTabSelect } = this.props

        if (tab.props.disabled) {
            return
        }

        this.setState({
            activeTab: tab.props.identifier,
        })

        if (onTabSelect) {
            onTabSelect(tab.props.identifier)
        }
    }

    render() {
        const { size, className, group, type } = this.props
        const { tabs } = this.state
        if (!tabs || !tabs.length) {
            return null
        }

        let tabCtrlClassName = "tab-control"
        tabCtrlClassName += ` tab-control--${size || "large"}`
        group ? (tabCtrlClassName += " tab-control--group") : ""
        tabCtrlClassName += className ? ` ${className}` : ""

        if (type == "scoped") {
            const { activeTab: activeTabIdentifier } = this.state
            const activeTab = (this.state.tabs || []).find((x: Tab) => x.props.identifier == activeTabIdentifier)

            return (
                <div className="tab-control__wrapper">
                    <div className={tabCtrlClassName} ref={this.props.tabsRef as Ref<HTMLDivElement>}>
                        {tabs.map(this.renderTabs)}
                    </div>
                    {activeTab && activeTab.props.renderContent && (
                        <div className="tab-control__content">{activeTab.props.renderContent(activeTab.props.identifier)}</div>
                    )}
                </div>
            )
        }

        return (
            <div className={tabCtrlClassName} ref={this.props.tabsRef as Ref<HTMLDivElement>}>
                {tabs.map(this.renderTabs)}
            </div>
        )
    }

    renderTabs(tab: Tab, idx: number) {
        const { layout, type } = this.props
        const { activeTab } = this.state
        const { skin, disabled, identifier, className, htmlId, style } = tab.props

        let tabClassName = "tab"
        tabClassName += type ? ` tab--${type}` : ""
        tabClassName += layout ? ` tab--${layout}` : ""
        tabClassName += skin ? ` tab--${skin}` : ""
        tabClassName += disabled ? " tab--disabled" : ""
        tabClassName += activeTab == identifier ? " is-selected" : ""
        tabClassName += className ? ` ${className}` : ""

        return (
            <div
                id={htmlId}
                accessKey={`tab-${idx}-${identifier}`}
                key={`tab-${idx}-${identifier}`}
                className={tabClassName}
                onClick={this.handleSelectTab.bind(this, tab)}
                style={style}
            >
                <div className="tab__content">{tab.props.children}</div>
            </div>
        )
    }
}

import { Component, ComponentClass, ComponentType, createElement } from "react"
import { Omit } from "@tm/utils"
import { WorkTask } from "../model"
import { WorkTaskProviderContext } from "../model/context"

export type WithWorkTaskProps = Pick<
    WorkTask,
    "workTaskId" | "workTask" | "createWorkTask" | "attachToWorkTask" | "openWorkTask" | "reloadWorkTask" | "workTaskLoading" | "newWorkTaskLoading"
>

export function withWorkTask<P extends WithWorkTaskProps>(component: ComponentType<P>): ComponentClass<Omit<P, keyof WithWorkTaskProps>> {
    return class extends Component<Omit<P, keyof WithWorkTaskProps>> {
        showError() {
            console.error("withWorkTask: No wrapping WorkTask Provider was found!", component)
            return Promise.reject()
        }

        renderInnerComponent(workTask?: WorkTask) {
            const currentProps: any = this.props || {}

            const props: P & WithWorkTaskProps = {
                ...currentProps,
            }

            if (!workTask) {
                // this.showError()
                props.createWorkTask = () => this.showError()
                props.attachToWorkTask = () => this.showError()
                props.openWorkTask = () => this.showError()
                props.reloadWorkTask = () => this.showError()
            } else {
                props.workTaskId = workTask.workTaskId
                props.workTask = workTask.workTask
                props.createWorkTask = workTask.createWorkTask
                props.attachToWorkTask = workTask.attachToWorkTask
                props.openWorkTask = workTask.openWorkTask
                props.reloadWorkTask = workTask.reloadWorkTask
                props.workTaskLoading = workTask.workTaskLoading
                props.newWorkTaskLoading = workTask.newWorkTaskLoading
            }

            return createElement(component, props)
        }

        render() {
            return <WorkTaskProviderContext.Consumer>{(workTask?: WorkTask) => this.renderInnerComponent(workTask)}</WorkTaskProviderContext.Consumer>
        }
    }
}

export enum NoteTypes {
    ARTICLE = "Article",
    VEHICLE_ARTICLE = "VehicleArticle",
    CUSTOM_ARTICLE = "CustomArticle",
}

export type Note = {
    noteId: string
    text: string
    type: NoteTypes
    version?: {
        id: string
        revision: number
    }
}

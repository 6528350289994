import { ComponentType, createElement, FC, useContext } from "react"
import { BundleMicroContext } from "../lib/BundleMicroContext"
import { WithMicroProps } from "../models/props"

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function importMicro<P extends ComponentType<any>>(superClass: P) {
    return withMicro(superClass) as P
}

export function withMicro<P extends WithMicroProps<any>>(component: ComponentType<P>): FC<Omit<P, keyof WithMicroProps>> {
    const withMicro: FC<Omit<P, keyof WithMicroProps>> = (currentProps) => {
        const context = useContext(BundleMicroContext)
        if (!context) {
            throw "The bundle component doesn't provide a MicroContext"
        }
        return createElement(component, {
            ...(currentProps as any),
            renderMicro: context,
        })
    }
    return withMicro
}

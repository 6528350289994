// Rekonstruktion der absoluten Route des Moduls
export function createAbsoluteRoute(route?: string, parentRoute?: string): { path: string; exact: boolean } {
    const result = {
        path: parentRoute || "",
        exact: (route || "").indexOf("!") === 0,
    }

    result.path = `${result.path}/${(route || "").replace("!", "")}`.replace("//", "/")
    return result
}

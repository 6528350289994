import { Component, ComponentClass, ComponentType, createElement } from "react"
import { ThemeProviderContext } from "."

export type WithThemeProps = {
    theme: {}
}

export function withTheme<P extends WithThemeProps>(component: ComponentType<P>): ComponentClass<Omit<P, keyof WithThemeProps>> {
    return class extends Component<Omit<P, keyof WithThemeProps>> {
        renderInnerComponent(theme: {}) {
            const currentProps: any = this.props || {}

            const props: P & WithThemeProps = {
                ...currentProps,
                theme,
            }

            return createElement(component, props)
        }

        render() {
            return <ThemeProviderContext.Consumer>{(theme: {}) => this.renderInnerComponent(theme)}</ThemeProviderContext.Consumer>
        }
    }
}

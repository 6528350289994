import { Component, PropsWithChildren } from "react"

export default class ToolbarContent extends Component<PropsWithChildren> {
    constructor(props: any) {
        super(props)
    }

    render() {
        const baseName = "toolbar__content"
        const className = `${baseName}`

        return <div className={className}>{this.props.children}</div>
    }
}

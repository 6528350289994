import { memo, useCallback, useEffect, ReactNode, useState, useRef, ChangeEvent } from "react"
import {
    AllowedFileSelectionTypes,
    FileSelectError,
    FileTypeMaxSize,
    getAcceptAttribute,
    getMaxFileSizeByType,
    getMimeTypes,
} from "../../helper/fileTypes"

export type FileDropFieldProps = {
    /** To use more filetypes, extend the mapping object in @tm/controls/file-select-button */
    allowedFileTypes: AllowedFileSelectionTypes
    /** In kB */
    maxFileSize: number
    children?: ReactNode
    className?: string
    onLoad(fileData: string, fileName: string, fileMimeType: string): void
    onError?(type: FileSelectError, options?: { maxAllowedFileSize: number }): void
    maxFileSizesByType?: FileTypeMaxSize
    allowPaste?: boolean
}

export const FileDropField = memo<FileDropFieldProps>(
    ({ allowedFileTypes, maxFileSize, allowPaste, className, onError, onLoad, maxFileSizesByType, children }) => {
        const [active, setActive] = useState<boolean>(false)
        const ref = useRef<HTMLInputElement>(null)
        const maxFileSizes = getMaxFileSizeByType(maxFileSizesByType)

        const getAllowedFileSizeByType = (mimeType: string) => {
            if (maxFileSizes) {
                const fileSizeOption = maxFileSizes.find(({ mime }) => mime.includes(mimeType))

                return 1024 * (fileSizeOption?.size || maxFileSize)
            }

            return 1024 * maxFileSize
        }

        const saveFile = useCallback(
            (file: File): void => {
                if (getMimeTypes(allowedFileTypes).indexOf(file.type) <= -1) {
                    onError?.("WRONG_FILE_TYPE")
                } else if (file.size > getAllowedFileSizeByType(file.type)) {
                    onError?.("FILE_TOO_BIG", { maxAllowedFileSize: getAllowedFileSizeByType(file.type) / 1024 })
                } else {
                    const reader = new FileReader()

                    reader.onload = (e: any) => {
                        if (e.target && e.target.result) {
                            onLoad(e.target.result, file.name, file.type)

                            if (ref.current) {
                                ref.current.value = ""
                            }
                        }
                    }

                    reader.readAsDataURL(file)
                }
            },
            [onError, ref, onLoad]
        )

        const handlePaste = useCallback(
            (e: ClipboardEvent): void => {
                const clipboardItems: DataTransferItemList | undefined = e.clipboardData?.items

                if (!clipboardItems) {
                    return
                }

                const items = [].slice.call(clipboardItems).filter(({ type }: DataTransferItem) => getMimeTypes(allowedFileTypes).includes(type))

                if (items.length === 0) {
                    return
                }

                const item: DataTransferItem = items[0]
                const file = item.getAsFile()

                if (file) {
                    saveFile(file)
                }
            },
            [saveFile]
        )

        useEffect(() => {
            allowPaste && document.addEventListener("paste", handlePaste, true)

            return () => {
                document.removeEventListener("paste", handlePaste, true)
            }
        }, [allowPaste])

        const handleEnter = useCallback(() => {
            setActive(true)
        }, [])

        const handleExit = useCallback(() => {
            setActive(false)
        }, [])

        const onChangeFile = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                const { files } = event.target
                if (files && files.length) {
                    const file = files[0]

                    if (file) {
                        saveFile(file)
                    }
                }
            },
            [saveFile]
        )

        return (
            <div
                className={`file-drop-area ${active ? "is-active " : ""}${className || ""}`}
                onDragEnter={handleEnter}
                onDragLeave={handleExit}
                onBlur={handleExit}
            >
                {children}
                <input
                    className="file-input"
                    type="file"
                    accept={getAcceptAttribute(allowedFileTypes)}
                    ref={ref}
                    // multiple TODO: support multiple files
                    onChange={onChangeFile}
                />
            </div>
        )
    }
)

import { Box, lighten, styled } from "@mui/material"
import { Icon } from "../../../generics/Icons"

type Props = {
    iconName?: string
    isSecondLevel?: boolean
}

const IconWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isSecondLevel",
})<{ isSecondLevel?: boolean }>(({ theme, isSecondLevel }) => {
    const hoverBackgroundColor = lighten(theme.palette.primary.main, 0.5)
    const backGroundColor = theme.palette.grey[300]

    return {
        display: "flex",
        position: "relative",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "28px",
        height: "28px",
        borderRadius: isSecondLevel ? "50%" : "50% 50% 10% 50%",
        marginTop: "1px",
        marginBottom: "1px",
        background: backGroundColor,
        "div > svg": { background: backGroundColor },
        "&:hover": {
            background: hoverBackgroundColor,
            svg: {
                fill: theme.palette.getContrastText(hoverBackgroundColor),
            },
            "div > svg": {
                background: hoverBackgroundColor,
                svg: {
                    fill: theme.palette.getContrastText(hoverBackgroundColor),
                },
            },
        },
    }
})

export function SearchtreeIcon(props: Props) {
    if (!props.isSecondLevel) {
        return (
            <IconWrapper component="span">
                <Icon name={props.iconName} width="16px" height="16px" sx={{ opacity: 1 }} />
                <div>
                    <Icon
                        name="searchtree-arrow"
                        width="10px"
                        height="10px"
                        sx={{ opacity: 1, position: "absolute", right: 1, bottom: 1, borderRadius: "50%" }}
                    />
                </div>
            </IconWrapper>
        )
    }

    return (
        <IconWrapper isSecondLevel>
            <Icon name="searchtree-arrow" width="16px" height="16px" sx={{ opacity: 1, marginBottom: "4px", strokeWidth: 0.6 }} />
        </IconWrapper>
    )
}

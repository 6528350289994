import { ComponentType, ReactNode } from "react"
import { Badge as MuiBadge, styled, BadgeProps } from "@mui/material"

type BadgeAdvancedProps = BadgeProps & {
    size?: "small" | "medium"
    // onClick?: ReactNode
}

export const Badge = styled(MuiBadge, { shouldForwardProp: (prop) => prop !== "title" && prop !== "size" })<Omit<BadgeAdvancedProps, "title">>(
    ({ theme, size, color }) => {
        const defaultSmallProps = {
            height: "16px",
            fontSize: "12px",
            border: "0",
            padding: "0 2px",
        }

        return {
            "& .MuiBadge-root": {
                position: "absolute",
                top: "0",
                right: "3px",
            },

            "& .MuiBadge-badge": {
                border: `1px solid ${theme.palette.primary}`,
                padding: "0 2px",
            },

            ...(size === "small" && {
                "& .MuiBadge-badge": {
                    ...defaultSmallProps,
                },
            }),

            ...(color === undefined && {
                "& .MuiBadge-dot": {
                    backgroundColor: "#000000",
                },
                ...(size === "small" && {
                    "& .MuiBadge-badge": {
                        ...defaultSmallProps,
                        marginRight: "10px",
                        backgroundColor: "#000000",
                        color: "#FFFFFF",
                    },
                }),
                ...(size === "medium" && {
                    "& .MuiBadge-badge": {
                        backgroundColor: "#000000",
                        color: "#FFFFFF",
                    },
                }),
            }),
        }
    }
) as ComponentType<BadgeAdvancedProps>

import { RegisteredModels, ShowReturnsTotalNumbersRequest, ShowReturnsTotalNumbersResponse } from "@tm/models"
import { Container } from "@tm/nexus"
import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useTelesalesCustomerNumber } from ".."

/**
 * @memberof Context-Distribution
 */
const KEY = "cis_useCisReturnItemsTotalNumbers"
export function useCisReturnItemsTotalNumbers() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const enabled = enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, enabled],
        () => {
            const request: ShowReturnsTotalNumbersRequest = { customerNo: telesalesCustomerNo }
            return Container.getInstance<ShowReturnsTotalNumbersResponse | undefined>(RegisteredModels.CIS_ShowTotalNumbers).subscribe(request).load()
        },
        { enabled }
    )

    return { totalNumbers: data, totalNumbersLoading: isLoading }
}

export function useReloadCisReturnItemsTotalNumbers() {
    const queryClient = useQueryClient()

    const resetCisReturnItemsTotalNumbers = useCallback(() => {
        queryClient.resetQueries(KEY)
    }, [queryClient])

    return { resetCisReturnItemsTotalNumbers }
}

import { useState, useMemo, useEffect } from "react"

export type IFrameProps = {
    url: string
    id: string
    className?: string
    children?: JSX.Element
    contextDependent?: boolean
    allowFullscreen?: boolean
    allow?: string
    observeViewportIntersection?: boolean
    /** Can be either a valid sandbox value or true (equals sandbox="") */
    sandbox?: string | boolean
    refreshOnUrlChanged?: boolean
    refreshOnLoad?: boolean
    onLoad?(ev: Event): void
}

export default function IFrame(props: IFrameProps) {
    const { url, id, className, children } = props
    const [iframe, setIframe] = useState<HTMLIFrameElement>()
    const [container, setContainer] = useState<HTMLDivElement>()
    const [waitForBoundingRect, setWaitForBoundingRect] = useState(false)

    const observer = useMemo(
        () =>
            new IntersectionObserver(handleResize, {
                root: null,
                rootMargin: "0px",
                threshold: 1.0,
            }),
        []
    )

    function handleRef(el: HTMLDivElement) {
        if (el) {
            setContainer(el)
            observer && observer.observe(el)
        }
    }

    function handleResize() {
        setTimeout(() => iframe && container && setBoundingRect(iframe, container, waitForBoundingRect, setWaitForBoundingRect), 200)
    }

    useEffect(() => {
        iframe && container && setBoundingRect(iframe, container, waitForBoundingRect, setWaitForBoundingRect)
        window.addEventListener("resize", handleResize)

        return () => {
            iframe && hideIframe(iframe)
            window.removeEventListener("resize", handleResize)
            observer && observer.disconnect()
        }
    })

    useEffect(() => {
        if (iframe?.id == id) {
            iframe.onload = props.onLoad || null
            iframe.src = url
            return
        }

        const newIframe = getOrCreateIFrame(props)
        setIframe(newIframe)
    }, [url])

    return (
        <div className={className || ""} ref={handleRef}>
            {children}
        </div>
    )
}

function hideIframe(iframe: HTMLIFrameElement) {
    iframe.style.display = "none"
    iframe.style.pointerEvents = "none"
    iframe.style.zIndex = "-1"
}

function getOrCreateIFrame(props: IFrameProps) {
    let iframe = document.querySelector<HTMLIFrameElement>(`#${props.id}`)
    if (!iframe) {
        iframe = document.createElement("iframe")

        if (props.contextDependent) {
            iframe.classList.add("context-dependent")
        }

        // if(props.className) {
        //     const classNameList = props.className.split(' ')
        //     classNameList.forEach(item => iframe && iframe.classList.add(item))
        // }

        iframe.id = props.id
        iframe.src = props.url
        iframe.style.position = "absolute"
        iframe.style.display = "none"
        iframe.allowFullscreen = props.allowFullscreen || false
        iframe.allow = props.allow || ""

        if (typeof props.sandbox == "string" || props.sandbox === true) {
            iframe.sandbox.value = props.sandbox === true ? "" : props.sandbox
        }

        iframe.onload = props.onLoad || null

        document.body.appendChild(iframe)
    } else if ((props.refreshOnUrlChanged && iframe.src != props.url) || props.refreshOnLoad) {
        iframe.onload = props.onLoad || null
        iframe.src = props.url
    } else {
        props.onLoad?.(new Event("loaded"))
    }

    return iframe
}

function setBoundingRect(
    iframe: HTMLIFrameElement,
    container: HTMLDivElement,
    waitForBoundingRect: boolean,
    setWaitForBoundingRect: (value: boolean) => void
) {
    const containerBoundingRect = container.getBoundingClientRect()

    if (!container.clientWidth || containerBoundingRect.left > 0.5 * window.innerWidth) {
        setTimeout(() => setBoundingRect(iframe, container, waitForBoundingRect, setWaitForBoundingRect), 500)
        setWaitForBoundingRect(true)
        return
    }

    setWaitForBoundingRect(false)

    iframe.style.left = `${containerBoundingRect.left}px`
    iframe.style.top = `${containerBoundingRect.top}px`
    iframe.style.width = `${containerBoundingRect.width}px`
    iframe.style.height = `${containerBoundingRect.height}px`
    iframe.style.display = ""
    iframe.style.pointerEvents = ""
    iframe.style.zIndex = ""
}

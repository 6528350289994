import { styled } from "@mui/material"
import { ComponentType } from "react"
import { ForwardRefIconProps, Icon } from "../../../../generics/Icons"

export const ZoomIcon = styled(Icon)(() => ({
    height: 22,
    width: 22,
    maxWidth: 22,
    maxHeight: 22,
})) as ComponentType<ForwardRefIconProps>

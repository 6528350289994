import PropTypes from "prop-types"
import { Component, ComponentType, PropsWithChildren, createElement } from "react"
import { errorListener } from "./errorListener"
import { DefaultError } from "./DefaultError"

type ErrorBoundaryProps = PropsWithChildren<{
    errorComponent?: ComponentType
}>

export default class ErrorBoundary extends Component<ErrorBoundaryProps, any> {
    // eslint-disable-next-line
    static contextTypes = {
        // eslint-disable-next-line
        routes: PropTypes.array,
    }

    // eslint-disable-next-line
    context: { 
        routes: string[]
        router: any
    }

    getContext() {
        return {
            // eslint-disable-next-line
            routes: [...(this.context.routes || []), this.context.router.route.match.path],
        }
    }

    constructor(props: any) {
        super(props)
        this.state = { hasError: false }
    }

    // Error handling
    componentDidCatch(error: any, info: any) {
        errorListener.forEach((listener) => {
            try {
                listener(error)
            } finally {
            }
        })
        this.setState({ hasError: true })
        console.error(error, info)
    }

    render() {
        if (this.state.hasError) {
            if (this.props.errorComponent) {
                return createElement(this.props.errorComponent)
            }
            return <DefaultError />
        }
        return this.props.children
    }
}

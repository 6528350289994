import { Button, PopoverActions, styled } from "@mui/material"
import { useEffect, useRef } from "react"
import { Icon } from "../../generics/Icons"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { ResizablePopover } from "../ResizablePopover"
import { ImageViewer, ImageViewerRefActions } from "../ImageViewer"

const CloseButton = styled(Button)({
    position: "absolute",
    right: 2,
    top: 2,
})
CloseButton.defaultProps = {
    variant: "text",
    startIcon: <Icon name="close" />,
}

type ImageViewerProps = {
    anchorEl?: Element | null
    title?: string
    onClose(): void
}

export function ArticleImageViewer(props: ImageViewerProps) {
    const { title, anchorEl, onClose } = props
    const { isLoading, data, setEnabled } = useDefaultArticleItemState((x) => x.articleImages)

    useEffect(() => {
        setEnabled(true)
    }, [])

    const imageViewerActionsRef = useRef<ImageViewerRefActions>(null)
    const popoverRef = useRef<PopoverActions>(null)

    const onResize = () => {
        imageViewerActionsRef?.current?.fitImageToContent()
    }

    return (
        <ResizablePopover
            open
            action={popoverRef}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            sx={{ ml: 1 }}
            onResize={onResize}
            title={title}
        >
            <ImageViewer images={data || []} isLoading={isLoading} actions={imageViewerActionsRef} />
        </ResizablePopover>
    )
}

import { Box, Stack } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ErpPrice } from "@tm/models"

type Props = {
    price: ErpPrice
}

export function TooltipPrice({ price }: Props) {
    const { currency, translateText } = useLocalization()

    return (
        <Stack spacing={0.5}>
            {!!price.description && <Box>{`${price.description}: ${currency(price.value, price.currencySymbol || "")}`}</Box>}
            {!price.taxIncluded && price.valueTaxIncluded && (
                <Box>
                    {translateText(12851)}:&nbsp;
                    {currency(price.valueTaxIncluded, price.currencySymbol || "")}
                </Box>
            )}
            {!!price.rebatePercent && (
                <Box>
                    {translateText(54)}:&nbsp;
                    {price.rebatePercent.round(0.01)}%
                </Box>
            )}
        </Stack>
    )
}

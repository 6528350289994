import { NextTheme } from "@tm/components"
import { style } from "typestyle"
import { Component, ComponentClass, createElement } from "react"
import { StyleProps } from ".."

export function withStyle<P extends StyleProps<V>, S, T, V extends (props: P, theme: NextTheme) => Record<string, T>>(
    generateStyle: V,
    component: ComponentClass<P, S>
): ComponentClass<Omit<P, keyof StyleProps<V>>, S> {
    return class extends Component<P, S> {
        render() {
            const ss = generateStyle(this.props, (window as any).__NEXT_STYLETHEME__)

            const styles: Record<string, string> = {}
            const compName = `${component.prototype.constructor.name.toLowerCase()}--`

            Object.keys(ss).forEach((key) => {
                styles[key] = style({ ...ss[key], $debugName: `${compName + key}_` })
            })
            const props = { ...this.props, style: styles }
            return createElement(component, props)
        }
    } as any
}

import { styled, Typography, TypographyProps } from "@mui/material"

type Props = TypographyProps & {
    selected: boolean
}

export const SelectedTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "selected",
})<Props>(({ theme, selected }) => ({
    color: selected ? theme.palette.primary.main : "currentColor",
}))

import { QuerySearch } from "../base-event-models"
import { SearchStepDto, SearchFilterType, FittingSideType, ArticleListViewOptionsType } from "../../models/requests"
import { Filter } from "../../models/models"
import { TmaEModule } from "@tm/models"
import { StoreContext } from "../event-storage"

export interface AttributeFilter {
    /**
     * @property Also known as KritNr, Kriteriumsnummer
     */
    id: number

    /**
     * @property Also known as KritBez, Bez, Kriteriumsbezeichnung (eg. Color, Size)
     */
    description: string

    /**
     * @property Also known as KritVal, KritWert, Kriteriumswert (eg. Blue, 1.6)
     */
    value: string
}

export interface DataSupplierFilter {
    /// Article Count for this Data Supplier
    /// Also known as CntArt, Artikelmenge
    articleCount: number

    /// Data Supplier Id
    /// Also known as EinspNr, Einspeisernummer, Data Supplier Number
    id: number

    /// Indicates if the Data Supplier is selected
    /// Also known as Checked
    isSelected: boolean

    /// Also known as Prio
    priority?: number
}

export interface ProductGroupFilter {
    /// Article Count for this Product Group
    /// Also known as CntArt, Artikelmenge
    articleCount: number

    /// Also known as GenArtNr, Generische Artikelnummer, Generic Article Number
    id: number

    /// Indicates if the Product Group is selected
    /// Also known as Checked
    isSelected: boolean

    /// Also known as Prio
    priority?: number
}
/**
 * SearchFilter Interfaces
 */

export interface SearchFilter {
    id: SearchFilterType
    articleCount: number
    isSelected: boolean
}

export interface ProductGroupDataSupplier {
    productGroupId: number
    dataSupplierId: number
}

export interface PagingDto {
    /**
     * @property [optional] The Page Index.
     */
    pageIndex?: number

    /**
     * @property [optional] The Page Size.
     */
    pageSize?: number

    /**
     * @property [optional] The Page Index of the last page.
     */
    pageIndexMax?: number
}

export interface ArticleListInfo {
    /// Found product group data supplier combinations.
    productGroupDataSuppliers: Array<ProductGroupDataSupplier>

    /// Count of all articles in the list.
    articleCount: number

    /// Count of wholesaler articles in the list.
    wholesalerArticleCount: number

    /**
     * @property [optional] Restriction of article maximum for an article list.
     */
    maxArticleCount?: number

    paging: PagingDto
}

export interface SearchTreeDto {
    /// [optional] The Search Tree Id.
    /// Requires NodeId.
    /// Also known as Tree, TreeId, Suchbaumnummer
    treeId?: number

    /// [optional] The Node Id to receive.
    /// Requires TreeId.
    /// Also known as NodeId, Suchbaumknotennummer
    nodeId?: number
}

export interface VehicleIdentificationDto {
    /// The value of the id can be taken from the MDM list for available types of vehicle identification
    /// (see Visual Studio TeamExplorer - Project MDM - Documents - Development - Kundenverwaltung.xlsx - System-AddOn).
    /// IdentificationTypeId defines type of the identification process like identification by vehicle tree search,
    /// plateId, motor code, TecDocTypeId, Gerausiv webservice, etc.
    typeId: number

    /// Search keyword. In case of a plateId search the plateId.
    key: string
}

type ProductGroupId = number
export interface ArticleListFilteredRequest {
    context?: StoreContext
    workTaskId?: string
    eventStatus: "initial" | "progress" | "completed"
    /**
     * @property [optional] Selected vehicle.
     */
    vehicleId?: string
    querySearch?: QuerySearch
    attributeFilters?: Array<AttributeFilter>
    dataSupplierFilters?: Array<DataSupplierFilter>
    productGroupFilters?: Array<ProductGroupFilter>

    /**
     * @property [optional] The Search Filter. Used for Query Search. Also known as SearchArea, Bitvektor für die Suchbereiche, Bitvector for the Search Areas
     */
    searchFilters?: Array<SearchFilter>
    productGroupsPerSupplier: {
        [supplierId: number]: ProductGroupId
    }
    allProductGroupsPerSupplier?: {
        [supplierId: number]: ProductGroupId[]
    }
    /// Indicates the state of the checkbox "Extended assortment"
    extendedAssortment?: boolean

    /// Fitting Side Filter
    /// Used as Filter for SupplierArticleNos
    /// Also known as BinKrit100, Bitvektor für die Einbauseite, Bitvector for the Installation Location
    fittingSideFilter?: FittingSideType
    viewOptions?: ArticleListViewOptionsType
    articleList?: ArticleListInfo
    searchTree?: SearchTreeDto
    vehicleIdentification?: VehicleIdentificationDto
    searchStep: SearchStepDto
    origin: TmaEModule
    vin?: string
}
export interface ArticleListFilteredEventType {
    ARTICLE_LIST_FILTERED: ArticleListFilteredRequest
}

export type CategoryType = "vehicleParts" | "universalParts" | "directSearch" | "predictive"

/**
 * Data which is needed to be stored in order to augment the tma event data
 * add more properties if needed
 */
export type AnalyticsData = {
    filter?: Filters
}

export type Filters = {
    // selected?: AvailableData,
    available?: AvailableData
}
export type SelectedData = {
    productGroupIds?: Array<number>
    supplierIds?: Array<number>
    oeReferences?: Array<number>
}

export type AvailableData = {
    productGroupFilters?: Array<Filter>
    dataSupplierFilters?: Array<Filter>
    oeReferences?: Array<Filter>
}

export type EventPluginTypes = "submit" | "trackData" | "whatever"
export class EventPlugin {
    constructor(type: EventPluginTypes) {
        this.type = type
    }

    type: EventPluginTypes
}

export type PayloadExtraData = {
    vin?: string
}

export type SearchFilterCount = { filterType: number, count: number }

import { MessageType } from "."

export type OrderResponse = {
    workTaskId: string
    orders?: Array<OrderResponseGroup>
    allHaveSucceeded: boolean
    technicalErrorText: string
    orderRequestFailed: boolean
    traceId: string
    postSendOrderActions?: PostSendOrderAction[]
    basketIsNotUpToDate?: boolean
}

export type OrderResponseGroup = {
    orderVoucherId?: string
    orderNumber: string
    orderDate?: Date
    orderValidationMessage: OrderValidationMessage
    itemValidationMessages: Array<OrderValidationMessage>
    hasSucceeded: boolean
}

export type OrderValidationMessage = {
    id: string
    type: MessageType
    texts: Array<string>
}

export type PostSendOrderAction = {
    action: PostSendOrderActionType
    target: PostSendOrderActionTargetType
    value: string
}

export enum PostSendOrderActionType {
    OpenExternalUrl = 1,
    PostMessage = 2,
}
/**
 * for future use, add new types if
 */
export enum PostSendOrderActionTargetType {
    NewWindow = 1,
    ParentFrame = 2,
    Modal = 3,
}

import { Stack, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { Icon } from "../../../generics/Icons"
import { Typography } from "../../../generics/typographie"

const backgroundColor = "#e2e2e2"

const StyledStack = styled(Stack)(({ theme }) => ({
    padding: "0.5em 0.75em",
    alignItems: "center",
    borderRadius: theme.radius?.default || "3px",
    backgroundColor,
}))

export default function AlreadyOrderedArticle() {
    const { translateText } = useLocalization()

    return (
        <StyledStack direction="row" gap="1em">
            <Icon name="information-light" />
            <Typography variant="body3" color={(theme) => theme.palette.getContrastText(backgroundColor)}>
                {translateText(80)}
            </Typography>
        </StyledStack>
    )
}

import isMobile from "is-mobile"
import { NetworkType } from "./models"

export function checkNetworkType() {
    if (isMobile()) {
        return NetworkType.mobile
    }

    const info = (window.navigator as any).connection
    if (!info || !info.type) {
        return undefined
    }

    switch (info.type) {
        case "wifi": {
            return NetworkType.wifi
        }
        case "ethernet": {
            return NetworkType.lan
        }
        default: {
            return undefined
        }
    }
}

export function getGraphicCard() {
    const canvas = document.createElement("canvas")

    let context
    try {
        context = canvas.getContext("webgl") || canvas.getContext("experimental-webgl")
    } catch (e) {
        return undefined
    }

    const debugInfo = (context as WebGLRenderingContext)?.getExtension("WEBGL_debug_renderer_info")
    if (debugInfo) {
        const renderer = (context as WebGLRenderingContext).getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
        return renderer
    }

    return undefined
}

export function getBrowserFeatures() {
    const { hardwareConcurrency, cookieEnabled } = navigator
    return {
        window: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        desktop: {
            width: window.screen.width * window.devicePixelRatio,
            height: window.screen.height * window.devicePixelRatio,
        },
        numberOfLogicalProcessorCores: hardwareConcurrency,
        allowsCookies: !!cookieEnabled,
    }
}

import { Component, ComponentClass, PropsWithChildren, ReactNode, ReactText, MouseEvent, ReactElement, CSSProperties } from "react"
import VendorScrollbar from "react-custom-scrollbars-2"
import { equals } from "@tm/utils"
import { Link } from "react-router-dom"
import { bindMethodsToContext } from "../../helper"
import Scrollbar from "../scrollbar/index"

export type TableProps<T> = PropsWithChildren<{
    /**
     * Array of columns. Defines what should be displayed in the table header.
     */
    columns: Array<ReactElement<TableColumnProps>>
    /**
     * Array with data for each row that should be displayed.
     * Gets transferred to the renderItemContent function in the TableColumn props.
     * So that function has to create a TableCell, which contains children that should be displayed
     * in that row (to be decided by the rowdata) and column (depending on which TableColumn.renderItemContent function is called).
     */
    data?: Array<T>
    className?: string
    scrollable?: boolean
    /**
     * Also gets passed one item of the data Array.
     * Decides if the row is part of a group of rows that should be displayed together.
     * Returns an identifier for the group.
     */
    groupBy?(item: T): string | number
    /**
     * This function can define what should be displayed above the group of rows.
     * Gets the group identifier that was given by groupBy as parameter.
     */
    renderGroupHead?(item: ReactText): ReactNode
    /**
     * This function can define what should be displayed below the group of rows.
     * Gets the group identifier that was given by groupBy as parameter.
     */
    renderGroupFooter?(item: ReactText): ReactNode
    /**
     * Gets the data of one row, to give custom styles for that row.
     */
    getRowStyle?(rowData: T, rowIndex: number): CSSProperties
    /**
     *
     */
    getFocusedRowIndex?(data: Array<T>): number
    /**
     * Gets the data of one row, to give a custom class name for that row.
     */
    getRowClassName?(rowData: T, rowIndex: number): string
    /**
     * If this method is given, each row is rendered as link <a />
     */
    getRowLink?(rowData: T, rowIndex: number): string
    getRowKey?(rowData: T, rowIndex: number): string
    onScrollBottom?(): void
    onClickRow?(item: T): void
    onDoubleClickRow?(item: T): void
}>

export type TableColumnProps = {
    /**
     * The element in children will be displayed as Table Header for the column.
     */
    children?: ReactNode
    className?: string
    /**
     * This function has to create a TableCell, which contains children that should be displayed
     * in that row (to be decided by the rowdata) and column (depending on which TableColumn.renderItemContent function is called).
     */
    renderItemContent(rowData: any, rowIndex: number): ReactElement<TableCellProps>
}

export type TableCellProps = {
    /**
     * The element in children will be displayed as content for the cell.
     */
    children?: ReactNode
    className?: string
    title?: string
}

class TableColumn extends Component<TableColumnProps, any> {}
class TableCell extends Component<TableCellProps, any> {}

export default class Table<T> extends Component<TableProps<T>, any> {
    static Column: ComponentClass<TableColumnProps> = TableColumn

    static Cell: ComponentClass<TableCellProps> = TableCell

    private _scrollBarRef: VendorScrollbar | null

    private _rowRefs: Array<HTMLElement> = []

    constructor(props: TableProps<T>) {
        super(props)
        bindMethodsToContext(this)
    }

    componentDidUpdate() {
        if (this._scrollBarRef) {
            this.handleFocusRow(this._scrollBarRef)
            this.handleScroll(this._scrollBarRef)
        }
    }

    handleFocusRow(e: any) {
        const { getFocusedRowIndex, data } = this.props
        if (!getFocusedRowIndex) {
            return
        }

        const container: HTMLElement = e.target || e.view
        const index = data ? getFocusedRowIndex(data) : 0

        if (index >= 0 && this._rowRefs.length) {
            const focusedRow = this._rowRefs[index]

            if (focusedRow) {
                const top = focusedRow.offsetTop
                const height = focusedRow.offsetHeight
                const containerHeight = container.offsetHeight + (container.style.marginBottom ? parseInt(container.style.marginBottom) : 0)
                if (top + height > container.scrollTop + containerHeight) {
                    container.scrollTop = top + height - containerHeight
                }
                if (top < container.scrollTop) {
                    container.scrollTop = top
                }
            }
        }
    }

    handleScroll(e: any) {
        const { onScrollBottom } = this.props
        if (!onScrollBottom) {
            return
        }

        const el = e.target || e.view

        if (el.clientHeight + el.scrollTop >= el.scrollHeight - 20) {
            onScrollBottom()
        }
    }

    handleRowRef(ref: HTMLElement | null) {
        if (ref) {
            this.addRowRef(ref)
        }
    }

    handleAnchorRowRef(ref: HTMLAnchorElement) {
        this.addRowRef(ref)
    }

    addRowRef = (ref: HTMLAnchorElement | HTMLElement) => {
        if (!this._rowRefs.some((x) => x == ref)) {
            this._rowRefs.push(ref)
        }
    }

    renderHead() {
        const { columns } = this.props
        if (!columns.filter((x) => !!x.props.children).length) {
            return
        }

        return (
            <div className="fancy-list__head">
                {columns.map((column, idx) => {
                    const className = `fancy-list__block ${column.props.className || ""}`
                    return (
                        <span className={className} key={idx}>
                            {column.props.children}
                        </span>
                    )
                })}
            </div>
        )
    }

    renderBody() {
        const { scrollable } = this.props

        if (scrollable) {
            return (
                <div className="fancy-list__body">
                    <Scrollbar onScroll={this.handleScroll.bind(this)} onRef={(el) => (this._scrollBarRef = el)}>
                        <div
                            style={{ position: "relative" }}
                            className={`${this.props.className ? `${this.props.className}_body` : "fancy-list__inner"}`}
                        >
                            {this.renderRows()}
                        </div>
                    </Scrollbar>
                </div>
            )
        }

        return <div className="fancy-list__body">{this.renderRows()}</div>
    }

    renderRows() {
        const { data, groupBy, renderGroupHead, renderGroupFooter } = this.props

        if (!data) {
            return
        }

        if (!groupBy) {
            return data.map(this.renderRow.bind(this))
        }

        const groups: Array<{ item: ReactText; list: Array<T> }> = []
        data.forEach((item) => {
            const group = groupBy(item)
            let entry = groups.findFirst((x) => equals(x.item, group))
            if (!entry) {
                entry = { item: group, list: [] }
                groups.push(entry)
            }
            entry.list.push(item)
        })

        // const groups = data.groupBy(groupBy)
        return groups.map((group, idx) => {
            return (
                <div className="fancy-list__group" key={idx}>
                    <span className="fancy-list__title">{renderGroupHead ? renderGroupHead(group.item) : group.item}</span>
                    {group.list.map(this.renderRow.bind(this))}
                    {!!renderGroupFooter && <span className="fancy-list__footer">{renderGroupFooter(group.item)}</span>}
                </div>
            )
        })
    }

    handleClickRow(data: T, e: MouseEvent) {
        e && e.stopPropagation()
        this.props.onClickRow?.(data)
    }

    handleDoubleClickRow(data: T, e: MouseEvent) {
        e && e.stopPropagation()
        this.props.onDoubleClickRow?.(data)
    }

    renderRow(data: T, rowIndex: number): any {
        const { columns, getRowClassName, getRowLink, getRowStyle, getRowKey } = this.props

        const key = getRowKey ? getRowKey(data, rowIndex) : rowIndex
        const className = `fancy-list__item ${getRowClassName ? getRowClassName(data, rowIndex) || "" : ""}`
        const onClick = this.handleClickRow.bind(this, data)
        const onDoubleClick = this.handleDoubleClickRow.bind(this, data)
        const style = getRowStyle ? (getRowStyle(data, rowIndex) as any) : undefined

        const rowChildren = columns.map((col, colIdx) => {
            const { renderItemContent } = col.props
            const cellElement = renderItemContent(data, rowIndex)
            const cellTitle = cellElement.props.title || ""
            const overflowClass = cellTitle ? "fancy-list__block-overflow" : ""
            const cellClassName = `fancy-list__block ${col.props.className || ""} ${cellElement.props.className || ""} ${overflowClass}`

            return (
                <span title={cellTitle} className={cellClassName} key={colIdx}>
                    {cellElement.props.children}
                </span>
            )
        })

        if (getRowLink) {
            return (
                <Link
                    key={key}
                    className={className}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    to={getRowLink(data, rowIndex)}
                    style={style}
                    innerRef={this.handleAnchorRowRef.bind(this)}
                >
                    {rowChildren}
                </Link>
            )
        }

        return (
            <div key={key} className={className} onClick={onClick} onDoubleClick={onDoubleClick} style={style} ref={this.handleRowRef.bind(this)}>
                {rowChildren}
            </div>
        )
    }

    render() {
        const className = `fancy-list ${this.props.scrollable ? "fancy-list--scrollable" : ""} ${this.props.className || ""}`
        return (
            <div className={className}>
                {this.renderHead()}
                {this.renderBody()}
            </div>
        )
    }
}

import { Box, Popover, PopoverActions, PopoverProps, styled } from "@mui/material"
import { IconButton, Typography } from "@tm/components"
import { PropsWithChildren, useLayoutEffect, useRef, useState } from "react"
import { ResizableContainer, Size } from "./Resizable"
import { ZoomIcon } from "../ImageViewer/components/Actions/ZoomIcon"

type ResizablePopoverProps = PopoverProps & {
    title?: string
    onResizeStop?(size: Size): void
    onResize?(): void
    onClose?(): void
}

const PopoverHeaderBar = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey["400"]}`,
}))

export function ResizablePopover({
    title,
    onResize,
    onResizeStop,
    onClose,
    children,
    ...popoverProps
}: PropsWithChildren<ResizablePopoverProps>): JSX.Element | null {
    const [fullscreen, setFullscreen] = useState<boolean>(false)
    const popoverActionRef = useRef<PopoverActions>(null)

    useLayoutEffect(() => {
        requestAnimationFrame(() => {
            onResize?.()
            popoverActionRef?.current?.updatePosition()
        })
    })

    if (!popoverProps.open) {
        return null
    }

    function handleFullscreenToggle() {
        setFullscreen((value) => !value)
    }

    function handleClose() {
        setFullscreen(false)
        onClose?.()
    }

    return (
        <Popover
            {...popoverProps}
            anchorPosition={fullscreen ? { left: 8, top: 16 } : popoverProps.anchorPosition}
            anchorReference={fullscreen ? "anchorPosition" : popoverProps.anchorReference}
            action={popoverActionRef}
            onClose={onClose}
        >
            <ResizableContainer onResize={onResize} onResizeStop={onResizeStop} fullscreen={fullscreen}>
                <PopoverHeaderBar>
                    {title && (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", paddingLeft: "1em" }}>
                            <Typography>{title}</Typography>
                        </Box>
                    )}
                    <Box>
                        <IconButton className="maximize-btn" onClick={handleFullscreenToggle}>
                            <ZoomIcon name={!fullscreen ? "fullscreen" : "fullscreen-exit"} />
                        </IconButton>
                        {onClose && (
                            <IconButton className="close-btn" onClick={handleClose}>
                                <ZoomIcon name="close" />
                            </IconButton>
                        )}
                    </Box>
                </PopoverHeaderBar>
                {children}
            </ResizableContainer>
        </Popover>
    )
}

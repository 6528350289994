import { Article } from "@tm/models"

export const isSameArticle = (oldArticle: Article, newArticle: Article) => {
    if (!oldArticle || !newArticle) {
        return
    }
    return (
        oldArticle?.supplierArticleNo === newArticle?.supplierArticleNo &&
        oldArticle?.supplier?.id === newArticle?.supplier?.id &&
        oldArticle?.productGroup?.id === newArticle?.productGroup?.id
    )
}

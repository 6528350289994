import { useMemo } from "react"
import { UserModuleType } from "@tm/models"
import { useUser } from "../../user"

export function useAddToBasketModuleParameters() {
    const { userContext } = useUser()

    return useMemo(() => {
        const addToBasketModule = userContext.modules?.find((x) => x.type === UserModuleType.AddToBasket)
        return {
            addToNextBasket: addToBasketModule?.parameters?.find((x) => x.key === "AddToNextBasket")?.value === "1",
            showBasketButton: addToBasketModule?.parameters?.find((x) => x.key === "ShowBasketButton")?.value === "1",
            showCostEstimationButton: addToBasketModule?.parameters?.find((x) => x.key === "ShowCostEstimationButton")?.value === "1",
            sendArticlesPostMessage: addToBasketModule?.parameters?.find((x) => x.key === "SendArticlesPostMessage")?.value === "1",
            sendRepairTimesPostMessage: addToBasketModule?.parameters?.find((x) => x.key === "SendRepairTimesPostMessage")?.value === "1",
        }
    }, [userContext])
}

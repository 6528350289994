import { useLocalization } from "@tm/localization"
import { Box } from "@mui/material"
import { ChangeEvent } from "react"
import { Typography } from "../../../generics/typographie"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { FormControlLabel } from "../../../generics/FormControlLabel"
import { Checkbox } from "../../../generics/Checkbox"
import EmailEntry from "./EmailEntry"
import { EmailActions, EmailState } from "../hooks/useEmailState"

type EmailListProps = {
    emailState: EmailState
    emailActions: EmailActions
}

export function EmailList({ emailState, emailActions }: EmailListProps) {
    const { toggleBCCSend, emailChanged, handleAddEmail } = emailActions
    const { showBCCCheckbox, emails } = emailState
    const { translateText } = useLocalization()

    const handleCheckToggle = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        toggleBCCSend?.(checked)
    }

    return (
        <Box display="flex" flexDirection="column" gap="0.5em" minHeight="7.5em">
            <Typography variant="body1" fontWeight="medium">
                {translateText(13777)}
            </Typography>
            <Box display="grid" gridTemplateColumns="1fr 1fr">
                <Box display="flex" flexDirection="column" gap="0.5em">
                    {emails.map((email) => {
                        return <EmailEntry key={email.id} id={email.id} onEmailModified={emailChanged} startingEmail={email.email} />
                    })}
                </Box>
                <Box display="flex" justifyContent="flex-start">
                    <Button
                        variant="outlined"
                        size="medium"
                        startIcon={<Icon name="add" />}
                        onClick={handleAddEmail}
                        sx={{ marginLeft: "0.2em", marginRight: "0.2em", alignSelf: "end" }}
                    />
                </Box>
            </Box>
            {showBCCCheckbox && <FormControlLabel control={<Checkbox onChange={handleCheckToggle} />} label={translateText(13824)} />}
        </Box>
    )
}

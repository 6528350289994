import Bowser from "bowser"
import { TmaSender } from "../tma-sender"
import { LoginType, LoggedInRequest } from "./models"
import { TmaEvent, BaseTrackingModel } from "../../models/models"
import { checkNetworkType, getBrowserFeatures, getGraphicCard } from "./helper"

export class LoggedIn extends TmaSender<LoggedInRequest> implements TmaEvent {
    key = "LOGGED_IN"

    constructor() {
        // when extending tma sender we need to set the endpoint
        super("/logins/loggedin")
        this.requestBody = {}
    }

    handle = (trackingData: BaseTrackingModel<string>): Promise<void> | void => {
        if (trackingData.tmaEvent && trackingData.tmaEvent.toLocaleLowerCase() != this.key.toLocaleLowerCase()) {
            return
        }

        if (trackingData.payload) {
            this.requestBody.loginType = trackingData.payload == "hidden" ? LoginType.hidden : LoginType.form
        }

        const browser = Bowser.getParser(window.navigator.userAgent)
        const features = getBrowserFeatures()

        this.requestBody.clientInfo = {
            ApplicationVersion: (window as any).__version || "",
            Browser: navigator.userAgent, //  https://jira.dvse.de/browse/NEXT-29801 (comments)
            BrowserVersion: browser.getBrowserVersion(),
            CookiesEnabled: features.allowsCookies,
            ScreenHeight: features.desktop.height,
            ScreenWidth: features.desktop.width,
            ViewportHeight: features.window.height,
            ViewportWidth: features.window.width,
            OperatingSystem: browser.getOSName(),
            OperatingSystemVersion: browser.getOSVersion(),
            JavascriptEnabled: true,

            DotNetFrameworkVersion: undefined,
            DriveSpaceFree: undefined,
            DriveSpaceTotal: undefined,
            GraphicCard: getGraphicCard(),
            NetworkType: checkNetworkType(),
            ProcessorType: undefined, // we can get logical cores
            RamFree: undefined,
            RamTotal: undefined, // we can get an approximately value, in my case: i have 20gb ram but it will show me 8gib
        }

        if (window.tmJSShellMeta) {
            this.requestBody.shellMeta = window.tmJSShellMeta
        }

        if (this.requestBody.clientInfo && this.requestBody.loginType !== undefined) {
            return this.sendEvent()
        }
    }
}

import { styled } from "@mui/material/styles"
import { Checkbox as MuiCheckBox, CheckboxProps } from "@mui/material"

const CheckboxIcon = styled("span")<{ size: number }>(({ size, theme }) => ({
    borderRadius: theme.radius?.default,
    width: size,
    height: size,
    boxShadow: "inset 0 0 0 1px #c0c0c0, inset 0 -1px 0 #c0c0c0",
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
    },
    "input:hover ~ &": {
        boxShadow: `inset 0 0 0 1px${theme.palette.primary.main}`,
        backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
    },
}))

const CheckedIcon = styled(CheckboxIcon)(({ size, theme }) => ({
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
        display: "block",
        width: size,
        height: size,
        backgroundImage: `${
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23"
        }${theme.palette.primary.main.replace("#", "")}'/%3E%3C/svg%3E")`,
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: "#f5f8fa",
    },
}))

const StyledCheckbox = styled(MuiCheckBox)(({ theme }) => ({
    padding: "4px 8px",
    "&:hover": {
        bgcolor: "transparent",
        span: {
            boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}, inset 0 -1px 0 ${theme.palette.primary.main}`,
        },
    },
}))

// Inspired by blueprintjs
export function Checkbox(props: CheckboxProps) {
    const size = props.size === "small" ? 16 : 24
    return <StyledCheckbox disableRipple color="default" checkedIcon={<CheckedIcon size={size} />} icon={<CheckboxIcon size={size} />} {...props} />
}

import { memo, useEffect, useMemo } from "react"
import { Dropdown } from "../.."
import { ButtonLayout } from "../../button"
import DecimalAmountFieldInput from "./decimal-amountfield-input"
import { DecimalAmountFieldItem } from "./decimal-amountfield-item"
import { useDecimalItems } from "../useDecimalItems"

export type DecimalAmountItem = {
    value: number
    unit?: string
    stepSize?: number
    inputPrecision?: number
    maxAmount: number
    minAmount: number
}

export type DecimalAmountFieldProps = {
    className?: string
    value?: number
    unit?: string
    maxAmount?: number
    minAmount?: number
    inputPrecision?: number
    disabled?: boolean
    layout?: Array<ButtonLayout>
    onChange?: (item: DecimalAmountItem) => void
}

export const DecimalAmountField = memo<DecimalAmountFieldProps>((props) => {
    useEffect(() => {
        if (!props.disabled && !props.onChange) {
            console.warn("DecimalAmountField: onChange prop has to be set!")
        }
    }, [])

    let maxAmount = props.maxAmount || 9999
    const minAmount = props.minAmount || 0
    const division = 1

    if (division > maxAmount) {
        maxAmount = division
    }

    const { items } = useDecimalItems(maxAmount, minAmount, division, props.unit, props.inputPrecision)

    function createItem(value: number): DecimalAmountItem {
        const { unit, inputPrecision } = props

        return {
            value,
            unit,
            stepSize: 1,
            inputPrecision: inputPrecision || 2,
            maxAmount,
            minAmount,
        }
    }
    const activeItem = useMemo(() => {
        if (items?.length) {
            const activeItem = items.find((item) => item.value === props.value)
            return activeItem || (props.value && createItem(props.value)) || items[0]
        }
    }, [items, props.value])

    useEffect(() => {
        if (activeItem && activeItem.value !== props.value && props.onChange) {
            props.onChange(activeItem)
        }
    }, [activeItem?.value, props.value])

    if (!items?.length) {
        return null
    }

    return (
        <Dropdown
            className={`amount-field ${props.className || ""}`}
            amountItemsToShow={5}
            items={items}
            itemView={DecimalAmountFieldItem}
            inputView={DecimalAmountFieldInput}
            value={activeItem}
            layout={[...(props.layout || []), "iconRight"]}
            disabled={props.disabled}
            onChange={props.onChange}
        />
    )
})

import { styled, Theme, TypographyProps } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ErpPrice, PriceType } from "@tm/models"
import { CSSProperties } from "react"
import { Typography } from "../../../generics/typographie"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

export function mapPriceColor(type: PriceType, theme: Theme): string {
    switch (type) {
        case PriceType.Strikethrough:
            return theme.palette.primary.main
        case PriceType.Purchase:
            return theme.palette.primary.main
        default:
            return ""
    }
}

export function mapPriceFontWeight(type: PriceType): string | number {
    switch (type) {
        case PriceType.Purchase:
            return "bold"
        default:
            return 600
    }
}

export function mapPriceVariant(type: PriceType): TypographyProps["variant"] {
    switch (type) {
        case PriceType.Strikethrough:
            return "label"
        default:
            return "body2"
    }
}

type StyledPriceProps = {
    priceCustomization?: CSSProperties
    priceType: PriceType
}

const StyledPriceTypography = styled(Typography, {
    shouldForwardProp: (prop: string) => !["priceCustomization", "priceType"].includes(prop),
})<StyledPriceProps>(({ theme, priceCustomization, priceType }) => ({
    textAlign: "right",
    whiteSpace: "nowrap",
    lineHeight: 1.2,
    color:
        priceCustomization?.color ??
        ((priceType === PriceType.Purchase && theme.overwrites?.components?.partListV2?.articleItem?.erpContainer?.purchasePriceColor) ||
            mapPriceColor(priceType, theme)),
    fontWeight: priceCustomization?.fontWeight ?? mapPriceFontWeight(priceType),
    textDecoration: priceCustomization?.textDecoration,
    ...(priceType === PriceType.Strikethrough &&
        !priceCustomization?.textDecoration && {
            textDecoration: "line-through",
        }),
    fontSize: priceCustomization?.fontSize,
    fontStyle: priceCustomization?.fontStyle,
}))

type PriceValueProps = {
    price: ErpPrice
    variant?: TypographyProps["variant"]
}

export function PriceValue({ price, variant }: PriceValueProps) {
    const { currency } = useLocalization()

    const prices = useDefaultArticleItemState((x) => x.options.erpDataCustomization?.prices)
    const priceCustomization = prices?.[price.type]

    return (
        <StyledPriceTypography variant={variant ?? mapPriceVariant(price.type)} priceCustomization={priceCustomization} priceType={price.type}>
            {currency(price.value, price.currencySymbol)}
        </StyledPriceTypography>
    )
}

import { Button } from "@tm/components"

type Props = {
    productGroup?: string
    onProductGroupClick?: () => void
}

export function CustomersAlsoBoughtButton({ productGroup, onProductGroupClick }: Props) {
    return (
        <Button onClick={onProductGroupClick} sx={{ width: "fit-content", padding: "1em" }}>
            {productGroup}
        </Button>
    )
}

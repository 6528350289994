export default class CreateKey {
    public static forRegisteredActionsStorage(actionName: string, params: any) {
        let key = actionName

        switch (typeof params) {
            case "function": {
                key += `|${params.toString()}`
                break
            }
            case "string":
            case "number": {
                key += `|${params}`
                break
            }

            case "object": {
                key += Array.isArray(params)
                    ? params.map((item) => CreateKey.forRegisteredActionsStorage("", item)).join("|")
                    : getKeysFromObject(params)
                break
            }

            default: {
                break
            }
        }

        return key
    }
}
const getKeysFromObject = (paramObject: any) => {
    let key = ""
    for (const objKey in paramObject) {
        if (paramObject.hasOwnProperty(objKey)) {
            const value = paramObject[objKey]
            key += `|${objKey}:${CreateKey.forRegisteredActionsStorage("", value)}`
        }
    }
    return key
}
